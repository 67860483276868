import { useState, useCallback } from "react"
import { IPoint } from "../../interfaces"
import { useCesiumOnLeftClick } from "../useCesiumOnClick"
import { CesiumClickEvent } from "../models/CesiumClickEvent"
import { airscopeColor } from "../../helpers/colours"
import * as Cesium from "cesium"

export const usePointSelector = (maxPoints: number = -1) => {
  const [points, setPoints] = useState([] as IPoint[])

  const clearPoints = useCallback(() => setPoints([]), [])
  const onLeftClickHandler = useCallback(
    (event: CesiumClickEvent) => {
      const position = event.pickPosition()
      if (!position) {
        return
      }
      const pickedList = event.drillPick()

      for (const object of pickedList) {
        if (object.id && object.id._id && object.id._id.startsWith("point-")) {
          return
        }
      }

      setPoints(points => {
        const uid = Math.random()
          .toString(36)
          .slice(2)
        const lastPoint = points[points.length - 1]

        const point: IPoint = {
          position,
          id: "point-" + uid,
          name: "Point " + uid,
          color: airscopeColor,
          distance: lastPoint
            ? Cesium.Cartesian3.distance(position.cartesian3, lastPoint.position.cartesian3)
            : null,
        }

        if (maxPoints === -1 || points.length < maxPoints) {
          return [...points, point]
        } else {
          return [point]
        }
      })

      event.viewer.scene.requestRender()
    },
    [maxPoints]
  )

  useCesiumOnLeftClick(onLeftClickHandler)

  return { points, setPoints, clearPoints }
}
