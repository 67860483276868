import axios from "axios"
import { apiHostname, apiSnapshotsCreate } from "./constants"

export interface APIError {
  error: object
  shortMessage: string
  longMessage: string
  variables: object
  tags: object
  status?: number
  statusText?: string
}

type FetchMethod = "GET" | "POST" | "DELETE" | "PUT"

interface IRequestObject {
  url: string
  method: FetchMethod
  responseMode?: "navigate" | "same-origin" | "no-cors" | "cors" | undefined
  headers?: Headers
}

export function isError<T>(response: T | APIError): response is APIError {
  // return (<APIError>response).error !== undefined
}

export function isAuthError<T>(response: T | APIError): response is APIError {
  const isError = (<APIError>response).status === 401

  if (isError) {
    window.location.reload()
  }

  return isError
}

const serialiseObject = (obj: object): string => {
  let pairs: string[]
  pairs = []
  for (const prop in obj) {
    if (!obj.hasOwnProperty(prop)) {
      continue
    }
    if (Object.prototype.toString.call(obj[prop]) === "[object Object]") {
      pairs.push(serialiseObject(obj[prop]))
      continue
    }
    pairs.push(prop + "=" + obj[prop])
  }
  return pairs.join("&")
}

export async function getRequest(endpoint: string) {
  axios.defaults.withCredentials = true
  const response = await axios({
    method: "GET",
    url: "/api/models/all",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  }).catch(error => {
    console.error(error)
  })
  return { ...response }
}

class API {
  version: number = 1

  headers = new Headers({
    "Content-type": `application/vnd.monocular+json; version=${this.version} charset=UTF-8`,
    "X-API": `v${this.version}`,
    "X-Requested-With": "XMLHttpRequest",
  })

  constructor(apiVersion: number) {
    this.version = apiVersion
  }

  async request<T>(
    { method, url, responseMode, headers }: IRequestObject,
    payload?: object
  ): Promise<T | APIError> {
    if (url.indexOf("http") === -1) url = this.generateURL(url)

    const token: string = localStorage.getItem("auth")
    const JSONPayload = JSON.stringify(payload)
    axios.defaults.withCredentials = true
    return await axios(url, {
      method,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      data: JSONPayload,
      withCredentials: true,
    })
      .then(response => {
        return response.data
      })
      .catch(err => {
        return {
          error: err,
          shortMessage: "JSON Error",
          longMessage: "Server Error",
          variables: {},
          tags: {},
          status: err.status,
          statusText: err.statusText,
        }
      })
  }

  private generateURL(url: string) {
    url = apiHostname + "/api" + url
    return url
  }

  async form<T>(url: string, payload: object, files: File[]): Promise<T | APIError> {
    url = this.generateURL(url)

    const formData = new FormData()
    for (let x = 0; x < files.length; x++) {
      formData.append("file" + x, files[x])
    }
    formData["payload"] = JSON.stringify(payload)

    const response = await fetch(`${url}`, {
      method: "post",
      headers: this.headers,
      mode: "cors",
      body: formData,
    })

    try {
      const json = await response.json()
      if (isError(json)) {
        return json as APIError
      }

      return json as T
    } catch (err) {
      return {
        error: err,
        shortMessage: "JSON Error",
        longMessage: "Server Error",
        variables: {},
        tags: {},
      }
    }
  }

  async put<T>(url: string, payload: object): Promise<T | APIError> {
    return this.request<T>(
      {
        url,
        method: "PUT",
        responseMode: "cors",
      },
      payload
    )
  }

  async delete<T>(url: string): Promise<T | APIError> {
    const token: string = localStorage.getItem("auth")
    const headers = {
      "content-type": "application/x-www-form-urlencoded",
    }
    axios.defaults.withCredentials = true
    return axios
      .get(apiHostname + "/api" + url, {
        headers,
        withCredentials: true,
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return error
      })
  }

  async post<T>(url: string, payload: object): Promise<T | APIError> {
    return this.request<T>({ url, method: "POST", responseMode: "cors" }, payload)
  }

  async get<T>(url: string, params?: object): Promise<T | APIError> {
    let urlParams = ""
    if (params) {
      urlParams = "?" + serialiseObject(params)
    }
    return this.request<T>({ url: url + urlParams, method: "GET", responseMode: "cors" })
  }

  async upload<T>(url: string, data: FormData): Promise<T | APIError> {
    axios.defaults.withCredentials = true
    const response = axios(apiHostname + "/api" + url, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      withCredentials: true,
      method: "POST",
      data,
    }).then(response => {
      return response.data
    })

    // const response = await fetch(`${apiHostname}/${url}`, {
    //   method: "POST",
    //   body: data,
    //   mode: "cors"
    // })
    return await response
  }
}

export const api = new API(1)
