import { library as IconLibrary } from "@fortawesome/fontawesome-svg-core"
import {
  faQuestion,
  faPencil,
  faBars,
  faPlusHexagon,
  faSignOutAlt,
  faCircle,
  faEye,
  faEyeSlash,
  faSearch,
  faProjectDiagram,
  faTruck,
  faUser,
  faBriefcaseMedical,
  faChevronDown,
  faChevronUp,
  faTrash,
  faEnvelope,
  faKey,
  faVoteNay,
  faVoteYea,
  faBullseye,
  faHome,
  faRuler,
  faTimes,
  faRulerHorizontal,
  faRulerTriangle,
  faCameraAlt,
  faImages,
  faMap,
  faMapMarker,
  faMapMarkerTimes,
  faSun,
  faUndo,
  faRedo,
  faCamera,
  faChevronDoubleLeft,
  faLock,
  faCreditCard,
  faHistory,
  faUsers,
  faPuzzlePiece,
  faBullhorn,
  faWarehouseAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faTv,
  faToolbox,
  faBookmark,
  faDownload,
  faShare,
  faPlaneDeparture,
  faPlusOctagon,
  faUpload,
  faFile,
  faLayerPlus,
  faLayerGroup,
  faTriangle,
  faWrench,
  faCloudDownload,
  faPlus,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faArchive,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faTasks,
  faCopy,
  faLocation,
  faAngleLeft,
  faPause,
  faShareAlt,
  faPlay,
  faStreetView,
  faSearchPlus,
  faGlobeAsia,
  faImage,
  faWindowRestore,
  faWindowClose,
} from "@fortawesome/pro-solid-svg-icons"
import { EntityCollection } from "cesium"
import { ISnapshot } from "../interfaces"
import * as Cesium from "cesium"

export const loadIcons = () => {
  IconLibrary.add(
    faQuestion,
    faPencil,
    faBars,
    faPlusHexagon,
    faSignOutAlt,
    faCircle,
    faEye,
    faEyeSlash,
    faSearch,
    faProjectDiagram,
    faTruck,
    faUser,
    faBriefcaseMedical,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faTrash,
    faEnvelope,
    faKey,
    faVoteNay,
    faVoteYea,
    faBullseye,
    faHome,
    faRuler,
    faTimes,
    faRulerHorizontal,
    faRulerTriangle,
    faCameraAlt,
    faImages,
    faMap,
    faMapMarker,
    faMapMarkerTimes,
    faSun,
    faUndo,
    faRedo,
    faCamera,
    faChevronDoubleLeft,
    faLock,
    faCreditCard,
    faHistory,
    faUsers,
    faProjectDiagram,
    faPuzzlePiece,
    faBullhorn,
    faWarehouseAlt,
    faExclamationCircle,
    faExclamationTriangle,
    faTv,
    faToolbox,
    faBookmark,
    faDownload,
    faShare,
    faPlaneDeparture,
    faPaperPlane,
    faSearch,
    faPlusOctagon,
    faUpload,
    faFile,
    faLayerPlus,
    faLayerGroup,
    faArchive,
    faTriangle,
    faWrench,
    faCloudDownload,
    faPlus,
    faMinus,
    faArrowLeft,
    faArrowRight,
    faExclamationCircle,
    faDownload,
    faTasks,
    faCopy,
    faLocation,
    faAngleLeft,
    faShareAlt,
    faStreetView,
    faPause,
    faPlay,
    faSearchPlus,
    faGlobeAsia,
    faImage,
    faWindowRestore,
    faWindowClose
  )
}

export function assertNonNull<T>(x: T | null | undefined): T {
  if (x === null || x === undefined) {
    throw new Error("non-null assertion failed")
  } else {
    return x
  }
}

export function imagify(rawsvg: string) {
  const svg = "data:image/svg+xml;base64," + window.btoa(rawsvg)

  const exclImage = new Image()
  exclImage.src = svg

  return exclImage
}

export const isEntityCollection = (
  ents: Cesium.EntityCollection | Cesium.Entity[]
): ents is Cesium.EntityCollection => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return (<EntityCollection>ents).add !== undefined
}

export const toTitleCase = (str: string): string => {
  return str.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function validateEmail(email: string): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function getDomainFromEmail(email: string): string {
  return email.replace(/.*@/, "")
}

export function fileSizeToHuman(bytes: number): string {
  const prefixes = ["B", "kB", "MB", "GB", "TB"]
  let prefixIdx = 0

  while (bytes > 1024) {
    bytes /= 1024.0
    prefixIdx++
  }

  return `${bytes.toFixed(2)} ${prefixes[prefixIdx]}`
}

export function mocStateToColor(stateString: string): string {
  switch (stateString) {
    case "proposed":
      return "#FF4136"
    case "approved":
      return "#FFB700"
    case "completed":
      return "#31A722"
    default:
      return "#FAA21B"
  }
}

export function latitudeDecimalToString(decimal: number) {
  const { degrees, minutes, seconds } = degreesDecimalToDegrees(decimal)
  const direction = degrees >= 0 ? "N" : "S"
  return `${Math.abs(degrees)}° ${Math.abs(minutes)}′ ${Math.abs(seconds).toFixed(5)}″ ${direction}`
}

export function longitudeDecimalToString(decimal: number) {
  const { degrees, minutes, seconds } = degreesDecimalToDegrees(decimal)
  const direction = degrees >= 0 ? "E" : "W"
  return `${Math.abs(degrees)}° ${Math.abs(minutes)}′ ${Math.abs(seconds).toFixed(5)}″ ${direction}`
}

export function degreesDecimalToDegrees(decimal: number) {
  const degrees = Math.trunc(decimal)
  const minutes = Math.trunc(60 * Math.abs(degrees - decimal))
  const seconds = 3600 * Math.abs(degrees - decimal) - 60 * minutes

  return { degrees, minutes, seconds }
}

export function distanceToText(distance: number) {
  if (distance >= 1000.0) {
    return (distance / 1000.0).toFixed(2) + " km"
  } else if (distance >= 1.0) {
    return distance.toFixed(2) + " m"
  } else if (distance >= 0.01) {
    return (distance * 100).toFixed(2) + " cm"
  }

  return (distance * 100 * 10).toFixed(1) + " mm"
}
export function getBaseURL() {
  return (
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "")
  )
}

export function getShareURL(snapshot: ISnapshot) {
  return getBaseURL() + "/bookmark/" + snapshot.siteId + "/" + snapshot.id
}

export function pasteAsPlainText(event: any) {
  const pasted = event.clipboardData.getData("text/plain")
  const selection = window.getSelection()
  if (!selection || !selection.rangeCount) {
    return
  }
  selection.deleteFromDocument()
  selection.getRangeAt(0).insertNode(document.createTextNode(pasted))
  event.preventDefault()
}

export const saveAs = (blob: Blob, filename: string) => {
  const blobUrl = URL.createObjectURL(blob)

  const anchorEl = document.createElement("a")
  document.body.appendChild(anchorEl)
  anchorEl.href = blobUrl
  anchorEl.download = filename
  anchorEl.click()

  // clean up
  window.URL.revokeObjectURL(filename)
  anchorEl.remove()
}

export const flattenObject = (obj: any) => {
  const flattened = {}

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]))
    } else {
      // @ts-ignore
      flattened[key] = obj[key]
    }
  })

  return flattened
}
