/* tslint:disable:jsx-no-lambda */
import React from "react"

export const PlaceFilter = (props: any) => {
  return (
    <span
      id={"places-filter-site-container"}
      className={"is-checkradio"}
      title={"Show places for all sites"}
    >
      <input
        id={"places-filter-site"}
        type={"checkbox"}
        name={"places-filter-site"}
        value={"all-sites"}
        checked={props.showAll}
        className={"is-checkradio"}
        onChange={evt => {
          props.setShowAll(evt.target.checked)
        }}
        onClick={evt => {
          // prevent card from collapsing
          evt.stopPropagation()
        }}
      />
      <label
        htmlFor="places-filter-site"
        onClick={evt => {
          evt.stopPropagation()
        }}
      >
        {" "}
        All
      </label>
    </span>
  )
}
