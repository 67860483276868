/* tslint:disable:jsx-no-lambda */
import { Fragment, useCallback, useContext } from "react"
import { Button } from "trunx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAreaSelect } from "../../hooks/useAreaSelect"
import { PointGrabSubmitForm } from "./PointGrabSubmitForm"
import { PointGrabTab } from "./PointGrabTabs"
import { ModalContext } from "../../context"

interface IMinusPlusProps {
  onClick: () => void
  icon: any
}

const MinusPlusButton = (props: IMinusPlusProps) => (
  <Button onClick={props.onClick} className={"pointgrab-button"}>
    <FontAwesomeIcon icon={props.icon} />
  </Button>
)

export const PointGrabTool = (props: any) => {
  const { pointcloud, setSelectedTab } = props
  const {
    attributes,
    updateDimensions,
    updatePosition,
    updateRotation,
    clearArea,
  } = useAreaSelect()
  const { showModal, hideModal } = useContext(ModalContext)

  const showSubmitFormModal = useCallback(() => {
    showModal(() => (
      <PointGrabSubmitForm
        pointcloud={pointcloud}
        selectedArea={attributes}
        onClose={(shouldShowDownloads: boolean) => {
          hideModal()
          if (shouldShowDownloads) {
            setSelectedTab(PointGrabTab.Downloads)
          }
        }}
      />
    ))
  }, [showModal, pointcloud, attributes, hideModal, setSelectedTab])

  return (
    <Fragment>
      <div key={"pg-form-vol-label"} className={"pgformVolumeTitle"}>
        Volume
      </div>
      <div key={"pg-form-vol-value"} className={"pgformVolumeValue"}>
        {attributes.volume.toFixed(2)} m³
      </div>
      <div className={"pointgrab-tool"} key={"pgform"} id={"pgformRoot"}>
        <div className={"column1"} id={"pgformCol1"}>
          <div className={"column-heading"} style={{ height: "11px" }}>
            <span>&nbsp;</span>
          </div>
          <div className={"pgcontainer"} id={"pfformCol1Container"}>
            <div id={"pgformCol1Row1"}>Length:</div>
            <div id={"pgformCol1Row2"}>Width:</div>
            <div id={"pgformCol1Row3"}>Height:</div>
            <div style={{ paddingTop: "12px" }} id={"pgformCol1Row4"}>
              Rotation:
            </div>
          </div>
        </div>
        <div className={"column2"} id={"pgformCol2"}>
          <div className={"column-heading"}>
            <span>Resize</span>
          </div>
          <div className={"pgcontainer"} id={"pfformCol2Container"}>
            <div
              className={"row"}
              style={{
                order: 1,
                flexShrink: 1,
              }}
              id={"pgformCol2Row1"}
            >
              <MinusPlusButton onClick={() => updateDimensions(-0.25, 0, 0)} icon={"minus"} />
              <span className={"translation-value"}>{attributes.dimension.x.toFixed(2)}</span>
              <MinusPlusButton onClick={() => updateDimensions(0.25, 0, 0)} icon={"plus"} />
            </div>
            <div
              className={"row"}
              style={{
                order: 2,
                flexShrink: 1,
              }}
              id={"pgformCol2Row2"}
            >
              <MinusPlusButton onClick={() => updateDimensions(0, -0.25, 0)} icon={"minus"} />
              <span className={"translation-value"}>{attributes.dimension.y.toFixed(2)}</span>
              <MinusPlusButton onClick={() => updateDimensions(0, 0.25, 0)} icon={"plus"} />
            </div>
            <div
              className={"row"}
              style={{
                order: 3,
                flexShrink: 1,
              }}
              id={"pgformCol2Row3"}
            >
              <MinusPlusButton onClick={() => updateDimensions(0, 0, -0.25)} icon={"minus"} />
              <span className={"translation-value"}>{attributes.dimension.z.toFixed(2)}</span>
              <MinusPlusButton onClick={() => updateDimensions(0, 0, 0.25)} icon={"plus"} />
            </div>
          </div>
          <div
            className={"rotation-container"}
            style={{
              order: 4,
            }}
          >
            <MinusPlusButton onClick={() => updateRotation(-10)} icon={"minus"} />
            <span className={"rotation-value"}>{attributes.rotation.toFixed(0) + "°"}</span>
            <MinusPlusButton onClick={() => updateRotation(10)} icon={"plus"} />
          </div>
        </div>
        <div className={"column3"} id={"pgformCol3"}>
          <div className={"column-heading"}>
            <span>Move</span>
          </div>
          <div className={"pgcontainer"} id={"pfformCol3Container"}>
            <div className={"row"} style={{ order: 1 }} id={"pgformCol2Row1"}>
              <MinusPlusButton onClick={() => updatePosition(-0.25, 0, 0)} icon={"arrow-left"} />
              <MinusPlusButton onClick={() => updatePosition(0.25, 0, 0)} icon={"arrow-right"} />
            </div>
            <div className={"row"} style={{ order: 2 }} id={"pgformCol2Row2"}>
              <MinusPlusButton onClick={() => updatePosition(0, -0.25, 0)} icon={"arrow-left"} />
              <MinusPlusButton onClick={() => updatePosition(0, 0.25, 0)} icon={"arrow-right"} />
            </div>
            <div className={"row"} style={{ order: 3 }} id={"pgformCol2Row3"}>
              <MinusPlusButton onClick={() => updatePosition(0, 0, -0.25)} icon={"arrow-left"} />
              <MinusPlusButton onClick={() => updatePosition(0, 0, 0.25)} icon={"arrow-right"} />
            </div>
          </div>
        </div>
      </div>
      <div className={"pgformButtons"}>
        <Button
          className={"clear-selection is-outlined"}
          key={"pgClearSelection"}
          onClick={clearArea}
          isActive={attributes.position != null}
        >
          Clear Selection
        </Button>
        <Button
          className={"export is-primary is-outlined"}
          key={"pgExport"}
          onClick={() => attributes.position != null && showSubmitFormModal()}
          isActive={attributes.position != null}
        >
          Export
        </Button>
      </div>
    </Fragment>
  )
}
