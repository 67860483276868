import * as React from "react"
import LoadingSVG from "../../assets/img/loading.svg"
import SVG from "react-inlinesvg"

interface IProps {
  width?: number
}

export const Loading = (props: IProps) => {
  return (
    <div className="loading" data-testid={"loading..."}>
      <div style={{ width: props.width || 50 }}>
        <SVG src={LoadingSVG} />
      </div>
    </div>
  )
}
