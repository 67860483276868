import { Fragment, useCallback, useContext, useMemo } from "react"
import { Link } from "react-router-dom"
import { Button } from "trunx"
import { FontAwesomeIcon } from "../../helpers/icon"
import SVG from "react-inlinesvg"
import { ModelsContext, ViewerContext } from "../../context"
import { apiHostname } from "../../api/constants"
import HomeIcon from "../../assets/icons/home.svg"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { TooltipConfigs } from "../../constants"

export const SiteSelectorButton = () => {
  const { selectedSite } = useContext(ModelsContext)
  const { flyToHome } = useContext(ViewerContext)

  const backgroundImage = useMemo(() => {
    if (selectedSite && selectedSite.data && selectedSite.data.avatar) {
      return `url(${selectedSite.data.avatar})`
    } else if (selectedSite) {
      return `url(${apiHostname}/files/snapshots/random/site/${selectedSite!.id})`
    }
    return ""
  }, [selectedSite])

  const goHomeHandler = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      event.preventDefault()

      flyToHome()
    },
    [flyToHome]
  )

  return (
    <div className={"site-selector-button"} data-testid="site-selector">
      <Tooltip
        title={<Typography fontSize={TooltipConfigs.FontSize}>Options Menu</Typography>}
        placement="bottom"
        enterDelay={TooltipConfigs.Delay}
        enterNextDelay={TooltipConfigs.Delay}
      >
        <div className={"site-selector-sidebar-button"}>
          <Link to={"/settings"} className={"button is-text"}>
            <FontAwesomeIcon icon={"bars"} size={"lg"} />
          </Link>
        </div>
      </Tooltip>

      {selectedSite ? (
        <Tooltip
          title={<Typography fontSize={TooltipConfigs.FontSize}>Site Selection Window</Typography>}
          placement="bottom"
          enterDelay={TooltipConfigs.Delay}
          enterNextDelay={TooltipConfigs.Delay}
        >
          <div className={"site-name"}>
            <Link to={"/sites"}>
              <Button isText={true} style={{ width: 282 }}>
                <span
                  data-testid="site-selected"
                  className={"site-avatar"}
                  style={{
                    backgroundImage,
                    backgroundSize: "cover",
                    width: 32,
                    height: 32,
                    display: "inline-block",
                    marginRight: 10,
                  }}
                />
                <span className={"site-actual-name"}>{selectedSite!.name}</span>
                <span>
                  <FontAwesomeIcon icon={"chevron-down"} />
                </span>
              </Button>
            </Link>
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          title={<Typography fontSize={TooltipConfigs.FontSize}>Site Selection Window</Typography>}
          placement="bottom"
          enterDelay={TooltipConfigs.Delay}
          enterNextDelay={TooltipConfigs.Delay}
        >
          <div className={"site-name"}>
            <Link to={"/sites"}>
              <Button isText={true}>
                Select Site
                <span style={{ marginLeft: 10 }}>
                  <FontAwesomeIcon icon={"chevron-down"} />
                </span>
              </Button>
            </Link>
          </div>
        </Tooltip>
      )}
      <Tooltip
        title={<Typography fontSize={TooltipConfigs.FontSize}>Home View</Typography>}
        placement="bottom"
        enterDelay={TooltipConfigs.Delay}
        enterNextDelay={TooltipConfigs.Delay}
      >
        <div className={"home-button"} onClick={goHomeHandler}>
          <Button isText={true}>
            <SVG src={HomeIcon} style={{ width: 20, marginTop: 3 }} />
          </Button>
        </div>
      </Tooltip>
    </div>
  )
}
