/* tslint:disable:jsx-no-lambda */
import { useContext } from "react"
import { Button, Section } from "trunx"
import { Route, RouterProps, Switch, withRouter } from "react-router"
import { ErrorBoundary } from "../ErrorBoundary"
import { CreateEditTeam, TeamsManagement } from "./TeamsManagement"
import { FontAwesomeIcon } from "../../helpers/icon"
import { InstanceSettings } from "./InstanceSettings"
import { CreateEditRole, RolesManagement } from "./RolesManagement"
import { CreateEditUser, UsersManagement } from "./UsersManagement"
import { ProfileSecuritySettings } from "./ProfileSecuritySettings"
import { ProfileAccountSettings } from "./ProfileAccountSettings"
import { ProfileNotificationSettings } from "./ProfileNotificationSettings"
import { OrganisationSecuritySettings } from "./OrganisationSecuritySettings"
import { IPermissionsMap, IUser } from "../../interfaces"
import { APIError } from "../../api"
import { SecureConsumer, SecurityContext } from "../../context/SecurityProvider"

export interface ISecurityState {
  loggedIn?: boolean
  user?: IUser | null
  users: IUser[]
  registering?: boolean
  checkingAuth?: boolean
  checkedAuth?: boolean
  loggingIn?: boolean
  error?: APIError | null
  registerError?: APIError | null
  permissions: IPermissionsMap | null
  acceptingDisclaimer?: boolean
  acceptedDisclaimer?: boolean
}

// tslint:disable-next-line:no-empty
const setPassword = () => {}

const SettingsPane = withRouter((props: RouterProps) => {
  const { userData, setUserData } = useContext(SecurityContext)

  return (
    <ErrorBoundary>
      <SecureConsumer>
        <div className={"settings-pane"} data-testid={"settings-pane"}>
          <Section
            onClick={(e: any) => {
              if (e.currentTarget === e.target) {
                props.history.push("/")
              }
            }}
          >
            <Button onClick={() => props.history.push("/")} style={{ float: "right", margin: 10 }}>
              <FontAwesomeIcon icon={"times"} />
            </Button>
            <Switch>
              <Route path={"/settings/instance"} render={() => <InstanceSettings />} />
              <Route
                path={"/settings/organisation/security"}
                render={() => <OrganisationSecuritySettings />}
              />
              <Route
                path={"/settings/profile/security"}
                render={() => (
                  <ProfileSecuritySettings
                    initialModel={{
                      enable2FA: userData.data?.security?.enable2FA,
                      oldPassword: "",
                      newPassword: "",
                      confirmNewPassword: "",
                    }}
                    // @ts-ignore
                    updateSecuritySettings={userData.updateSecuritySettings}
                    setPassword={setPassword}
                  />
                )}
              />
              <Route
                path={"/settings/profile/account"}
                render={() => (
                  <ProfileAccountSettings
                    initialModel={{
                      firstName: userData.firstName,
                      lastName: userData.lastName,
                    }}
                  />
                )}
              />

              <Route
                path={"/settings/profile/notifications"}
                render={() => (
                  <ProfileNotificationSettings
                    initialModel={{
                      featuresEmail: userData.data.subscriptions.featuresEmail,
                      featuresSMS: userData.data.subscriptions.featuresSMS,
                      pointGrabEmail: userData.data.subscriptions.pointGrabEmail,
                      pointGrabSMS: userData.data.subscriptions.pointGrabSMS,
                    }}
                    // @ts-ignore
                    updateNotificationSettings={userData.updateNotificationSettings}
                  />
                )}
              />
              <Route
                exact={true}
                path={"/settings/security/users"}
                render={() => <UsersManagement />}
              />
              <Route
                exact={true}
                path={"/settings/security/users/new"}
                render={() => <CreateEditUser />}
              />
              <Route
                exact={true}
                path={"/settings/security/users/:id/edit"}
                render={() => <CreateEditUser />}
              />

              <Route
                exact={true}
                path={"/settings/security/teams"}
                render={() => <TeamsManagement />}
              />
              <Route
                exact={true}
                path={"/settings/security/teams/new"}
                render={() => <CreateEditTeam />}
              />
              <Route
                exact={true}
                path={"/settings/security/teams/:id/edit"}
                render={() => <CreateEditTeam />}
              />

              <Route
                exact={true}
                path={"/settings/security/roles"}
                render={() => <RolesManagement />}
              />
              <Route
                exact={true}
                path={"/settings/security/roles/new"}
                render={() => <CreateEditRole />}
              />
              <Route
                exact={true}
                path={"/settings/security/roles/:id/edit"}
                render={() => <CreateEditRole />}
              />
            </Switch>
          </Section>
        </div>
      </SecureConsumer>
    </ErrorBoundary>
  )
})

export { SettingsPane }
