import { useCallback } from "react"
import * as THREE from "three"

export class TextureLoader {
  textureLoader: THREE.TextureLoader
  fileLoader: THREE.FileLoader
  url: string
  onProgress: (request: ProgressEvent<EventTarget>) => void
  onError: (event: ErrorEvent) => void
  onLoad: (texture: THREE.Texture) => void
  imageElement: HTMLElement
  objUrl: string
  cancelled: boolean

  constructor(
    url: string,
    onLoad: (texture: THREE.Texture) => void,
    onProgress: (request: ProgressEvent<EventTarget>) => void,
    onError: (event: ErrorEvent) => void
  ) {
    this.textureLoader = new THREE.TextureLoader()
    this.fileLoader = new THREE.FileLoader()
    this.fileLoader.setResponseType("blob")

    this.url = url
    this.onProgress = onProgress
    this.onError = onError
    this.onLoad = onLoad
    this.cancelled = false

    this.start()
  }

  start() {
    this.fileLoader.load(
      this.url,
      this.cacheImage.bind(this),
      this.progressCallback.bind(this),
      this.errorCallback.bind(this)
    )
  }

  cancel() {
    this.cancelled = true
  }

  progressCallback(request: ProgressEvent<EventTarget>) {
    if (!this.cancelled) {
      this.onProgress(request)
    }
  }

  loadCallback(texture: THREE.Texture) {
    if (!this.cancelled) {
      this.onLoad(texture)
    }
  }

  errorCallback(event: ErrorEvent) {
    if (!this.cancelled) {
      this.onError(event)
    }
  }

  cacheImage(blob: any) {
    if (blob.constructor.name === "HTMLImageElement") {
      this.textureLoader.load(
        this.url,
        this.loadCallback.bind(this),
        // tslint:disable-next-line:no-empty
        () => {},
        this.errorCallback.bind(this)
      )
      return
    }

    this.objUrl = URL.createObjectURL(blob)
    this.imageElement = document.createElementNS("http://www.w3.org/1999/xhtml", "img")

    this.imageElement.onload = this.imageLoadFinished.bind(this)

    // @ts-ignore
    this.imageElement.src = this.objUrl
    this.imageElement.style.visibility = "hidden"
    document.body.appendChild(this.imageElement)
  }

  imageLoadFinished() {
    THREE.Cache.add(this.url, this.imageElement)
    URL.revokeObjectURL(this.objUrl)
    document.body.removeChild(this.imageElement)

    this.textureLoader.load(
      this.url,
      this.loadCallback.bind(this),
      // tslint:disable-next-line:no-empty
      () => {},
      this.errorCallback.bind(this)
    )
  }
}

export const useLoadTexture = () => {
  THREE.Cache.enabled = true

  const loadTexture = useCallback((url, onLoad, onProgress, onError) => {
    return new TextureLoader(url, onLoad, onProgress, onError)
  }, [])

  return { loadTexture }
}
