/* tslint:disable:jsx-no-lambda */
import { useState } from "react"
import reformed from "../../lib/reformed"
import { IsRequired, Validate } from "../../lib/reformed/validate"
import { api, isAuthError, isError } from "../../api"
import { toast } from "react-toastify"
import { Field, Input, Textarea } from "trunx"
import { ScreenshotTool } from "./ScreenshotTool"
import { Button, Checkbox, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"

const ValidNumberIfCallback = (
  model: any,
  errors: { [key: string]: string }
): { [key: string]: string } => {
  if (model.callback && !model.phoneNumber) {
    errors["phoneNumber"] = "A phone number is required to receive a call back"
  }

  return errors
}

const Form = (props: any) => {
  const { onClose, bindInput, validation, model, setProperty } = props
  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (validation.isValid()) {
      setSubmitting(true)

      try {
        const response = await api.post(`/users/feedbackform`, model)

        if (isAuthError(response) || isError(response)) {
          throw new Error()
        }

        toast.success(`Thanks! Your feedback has been received.
				If you requested to be contacted, we'll be in touch soon.`)
      } catch (e) {
        toast.error(`It appears the message server is having
				problems sending messages right now. Please try again later.
				Alternatively contact your administrator to provide feedback
				directly.`)
      }

      onClose()
      setSubmitting(false)
    }
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => onClose()} />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Feedback Form</p>
          <IconButton
            className={"close-feedback-form"}
            aria-label="close"
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
        </header>
        <section className="modal-card-body">
          <form onSubmit={e => handleSubmit(e)}>
            <FormLabel component="legend" sx={{ color: "white" }}>
              Feedback type
            </FormLabel>
            <RadioGroup
              className={"buttons-feedback-form"}
              row={true}
              aria-label="feedback type"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="Feature idea"
                control={
                  <Radio
                    checked={model.feedbackType === "idea"}
                    value={"idea"}
                    name={"feedbackType"}
                    onChange={() => setProperty("feedbackType", "idea")}
                  />
                }
                label="Feature Idea"
              />
              <FormControlLabel
                value="Report an issue"
                control={
                  <Radio
                    checked={model.feedbackType === "issue"}
                    value={"issue"}
                    name={"feedbackType"}
                    onChange={() => setProperty("feedbackType", "issue")}
                  />
                }
                label="Report an issue"
              />
              <FormControlLabel
                value="Miscellaneous"
                control={
                  <Radio
                    checked={model.feedbackType === "misc"}
                    value={"misc"}
                    name={"feedbackType"}
                    onChange={() => setProperty("feedbackType", "misc")}
                  />
                }
                label="Miscellaneous"
              />
            </RadioGroup>
            <FormLabel component="legend" sx={{ color: "white" }}>
              Phone number
            </FormLabel>
            <Field
              isHorizontal={true}
              defaultValue={"Phone number"}
              onError={validation.errors["phoneNumber"]}
            >
              <Input addonBefore={"+61"} {...bindInput("phoneNumber")} />
            </Field>
            <FormLabel component="legend" sx={{ color: "white" }}>
              callback
            </FormLabel>
            <FormControlLabel
              value="Feature idea"
              control={
                <Checkbox
                  className={"buttons-feedback-form"}
                  defaultValue="I would like a call back"
                  checked={model.callback}
                  value={"callback"}
                  onChange={() => setProperty("callback", !model.callback)}
                />
              }
              label={"I would like a call back"}
            />
            <FormLabel component="legend" sx={{ color: "white" }}>
              Description
            </FormLabel>
            <Field
              isHorizontal={true}
              defaultValue={"Description"}
              onError={validation.errors["details"]}
            >
              <Textarea {...bindInput("details")} />
            </Field>
            <FormLabel component="legend" sx={{ color: "white" }}>
              Screenshot
            </FormLabel>
            <Field isHorizontal={true} defaultValue={"Screenshot"}>
              <ScreenshotTool onChange={(dataUrl: string) => setProperty("screenshot", dataUrl)} />
              <p className="screenshot-note">
                When taking a screenshot you will be prompted to select the screen or program you
                wish to share. Simply select the screen containing Visualize and click the share
                button.
              </p>
            </Field>

            <div className="submit-container">
              <Button
                variant={"outlined"}
                sx={{ borderColor: "white", color: "white" }}
                type={"submit"}
                isActive={!isSubmitting}
              >
                Submit
              </Button>
            </div>
          </form>
        </section>
      </div>
    </div>
  )
}
const FeedbackForm = reformed()(
  Validate<any, any>(
    IsRequired({
      details: "Please tell us some details regarding your feedback",
    }),
    ValidNumberIfCallback
  )(Form)
)

export { FeedbackForm }
