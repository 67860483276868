/* tslint:disable:jsx-no-lambda */
import React from "react"
import { Tab, Tabs } from "@mui/material"

export enum PointGrabTab {
  Tool,
  Downloads,
}

interface IPointGrabTabs {
  onTabSelect: (tab: PointGrabTab) => void
  selectedTab: PointGrabTab
}

export const PointGrabTabs = (props: IPointGrabTabs) => {
  const [value, setValue] = React.useState(0)
  const { onTabSelect } = props
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const style = {
    color: "white",
    borderBottom: "1px solid white",
    height: 30,
  }

  return (
    <Tabs
      TabIndicatorProps={{ style: { background: "Orange" } }}
      value={value}
      onChange={handleChange}
      className={"point-grab-tabs"}
    >
      <Tab style={style} onClick={() => onTabSelect(PointGrabTab.Tool)} label={"Tool"} />
      <Tab style={style} onClick={() => onTabSelect(PointGrabTab.Downloads)} label={"Downloads"} />
    </Tabs>
  )
}
