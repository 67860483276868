import axios from "axios"
import { apiHostname } from "../../../api/constants"
import { computeTransformWithOrientation, Orientation, Position } from "./Transformation"
import * as Cesium from "cesium"
import { Cesium3DTileset } from "cesium"

export async function getModelsFromJson(modelPath: string) {
  const response = await axios.get(apiHostname + modelPath)
  return response.data?.models
}

export async function positionTileset(
  tileset: Cesium3DTileset,
  position: Position,
  orientation: Orientation
) {
  tileset.root.transform = Cesium.Matrix4.IDENTITY
  tileset.modelMatrix = await computeTransformWithOrientation(position, orientation)
  return tileset
}
