/* tslint:disable:jsx-no-lambda */
import { useState } from "react"
import { Button } from "trunx"
import { Loading } from "../Loading"
import { IRole, ITeam, IUser } from "../../interfaces"

export interface IListBoxItem {
  id: string
  value: string
}

interface IListBoxProps {
  items: Array<IListBoxItem>
  onItemClick: (itemId: string) => void
  highlightedItems: Array<string>
  loading: boolean
}

interface IDualListBoxProps {
  listItems: Array<IListBoxItem>
  selectedItems: Array<IListBoxItem>
  onSelectedItemsChange: (selectedItems: Array<IListBoxItem>) => void
  loading: boolean
  leftHeader?: string
  rightHeader?: string
}

const ListBox = (props: IListBoxProps) => {
  if (props.loading) {
    return (
      <div className={"listbox-container"}>
        <Loading />
      </div>
    )
  }

  return (
    <div className={"listbox-container"}>
      <div>
        {props.items.map(item => {
          const classes = props.highlightedItems.includes(item.id)
            ? "listbox-item selected"
            : "listbox-item"

          return (
            <div key={item.id} className={classes} onClick={() => props.onItemClick(item.id)}>
              {item.value}
            </div>
          )
        })}
      </div>
    </div>
  )
}

function userListTransform(user: IUser) {
  return {
    id: user.id.toString(),
    value: user.lastName + ", " + user.firstName + " (" + user.email + ")",
  }
}

function roleListTransform(role: IRole) {
  return {
    id: role.id,
    value: role.displayName,
  }
}

function teamListTransform(team: ITeam) {
  return {
    id: team.id,
    value: team.displayName,
  }
}

function toggleItemInArray(itemId: string, originalArray: any[], callback: Function) {
  const array = originalArray.slice(0)
  const idx = array.indexOf(itemId)

  if (idx < 0) {
    array.push(itemId)
  } else {
    array.splice(idx, 1)
  }

  callback(array)
}

const DualListBox = (props: IDualListBoxProps) => {
  const [leftHighlighted, setLeftHighlighted] = useState([])
  const [rightHighlighted, setRightHighlighted] = useState([])

  const moveRight = () => {
    const highlightedItems = props.listItems.filter(item => leftHighlighted.includes(item.id))
    const updatedSelectedItems = props.selectedItems.concat(highlightedItems)
    props.onSelectedItemsChange(updatedSelectedItems)
    setLeftHighlighted([])
  }

  const moveLeft = () => {
    const updatedSelectedItems = props.selectedItems.filter(
      item => !rightHighlighted.includes(item.id)
    )
    props.onSelectedItemsChange(updatedSelectedItems)
    setRightHighlighted([])
  }

  const leftItems = props.listItems.filter(
    item => props.selectedItems.findIndex(selItem => selItem.id === item.id) < 0
  )

  return (
    <div className={"dual-listbox-container"}>
      <div className={"dual-listbox-list-container"}>
        {props.leftHeader && <div className={"dual-listbox-list-header"}>{props.leftHeader}</div>}
        <ListBox
          loading={props.loading}
          items={leftItems}
          onItemClick={(itemId: string) =>
            toggleItemInArray(itemId, leftHighlighted, setLeftHighlighted)
          }
          highlightedItems={leftHighlighted}
        />
      </div>
      <div className={"dual-listbox-middle-container"}>
        <Button onClick={() => moveRight()}>&gt;</Button>
        <Button onClick={() => moveLeft()}>&lt;</Button>
      </div>
      <div className={"dual-listbox-list-container"}>
        {props.rightHeader && <div className={"dual-listbox-list-header"}>{props.rightHeader}</div>}
        <ListBox
          loading={props.loading}
          items={props.selectedItems}
          onItemClick={(itemId: string) =>
            toggleItemInArray(itemId, rightHighlighted, setRightHighlighted)
          }
          highlightedItems={rightHighlighted}
        />
      </div>
    </div>
  )
}

export { DualListBox, userListTransform, roleListTransform, teamListTransform }
