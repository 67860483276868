import * as ReactDOM from "react-dom"
import { Component } from "react"

interface ITooltipState {
  tipText: string
  target: Element | null
  clientX: number
  clientY: number
}

class Tooltips extends Component<{}, ITooltipState> {
  constructor(props: {}) {
    super(props)
    this.state = {
      tipText: "",
      target: null,
      clientX: 0,
      clientY: 0,
    }
  }

  componentDidMount() {
    window.addEventListener("mousemove", this.onMouseMove, false)
  }

  componentWillUnmount(): void {
    window.removeEventListener("mousemove", this.onMouseMove, false)
  }

  onMouseMove = (e: MouseEvent) => {
    const target = e.target as HTMLElement

    if (this.state.target) {
      if (this.state.target.contains(target)) {
        this.setState({
          clientX: e.clientX,
          clientY: e.clientY,
        })
        return
      } else {
        this.setState({
          target: null,
        })
        return
      }
    }

    if (target) {
      const tipElement = target.closest("[data-tip]")
      if (tipElement) {
        const tipText = tipElement.getAttribute("data-tip")
        if (tipText) {
          this.setState({
            target: tipElement,
            tipText,
            clientX: e.clientX,
            clientY: e.clientY,
          })
          return
        }
      }
    }
    if (this.state.target) {
      this.setState({ target: null })
    }
  }

  render() {
    const node = document.getElementById("external")
    if (!node) {
      return null
    }
    const { tipText, target } = this.state
    if (!target) {
      return null
    }
    return ReactDOM.createPortal(
      <div
        className={"tooltip"}
        style={{ left: this.state.clientX + 20, top: this.state.clientY - 5 }}
      >
        {tipText}
      </div>,
      node
    )
  }
}

export { Tooltips }
