import * as React from "react"
import { ProjectionSelector } from "../ProjectionSelector"
import { QualitySelector } from "../QualitySelector"
import { VisualizeBrand } from "../VisualizeBrand"
import { RenderIfPermission } from "../../helpers/Permissions"

export const BottomBar = () => (
  <div className={"vis-bottom-nav"}>
    <VisualizeBrand />
    <div id="NearbyPhotoFooter" />

    <div className="viewer-configurators">
      <ProjectionSelector />
      <RenderIfPermission token={"VIEW:ADJUSTQUALITY"} siteSpecific={true}>
        <QualitySelector />
      </RenderIfPermission>
    </div>
  </div>
)
