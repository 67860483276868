import { useEffect, useContext } from "react"
import { airscopeColor, airscopeDashed } from "../helpers/colours"
import { createArcLine } from "../helpers/viewer"
import { ViewerContext } from "../context"
import { circle } from "../assets"
import { useEntityGroups } from "./viewer/useEntityGroups"
import { usePointSelector } from "./viewer/usePointSelector"
import { usePointDistanceCursor } from "./viewer/usePointDistanceCursor"
import * as Cesium from "cesium"

interface IAngleLabel {
  text: string
  position: Cesium.Cartesian3
  offsetPosition?: Cesium.Cartesian2
  isAngleLabel?: boolean
}

export const useThreePointAngleMeasure = () => {
  const { viewersForEach } = useContext(ViewerContext)
  const { datasources } = useEntityGroups()
  const { points, setPoints, clearPoints } = usePointSelector(3)
  usePointDistanceCursor(points)

  useEffect(() => {
    const lines: Cesium.Cartesian3[][] = []
    const labels: IAngleLabel[] = []

    if (points.length >= 2) {
      lines.push([points[0].position.cartesian3, points[1].position.cartesian3])
    }

    if (points.length === 3) {
      const point1 = points[0].position.cartesian3
      const point2 = points[1].position.cartesian3
      const point3 = points[2].position.cartesian3

      const v1 = Cesium.Cartesian3.subtract(point1, point2, new Cesium.Cartesian3())
      const u1 = Cesium.Cartesian3.normalize(v1, new Cesium.Cartesian3())
      const v2 = Cesium.Cartesian3.subtract(point3, point2, new Cesium.Cartesian3())
      const u2 = Cesium.Cartesian3.normalize(v2, new Cesium.Cartesian3())

      const dp = Cesium.Cartesian3.dot(v1, v2)
      const angle =
        (Math.acos(dp / (Cesium.Cartesian3.magnitude(v1) * Cesium.Cartesian3.magnitude(v2))) /
          Math.PI) *
        180

      let scaleLength
      if (Cesium.Cartesian3.magnitude(v2) >= Cesium.Cartesian3.magnitude(v1)) {
        scaleLength = 0.2 * Cesium.Cartesian3.magnitude(v1)
      } else {
        scaleLength = 0.2 * Cesium.Cartesian3.magnitude(v2)
      }

      if (scaleLength > 0.5 && angle <= 90) {
        scaleLength = 0.5
      } else if (scaleLength > 0.2 && angle > 90) {
        scaleLength = 0.2
      }

      const a0 = Cesium.Cartesian3.add(
        point2,
        Cesium.Cartesian3.multiplyByScalar(u2, scaleLength, new Cesium.Cartesian3()),
        new Cesium.Cartesian3()
      )
      const a1 = Cesium.Cartesian3.add(
        point2,
        Cesium.Cartesian3.multiplyByScalar(u1, scaleLength, new Cesium.Cartesian3()),
        new Cesium.Cartesian3()
      )

      lines.push([point2, point3])
      lines.push(createArcLine(a0, a1, point2, (2 / 180) * Math.PI))

      labels.push({
        text: angle.toFixed(1) + "°",
        position: a1,
        isAngleLabel: true,
      })

      labels.push({
        text: Cesium.Cartesian3.magnitude(v1).toFixed(2) + " m",
        position: Cesium.Cartesian3.add(
          point2,
          Cesium.Cartesian3.multiplyByScalar(v1, 0.5, new Cesium.Cartesian3()),
          new Cesium.Cartesian3()
        ),
      })

      labels.push({
        text: Cesium.Cartesian3.magnitude(v2).toFixed(2) + " m",
        position: Cesium.Cartesian3.add(
          point2,
          Cesium.Cartesian3.multiplyByScalar(v2, 0.5, new Cesium.Cartesian3()),
          new Cesium.Cartesian3()
        ),
      })
    }

    viewersForEach((viewer: Cesium.Viewer, viewerIdx: number) => {
      points.forEach(point => {
        const targetObj: Cesium.EntityOptions = {
          id: point.id + "-target",
          position: point.position.cartesian3,
          billboard: {
            image: circle,
            width: 10,
            height: 10,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            show: true,
            color: new Cesium.Color(1.0, 1.0, 1.0, 0.9),
          },
        }
        datasources.current[viewerIdx].entities.add(targetObj)
      })

      lines.forEach(positions => {
        const dMaterial = positions.length > 2 ? airscopeColor : airscopeDashed

        const targetObj: Cesium.EntityOptions = {
          polyline: {
            positions,
            width: 2,
            material: airscopeColor,
            followSurface: false,
            depthFailMaterial: dMaterial,
          },
        }
        datasources.current[viewerIdx].entities.add(targetObj)
      })

      labels.forEach(label => {
        const labelObj: Cesium.EntityOptions = {
          position: label.position,
          label: {
            text: label.text,
            font: "14px sans-serif",
            showBackground: true,
            pixelOffset: label.offsetPosition,
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
        }

        if (label.isAngleLabel) {
          Object.assign(labelObj.label, {
            fillColor: Cesium.Color.BLACK,
            backgroundColor: Cesium.Color.LIGHTGREY,
            pixelOffset: new Cesium.Cartesian2(20, 5),
          })
        }

        datasources.current[viewerIdx].entities.add(labelObj)
      })

      viewer.scene.requestRender()
    })

    return () => {
      viewersForEach((viewer: Cesium.Viewer, viewerIdx: number) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        datasources.current[viewerIdx].entities.removeAll()
        viewer.scene.requestRender()
      })
    }
  }, [viewersForEach, points, datasources])

  return { points, setPoints, clearPoints }
}
