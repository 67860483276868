import { flattenObject, saveAs } from "./index"

const getPropsFromObject = (object: any, isKeyOnly = false, fields: string[]): any[] => {
  const results = []

  const flattened = flattenObject(object)
  for (const [key, value] of Object.entries(flattened)) {
    if (fields.indexOf(key) >= 0) {
      if (isKeyOnly) {
        results.push(key)
      } else {
        // values only
        results.push(value)
      }
    }
  }
  return results
}

const getCsvHeaders = (row: any, fields: string[]): any[] => {
  return getPropsFromObject(row, true, fields)
}

const getCsvFields = (row: any, fields: string[]): any[] => {
  return getPropsFromObject(row, false, fields)
}

export const exportToCsv = (rows: any[], fields: string[], filename = "export.csv") => {
  if (!rows || rows.length <= 0) {
    return
  }
  const delimiter = ","
  const first = rows[0]

  // create csv headers
  const headers = getCsvHeaders(first, fields)
  const contentArr = [headers.join(delimiter)]

  for (const row of rows) {
    const record = getCsvFields(row, fields).join(delimiter)
    contentArr.push(record)
  }

  const blob = new Blob([contentArr.join("\n")], {
    type: "text/csv;charset=utf-8",
  })

  saveAs(blob, filename)
}
