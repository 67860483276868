import { BingMapsStyle } from "cesium"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const env = process.env.NODE_ENV || "development"

export const Constants = {
  Settings: {
    showProfileSecuritySettings: false,
    showNotificationSettings: false,
    showOrganisationSettings: false,
    showSiteSettings: false,
    showInstanceSettings: false,
    showAdminSettings: false,
  },
  ImageryProvider: {
    url: "https://dev.virtualearth.net",
    key: "AkRU_2vuNOoZxtDCTE8Fx1MTKHd5qq8MbjmpOADljR84p5pQpikw7yZoMHwB8pFQ",
    mapStyle: BingMapsStyle.AERIAL_WITH_LABELS,
  },
}

export const TooltipConfigs = {
  // Display delay in milliseconds
  Delay: 500,
  // Font size
  FontSize: 14,
}

export const modelsFileName = "models.json"
export const tilesetFileName = "tileset.json"
