import * as Cesium from "cesium"
import axios from "axios"
import { degreesToRadians } from "../../../helpers/mathFunctions"

export interface Position {
  longitude: number
  latitude: number
  height: number
}

export interface Orientation {
  heading: number
  pitch: number
  roll: number
}

export function computeTransform(position: Position) {
  const cartesianOffset = Cesium.Cartesian3.fromDegrees(
    position.longitude,
    position.latitude,
    position.height
  )
  return Cesium.Transforms.headingPitchRollToFixedFrame(
    cartesianOffset,
    new Cesium.HeadingPitchRoll()
  )
}

export function computeTransformWithOrientation(position: Position, orientation: Orientation) {
  const cartesianOffset = Cesium.Cartesian3.fromDegrees(
    position.longitude,
    position.latitude,
    position.height
  )

  return Cesium.Transforms.headingPitchRollToFixedFrame(
    cartesianOffset,
    new Cesium.HeadingPitchRoll(
      degreesToRadians(orientation.heading),
      degreesToRadians(orientation.pitch),
      orientation.roll
    )
  )
}

export async function getTilesetPosition(tilesetFile: string) {
  const response = await axios.get(tilesetFile)
  const tileset = response.data
  return tileset.position
}
