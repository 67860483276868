// @ts-ignore
import React from "react"
import { apiHostname } from "../../api/constants"

const clientId = "e9004329-bdcc-4c64-8ec2-5bb207bcb948"
const state = `12345`
const redirectUri = `${apiHostname}/ssoAuth`
const nonce = `123456`
const responseType = `id_token`
const scope = `openid email`
const ssoUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&response_mode=form_post&scope=${scope}&state=${state}&nonce=${nonce}`

export const MicrosoftSSO = () => {
  return (
    <div className="sso-container" style={{ margin: 10, borderRadius: 50 }}>
      <a href={ssoUrl} className="sso-button" />
    </div>
  )
}
