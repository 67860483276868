import { useEffect, useState } from "react"
import { IRole, ITeam, IUser } from "../../interfaces"
import {
  apiHostname,
  apiPermissionsTeam,
  apiPermissionsTeamDelete,
  apiPermissionsTeams,
  apiPermissionsTeamUpdate,
} from "../constants"
import { api, APIError, isAuthError, isError } from "../api"
import { toast } from "react-toastify"
import { useConfirmationModal } from "../../hooks/useConfirmationModal"
import axios from "axios"

export const useApiTeams = () => {
  const [teams, setTeams] = useState([] as ITeam[])
  const [teamsLoading, setLoading] = useState(true)
  const { getConfirmation } = useConfirmationModal()

  const token: string = localStorage.getItem("auth")

  useEffect(() => {
    axios.defaults.withCredentials = true
    axios
      .get(apiHostname + "/api" + apiPermissionsTeams, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      })
      .then(response => {
        setTeams(response.data.teams)
      })
      .catch(error => {
        toast.error("There was a problem fetching teams")
      })

    setLoading(false)
  }, [])

  const removeTeam = async (id: string) => {
    const confirmed = await getConfirmation(
      "Delete team",
      "Are you sure you want to delete this team?"
    )
    if (!confirmed) {
      return
    }

    const response: any | APIError = await DeleteTeam(id)
    if (isAuthError(response) && isError(response)) {
      toast.error("There was a problem deleting the team")
      return false
    } else {
      toast.success("Team was successfully deleted")
      const updatedTeams = teams.filter(team => team.id !== id)
      setTeams(updatedTeams)
      return true
    }
  }

  return { teams, teamsLoading, removeTeam }
}

export async function DeleteTeam(id: string) {
  return await api.delete(apiPermissionsTeamDelete(id))
}

export const useApiTeam = (id?: string) => {
  const [team, setTeam] = useState({
    displayName: "",
    description: "",
    userList: [] as IUser[],
    roleList: [] as IRole[],
  } as ITeam)

  const [teamLoading, setLoading] = useState(id !== undefined)

  useEffect(() => {
    if (id) {
      const response: any | APIError = api
        .get(apiPermissionsTeam(id))
        .then(response => {
          // @ts-ignore
          setTeam(Object.assign({}, team, response.team))
        })
        .catch(error => {
          toast.error("There was a problem fetching the team")
        })

      setLoading(false)
    }
  }, [])

  const saveTeam = async () => {
    const data = {
      displayName: team.displayName,
      description: team.description,
      userList: team.userList.map(user => user.id),
      roleList: team.roleList.map(role => role.id),
    }

    let response: any
    if (id !== undefined) {
      response = await api.post(apiPermissionsTeamUpdate(team.id), data)
    } else {
      response = await api.post(apiPermissionsTeams, data)
    }

    return response
  }

  return { team, setTeam, saveTeam, teamLoading }
}
