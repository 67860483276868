/* tslint:disable:jsx-no-lambda */
import { useState } from "react"
import { withRouter } from "react-router"
import { Box } from "trunx"
import { toast } from "react-toastify"
import { styled } from "@mui/material/styles"
import { useApiRoles, useApiTeam, useApiUsers } from "../../../api/ApiHooks"
import { DualListBox, IListBoxItem, roleListTransform, userListTransform } from "../DualListBox"
import { Button, TextField } from "@mui/material"
import { grey, orange } from "@mui/material/colors"

const textFieldStyle = {
  color: "white",
  borderColour: "white",
  mt: 2,
  mr: 2,
  mb: 2,
  "& .MuiInputLabel-root": { color: "white" },
  "& .MuiOutlinedInput-input": { color: "white" },
  "& label.Mui-focused": { color: "white" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "orange",
    },
    "&.Mui-focused fieldset": {
      borderColor: "orange",
    },
  },
}
const errorTextFieldStyle = {
  color: "white",
  borderColour: "white",
  mt: 2,
  mr: 2,
  mb: 2,
  "& .MuiOutlinedInput-input": { color: "white" },
}

const SaveButton = styled(Button)(({ theme }) => ({
  color: "White",
  backgroundColor: "Orange",
  "&:hover": {
    backgroundColor: orange[600],
    boxShadow: "none",
  },
  height: 40,
  marginTop: 30,
  marginRight: 10,
}))

const CancelButton = styled(Button)(({ theme }) => ({
  color: "White",
  backgroundColor: "#2b2b2b",
  "&:hover": {
    backgroundColor: grey[900],
  },
  height: 40,
  marginTop: 30,
}))

export const CreateEditTeam = withRouter((props: any) => {
  const isEditMode = "id" in props.match.params

  const { team, setTeam, teamLoading, saveTeam } = useApiTeam(props.match.params.id)
  const { users, usersLoading } = useApiUsers()
  const { roles, rolesLoading } = useApiRoles()
  const [errors, setErrors] = useState({ displayName: "" })
  const [displayNameErrorOpen, setDisplayNameErrorOpen] = useState(false)

  const updateMemberList = (selectedItems: Array<IListBoxItem>) => {
    const userList = users.filter(user =>
      selectedItems.find(item => item.id === user.id.toString())
    )

    setTeam(Object.assign({}, team, { userList }))
  }

  const updateRoleList = (selectedItems: Array<IListBoxItem>) => {
    const roleList = roles.filter(role => selectedItems.find(item => item.id === role.id))

    setTeam(Object.assign({}, team, { roleList }))
  }

  const performSaveTeam = async () => {
    if (team.displayName.length === 0) {
      setErrors({
        displayName: "Please enter a display name",
      })
      setDisplayNameErrorOpen(true)
      return
    }

    const saveSuccess = await saveTeam()
    if (!saveSuccess) {
      toast.error("There was a problem saving this team")
    } else {
      toast.success(`Team has been ${isEditMode ? "updated" : "created"}`)
      props.history.push("/settings/security/teams")
    }
  }

  return (
    <Box className={"teams-management"}>
      <div className={"edit-role-form"}>
        <h2>{isEditMode ? "Edit" : "Create"} Team</h2>

        <div className={"edit-role-form-general"}>
          {displayNameErrorOpen ? (
            <TextField
              error={true}
              sx={{ ...errorTextFieldStyle }}
              id="outlined-error-helper-text"
              label="Error"
              variant={"outlined"}
              defaultValue={"Display Name"}
              value={team.displayName}
              onChange={e =>
                setTeam(Object.assign({}, team, { displayName: e.currentTarget.value }))
              }
              helperText="Please enter a display name"
            />
          ) : (
            <TextField
              sx={{ ...textFieldStyle }}
              defaultValue={"Display Name"}
              inputProps={{ "data-testid": "displayName" }}
              value={team.displayName}
              label={"Display Name"}
              onChange={e =>
                setTeam(Object.assign({}, team, { displayName: e.currentTarget.value }))
              }
            />
          )}
          <TextField
            sx={{ ...textFieldStyle }}
            defaultValue={"Descripton"}
            value={team.description}
            label={"Description"}
            onChange={e => setTeam(Object.assign({}, team, { description: e.currentTarget.value }))}
          />
        </div>
        <div className={"edit-role-form-membership"}>
          <div>
            <div className={"edit-role-form-section-header"}>User Membership</div>
            <DualListBox
              listItems={users.map(userListTransform)}
              selectedItems={team.userList.map(userListTransform)}
              onSelectedItemsChange={updateMemberList}
              loading={usersLoading}
              leftHeader={"Remaining Users"}
              rightHeader={"Team Members"}
            />
          </div>
        </div>

        <div className={"edit-role-form-membership"}>
          <div>
            <div className={"edit-role-form-section-header"}>Role Membership</div>
            <DualListBox
              listItems={roles.map(roleListTransform)}
              selectedItems={team.roleList.map(roleListTransform)}
              onSelectedItemsChange={updateRoleList}
              loading={rolesLoading}
              leftHeader={"Remaining Roles"}
              rightHeader={"Assigned Roles"}
            />
          </div>
        </div>

        <div>
          <SaveButton onClick={performSaveTeam} data-testid={"saveTeam"}>
            Save Team
          </SaveButton>
          <CancelButton onClick={() => props.history.push("/settings/security/teams")}>
            Cancel
          </CancelButton>
        </div>
      </div>
    </Box>
  )
})
