import * as React from "react"
import { Box, Button, Field, Radio } from "trunx"
import { TextField } from "@mui/material"
import reformed, { InjectedProps } from "../../../lib/reformed"
import { IsMatching, IValidatorProps, Validate } from "../../../lib/reformed/validate"
import { Loading } from "../../Loading"
import { toast } from "react-toastify"
// import SecurityController from "../../../controllers/security"
import { Constants } from "../../../constants"
import SecurityController from "../../../controllers/security"
import { api } from "../../../api"
import { IUser } from "../../../interfaces"
import { apiAuthSetPassword } from "../../../api/constants"

interface IProfileSecurityModel {
  enable2FA: boolean
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

interface IProps {
  updateSecuritySettings: SecurityController["updateSecuritySettings"]
  setPassword: SecurityController["setPassword"]
}

interface IState {
  sending: boolean
  fetching: boolean
}

const textFieldStyle = {
  color: "white",
  borderColour: "white",
  mt: 2,
  "& .MuiInputLabel-root": { color: "white" },
  "& .MuiOutlinedInput-input": { color: "white" },
}

class ProfileSecuritySettings extends React.Component<
  InjectedProps<IProfileSecurityModel> & IValidatorProps & IProps,
  IState
> {
  state: IState = {
    sending: false,
    fetching: false,
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.currentTarget.name as keyof IProfileSecurityModel
    let value: string | boolean = e.target.value
    if (key === "enable2FA") {
      value = !this.props.model![key]
    }
    this.props.setProperty!(key, value)
  }
  onSubmitSecurity = async (e: React.FormEvent) => {
    e.preventDefault()
    const { model } = this.props

    console.debug("Submitting", model)
    if (!model) {
      console.error("no model in form")
      return
    }

    await this.props.updateSecuritySettings(model.enable2FA)
  }
  onSubmitPassword = async (e: React.FormEvent) => {
    e.preventDefault()
    const { model } = this.props
    if (!this.props.validation) {
      console.error("No validation")
      return
    }
    if (!model) {
      console.error("no model")
      return
    }

    if (!model.newPassword || !model.confirmNewPassword) {
      toast.error("New password must be provided")
      return
    }
    const { hasErrors, errors } = this.props.validation
    if (hasErrors) {
      // tslint:disable-next-line:forin
      for (const err in errors) {
        toast.error(errors[err])
      }
      return
    }
    console.debug("Submitting", model)
    if (!model) {
      console.error("no model in form")
      return
    }

    await this.updatePassword(model)
  }

  private async updatePassword(model: IProfileSecurityModel) {
    const response = await api
      .post<IUser>(apiAuthSetPassword, {
        oldPassword: model.oldPassword,
        newPassword: model.newPassword,
        confirmNewPassword: model.confirmNewPassword,
      })
      .then(response => {
        if (response.error) toast.error("Update failed.")
        else toast.success("Password updated.")
      })
  }

  render() {
    const { model } = this.props

    if (this.state.fetching || this.state.sending) {
      return <Loading />
    }
    return (
      <React.Fragment>
        <h2>Profile Security Settings</h2>
        <Box>
          {Constants.Settings.showProfileSecuritySettings && (
            <form onSubmit={this.onSubmitSecurity}>
              <Field
                defaultValue={"Two Factor Authentication"}
                isLarge={true}
                style={{ textAlign: "left" }}
              >
                <div className={"switch-container"}>
                  <div className={"switch-item"}>
                    <Radio name={"enable2FA"} onChange={this.onChange} checked={model!.enable2FA}>
                      {"Enable"}
                    </Radio>
                  </div>
                </div>
              </Field>
              <Button
                style={{ marginTop: 14, marginBottom: "10px" }}
                isLarge={false}
                type={"submit"}
              >
                Update
              </Button>
            </form>
          )}
          <form onSubmit={this.onSubmitPassword}>
            <Field
              className={"formInput"}
              defaultValue={"Old Password"}
              isLarge={true}
              style={{ textAlign: "left" }}
              aria-label={"Old Password"}
            >
              <TextField
                onChange={this.onChange}
                sx={{ ...textFieldStyle }}
                name={"oldPassword"}
                value={model!.oldPassword || ""}
                type="password"
                inputProps={{ "data-testid": "oldPassword" }}
                label="Old Password"
                variant="outlined"
              />
            </Field>
            <Field defaultValue={"New Password"} isLarge={true} style={{ textAlign: "left" }}>
              <TextField
                onChange={this.onChange}
                sx={{ ...textFieldStyle }}
                name={"newPassword"}
                value={model!.newPassword || ""}
                type="password"
                inputProps={{ "data-testid": "newPassword" }}
                label="New Password"
                variant="outlined"
              />
            </Field>
            <Field defaultValue={"Confirm Password"} isLarge={true} style={{ textAlign: "left" }}>
              <TextField
                onChange={this.onChange}
                sx={{ ...textFieldStyle }}
                name={"confirmNewPassword"}
                value={model!.confirmNewPassword || ""}
                type="password"
                inputProps={{ "data-testid": "confirmPassword" }}
                label="Confirm Password"
                variant="outlined"
              />
            </Field>

            <Button
              style={{ marginTop: 14 }}
              isLarge={false}
              type={"submit"}
              data-testid={"submitButton"}
            >
              Update
            </Button>
          </form>
        </Box>
      </React.Fragment>
    )
  }
}

const Reformed = reformed({
  initialModel: {
    enable2FA: false,
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  },
})(
  Validate<IProfileSecurityModel, IProps>(
    IsMatching<IProfileSecurityModel>("newPassword", "confirmNewPassword")
  )(ProfileSecuritySettings)
)

export { Reformed as ProfileSecuritySettings }
