/* tslint:disable:jsx-no-lambda */
import { FontAwesomeIcon } from "../../helpers/icon"
import React from "react"

export const Help = () => {
  const userGuideUrl = "/assets/docs/Visualize_User_Guide.pdf"

  return (
    <div className="help-button">
      <span className="help-button-icon">
        <FontAwesomeIcon icon="question" size={"2x"} />
      </span>

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={() => window.open(userGuideUrl, "_blank")}>
        User
        <br /> Guide
      </a>
    </div>
  )
}
