import * as React from "react"
import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

interface IProps {
  onClose: () => void
}

export const SelectorAlert = (props: IProps) => (
  <div className="modal is-active">
    <div className="modal-background" onClick={props.onClose} />
    <div className="modal-content">
      <div className="message is-danger">
        <div style={{ backgroundColor: "Orange", paddingLeft: "10px" }}>
          Model set not included
          <IconButton className="close-selector-alert" aria-label="delete" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div
          className="message-body"
          style={{ backgroundColor: "White", color: "Black", height: 110 }}
        >
          <p style={{ paddingLeft: "20px", paddingTop: "20px" }}>
            Unfortunately your subscription does not include this model type.
          </p>
          <p style={{ paddingLeft: "20px" }}>Please speak to your administrator.</p>
        </div>
      </div>
    </div>
  </div>
)
