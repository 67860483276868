/* tslint:disable:jsx-no-lambda */
import { Fragment, useCallback, useContext, useState } from "react"
import { ModalContext } from "../context"
import { Loading } from "../components/Loading"
import { isRequired, useForm } from "../hooks/useForm"
import { Button, TextField } from "@mui/material"

export interface ITextConfirmationOptions {
  buttonText?: string
  fieldLabel?: string
}

const DEFAULT_TEXT_OPTIONS: ITextConfirmationOptions = {
  buttonText: "Confirm",
  fieldLabel: "",
}

const ConfirmationModal = (props: any) => {
  const { title, message, buttonText, onClose, onConfirm } = props
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const performSubmit = useCallback(() => {
    if (hasSubmitted) {
      return
    }
    setHasSubmitted(true)
    onConfirm()
  }, [hasSubmitted, onConfirm])

  return (
    <Fragment>
      <header className="modal-card-head">
        <p className="modal-card-title">{title}</p>
      </header>
      <section className="modal-card-body">
        <p>{message}</p>
        <div className="pointgrab-delete-footer">
          <Button type={"submit"} onClick={onClose}>
            Cancel
          </Button>
          <Button type={"submit"} style={{ minWidth: "63px" }} onClick={performSubmit}>
            {hasSubmitted ? <Loading width={15} /> : buttonText}
          </Button>
        </div>
      </section>
    </Fragment>
  )
}

const TextConfirmationModal = (props: any) => {
  const { title, message, onClose, onConfirm, options } = props
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const { formValues, formErrors, setFormValue, isFormValid } = useForm({
    text: {
      defaultValue: "",
      validators: [isRequired],
      class: "input",
    },
  })

  const performSubmit = useCallback(() => {
    if (hasSubmitted) {
      return
    }
    setHasSubmitted(true)
    // @ts-ignore
    onConfirm(formValues["text"])
  }, [formValues, hasSubmitted, onConfirm])

  return (
    <Fragment>
      <header className="modal-card-head">
        <p className="modal-card-title">{title}</p>
      </header>
      <section className="modal-card-body">
        <p>{message}</p>
        <TextField
          className="modal-edit-field"
          value={formValues["text"]}
          onChange={(e: any) => setFormValue("text", e.target.value)}
          error={formErrors["text"]}
        />
        <div className="pointgrab-delete-footer">
          <Button type={"submit"} onClick={onClose}>
            Cancel
          </Button>
          <Button type={"submit"} style={{ minWidth: "63px" }} onClick={performSubmit}>
            {hasSubmitted ? <Loading width={15} /> : options.buttonText}
          </Button>
        </div>
      </section>
    </Fragment>
  )
}

export const useConfirmationModal = () => {
  const { showModal, hideModal } = useContext(ModalContext)

  const getConfirmation = useCallback(
    async (title: string, message: string, buttonText: string = "Confirm") => {
      return new Promise<boolean>(resolve => {
        showModal(() => (
          <ConfirmationModal
            title={title}
            message={message}
            buttonText={buttonText}
            onClose={async () => {
              resolve(false)
              hideModal()
            }}
            onConfirm={async () => {
              resolve(true)
              hideModal()
            }}
          />
        ))
      })
    },
    [hideModal, showModal]
  )

  const getTextConfirmation = useCallback(
    async (title: string, message: string, options?: ITextConfirmationOptions) => {
      const modalOptions = options
        ? Object.assign({}, DEFAULT_TEXT_OPTIONS, options)
        : DEFAULT_TEXT_OPTIONS
      return new Promise<string | null>(resolve => {
        showModal(() => (
          <TextConfirmationModal
            title={title}
            message={message}
            options={modalOptions}
            onClose={async () => {
              resolve(null)
              hideModal()
            }}
            onConfirm={async (text: string) => {
              resolve(text)
              hideModal()
            }}
          />
        ))
      })
    },
    [hideModal, showModal]
  )

  return { getConfirmation, getTextConfirmation }
}
