import { useEffect, useState } from "react"
import { ITeam, IUser, IRole } from "../../interfaces"
import {
  apiUsers,
  apiUsersForgotpassword,
  apiUserEnable,
  apiUserDisable,
  apiUser,
  apiUserClearSSO,
  apiUserDelete,
  apiUserUpdate,
  apiHostname,
} from "../constants"
import { api, isError, isAuthError, APIError } from "../api"
import { toast } from "react-toastify"
import { useConfirmationModal } from "../../hooks/useConfirmationModal"
import axios from "axios"

export const useApiUsers = (showArchived = false) => {
  const [users, setUsers] = useState([] as IUser[])
  const [usersLoading, setLoading] = useState(true)
  const { getConfirmation } = useConfirmationModal()

  const token: string = localStorage.getItem("aut")

  useEffect(() => {
    axios.defaults.withCredentials = true
    axios
      .get(apiHostname + "/api" + apiUsers, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      })
      .then(response => {
        setUsers(response.data.users)
      })
      .catch(error => {
        toast.error("There was a problem fetching users")
      })

    setLoading(false)
  }, [])

  const disableUser = async (user: IUser) => {
    const confirmed = await getConfirmation(
      "Disable User",
      "Are you sure you want to disable this user?"
    )
    if (!confirmed) {
      return
    }

    const response = await api.post(apiUserDisable(user.id), {})

    if (isAuthError(response) && isError(response)) {
      toast.error("There was a problem disabling this user")
    } else {
      toast.success("User has been successfully disabled")
    }

    const updatedUsers = users.map(currentUser => {
      if (currentUser.id === user.id) {
        return Object.assign({}, currentUser, { disabled: true })
      } else {
        return currentUser
      }
    })
    setUsers(updatedUsers)
  }

  const enableUser = async (user: IUser) => {
    const confirmed = await getConfirmation(
      "Enable User",
      "Are you sure you want to enable this user?"
    )
    if (!confirmed) {
      return
    }

    const response = await api.post(apiUserEnable(user.id), {})

    if (isAuthError(response) && isError(response)) {
      toast.error("There was a problem enabling this user")
    } else {
      toast.success("User has been successfully enabled")
    }

    const updatedUsers = users.map(currentUser => {
      if (currentUser.id === user.id) {
        return Object.assign({}, currentUser, { disabled: false })
      } else {
        return currentUser
      }
    })
    setUsers(updatedUsers)
  }

  const resetPassword = async (user: IUser) => {
    const payload = { email: user.email }

    toast.info("Processing password reset")

    const response = await api.post(apiUsersForgotpassword, payload)

    if (isAuthError(response) && isError(response)) {
      toast.error("There was a problem sending the password reset email")
    } else {
      toast.success("User has been sent a password reset email")
    }
  }

  const archiveUser = async (user: IUser) => {
    const confirmed = await getConfirmation(
      "Delete User",
      "Are you sure you want to delete this user?"
    )
    if (!confirmed) {
      return
    }

    const response = await api.delete(apiUserDelete(user.id))
    if (isAuthError(response) && isError(response)) {
      toast.error("There was a problem deleting the user")
    } else {
      toast.success("User has been successfully deleted")
    }

    const updatedUsers = users.filter(currentUser => currentUser.id !== user.id)
    setUsers(updatedUsers)
  }

  const clearSSOAccount = async (user: IUser) => {
    const confirmed = await getConfirmation(
      "Clear Azure SSO association",
      "Are you sure you want to clear the Azure SSO association for this user?"
    )
    if (!confirmed) {
      return
    }

    const response = await api.post(apiUserClearSSO(user.id), {})

    if (isAuthError(response) && isError(response)) {
      toast.error("There was a problem clearing the Azure SSO association for this user")
    } else {
      toast.success("Azure SSO association has been successfully enabled")
    }

    const updatedUsers = users.map(currentUser => {
      if (currentUser.id === user.id) {
        return Object.assign({}, currentUser, { ssoTid: null, ssoSub: null })
      } else {
        return currentUser
      }
    })
    setUsers(updatedUsers)
  }

  return {
    users,
    usersLoading,
    resetPassword,
    enableUser,
    disableUser,
    archiveUser,
    clearSSOAccount,
  }
}

export const useApiUser = (id?: number) => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    roleList: [] as IRole[],
    teamList: [] as ITeam[],
  } as IUser)

  const [userLoading, setLoading] = useState(id !== undefined)

  useEffect(() => {
    if (id) {
      const response: any | APIError = api
        .get(apiUser(id))
        .then(response => {
          // @ts-ignore
          setUser(Object.assign({}, user, response.user))
        })
        .catch(error => {
          toast.error("There was a problem fetching the user")
        })
      setLoading(false)
    }
  }, [])

  const saveUser = async () => {
    const data = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: user.password,
      roleList: user.roleList.map(role => role.id),
      teamList: user.teamList.map(team => team.id),
    }

    let response: any | APIError
    if (id !== undefined) {
      response = await api.post(apiUserUpdate(user.id), data)
    } else {
      response = await api.post(apiUsers, data)
    }

    return !(isAuthError(response) || isError(response))
  }

  return { user, setUser, saveUser, userLoading }
}
