import { useContext, useEffect } from "react"
import { NearbyImagesContext, ViewerContext } from "../../context"
import { NearbyImageMode } from "../../context/NearbyImages"

export const NearbyPhotoTool = () => {
  const { ensureNearbyImagesIsOpen } = useContext(ViewerContext)
  const { mode, setNearbyToolActive } = useContext(NearbyImagesContext)

  useEffect(() => {
    setNearbyToolActive(true)
    ensureNearbyImagesIsOpen()
    return () => setNearbyToolActive(false)
  }, [ensureNearbyImagesIsOpen])

  return (
    <div className="nearbyToolTip">
      {mode === NearbyImageMode.Click
        ? "Click anywhere on the 3D Digital Twin to search for photos of that area"
        : "Move the 3D camera to any position looking at your Digital Twin to find photos matching your view"}
    </div>
  )
}
