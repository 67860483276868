import { useEffect, useContext, useRef, useCallback } from "react"
import { useCesiumOnMouseMove } from "../useCesiumMouseMove"
import { CesiumMoveEvent } from "../models/CesiumMoveEvent"
import { distanceToText } from "../../helpers"
import { ViewerContext } from "../../context"
import { IPoint } from "../../interfaces"
import * as Cesium from "cesium"

export const usePointDistanceCursor = (points: IPoint[]) => {
  const { viewer } = useContext(ViewerContext)

  const pendingLineFlag = useRef(null as Cesium.Entity | null)

  useEffect(() => {
    if (!viewer) {
      return
    }

    const flagDataSource = new Cesium.CustomDataSource("measure-flag")
    viewer.dataSources.add(flagDataSource)

    pendingLineFlag.current = flagDataSource.entities.add({
      position: new Cesium.Cartesian3(),
      label: {
        font: "14px sans-serif",
        text: "",
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        pixelOffset: new Cesium.Cartesian2(15, 30),
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        showBackground: true,
        show: true,
      },
    })

    return () => {
      flagDataSource.entities.removeAll()
      viewer.dataSources.remove(flagDataSource, true)
      viewer.scene.requestRender()
      pendingLineFlag.current = null
    }
  }, [viewer])

  const mouseMoveHandler = useCallback(
    (e: CesiumMoveEvent) => {
      const flag = pendingLineFlag.current
      if (!flag || !viewer) {
        return
      }
      const pickPosition = e.pickPosition()
      if (!pickPosition) {
        return
      }

      const position = pickPosition.cartesian3
      flag.position = position

      if (points.length > 0) {
        const pF = points[points.length - 1].position.cartesian3
        const distance = Cesium.Cartesian3.distance(position, pF)
        flag.label.text = distanceToText(distance)
      } else {
        flag.label.text = ""
      }

      viewer.scene.requestRender()
    },
    [viewer, points]
  )

  useCesiumOnMouseMove(mouseMoveHandler)
}
