import { Box, Button, Field, Radio } from "trunx"
import reformed, { InjectedProps } from "../../../lib/reformed"
import { Validate } from "../../../lib/reformed/validate"
import { Loading } from "../../Loading"
import { toast } from "react-toastify"
import { Component, Fragment } from "react"

interface IOrganisationSecurityModel {
  enable2FA: boolean
  enableSSO: boolean
}

// tslint:disable-next-line:no-empty-interface
interface IProps {}

interface IState {
  sending: boolean
  fetching: boolean
  alert: boolean
}

class OrganisationSecuritySettings extends Component<
  InjectedProps<IOrganisationSecurityModel> & IProps,
  IState
> {
  state: IState = {
    sending: false,
    fetching: false,
    alert: false,
  }
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.currentTarget.name as keyof IOrganisationSecurityModel
    const value = !this.props.model![key]

    if (key === "enableSSO") {
      this.openAlert()
      return
    }
    this.props.setProperty!(key, value)
  }
  onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const { model } = this.props
    console.debug("Submitting", model)
    if (!model) {
      console.error("no model in form")
      return
    }
    console.log(model)
    toast.success("Settings updated.")
  }

  openAlert = () => {
    this.setState({ alert: true })
  }
  closeAlert = () => {
    this.setState({ alert: false })
  }

  render() {
    const { model } = this.props
    if (this.state.fetching || this.state.sending) {
      return <Loading />
    }
    return (
      <Fragment>
        <h2>Organisation Security Settings</h2>
        <p>These settings will affect the entire organisation.</p>
        <Box>
          <form onSubmit={this.onSubmit}>
            <Field
              defaultValue={"Two Factor Authentication"}
              isLarge={true}
              style={{ textAlign: "left" }}
            >
              <div className={"switch-container"}>
                <div className={"switch-item"}>
                  <Radio defaultValue={"Enable"} name={"enable2FA"} onChange={this.onChange}>
                    {model!.enable2FA}
                  </Radio>
                </div>
              </div>
            </Field>
            <Field defaultValue={"Single Sign On"} isLarge={true} style={{ textAlign: "left" }}>
              <div className={"switch-container"}>
                <div className={"switch-item"}>
                  <Radio defaultValue={"Enable"} name={"enableSSO"} onChange={this.onChange}>
                    {model!.enableSSO}
                  </Radio>
                </div>
              </div>
            </Field>
            <Button style={{ marginTop: 14 }} isLarge={false} type={"submit"}>
              Update
            </Button>
          </form>
        </Box>
        {this.state.alert && (
          <div className="modal is-active">
            <div className="modal-background" onClick={this.closeAlert} />
            <div className="modal-content">
              <div className="message is-danger">
                <div className="message-header">
                  Single Sign On not available{" "}
                  <button className="delete" aria-label="delete" onClick={this.closeAlert} />
                </div>
                <div className="message-body">
                  Unfortunately this instance of Visualize does not include Single Sign On. Please
                  contact Airscope to enable this feature.
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

const Reformed = reformed({
  initialModel: {
    enable2FA: false,
    enableSSO: false,
  },
})(Validate<IOrganisationSecurityModel, IProps>()(OrganisationSecuritySettings))

export { Reformed as OrganisationSecuritySettings }
