import { useEffect, useContext, useRef } from "react"
import { ViewerContext } from "../../context"
import * as Cesium from "cesium"

export const useEntityGroups = () => {
  const { viewersForEach } = useContext(ViewerContext)
  const datasources = useRef([] as Cesium.CustomDataSource[])

  useEffect(() => {
    viewersForEach((viewer: Cesium.Viewer, viewerIdx: number) => {
      if (!datasources.current[viewerIdx]) {
        datasources.current[viewerIdx] = new Cesium.CustomDataSource("moc-polygon-pins")
      }
      viewer.dataSources.add(datasources.current[viewerIdx])
    })

    return () => {
      viewersForEach((viewer: Cesium.Viewer, viewerIdx: number) => {
        datasources.current[viewerIdx].entities.removeAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        viewer.dataSources.remove(datasources.current[viewerIdx], true)
      })
    }
  }, [viewersForEach])

  return { datasources }
}
