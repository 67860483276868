import { useState, useEffect, useContext } from "react"
import { airscopeColor } from "../helpers/colours"
import { IPoint } from "../interfaces"
import { ViewerContext, ModelsContext } from "../context"
import { ThickCrosshairSVG } from "../assets"
import { prettyPrintCoords } from "../helpers/projections"
import { useEntityGroups } from "./viewer/useEntityGroups"
import { usePointSelector } from "./viewer/usePointSelector"
import * as Cesium from "cesium"

export const useTargetMode = () => {
  const { viewersForEach } = useContext(ViewerContext)
  const { modelProjectionType } = useContext(ModelsContext)
  const { datasources } = useEntityGroups()
  const { points, setPoints, clearPoints } = usePointSelector()
  const [hoveredPoint, setHoveredPoint] = useState(null as null | IPoint)

  useEffect(() => {
    viewersForEach((viewer: Cesium.Viewer, viewerIdx: number) => {
      points.forEach((point: IPoint) => {
        const lableObj = {
          id: point.id + "-label",
          position: point.position.cartesian3,
          name: point.name,
          label: {
            text: prettyPrintCoords(modelProjectionType, point.position.cartesian3, {
              lineBreaks: true,
            }),
            showBackground: true,
            backgroundColor: new Cesium.CallbackProperty(() => {
              if (point === hoveredPoint) {
                return airscopeColor.withAlpha(0.7)
              }
              return new Cesium.Color(0.165, 0.165, 0.165, 0.8)
            }, true),
            backgroundPadding: new Cesium.Cartesian2(7, 5),
            font: "18pt sans-serif",
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            pixelOffset: new Cesium.Cartesian2(0, -60),
            scale: 0.75,
          },
        }
        const targetObj = {
          id: point.id + "-target",
          position: point.position.cartesian3,
          billboard: {
            image: ThickCrosshairSVG,
            width: 45,
            height: 45,
            eyeOffset: new Cesium.Cartesian3(0, 0, -0.15),
          },
        }
        datasources.current[viewerIdx].entities.add(lableObj)
        datasources.current[viewerIdx].entities.add(targetObj)
      })

      viewer.scene.requestRender()
    })
    return () => {
      viewersForEach((viewer: Cesium.Viewer, viewerIdx: number) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        datasources.current[viewerIdx].entities.removeAll()
        viewer.scene.requestRender()
      })
    }
  }, [viewersForEach, points, hoveredPoint, modelProjectionType, datasources])

  return { points, setPoints, clearPoints, setHoveredPoint }
}
