import * as React from "react"
import { Fragment, useContext } from "react"
import { MenuItem, Select } from "@mui/material"
import { ModelProjectionType, ModelsContext } from "../../context/Models"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { TooltipConfigs } from "../../constants"

export const ProjectionSelector = () => {
  const { modelProjectionType, setModelProjectionType } = useContext(ModelsContext)

  const handleChange = event => {
    const value = Number(event.target.value)
    setModelProjectionType(value)
  }

  const style = {
    color: "white",
    borderBottom: "1px solid orange",
    height: 30,
  }

  return (
    <Fragment>
      <Tooltip
        title={
          <Typography fontSize={TooltipConfigs.FontSize}>Coordinate Projection Type</Typography>
        }
        placement="left-end"
        enterDelay={TooltipConfigs.Delay}
        enterNextDelay={TooltipConfigs.Delay}
      >
        <Select
          name={"projectionType"}
          style={style}
          onChange={handleChange}
          defaultValue={ModelProjectionType.WGS84}
        >
          <MenuItem value={ModelProjectionType.UTM}>UTM</MenuItem>
          <MenuItem value={ModelProjectionType.WGS84}>WGS84</MenuItem>
        </Select>
      </Tooltip>
    </Fragment>
  )
}
