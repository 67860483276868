/* tslint:disable:jsx-no-lambda */
import { useContext } from "react"
import { Box, Media } from "trunx"
import { withRouter } from "react-router"
import { ModelsContext, ViewerContext } from "../../context"

export const SiteBox = withRouter((props: any) => {
  const { site, history } = props
  const { setSelectedSiteId } = useContext(ModelsContext)
  const { setPendingFlyHome } = useContext(ViewerContext)

  return (
    <Box
      className={"ss-site"}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setPendingFlyHome(true)
        setSelectedSiteId(site.id)

        history.push("/")
      }}
    >
      <Media>
        <h4>{site.name}</h4>
        <p>{site.description}</p>
      </Media>
    </Box>
  )
})
