import { useCallback, useEffect, useState } from "react"
import { apiHostname } from "../../api/constants"
import { getClampNumber } from "../../helpers/mathFunctions"
import { PhotoZoomIsEnabled } from "../../helpers/PhotoZoomIsEnabled"

export function modifyScaleByDelta(scale: number, delta: number) {
  return scale - delta
}

export const PhotoViewer2d = (props: any) => {
  const { zoomEnabled, photo, photoRotation } = props
  const [mouseOver, setMouseOver] = useState(false)
  const [transformOrigin, setTransformOrigin] = useState("50% 50%")
  const [baseScale, setBaseScale] = useState(1)
  const [currScale, setCurrScale] = useState(1)
  const [maxScale, setMaxScale] = useState(500)
  const [zoomScale, setZoomScale] = useState(250)
  const [imageAspect, setImageAspect] = useState(1)

  useEffect(() => {
    setCurrScale(PhotoZoomIsEnabled(zoomEnabled, zoomScale, baseScale))
  }, [baseScale, zoomEnabled, zoomScale])

  useEffect(() => {
    if (!photo) {
      return
    }

    const image = new Image()
    image.src = `${apiHostname}/files/snapshots/${photo.filename}/thumb`

    image.onload = () => {
      const aspect = image.naturalWidth / image.naturalHeight
      setImageAspect(aspect)
      setMaxScale(Math.min(image.naturalHeight, image.naturalWidth) / 50)
      setZoomScale(maxScale / 2)
    }
  }, [maxScale, photo])

  useEffect(() => {
    if (photoRotation % 180 !== 0) {
      setBaseScale(imageAspect)
    } else {
      setBaseScale(1)
    }
  }, [photoRotation, imageAspect])

  const onMouseZoom = useCallback(
    (e: any) => {
      setCurrScale(getClampNumber(modifyScaleByDelta(currScale, e.deltaY), baseScale, maxScale))
    },
    [baseScale, currScale, maxScale]
  )

  const onMouseMove = useCallback(
    (e: any) => {
      const viewportOffset = e.target.parentNode.getBoundingClientRect()
      const transformOriginX = getClampNumber(
        (100 * (e.pageX - viewportOffset.left)) / e.target.offsetWidth,
        0,
        100
      )
      const transformOriginY = getClampNumber(
        (100 * (e.pageY - viewportOffset.top)) / e.target.offsetHeight,
        0,
        100
      )

      switch (photoRotation % 360) {
        case 0:
          setTransformOrigin(`${transformOriginX}% ${transformOriginY}%`)
          break
        case 90:
          setTransformOrigin(`${transformOriginY}% ${100.0 - transformOriginX}%`)
          break
        case 180:
          setTransformOrigin(`${100.0 - transformOriginX}% ${100.0 - transformOriginY}%`)
          break
        case 270:
          setTransformOrigin(`${100.0 - transformOriginY}% ${transformOriginX}%`)
          break
      }

      setMouseOver(true)
    },
    [photoRotation]
  )

  const onMouseLeave = useCallback(() => {
    setMouseOver(false)
    if (!zoomEnabled) setCurrScale(baseScale)
    setTransformOrigin("50% 50%")
  }, [baseScale, zoomEnabled])

  return (
    <div
      className={"photo"}
      style={{
        backgroundImage: `url(${apiHostname}/files/snapshots/${photo.filename}/large)`,
        transformOrigin,
        transform: `scale(${currScale}) rotate(${photoRotation}deg)`,
      }}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onWheel={onMouseZoom}
    />
  )
}
