import { useEffect, useRef, useState } from "react"
import { TextureLoader, useLoadTexture } from "./PhotoViewer3d/TextureLoader"
import * as THREE from "three"
import { PhotoZoomIsEnabled } from "../../helpers/PhotoZoomIsEnabled"
import { getClampNumber } from "../../helpers/mathFunctions"
import { getSnapshots } from "../../api/Hooks/SnapshotHooks"

const TextureLoadingProgress = (props: any) => {
  const { loadingPercentage } = props

  return (
    <div className="nearbyLoadingOverlay">
      <div className="nearbyImageLoadingProgress">
        <div
          className="innerProgress"
          data-testid="innerProgress"
          style={{
            width: `${loadingPercentage.toFixed(0)}%`,
          }}
        />
      </div>
    </div>
  )
}

const defaultPanosphereFieldOfView = 100
const zoomButtonPanosphereFieldOfView = 50

export const ThreePanosphereViewer = (props: any) => {
  const { texture, zoomEnabled } = props
  const renderElement = useRef(null)
  const [camera, setCamera] = useState(new THREE.PerspectiveCamera(100, 0.8, 1, 1100))

  const fieldOfView = 100
  let running = true
  let isUserInteracting = false
  let zoom = false

  const mouseDownLocation = { x: 0, y: 0 }
  const mouseDownLatitudeLongitude = { latitude: 0, longitude: 0 }

  let longitude = 0
  let latitude = 0
  let phi = 0
  let theta = 0

  const currentCameraTarget = new THREE.Vector3(0, 0, 0)
  const scene = new THREE.Scene()

  // The renderer needs to be explicitly 'nulled' on render so that this component doesn't consume all the WebGL contexts
  let renderer: THREE.WebGLRenderer = null

  const resizeObserver = new ResizeObserver(() => {
    if (renderElement.current) {
      const [canvasWidth, canvasHeight] = getContainerSize(renderer.domElement)
      const width = renderElement.current.clientWidth
      const height = renderElement.current.clientHeight
      if (width !== canvasWidth || height !== canvasHeight) {
        renderer.setSize(width, height)
        camera.aspect = width / height
        camera.updateProjectionMatrix()
      }
    }
  })

  const onDocumentMouseDown = (event: any) => {
    event.preventDefault()
    isUserInteracting = true
    mouseDownLocation.x = event.clientX
    mouseDownLocation.y = event.clientY
    mouseDownLatitudeLongitude.latitude = latitude
    mouseDownLatitudeLongitude.longitude = longitude
  }

  const onDocumentMouseMove = (event: any) => {
    if (isUserInteracting) {
      longitude = (mouseDownLocation.x - event.clientX) * 0.1 + mouseDownLatitudeLongitude.longitude
      latitude = (event.clientY - mouseDownLocation.y) * 0.1 + mouseDownLatitudeLongitude.latitude
    }
  }

  const onDocumentMouseUp = () => {
    isUserInteracting = false
  }

  const onMouseWheel = (event: any) => {
    const delta = event.wheelDeltaY || event.wheelDelta || event.detail

    camera.fov = !event.detail ? camera.fov - delta * 0.05 : camera.fov + delta
    camera.fov = getClampNumber(camera.fov, 20, 120)
    camera.updateProjectionMatrix()
  }

  const onZoomButton = (event: any) => {
    zoom = !zoom
    camera.fov = PhotoZoomIsEnabled(
      zoom,
      zoomButtonPanosphereFieldOfView,
      defaultPanosphereFieldOfView
    )
    camera.fov = getClampNumber(camera.fov, 20, 120)
    camera.updateProjectionMatrix()
  }

  const getContainerSize = (container: any) => {
    const containerStyle = window.getComputedStyle(container, null)
    const width = Number(containerStyle.getPropertyValue("width").replace(/\D\d*/g, ""))
    const height = Number(containerStyle.getPropertyValue("height").replace(/\D\d*/g, ""))
    return [width, height]
  }

  const animate = () => {
    if (!running) return

    latitude = getClampNumber(latitude, -85, 85)
    phi = ((90 - latitude) * Math.PI) / 180
    theta = (longitude * Math.PI) / 180
    currentCameraTarget.x = 500 * Math.sin(phi) * Math.cos(theta)
    currentCameraTarget.y = 500 * Math.cos(phi)
    currentCameraTarget.z = 500 * Math.sin(phi) * Math.sin(theta)

    camera.lookAt(currentCameraTarget)
    renderer.render(scene, camera)
    requestAnimationFrame(animate)
  }

  function generateMeshForScene(materialParameters: any) {
    const material = new THREE.MeshBasicMaterial(materialParameters)
    material.side = THREE.BackSide
    const geometry = new THREE.SphereGeometry(20, 60, 40)
    const mesh = new THREE.Mesh(geometry, material)
    mesh.scale.x = -1
    return mesh
  }

  useEffect(() => {
    const container: any = renderElement.current
    if (container && texture) {
      const [containerWidth, containerHeight] = getContainerSize(container)
      // if a new renderer is not created here, the previous WebGL context is not released
      renderer = new THREE.WebGLRenderer()
      setCamera(new THREE.PerspectiveCamera(fieldOfView, containerWidth / containerHeight, 1, 1100))
      scene.add(generateMeshForScene({ map: texture }))

      renderer.setSize(containerWidth, containerHeight)
      container.appendChild(renderer.domElement)
      container.addEventListener("mousedown", onDocumentMouseDown, false)
      container.addEventListener("mousemove", onDocumentMouseMove, false)
      container.addEventListener("mouseup", onDocumentMouseUp, false)
      container.addEventListener("mousewheel", onMouseWheel, false)
      container.addEventListener("DOMMouseScroll", onMouseWheel, false)
      const zoomButton = document.getElementById("zoomButton")
      if (zoomButton) zoomButton.addEventListener("click", onZoomButton, false)

      resizeObserver.observe(renderElement.current, { box: "content-box" })
      animate()

      return () => {
        running = false
        container.removeEventListener("mousedown", onDocumentMouseDown)
        container.removeEventListener("mousemove", onDocumentMouseMove)
        container.removeEventListener("mouseup", onDocumentMouseUp)
        container.removeEventListener("mousewheel", onMouseWheel)
        container.removeEventListener("DOMMouseScroll", onMouseWheel)
        container.removeEventListener("click", onZoomButton)

        resizeObserver.disconnect()

        texture.dispose()
        scene.dispose()
        renderer.dispose()
        renderer.forceContextLoss() // Important, need to release the WebGL context
      }
    }
  }, [texture])

  return <div data-testid="loaded" ref={renderElement} className="threePanosphereContainer" />
}

export const PhotoViewer3d = (props: any) => {
  const { photo, zoomEnabled } = props
  const { loadTexture } = useLoadTexture()
  const [currentTextureLoader, setCurrentLoadingTexture] = useState(null)
  const [isTextureLoading, setIsTextureLoading] = useState(false)
  const [textureLoadingPercentage, setTextureLoadingPercentage] = useState(0)
  const [texture, setTexture] = useState(null)

  useEffect(() => {
    setCurrentLoadingTexture(currentTextureLoader => {
      if (currentTextureLoader) {
        currentTextureLoader.cancel()
      }

      setTexture(null)
      setIsTextureLoading(true)
      setTextureLoadingPercentage(0)

      getSnapshots(photo).then(response => {
        const textureLoader = loadTexture(
          URL.createObjectURL(new Blob([response.data])),
          (loadedTexture: THREE.Texture) => {
            setTexture(loadedTexture)
            // setIsTextureLoading(false)
            setCurrentLoadingTexture(null)
            setIsTextureLoading(false)
          },
          (xhr: any) => {
            setTextureLoadingPercentage((100 * xhr.loaded) / xhr.total)
          },
          () => {
            setTexture(null)
            setCurrentLoadingTexture(null)
            setIsTextureLoading(false)
            // TODO: Error handling
          }
        )
      })
    })

    return
  }, [loadTexture, photo, setCurrentLoadingTexture])

  return isTextureLoading ? (
    <TextureLoadingProgress loadingPercentage={textureLoadingPercentage} />
  ) : (
    <ThreePanosphereViewer texture={texture} zoomEnabled={zoomEnabled} />
  )
}
