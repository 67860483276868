import { useState, useEffect, useCallback } from "react"
export { isRequired } from "./models/FormValidators"

export const useForm = (formDetails: any) => {
  const [formValues, setValues] = useState(() => {
    const defaultValues = {}
    Object.keys(formDetails).forEach(key => {
      // @ts-ignore
      defaultValues[key] = formDetails[key].defaultValue
    })
    return defaultValues
  })
  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    const newErrorValue = {}

    Object.keys(formValues).forEach(key => {
      if (!formDetails[key] || !formDetails[key].validators) {
        return
      }

      const value = formValues[key]
      const errors = formDetails[key].validators.map((validator: any) => validator(value))
      newErrorValue[key] = errors[0] || ""
    })

    // setErrors(errors => {
    // 	const newErrors = Object.assign({}, errors, newErrorValue)
    // 	const isInvalid = Object.keys(newErrors).some(key => newErrors[key] != null && newErrors[key].length > 0)
    // 	setIsFormValid(!isInvalid)

    // 	return newErrors
    // })
  }, [formDetails, formValues])

  const [formErrors, setErrors] = useState(() => {
    const defaultErrors = {}
    Object.keys(formDetails).forEach(key => {
      // @ts-ignore
      defaultErrors[key] = ""
    })
    return defaultErrors
  })

  const setFormValue = useCallback(
    (key: string, value: any) => {
      if (!formDetails[key]) {
        console.error(`Key ${key} doesn't exist in this form`)
        return
      }

      const newValue = {}
      newValue[key] = value

      setValues(values => {
        return Object.assign({}, values, newValue)
      })
    },
    [formDetails]
  )

  const onFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const key = e.currentTarget.name
      const value = e.currentTarget.value
      setFormValue(key, value)
    },
    [setFormValue]
  )

  return { formValues, formErrors, setFormValue, onFieldChange, isFormValid }
}
