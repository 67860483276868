import { useEffect, useState } from "react"
import { ISite, IModelGroup, IModel, IPin } from "../../interfaces"
import {
  apiHostname,
  apiSitesAll,
  apiModelgroupsAll,
  apiModelsAll,
  apiSitesPins,
} from "../constants"
import { api, isError, APIError } from "../api"
import { toast } from "react-toastify"
import axios from "axios"

export const useApiSites = async (setSites: any, setLoading: any) => {
  // const [sites, setSites] = useState([] as Array<ISite>)
  // const [sitesLoading, setLoading] = useState(true)
  const url: string = apiHostname + apiSitesAll
  const token: string = localStorage.getItem("auth")

  useEffect(() => {
    axios.defaults.withCredentials = true
    axios
      .get(url, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .then(response => {
        setSites(Object.keys(response.data).map(key => response.data[key]))
      })
      .catch(error => {
        localStorage.removeItem("auth")
        localStorage.removeItem("userData")
        toast.error("There was a problem fetching sites")
        // window.location.reload()
      })

    setLoading(false)
  }, [])

  // return { sites, sitesLoading }
}

export const useApiModelGroups = async (setModelsGroups: any, setLoading: any) => {
  const token: string = localStorage.getItem("auth")
  const url: string = apiHostname + apiModelgroupsAll

  useEffect(() => {
    axios.defaults.withCredentials = true
    axios
      .get(url, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .then(response => {
        setModelsGroups(Object.keys(response.data).map(key => response.data[key]))
      })
      .catch(error => {
        toast.error("There was a problem fetching model groups")
      })

    setLoading(false)
  }, [])

  // return { modelGroups, modelGroupsLoading }
}

export const useApiModels = (setModels: any, setLoading: any) => {
  // const [models, setModels] = useState([] as Array<IModel>)
  // const [modelsLoading, setLoading] = useState(true)
  const token: string = localStorage.getItem("auth")
  const url: string = apiHostname + apiModelsAll

  useEffect(() => {
    axios.defaults.withCredentials = true
    axios
      .get(url, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      })
      .then(response => {
        setModels(Object.keys(response.data).map(key => response.data[key]))
      })
      .catch(error => {
        toast.error("There was a problem fetching models")
      })

    setLoading(false)
  }, [])
}

export const useApiSitePins = () => {
  const [sitePins, setSitePins] = useState([] as Array<IPin>)
  const [sitePinsLoading, setLoading] = useState(true)

  const token: string = localStorage.getItem("auth")
  const api: string = apiHostname + apiSitesPins

  useEffect(() => {
    axios.defaults.withCredentials = true
    axios
      .get(api, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      })
      .then(response => {
        setSitePins(Object.keys(response.data).map(key => response.data[key]))
      })
      .catch(error => {
        toast.error("There was a problem fetching site pins")
      })

    setLoading(false)
  }, [])

  return { sitePins, sitePinsLoading }
}
