import { useContext, useEffect, useState, useCallback } from "react"
import { ModelSelector } from "../ModelSelector"
import { NearbyImagesContext, ViewerContext } from "../../context"
import { NearbyPhotos } from "../NearbyPhotos/NearbyPhotos"
import { PopoutWindow } from "./PopoutWindow"

export const NearbyImagesStageViewer = (props: any) => {
  const { index, selectedModelType, setSelectedModelType } = props
  const { updateActiveWindows } = useContext(NearbyImagesContext)
  const { updateTilesets } = useContext(ViewerContext)
  const [poppedOut, setPoppedOut] = useState(false)

  useEffect(() => {
    updateTilesets(index, null, selectedModelType)
    updateActiveWindows(true)
    return () => updateActiveWindows(false)
  }, [index, selectedModelType])

  const windowCloseHandler = useCallback(() => setPoppedOut(false), [])

  return (
    <PopoutWindow poppedOut={poppedOut} onClose={windowCloseHandler}>
      <div className={"cesium-stage-viewer"}>
        <ModelSelector
          selectedModelType={selectedModelType}
          setSelectedModelType={setSelectedModelType}
        />
        <NearbyPhotos poppedOut={poppedOut} setPoppedOut={setPoppedOut} />
      </div>
    </PopoutWindow>
  )
}
