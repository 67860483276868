import * as React from "react"
import * as ReactDOM from "react-dom"

import "cesium/Build/Cesium/Widgets/widgets.css"
import "../src/assets/scss/dark.scss"

import { App } from "./components/App"
import { loadIcons } from "./helpers"

loadIcons()

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement)
