import { IAttachment } from "../../interfaces"
import { filesAttachmentsUpload } from "../constants"
import { api, isError, APIError, isAuthError } from "../api"

export const useApiAttachment = () => {
  const createAttachment = async (file: File): Promise<IAttachment> => {
    const data = new FormData()
    data.append("attachment", file)

    const response: any | APIError = await api.upload(filesAttachmentsUpload, data)
    if (isAuthError(response) || isError(response)) {
      throw new Error("Failed to upload attachment")
    }

    return response.attachment
  }

  return { createAttachment }
}
