/* tslint:disable:jsx-no-lambda */
import { useCallback, useContext, useMemo, useState } from "react"
import { IMOC } from "../../interfaces"
import { Button } from "trunx"
import { FontAwesomeIcon } from "../../helpers/icon"
import { Link } from "react-router-dom"
import { RenderIfPermission } from "../../helpers/Permissions"
import { useLocalStorageBoolean } from "../../hooks/useLocalStorage"
import { Loading } from "../Loading"
import { ShowMocCard } from "./ShowMocCard"
import { MocsContext } from "../../context"
import { VizTransition } from "../VizTransition"
import { withRouter } from "react-router"
import { FormControlLabel, FormGroup, Switch } from "@mui/material"
import styled from "@emotion/styled"

const MocItem = (props: any) => {
  const { moc, selected, hidden, onSelect, onHideChange } = props

  const toggleHidden = useCallback(
    e => {
      e.stopPropagation()
      onHideChange(!hidden)
    },
    [hidden, onHideChange]
  )

  return (
    <div
      className={`moc-card-line${selected ? " selected" : ""}`}
      onClick={onSelect}
      style={{ opacity: hidden ? 0.6 : 1 }}
    >
      <span className={"moc-name"}>{moc.name}</span>
      <span>
        <span className={"moc-hidden-icon"} onClick={toggleHidden}>
          <FontAwesomeIcon icon={hidden ? "eye-slash" : "eye"} />
        </span>
        {moc.state === "completed" && <span className="tag is-success">Completed</span>}
        {moc.state === "approved" && <span className="tag is-warning">Approved</span>}
        {moc.state === "proposed" && <span className="tag is-danger">Proposed</span>}
      </span>
    </div>
  )
}

export const MocsCard = withRouter((props: any) => {
  const {
    mocs,
    mocsLoading,
    showMocs,
    setShowMocs,
    hiddenMocsIDs,
    addToHiddenMocsIDs,
    removeFromHiddenMocsIDs,
  } = useContext(MocsContext)
  const [selectedMocId, setSelectedMocId] = useState(null as number | null)
  const [expanded, setExpanded] = useLocalStorageBoolean("mocs-site-card-expanded", true)

  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded, setExpanded])
  const toggleShowMocs = useCallback(() => {
    setShowMocs(!showMocs)
  }, [setShowMocs, showMocs])
  const clearSelectedMoc = useCallback(() => setSelectedMocId(null), [])

  const mocItems = useMemo(
    () =>
      mocs.map((moc: IMOC) => (
        <MocItem
          moc={moc}
          key={`moc-item-${moc.id}`}
          selected={moc.id === selectedMocId}
          hidden={moc.id && hiddenMocsIDs.includes(moc.id)}
          onSelect={() => setSelectedMocId(moc.id)}
          onHideChange={(hidden: boolean) =>
            hidden ? addToHiddenMocsIDs(moc.id) : removeFromHiddenMocsIDs(moc.id)
          }
        />
      )),
    [mocs, selectedMocId, hiddenMocsIDs, addToHiddenMocsIDs, removeFromHiddenMocsIDs]
  )

  const selectedMoc = mocs.find((moc: IMOC) => moc.id === selectedMocId)
  const newMocIsEnabled = props.location.pathname === "/mocs/add"

  const OrangeSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#faa21b",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      color: "#fff",
      backgroundColor: "#faa21b",
    },
  }))

  return (
    <VizTransition timeout={300}>
      <div>
        <div key={"moc-card-container"} className="card-container two-cards">
          <header className="card-header" onClick={toggleExpanded}>
            <p className="card-header-title">
              <span>Management of Change</span>
              <Button isText={true}>
                <span className="icon">
                  <FontAwesomeIcon icon={expanded ? "chevron-up" : "chevron-down"} />
                </span>
              </Button>
            </p>
          </header>
          {expanded && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ margin: "10px" }}>
                <FormGroup>
                  <FormControlLabel
                    onChange={toggleShowMocs}
                    control={<OrangeSwitch checked={showMocs} />}
                    label={"Show MOC"}
                  />
                </FormGroup>
              </div>
              <RenderIfPermission token={"MOC:CREATE"} siteSpecific={true}>
                <Link key={"add-moc-link"} to={newMocIsEnabled ? "/mocs" : "/mocs/add"}>
                  <Button isText={true} data-tip="Create a new MOC" isActive={newMocIsEnabled}>
                    <FontAwesomeIcon icon={"plus-octagon"} />
                    <span style={{ marginLeft: 10 }}>Add MOC</span>
                  </Button>
                </Link>
              </RenderIfPermission>
            </div>
          )}
          {mocsLoading ? (
            <Loading />
          ) : (
            <div className="card-content" key="moc-card-container">
              <div className="content">
                <div className={"mocs-results"}>
                  {expanded
                    ? mocItems
                    : `${mocs.length} ${mocs.length <= 1 ? "MOC" : "MOCs"}  available.`}
                </div>
              </div>
            </div>
          )}
        </div>
        {selectedMoc && <ShowMocCard moc={selectedMoc} onDeselect={clearSelectedMoc} />}
      </div>
    </VizTransition>
  )
})
