/* tslint:disable:jsx-no-lambda */
import { Fragment, useCallback, useContext, useEffect, useState } from "react"
import { SelectorButton } from "./selectorButton"
import { SelectorAlert } from "./selectorAlert"
import { ModelType } from "../../interfaces"
import { RenderIfPermission } from "../../helpers/Permissions"
import { ModelsContext } from "../../context"
import { defaultToAvailableModelType } from "./modelSelectorHelper"

import TexturedIcon from "../../assets/icons/textured.jpg"
import ShadedIcon from "../../assets/icons/shaded.jpg"
import ProjectLayerIcon from "../../assets/icons/projectlayer.jpg"
import PointCloudIcon from "../../assets/icons/pointcloud.jpg"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { TooltipConfigs } from "../../constants"

const buttons = [
  { type: ModelType.Textured, title: "Textured", icon: TexturedIcon, permission: "VIEW:TEXTURED" },
  { type: ModelType.Shaded, title: "Shaded", icon: ShadedIcon, permission: "VIEW:SHADED" },
  {
    type: ModelType.PointCloud,
    title: "Point Cloud",
    icon: PointCloudIcon,
    permission: "VIEW:POINTCLOUD",
  },
  { type: ModelType.CAD, title: "Project Layer", icon: ProjectLayerIcon, permission: "VIEW:CAD" },
  {
    type: ModelType.NearbyPhotos,
    title: "Nearby Photos",
    icon: TexturedIcon,
    permission: "FEATURE:NEARBYSNAPSHOTS",
  },
]

export const ModelSelector = (props: any) => {
  const { selectedModelType, setSelectedModelType } = props
  const { models, selectedModelGroup } = useContext(ModelsContext)

  const [alert, setAlert] = useState(false)
  const [isHovered, setHovered] = useState(false)

  useEffect(() => {
    setSelectedModelType(defaultToAvailableModelType(selectedModelType, models, selectedModelGroup))
  }, [selectedModelGroup, models, setSelectedModelType])

  const handleClick = useCallback(
    (modelType: ModelType) => {
      if (!selectedModelGroup) {
        return
      }

      const model = models.find(
        m => m.modelgroupId === selectedModelGroup.id && m.modeltypeId === modelType
      )

      if (modelType === ModelType.NearbyPhotos || model) {
        setSelectedModelType(modelType)
      } else {
        setAlert(true)
      }
    },
    [selectedModelGroup, models, setSelectedModelType]
  )

  const buttonComponents = buttons.map((button, i) => {
    const selected = button.type === selectedModelType

    return (
      <RenderIfPermission token={button.permission} siteSpecific={true} key={`button-${i}`}>
        <SelectorButton
          {...button}
          onClick={() => handleClick(button.type)}
          active={selected}
          style={{ order: !isHovered && selected ? -1 : i }}
        />
      </RenderIfPermission>
    )
  })

  // Displays model selector
  if (selectedModelGroup) {
    return (
      <Fragment>
        <Tooltip
          title={<Typography fontSize={TooltipConfigs.FontSize}>Layer Selection Menu</Typography>}
          placement="bottom-end"
          enterDelay={TooltipConfigs.Delay}
          enterNextDelay={TooltipConfigs.Delay}
        >
          <div
            className={"model-selector"}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {buttonComponents}
          </div>
        </Tooltip>
        {alert && <SelectorAlert onClose={() => setAlert(false)} />}
      </Fragment>
    )
  }

  return null
}
