/* tslint:disable:jsx-no-lambda */
import { Fragment, useState } from "react"
import { Box } from "trunx"
import { Link } from "react-router-dom"
import { styled } from "@mui/material/styles"
import { IRole } from "../../../interfaces"
import { useApiRoles } from "../../../api/ApiHooks"
import { Loading } from "../../Loading"
import { RenderIfPermission } from "../../../helpers/Permissions"
import { StandardDate } from "../../StandardDate"
import { Button } from "@mui/material"
import { orange } from "@mui/material/colors"

const AddButton = styled(Button)(({ theme }) => ({
  color: "White",
  backgroundColor: "Orange",
  "&:hover": {
    backgroundColor: orange[600],
    boxShadow: "none",
  },
  height: 40,
}))

interface IRoleRowProps {
  role: IRole
  onDelete: () => void
}

const RoleRow = (props: IRoleRowProps) => {
  const [expanded, setExpanded] = useState(false)
  const { role, onDelete } = props

  return (
    <Fragment>
      <div
        className={"list-row" + (expanded ? " list-row-selected" : "")}
        onClick={() => setExpanded(!expanded)}
      >
        <div className={"roles-list-item displayname"}>{role.displayName}</div>
        <div className={"roles-list-item displayname"}>{role.description}</div>
        <div className={"roles-list-item count"}>{role.userCount}</div>
        <div className={"roles-list-item created"}>
          <StandardDate date={role.created} />
        </div>
      </div>
      {expanded && (
        <div className={"list-row-controls-panel"}>
          <div className={"controls-container"}>
            <div style={{ marginRight: "10px" }}>Actions:</div>
            <RenderIfPermission token={"USERS:ARCHIVE"}>
              <div className={"action"} onClick={onDelete}>
                Delete
              </div>
            </RenderIfPermission>
            <RenderIfPermission token={"USERS:UPDATE"}>
              <div className={"action"}>
                <Link to={`/settings/security/roles/${role.id}/edit`}>Edit</Link>
              </div>
            </RenderIfPermission>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export const RolesManagement = () => {
  const { roles, rolesLoading, removeRole } = useApiRoles()

  return (
    <Box className={"roles-management"}>
      <h4 className={"is-4 title"}>Roles</h4>
      {rolesLoading && <Loading />}
      {!rolesLoading && (
        <Fragment>
          <RenderIfPermission token={"USERS:CREATE"}>
            <Link to={`/settings/security/roles/new`}>
              <AddButton style={{ marginBottom: "40px" }}>Add New Role</AddButton>
            </Link>
          </RenderIfPermission>

          <div className={"list-container"}>
            <div className={"list-row header"}>
              <div className={"roles-list-item header"}>Role Name</div>
              <div className={"roles-list-item header"}>Description</div>
              <div className={"roles-list-item header"}>Number of Users</div>
              <div className={"roles-list-item header"}>Created</div>
            </div>
            {roles?.length === 0 && (
              <div className={"list-empty-warning"}>There are no Roles setup yet</div>
            )}
            {roles?.map((role: IRole) => (
              <RoleRow key={role.id} role={role} onDelete={() => removeRole(role.id)} />
            ))}
          </div>
        </Fragment>
      )}
    </Box>
  )
}
