import { useState, useContext, useEffect } from "react"
import { ModelsContext } from "../../context"
import { useAsyncEffect } from "../../lib/UseAsyncEffect"
import {
  apiMetadataRoot,
  apiMetadataChild,
  apiMetadataItem,
  apiMetadataExternalQuery,
  apiHostname,
} from "../constants"
import { api, isError, APIError } from "../api"
import { toast } from "react-toastify"
import axios from "axios"
import { SecurityContext } from "../../context/SecurityProvider"

export const useApiCad = (path?: string) => {
  const { selectedSiteId } = useContext(ModelsContext)
  const { userData } = useContext(SecurityContext)
  const [items, setItems] = useState([] as Array<any>)
  const [itemsLoading, setLoading] = useState(true)

  const token: string = localStorage.getItem("auth")

  useEffect(() => {
    setLoading(true)
    const urlPath = path ? apiMetadataChild(selectedSiteId, path) : apiMetadataRoot(selectedSiteId)
    const url = apiHostname + "/api" + urlPath

    axios.defaults.withCredentials = true
    axios
      .get(url, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      })
      .then(response => {
        setItems(response.data.metadata)
      })
      .catch(error => {
        toast.error("There was a problem fetching CAD metadata")
      })

    setLoading(false)
  }, [])

  return { items, itemsLoading }
}

export const useApiCadMetadata = (item?: any) => {
  const { selectedSiteId } = useContext(ModelsContext)
  const { userData } = useContext(SecurityContext)
  const [fullItem, setFullItem] = useState(null as any)
  const [fullItemLoading, setLoading] = useState(true)

  const token: string = localStorage.getItem("auth")

  useEffect(() => {
    if (!item) {
      return
    }
    setLoading(true)

    axios.defaults.withCredentials = true

    const urlPath = apiHostname + "/api" + apiMetadataItem(selectedSiteId, item.id)
    axios
      .get(urlPath, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      })
      .then(response => {
        const result = response.data.metadata
        result.metadata = JSON.parse(result.metadata)
        setFullItem(response.data.metadata)
      })
      .catch(error => {
        toast.error("There was a problem fetching CAD metadata")
      })

    setLoading(false)
  }, [item])

  return { fullItem, fullItemLoading }
}

export const useApiCadExternalMetadata = (externalSource: any) => {
  const { selectedSiteId } = useContext(ModelsContext)
  const [metadata, setMetadata] = useState(null as any)
  const [metadataLoading, setLoading] = useState(true)

  useAsyncEffect(async () => {
    setLoading(true)

    const response: any | APIError = await api.get(
      apiMetadataExternalQuery(selectedSiteId, externalSource.sourceId, externalSource.tag)
    )

    if (isError(response)) {
      toast.error("There was a problem fetching CAD metadata")
    } else {
      const result = response.metadata
      setMetadata(result)
    }
    setLoading(false)
  }, [externalSource])

  return { metadata, metadataLoading }
}
