import * as React from "react"

export interface IButtonProps {
  active?: boolean
  onClick: () => void
  title: string
  icon: string
  style: any
}

export const SelectorButton = (props: IButtonProps) => {
  const { onClick, active, title, icon, style } = props

  return (
    <div
      key={"model-selection-button"}
      className={"model-selection" + (active ? " active" : "")}
      style={{ backgroundImage: `url(${icon})`, ...style }}
      onClick={onClick}
    >
      <span className={"model-label"}>{title}</span>
    </div>
  )
}
