import { Axis } from "../interfaces"

export function idSuffixToToggle(suffixStr: string) {
  const toggle: ISelectedToggle = {
    scalarDirection: 1,
    axis: Axis.X,
  }

  switch (suffixStr[0]) {
    case "+":
      toggle.scalarDirection = 1
      break
    case "-":
      toggle.scalarDirection = -1
      break
    default:
      throw new Error("Invalid toggle suffix")
  }

  switch (suffixStr[1]) {
    case "x":
      toggle.axis = Axis.X
      break
    case "y":
      toggle.axis = Axis.Y
      break
    case "z":
      toggle.axis = Axis.Z
      break
    default:
      throw new Error("Invalid toggle suffix")
  }

  return toggle
}

export interface ISelectedToggle {
  axis: Axis
  scalarDirection: number
}
