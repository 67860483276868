/* tslint:disable:jsx-no-lambda */
import { IUser } from "../../../interfaces"
// import {Button} from "trunx";
// const { Button } = require('@ninjadojo/bulma-components');
// import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Button } from "trunx"
import { exportToCsv } from "../../../helpers/export"
import React from "react"

interface IUsersExportToCsv {
  users: IUser[]
}

export const UsersExportToCsv = (props: IUsersExportToCsv) => {
  const exportCsvRequiredFields = [
    "firstName",
    "lastName",
    "email",
    "role",
    "disabledOn",
    "createdAt",
    "lastActive",
  ]

  return (
    <Button
      isSuccess={true}
      style={{ float: "right" }}
      onClick={() => exportToCsv(props.users, exportCsvRequiredFields)}
    >
      Export to CSV
    </Button>
  )
}
