import { useEffect, useContext, useRef } from "react"
import { ViewerContext } from "../context"
import { CesiumDragEvent, DragEvent } from "./models/CesiumDragEvent"
import * as Cesium from "cesium"

export const useCesiumOnDrag = (handler: (e: CesiumDragEvent) => boolean) => {
  const { viewer, pickedPointCache } = useContext(ViewerContext)
  const mouseDown = useRef(false)
  const handlerRef = useRef(handler)

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  useEffect(() => {
    if (!viewer) {
      return
    }

    const eventHandler = new Cesium.ScreenSpaceEventHandler(viewer.canvas)

    eventHandler.setInputAction((e: Cesium.PositionedEvent) => {
      const event = new CesiumDragEvent(viewer, e.position, DragEvent.DragStart, pickedPointCache)

      if (handlerRef.current(event)) {
        viewer.scene.screenSpaceCameraController.enableRotate = false
        mouseDown.current = true
      }
    }, Cesium.ScreenSpaceEventType.LEFT_DOWN)

    eventHandler.setInputAction((e: Cesium.PositionedEvent) => {
      if (mouseDown.current) {
        viewer.scene.screenSpaceCameraController.enableRotate = true
        const event = new CesiumDragEvent(viewer, e.position, DragEvent.DragEnd, pickedPointCache)
        handlerRef.current(event)
        mouseDown.current = false
      }
    }, Cesium.ScreenSpaceEventType.LEFT_UP)

    eventHandler.setInputAction((e: Cesium.MoveEvent) => {
      if (mouseDown.current) {
        const event = new CesiumDragEvent(
          viewer,
          e.endPosition,
          DragEvent.MouseMove,
          pickedPointCache
        )
        handlerRef.current(event)
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    return () => {
      eventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN)
      eventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP)
      eventHandler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE)
      eventHandler.destroy()
    }
  }, [pickedPointCache, viewer])
}
