import { createContext, useEffect, useState } from "react"
import { useLocalStorageBoolean } from "../hooks/useLocalStorage"

export enum SplitMode {
  Hidden,
  None,
  Top,
  Right,
  Bottom,
  Left,
}

export const AppContext = createContext({
  controlsHidden: false,
  // tslint:disable-next-line:no-empty
  setControlsHidden: (value: boolean) => {},
  screenWidth: 0,
  screenHeight: 0,
  theme: "dark",
  // tslint:disable-next-line:no-empty
  setTheme: (value: string) => {},
  splitMode: SplitMode.None,
  // tslint:disable-next-line:no-empty
  setSplitMode: (value: SplitMode) => {},
})

export const AppContextProvider = (props: any) => {
  const [controlsHidden, setControlsHidden] = useLocalStorageBoolean("controls-hidden", false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const [theme, setTheme] = useState("dark")
  const [splitMode, setSplitMode] = useState(SplitMode.Hidden)

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth)
      setScreenHeight(window.innerHeight)
    })
  }, [])

  useEffect(() => {
    const darkMode = theme === "dark"
    let stylesheet = document.styleSheets.item(0)

    for (let i = 0; stylesheet; stylesheet = document.styleSheets.item(i++)) {
      if (stylesheet.href && stylesheet.href.endsWith("/dark.css")) {
        stylesheet.disabled = !darkMode
      } else if (stylesheet.href && stylesheet.href.endsWith("/light.css")) {
        stylesheet.disabled = darkMode
      }
    }
  }, [theme])

  return (
    <AppContext.Provider
      value={{
        controlsHidden,
        setControlsHidden,
        screenWidth,
        screenHeight,
        theme,
        setTheme,
        splitMode,
        setSplitMode,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}
