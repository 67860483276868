function lengthOfLocalGreaterThan(email: string, limit: number) {
  const splitEmail = email.split("@")
  return splitEmail[0].length > limit
}

function multipleAtSymbols(email: string) {
  return (email.match(/@/g) || []).length > 1
}

export function ValidateEmail(email: string) {
  const re = /\S+@\S+\.\S+/
  const specialCharacters = /[`#$^&*()_\=\[\]{};'\\|,<>?~"]/

  if (lengthOfLocalGreaterThan(email, 64)) return false
  if (multipleAtSymbols(email)) return false
  if (specialCharacters.test(email)) return false
  return re.test(email)
}
