/* tslint:disable:jsx-no-lambda */
import { useState } from "react"
import { Link } from "react-router-dom"
import { Loading } from "../../Loading"
import { useApiUsers } from "../../../api/ApiHooks"
import { RenderIfPermission } from "../../../helpers/Permissions"
import { StandardDate } from "../../StandardDate"
import { UsersExportToCsv } from "./UsersExportToCsv"
import { Box } from "trunx"
import { styled } from "@mui/material/styles"
import { Button } from "@mui/material"
import { orange } from "@mui/material/colors"

const AddButton = styled(Button)(({ theme }) => ({
  color: "White",
  backgroundColor: "Orange",
  "&:hover": {
    backgroundColor: orange[600],
    boxShadow: "none",
  },
  height: 40,
}))

const UserRow = (props: any) => {
  const { user, onEnable, onDisable, onArchive, onResetPassword, onClearSSO, archived } = props
  const [expanded, setExpanded] = useState(false)

  return (
    <div>
      {!archived ? (
        <div
          onClick={() => setExpanded(!expanded)}
          className={"list-row" + (expanded ? " list-row-selected" : "")}
        >
          <div className={"users-list-item lastname"}>{user.lastName}</div>
          <div className={"users-list-item firstname"}>{user.firstName}</div>
          <div className={"users-list-item email"}>{user.email}</div>
          <div className={"users-list-item last-active"}>
            <StandardDate date={user.data.lastActive} emptyText={"Never"} />
          </div>
          <div className={"users-list-item sso-active"}>
            {user.ssoTid && user.ssoSub && (
              <img src="/assets/icons/sso_microsoft.svg" title="Azure SSO Enabled" alt="" />
            )}
          </div>
        </div>
      ) : null}
      {expanded ? (
        <div className={"list-row-controls-panel"}>
          <div className={"controls-container"}>
            <div style={{ marginRight: "10px" }}>Actions:</div>
            <RenderIfPermission token={"USERS:CREATE"}>
              <div className={"action"} onClick={onArchive}>
                Delete
              </div>
            </RenderIfPermission>

            <RenderIfPermission token={"USERS:UPDATE"}>
              <div className={"action"}>
                <Link to={`/settings/security/users/${user.id}/edit`}>Edit</Link>
              </div>

              <div className={"action"} onClick={onResetPassword}>
                Send Password Reset Email
              </div>

              <div className={"action"} onClick={user.disabled ? onEnable : onDisable}>
                {user.disabled ? "Enable" : "Disable"} User
              </div>

              {user.ssoTid && user.ssoSub && (
                <div className={"action"} onClick={onClearSSO}>
                  Clear Azure SSO association
                </div>
              )}
            </RenderIfPermission>
          </div>
        </div>
      ) : null}
    </div>
  )
}

const UsersManagement = () => {
  const [archived, setArchived] = useState(false)
  const {
    users,
    usersLoading,
    resetPassword,
    enableUser,
    disableUser,
    archiveUser,
    clearSSOAccount,
  } = useApiUsers(archived)

  const toggleArchived = () => {
    if (archived) setArchived(false)
    setArchived(true)
  }

  const getArchived = () => {
    if (archived) return "true"
    return "false"
  }

  if (usersLoading) {
    return (
      <Box className={"admin-users"}>
        <Loading />
      </Box>
    )
  }

  return (
    <Box className={"admin-users"}>
      <h4 className={"is-4 title"}>Manage Users</h4>

      <RenderIfPermission token={"USERS:CREATE"}>
        <Link to={`/settings/security/users/new`}>
          <AddButton style={{ marginBottom: "40px" }}>Add New User</AddButton>
        </Link>
      </RenderIfPermission>

      <UsersExportToCsv users={users} />

      <div className={"list-container"}>
        <div className={"list-row header"}>
          <div className={"users-list-item lastname header"}>Last Name</div>
          <div className={"users-list-item firstname header"}>First Name</div>
          <div className={"users-list-item email header"}>Email</div>
          <div className={"users-list-item last-active header"}>Last Active</div>
          <div className={"users-list-item sso-active header"}>SSO</div>
        </div>
        {users?.length === 0 ? (
          <div className={"list-empty-warning"}>There are no Users setup yet</div>
        ) : null}
        {users?.map(user => (
          <UserRow
            key={user.id}
            user={user}
            onEnable={() => enableUser(user)}
            onDisable={() => disableUser(user)}
            onResetPassword={() => resetPassword(user)}
            onArchive={() => archiveUser(user)}
            onClearSSO={() => clearSSOAccount(user)}
            archived={user.archived}
          />
        ))}
      </div>
    </Box>
  )
}

export { UsersManagement }
