import { useContext, useCallback } from "react"
import dateFormat from "dateformat"
import { getBookmark } from "../helpers/viewer"
import { ISnapshot } from "../interfaces"
import { PermissionContext, ViewerContext, ModelsContext } from "../context"
import * as Cesium from "cesium"

export const useTakeScreenshot = () => {
  const { currentUser } = useContext(PermissionContext)
  const { viewer } = useContext(ViewerContext)
  const { selectedSiteId } = useContext(ModelsContext)

  const takeScreenshot = useCallback(
    (name: string): Promise<{ payload: ISnapshot; blob: Blob }> => {
      return new Promise((res, rej) => {
        if (!viewer) {
          rej()
          return
        }

        const now = new Date()
        const description = "Created: " + dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT")
        const bookmark = getBookmark(viewer, name, description)

        viewer.canvas.toBlob(
          (blob: Blob) => {
            const snapshot: ISnapshot = {
              name,
              description,
              siteId: selectedSiteId,
              takenBy: currentUser.id,
              data: { bookmark },
            }
            const cartographic = Cesium.Cartographic.fromCartesian(bookmark.position)
            const poi = {
              latitude: Cesium.Math.toDegrees(cartographic.latitude),
              longitude: Cesium.Math.toDegrees(cartographic.longitude),
              altitude: cartographic.height,
              data: {},
            }

            const payload = { ...snapshot, pois: { 1: poi } }

            res({ payload, blob })
          },
          "image/jpeg",
          0.9
        )
      })
    },
    [viewer, currentUser, selectedSiteId]
  )

  return { takeScreenshot }
}
