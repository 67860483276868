import proj4 from "proj4"
import { IUTM, LongLat } from "../interfaces"
import { ModelProjectionType } from "../context/Models"
import * as Cesium from "cesium"

const utmProjectionWithZone = (zone: number) => {
  return `+proj=utm +south +zone=${zone} +ellps=WGS84 +datum=WGS84 +units=m +no_defs`
}

export const prettyPrintCoords = (
  projectionType: ModelProjectionType,
  position: Cesium.Cartesian3,
  opts?: {
    lineBreaks?: boolean
    isHTML?: boolean
  }
): string => {
  const cartographic = Cesium.Cartographic.fromCartesian(position)
  const long = Cesium.Math.toDegrees(cartographic.longitude)
  const lat = Cesium.Math.toDegrees(cartographic.latitude)
  const height = cartographic.height
  let text = []

  if (!opts) {
    opts = { lineBreaks: false, isHTML: false }
  }
  switch (projectionType) {
    case ModelProjectionType.UTM:
      const result = printCoords(projectionType, long, lat)
      if (!isUTM(result)) {
        console.debug("UTM Check failed: VIS32165")
        return ""
      }
      text = [
        `Easting: ${result.easting.toFixed(4)}`,
        `Northing: ${result.northing.toFixed(4)}`,
        `Zone: ${result.zone.toFixed(4)}`,
        `Height: ${height.toFixed(2)}m`,
      ]

      break
    default:
      text = [
        `Lon: ${long.toFixed(6)}°`,
        `Lat: ${lat.toFixed(6)}°`,
        `Height: ${height.toFixed(2)}m`,
      ]
  }

  return text.join(opts.lineBreaks ? (opts.isHTML ? "<br/>" : "\n") : " ")
}

export const printCoords = (
  type: ModelProjectionType,
  long: number,
  lat: number
): LongLat | IUTM => {
  if (type === ModelProjectionType.UTM) {
    return wgs84toUTM(long, lat)
  }

  return { latitude: lat, longitude: long }
}

const longitude2utmZone = (longitude: number): number => {
  return (Math.floor((longitude + 180) / 6) % 60) + 1
}

// expects degrees
export const wgs84toUTM = (long: number, lat: number): IUTM => {
  const zone = longitude2utmZone(long)
  const utm = utmProjectionWithZone(zone)

  const result = proj4(utm, [long, lat])
  return {
    easting: result[0],
    northing: result[1],
    zone,
  }
}

export const isUTM = (coords: IUTM | LongLat): coords is IUTM => {
  return (coords as IUTM).easting !== undefined
}
