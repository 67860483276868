import { React } from "react"

import { PhotoViewer2d } from "./PhotoViewer2d"
import { PhotoViewer3d } from "./PhotoViewer3d"

export const PhotoViewer = (props: any) => {
  const { photo, waitingOnClick } = props

  if (!photo) {
    return (
      <div className={"photo-call-to-action"}>
        {waitingOnClick
          ? "Click on the model to show nearby photos around that location"
          : "We couldn't find any images around that location. Please try again in a different area"}
      </div>
    )
  }

  return photo.heightType === "panosphere" ? (
    <PhotoViewer3d {...props} />
  ) : (
    <PhotoViewer2d {...props} />
  )
}
