/* tslint:disable:jsx-no-lambda */
import { useContext, useEffect, useState } from "react"
import { ViewerContext } from "../../context"
import { useLocalStorageString } from "../../hooks/useLocalStorage"
import { GithubPicker } from "react-color"
import { Cesium3DTileStyle, Viewer } from "cesium"

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]]/g, "\\$&") // $& means the whole matched string
}

export const CadFilterMenu = (props: any) => {
  const { item, hoveredPath } = props
  const { tilesets, viewersForEach } = useContext(ViewerContext)
  const [mode, setMode] = useLocalStorageString("metadata-mode", "show-selected")
  const [alpha, setAlpha] = useState(2)
  const [colour, setColour] = useLocalStorageString("metadata-filter-colour", "#00FFFF")

  useEffect(() => {
    viewersForEach((viewer: Viewer, viewerIdx: number) => {
      const tileset = tilesets[viewerIdx]
      // @ts-ignore
      if (!tileset || !tileset._basePath.includes("/cad")) {
        return
      }

      const conditions: any = []

      if (hoveredPath) {
        // eslint-disable-next-line no-template-curly-in-string
        conditions.push([
          "regExp('^" + escapeRegExp(hoveredPath) + ".*').test(String(${pathId})) === true",
          `color('${colour}', 1)`,
        ])
      }

      if (item != null) {
        switch (mode) {
          case "hide-selected":
            // eslint-disable-next-line no-template-curly-in-string
            conditions.push([
              "regExp('^" + escapeRegExp(item.batchId) + ".*').test(String(${pathId})) === false",
              `rgba(255, 255, 255, ${(alpha / 100.0).toFixed(3)})`,
            ])
            break
          case "show-selected":
            // eslint-disable-next-line no-template-curly-in-string
            conditions.push([
              "regExp('^" + escapeRegExp(item.batchId) + ".*').test(String(${pathId})) === true",
              `color('${colour}', 1)`,
            ])
            break
        }
      }

      conditions.push(["true", "rgba(255, 255, 255, 1.0)"])

      tileset.style = new Cesium3DTileStyle({
        color: { conditions },
      })

      viewer.scene.requestRender()
    })
  }, [viewersForEach, item, hoveredPath, mode, alpha, colour, tilesets])

  return (
    <div className={"cad-filter-menu"}>
      <span className={"cad-filter-icon" + (mode === "show-selected" ? " selected" : "")}>
        <img
          src={"assets/icons/show_unselected.png"}
          onClick={() => setMode("show-selected")}
          alt=""
        />
      </span>

      <span className={"cad-filter-icon" + (mode === "hide-selected" ? " selected" : "")}>
        <img
          src={"assets/icons/hide_unselected.png"}
          onClick={() => setMode("hide-selected")}
          alt=""
        />
        <input
          type="range"
          min="0"
          max="100"
          value={alpha}
          disabled={mode !== "hide-selected"}
          onChange={(e: any) => setAlpha(e.target.value)}
        />
        <span>{alpha}%</span>
      </span>
      <span className={"cad-filter-icon colour-picker-icon"}>
        <span className={"colour-block"} style={{ backgroundColor: colour }} />
        <span className={"colour-picker"}>
          <span className={"colour-picker-content"}>
            <GithubPicker
              width="190px"
              triangle="top-right"
              onChange={color => setColour(color.hex)}
            />
          </span>
        </span>
      </span>
    </div>
  )
}
