import { useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StageViewerContext } from "../CesiumViewer/CesiumStageViewer"

const classNameEnabled = "camera-navigation-button"
const classNameDisabled = "camera-navigation-button-disabled"

export const BackForwardControls = () => {
  const { cameraHistory, cameraGoBack, cameraGoForward } = useContext(StageViewerContext)

  const backClassName = cameraHistory.historyOffset > 0 ? classNameEnabled : classNameDisabled
  const forwardClassName =
    cameraHistory.historyOffset < cameraHistory.cameraHistory?.length - 1
      ? classNameEnabled
      : classNameDisabled

  return (
    <div className={"camera-navigation"}>
      <span className={backClassName} onClick={cameraGoBack}>
        <FontAwesomeIcon icon={"arrow-left"} title={"Previous Position"} />
      </span>

      <span className={forwardClassName} onClick={cameraGoForward}>
        <FontAwesomeIcon icon={"arrow-right"} title={"Next Position"} />
      </span>
    </div>
  )
}
