/* tslint:disable:jsx-no-lambda */
import * as React from "react"
import { Fragment, useState } from "react"
import { useApiDefaultPermissions, useApiSites } from "../../../api/ApiHooks"
import { IPermissionFieldDefault, IPermissionsMap, ISite } from "../../../interfaces"
import { FeaturePermissionToggle } from "./FeaturePermissionToggle"
import { Tab, Tabs } from "@mui/material"

const CATEGORY_TABLE = {
  general: { name: "General", order: 1 },
  moc: { name: "MOC", order: 2 },
  pointgrab: { name: "Point Grab", order: 3 },
  snapshots: { name: "Snapshots", order: 4 },
  tools: { name: "Tools", order: 5 },
  licensing: { name: "Licensing", order: 6 },
  users: { name: "Users", order: 7 },
  teams: { name: "Teams", order: 8 },
  roles: { name: "Roles", order: 9 },
}

const GLOBAL_SITE = [{ id: 0, name: "Global" }]

interface IPermissionTableProps {
  permissionMap: IPermissionsMap
  onPermissionChange: (siteID: number, token: string, value: boolean | null) => void
}

interface IPermissionFieldProps {
  siteSpecific: boolean
  defaultPermissions: Array<IPermissionFieldDefault>
  sites: Array<ISite>
  permissionMap: IPermissionsMap
  onPermissionChange: (siteID: number, token: string, value: boolean | null) => void
}

const PermissionTable = (props: IPermissionFieldProps) => {
  const { siteSpecific, defaultPermissions, permissionMap, onPermissionChange } = props
  const permissions = defaultPermissions.filter(
    permission => permission.siteSpecific === siteSpecific
  )
  const categoryHeaders = permissions.reduce(
    (acc: Array<string>, permission) =>
      acc.includes(permission.category) ? acc : acc.concat([permission.category]),
    []
  )
  const sites = siteSpecific ? props.sites : GLOBAL_SITE

  const categories = categoryHeaders
    .sort((a, b) => CATEGORY_TABLE[a].order - CATEGORY_TABLE[b].order)
    .map(categoryKey => (
      <Fragment key={categoryKey}>
        <tr>
          <th className="category-header">{CATEGORY_TABLE[categoryKey].name}</th>
        </tr>

        {permissions
          .filter(permission => permission.category === categoryKey)
          .map(permission => (
            <tr key={permission.token}>
              <th>{permission.displayName}</th>
              {sites.map(site => (
                <td key={`${permission.token}-${site.id}`}>
                  <FeaturePermissionToggle
                    permissionValue={
                      permissionMap[site.id] ? permissionMap[site.id][permission.token] : null
                    }
                    setPermissionValue={value =>
                      onPermissionChange(site.id, permission.token, value)
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
      </Fragment>
    ))

  return (
    <table>
      <thead>
        <tr>
          <th />
          {sites.map(site => (
            <th key={site.id}>{site.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>{categories}</tbody>
    </table>
  )
}

export const PermissionField = (props: IPermissionTableProps) => {
  const { permissionMap, onPermissionChange } = props
  const [siteSpecificFilter, setSiteSpecificFilter] = useState(true)
  const { defaultPermissions, defaultPermissionsLoading } = useApiDefaultPermissions()
  const [sites, setSites] = useState([] as Array<ISite>)
  const [sitesLoading, setSitesLoading] = useState(true)
  useApiSites(setSites, setSitesLoading)

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const style = {
    color: "white",
    borderBottom: "1px solid white",
    height: 30,
  }

  return (
    <div className="role-permission-field">
      <Tabs
        TabIndicatorProps={{ style: { background: "Orange" } }}
        value={value}
        onChange={handleChange}
      >
        <Tab
          style={style}
          label="Site Specific"
          {...a11yProps(0)}
          onClick={() => setSiteSpecificFilter(true)}
        />
        <Tab
          style={style}
          label="Global"
          {...a11yProps(1)}
          onClick={() => setSiteSpecificFilter(false)}
        />
      </Tabs>
      <div className="table-container">
        <PermissionTable
          siteSpecific={siteSpecificFilter}
          defaultPermissions={defaultPermissions}
          sites={sites}
          permissionMap={permissionMap}
          onPermissionChange={onPermissionChange}
        />
      </div>
    </div>
  )
}
