import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react"
import { useNearbyImages } from "../hooks/useNearbyImages"
import { INearbySnapshot } from "../interfaces"
import { ModelsContext, ViewerContext } from "../context"
import {
  useLocalStorageArray,
  useLocalStorageBoolean,
  useLocalStorageEnum,
} from "../hooks/useLocalStorage"
import { Matrix4 } from "cesium"

export enum NearbyImageMode {
  Click = "click",
  Camera = "camera",
}

export enum NearbyImageHeightMode {
  All = "all",
  Ground = "ground",
  Airborne = "airborne",
  Panosphere = "panosphere",
}

export enum NearbyImageSearchRadius {
  Near = 1,
  Middle = 2,
  Far = 3, // TODO: what is this supposed to be? It's 10 for exxon, 3 according to code
}

export const NearbyImagesContext = createContext({
  nearbyToolActive: false,
  // tslint:disable-next-line:no-empty
  setNearbyToolActive: (active: boolean) => {},
  // tslint:disable-next-line:no-empty
  updateActiveWindows: (isOpen: boolean) => {},
  mode: NearbyImageMode.Click,
  // tslint:disable-next-line:no-empty
  setMode: (mode: NearbyImageMode) => {},
  nearbyImagesLoading: false,
  nearbyImages: null as null | INearbySnapshot[],
  getNearbyPhotosTransform: (): Promise<Matrix4 | null> => new Promise(res => res(null)),
  billboardsEnabled: true,
  // tslint:disable-next-line:no-empty
  setBillboardsEnabled: (enabled: boolean) => {},
  nearbyImageFilter: [] as Array<NearbyImageHeightMode>,
  // tslint:disable-next-line:no-empty
  addNearbyImageFilter: (value: NearbyImageHeightMode) => {},
  // tslint:disable-next-line:no-empty
  removeNearbyImageFilter: (value: NearbyImageHeightMode) => {},
})

export const NearbyImagesContextProvider = (props: any) => {
  const { getTilesetTransform } = useContext(ViewerContext)
  const { selectedSite } = useContext(ModelsContext)

  const [activeWindows, setActiveWindows] = useState(0)
  const [nearbyToolActive, setNearbyToolActive] = useState(false)
  const [mode, setMode] = useLocalStorageEnum<NearbyImageMode>(
    "nearby-image-mode",
    NearbyImageMode.Click
  )
  const [billboardsEnabled, setBillboardsEnabled] = useLocalStorageBoolean(
    "nearby-billboards-enabled",
    true
  )
  const [nearbyImageFilter, addNearbyImageFilter, removeNearbyImageFilter] = useLocalStorageArray<
    NearbyImageHeightMode
  >("nearby-image-filter", [
    NearbyImageHeightMode.Airborne,
    NearbyImageHeightMode.Ground,
    NearbyImageHeightMode.Panosphere,
  ])
  const [searchRadius] = useLocalStorageEnum<NearbyImageSearchRadius>(
    "nearby-search-radius",
    NearbyImageSearchRadius.Far
  )
  const currentTransform = useRef(null as Matrix4 | null)

  useEffect(() => {
    // Reset memoized transform on site change
    currentTransform.current = null
  }, [selectedSite])

  const getNearbyPhotosTransform = useCallback(async () => {
    let transform = currentTransform.current

    if (!transform) {
      // Use the poincloud transform first, if it doesn't exist use the textured model transform
      currentTransform.current = await getTilesetTransform(1)

      if (!currentTransform.current) {
        currentTransform.current = await getTilesetTransform(3)
      }

      transform = currentTransform.current
    }
    return transform
  }, [getTilesetTransform])

  const updateActiveWindows = useCallback((isOpen: boolean) => {
    // Remove loading image. Only required when nearby images in the only viewer active
    const loading = document.getElementById("app-loading")
    if (loading && loading.parentNode) {
      loading.parentNode.removeChild(loading)
    }

    setActiveWindows((activeWindowCount: number) => {
      return isOpen ? activeWindowCount + 1 : activeWindowCount - 1
    })
  }, [])

  const { nearbyImagesLoading, nearbyImages } = useNearbyImages(
    nearbyToolActive,
    activeWindows,
    mode,
    nearbyImageFilter,
    searchRadius,
    billboardsEnabled,
    getNearbyPhotosTransform
  )

  return (
    <NearbyImagesContext.Provider
      value={{
        nearbyToolActive,
        setNearbyToolActive,
        updateActiveWindows,
        mode,
        setMode,
        nearbyImagesLoading,
        nearbyImages,
        getNearbyPhotosTransform,
        billboardsEnabled,
        setBillboardsEnabled,
        nearbyImageFilter,
        addNearbyImageFilter,
        removeNearbyImageFilter,
      }}
    >
      {props.children}
    </NearbyImagesContext.Provider>
  )
}
