import { Fragment, useCallback, useContext } from "react"
import { ButtonGroup, Checkbox, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { NearbyImagesContext, ViewerContext } from "../../context"
import { NearbyImageMode } from "../../context/NearbyImages"
import { StageViewerContext } from "../CesiumViewer/CesiumStageViewer"
import { PhotoTypesSelector } from "./PhotoTypesSelector"

import CameraAltIcon from "@mui/icons-material/CameraAlt"
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined"
import ImageIcon from "@mui/icons-material/Image"
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined"
import DownloadIcon from "@mui/icons-material/Download"
import SendIcon from "@mui/icons-material/Send"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import RotateLeftIcon from "@mui/icons-material/RotateLeft"
import RotateRightIcon from "@mui/icons-material/RotateRight"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import ZoomOutIcon from "@mui/icons-material/ZoomOut"
import { apiHostname } from "../../api/constants"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { TooltipConfigs } from "../../constants"

const iconStyle = {
  color: "white",
  width: 30,
  height: 30,
}

export const PhotoControls = (props: any) => {
  const {
    currentPhoto,
    zoomEnabled,
    setZoomEnabled,
    nextImage,
    setPhotoRotation,
    poppedOut,
    setPoppedOut,
  } = props
  const { flyTo } = useContext(ViewerContext)
  const {
    getNearbyPhotosTransform,
    setMode,
    mode,
    billboardsEnabled,
    setBillboardsEnabled,
  } = useContext(NearbyImagesContext)
  const { currentWindow } = useContext(StageViewerContext)

  const flyToCurrentPhoto = useCallback(() => {
    if (currentPhoto !== null) {
      if (currentPhoto?.data !== null) {
        const bookmark = currentPhoto.data.bookmark
        getNearbyPhotosTransform().then(response => {
          if (response) {
            flyTo(bookmark, response)
          }
        })
      }
    }
  }, [currentPhoto, flyTo, getNearbyPhotosTransform])
  /* tslint:disable */
  const RotateIcons = () => {
    if (currentPhoto?.heightType.toLowerCase() === "panosphere") return null
    return (
      <div className={"nearby-navigate-rotation"} data-testid={"rotateIcons"}>
        <ButtonGroup variant="contained">
          <span>
            <Tooltip
              title={
                <Typography fontSize={TooltipConfigs.FontSize}>Rotate Anti-clockwise</Typography>
              }
              placement="left"
              enterDelay={TooltipConfigs.Delay}
              enterNextDelay={TooltipConfigs.Delay}
            >
              <IconButton
                onClick={() => setPhotoRotation((curRotation: number) => curRotation - 90)}
              >
                <RotateLeftIcon sx={{ ...iconStyle }} />
              </IconButton>
            </Tooltip>
          </span>
          <span>
            <Tooltip
              title={<Typography fontSize={TooltipConfigs.FontSize}>Rotate Clockwise</Typography>}
              placement="left"
              enterDelay={TooltipConfigs.Delay}
              enterNextDelay={TooltipConfigs.Delay}
            >
              <IconButton
                onClick={() => setPhotoRotation((curRotation: number) => curRotation + 90)}
                className="mirror"
              >
                <RotateRightIcon sx={{ ...iconStyle }} />
              </IconButton>
            </Tooltip>
          </span>
        </ButtonGroup>
      </div>
    )
  }

  const NavigateControls = () => {
    return (
      <div className={"nearby-navigate-photo"}>
        <ButtonGroup variant="contained">
          <span>
            <Tooltip
              title={<Typography fontSize={TooltipConfigs.FontSize}>Image Left</Typography>}
              placement="left"
              enterDelay={TooltipConfigs.Delay}
              enterNextDelay={TooltipConfigs.Delay}
            >
              <IconButton onClick={() => nextImage(-1)}>
                <ChevronLeftIcon sx={{ ...iconStyle }} />
              </IconButton>
            </Tooltip>
          </span>
          <span>
            <Tooltip
              title={<Typography fontSize={TooltipConfigs.FontSize}>Image Right</Typography>}
              placement="left"
              enterDelay={TooltipConfigs.Delay}
              enterNextDelay={TooltipConfigs.Delay}
            >
              <IconButton onClick={() => nextImage(1)}>
                <ChevronRightIcon sx={{ ...iconStyle }} />
              </IconButton>
            </Tooltip>
          </span>
        </ButtonGroup>
      </div>
    )
  }

  return (
    <Fragment>
      <div className={"nearby-actions"}>
        <Tooltip
          title={<Typography fontSize={TooltipConfigs.FontSize}>Enable Camera Mode</Typography>}
          placement="left"
          enterDelay={TooltipConfigs.Delay}
          enterNextDelay={TooltipConfigs.Delay}
        >
          <span>
            <Checkbox
              icon={<CameraAltIcon sx={{ ...iconStyle }} />}
              checkedIcon={<CameraAltOutlinedIcon sx={{ ...iconStyle }} />}
              checked={mode === NearbyImageMode.Camera}
              onClick={() =>
                setMode(
                  mode === NearbyImageMode.Click ? NearbyImageMode.Camera : NearbyImageMode.Click
                )
              }
            />
          </span>
        </Tooltip>
        <Tooltip
          title={
            <Typography fontSize={TooltipConfigs.FontSize}>
              Enable Image Thumbnails in 3D View
            </Typography>
          }
          placement="left"
          enterDelay={TooltipConfigs.Delay}
          enterNextDelay={TooltipConfigs.Delay}
        >
          <span>
            <Checkbox
              icon={<ImageIcon sx={{ ...iconStyle }} />}
              checkedIcon={<ImageOutlinedIcon sx={{ ...iconStyle }} />}
              checked={billboardsEnabled}
              onClick={() => setBillboardsEnabled(!billboardsEnabled)}
            />
          </span>
        </Tooltip>
        {poppedOut ? (
          <Tooltip
            title={<Typography fontSize={TooltipConfigs.FontSize}>Close window</Typography>}
            placement="left"
            enterDelay={TooltipConfigs.Delay}
            enterNextDelay={TooltipConfigs.Delay}
          >
            <span>
              <IconButton onClick={() => setPoppedOut(false)}>
                <CloseIcon sx={{ ...iconStyle }} />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              <Typography fontSize={TooltipConfigs.FontSize}>Pop out to new window</Typography>
            }
            placement="left"
            enterDelay={TooltipConfigs.Delay}
            enterNextDelay={TooltipConfigs.Delay}
          >
            <span>
              <IconButton onClick={() => setPoppedOut(true)}>
                <OpenInNewIcon sx={{ ...iconStyle }} />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <span className="nearby-separator" />
        <Tooltip
          title={<Typography fontSize={TooltipConfigs.FontSize}>Download</Typography>}
          placement="left"
          enterDelay={TooltipConfigs.Delay}
          enterNextDelay={TooltipConfigs.Delay}
        >
          <span>
            <IconButton
              onClick={() =>
                currentWindow.open(
                  `${apiHostname}/files/snapshots/${currentPhoto.filename}/native`,
                  "_blank"
                )
              }
            >
              <DownloadIcon sx={{ ...iconStyle }} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={<Typography fontSize={TooltipConfigs.FontSize}>Fly To</Typography>}
          placement="left"
          enterDelay={TooltipConfigs.Delay}
          enterNextDelay={TooltipConfigs.Delay}
        >
          <span>
            <IconButton onClick={() => flyToCurrentPhoto()}>
              <SendIcon sx={{ ...iconStyle }} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={<Typography fontSize={TooltipConfigs.FontSize}>Zoom In</Typography>}
          placement="left"
          enterDelay={TooltipConfigs.Delay}
          enterNextDelay={TooltipConfigs.Delay}
        >
          <span data-testid={"zoomButtonTest"}>
            <Checkbox
              id={"zoomButton"}
              icon={<ZoomInIcon sx={{ ...iconStyle }} data-testid={"zoomButtonIn"} />}
              checkedIcon={<ZoomOutIcon sx={{ ...iconStyle }} data-testid={"zoomButtonOut"} />}
              onClick={() => setZoomEnabled(!zoomEnabled)}
            />
          </span>
        </Tooltip>
      </div>
      <NavigateControls />
      <RotateIcons />
      <PhotoTypesSelector />
    </Fragment>
  )
}
