import { useCallback, useContext, useRef, useState } from "react"
import { ViewerContext } from "../context"
import { useEntityGroups } from "./viewer/useEntityGroups"
import { useCesiumOnLeftClick } from "./useCesiumOnClick"
import { CesiumDragEvent, DragEvent } from "./models/CesiumDragEvent"
import { CesiumClickEvent } from "./models/CesiumClickEvent"
import { Axis } from "../interfaces"
import { useCesiumOnDrag } from "./useCesiumOnDrag"
import { useCesiumOnMouseMove } from "./useCesiumMouseMove"
import { CesiumMoveEvent } from "./models/CesiumMoveEvent"
import * as Cesium from "cesium"
import {
  CallbackProperty,
  Cartesian2,
  Cartesian3,
  Color,
  ColorMaterialProperty,
  Matrix3,
  Matrix4,
  Quaternion,
  Viewer,
} from "cesium"
import { idSuffixToToggle, ISelectedToggle } from "../helpers/cesiumHelpers"

export interface ISelectedAreaAttributes {
  dimension: Cartesian3
  position: Cartesian3 | null
  rotation: number
  volume: number
}

interface IResizeToggle {
  label: String
  offset: Cartesian3
  axis: Axis
}

const RESIZE_TOGGLES: IResizeToggle[] = [
  { label: "+x", offset: new Cartesian3(0.5, 0, 0), axis: Axis.X },
  { label: "-x", offset: new Cartesian3(-0.5, 0, 0), axis: Axis.X },
  { label: "+y", offset: new Cartesian3(0, 0.5, 0), axis: Axis.Y },
  { label: "-y", offset: new Cartesian3(0, -0.5, 0), axis: Axis.Y },
  { label: "+z", offset: new Cartesian3(0, 0, 0.5), axis: Axis.Z },
  { label: "-z", offset: new Cartesian3(0, 0, -0.5), axis: Axis.Z },
]

const MINIMUM_DIMENSION = 0.05

export const useAreaSelect = () => {
  const {
    viewersForEach,
    getTilesetCoordUnitVector,
    getActiveTilesetTransform,
    getTilesetTransform,
    viewer,
  } = useContext(ViewerContext)
  const { datasources } = useEntityGroups()
  const boxDisplayed = useRef(false)
  const centerPointRef = useRef(null as Cesium.Cartesian3 | null)
  const dimensionsRef = useRef(new Cesium.Cartesian3(2.5, 2.5, 2.5))
  const rotationRef = useRef(0)
  const mouseStartPosition = useRef(null as Cartesian2 | null)
  const selectedToggle = useRef(null as ISelectedToggle | null)
  const highlightedToggleId = useRef(null as string | null)
  const pointcloudTransform = useRef(null as Matrix4 | null)

  const [attributes, setAttributes] = useState({
    dimension: dimensionsRef.current,
    position: centerPointRef.current,
    rotation: rotationRef.current,
    volume: Math.abs(dimensionsRef.current.x * dimensionsRef.current.y * dimensionsRef.current.z),
  } as ISelectedAreaAttributes)

  useCesiumOnMouseMove((event: CesiumMoveEvent) => {
    if (selectedToggle.current) {
      return
    }

    const pickedList = event.drillPick(5)

    for (const object of pickedList) {
      if (object.id && object.id._id && object.id._id.startsWith("resize_toggle:")) {
        highlightedToggleId.current = object.id._id
        return
      }
    }
    highlightedToggleId.current = null
  })

  useCesiumOnLeftClick((event: CesiumClickEvent) => {
    const position = event.pickPosition()
    if (!position) {
      return
    }
    centerPointRef.current = position.cartesian3
    updateAttributes()

    if (!boxDisplayed.current) {
      viewersForEach((viewer: Viewer, viewerIdx: number) => {
        const datasource = datasources.current[viewerIdx]

        datasource.entities.add({
          position: new CallbackProperty(() => {
            return centerPointRef.current
          }, false),
          orientation: new CallbackProperty(() => {
            const modelTransform = getActiveTilesetTransform()
            if (!modelTransform) {
              return null
            }

            const mR = Matrix3.fromRotationZ((rotationRef.current / 180) * Math.PI, new Matrix3())
            const mR4 = Matrix4.fromRotationTranslation(mR, new Matrix4())
            const mResult4 = Matrix4.multiply(modelTransform, mR4, new Matrix4())
            const mRot = Matrix4.getMatrix3(mResult4, new Matrix3())

            return Quaternion.fromRotationMatrix(mRot)
          }, false),
          box: {
            dimensions: new CallbackProperty(() => {
              return dimensionsRef.current
            }, false),
            material: Color.WHITE.withAlpha(0.3),
            outline: true,
            outlineColor: Color.WHITE.withAlpha(0.9),
          },
        })

        RESIZE_TOGGLES.forEach(toggle => {
          const id = `resize_toggle:${toggle.label}`

          const boxMaterialCallback = new CallbackProperty(() => {
            return id === highlightedToggleId.current ? Color.SEAGREEN : Color.DARKGREY
          }, false)

          datasource.entities.add({
            id,
            position: new CallbackProperty(() => {
              const modelUnitVec = getTilesetCoordUnitVector()
              if (!modelUnitVec || !centerPointRef.current) {
                return null
              }

              const angleRadians = (rotationRef.current / 180) * Math.PI
              const originOffset = Cartesian3.multiplyComponents(
                dimensionsRef.current,
                toggle.offset,
                new Cartesian3()
              )

              switch (toggle.axis) {
                case Axis.X:
                  originOffset.x += toggle.offset.x > 0 ? 0.1 : -0.1
                  break
                case Axis.Y:
                  originOffset.y += toggle.offset.y > 0 ? 0.1 : -0.1
                  break
                case Axis.Z:
                  originOffset.z += toggle.offset.z > 0 ? 0.1 : -0.1
                  break
              }

              const offset = new Cartesian3(
                originOffset.x * Math.cos(angleRadians) + originOffset.y * Math.sin(angleRadians),
                originOffset.x * Math.sin(angleRadians) - originOffset.y * Math.cos(angleRadians),
                originOffset.z
              )

              const dX = Cartesian3.multiplyByScalar(modelUnitVec.uX, offset.x, new Cartesian3())
              const dY = Cartesian3.multiplyByScalar(modelUnitVec.uY, offset.y, new Cartesian3())
              const dZ = Cartesian3.multiplyByScalar(modelUnitVec.uZ, offset.z, new Cartesian3())

              let updatedPosition = centerPointRef.current.clone(new Cartesian3())
              updatedPosition = Cartesian3.add(updatedPosition, dX, new Cartesian3())
              updatedPosition = Cartesian3.add(updatedPosition, dY, new Cartesian3())
              updatedPosition = Cartesian3.add(updatedPosition, dZ, new Cartesian3())

              return updatedPosition
            }, false),
            orientation: new CallbackProperty(() => {
              const modelTransform = getActiveTilesetTransform()
              if (!modelTransform) {
                return null
              }

              const mR = Matrix3.fromRotationZ((rotationRef.current / 180) * Math.PI, new Matrix3())
              const mR4 = Matrix4.fromRotationTranslation(mR, new Matrix4())
              const mResult4 = Matrix4.multiply(modelTransform, mR4, new Matrix4())
              const mRot = Matrix4.getMatrix3(mResult4, new Matrix3())

              return Quaternion.fromRotationMatrix(mRot)
            }, false),
            box: {
              dimensions: new CallbackProperty(() => {
                const dimensions = Cartesian3.multiplyByScalar(
                  dimensionsRef.current,
                  0.2,
                  new Cartesian3()
                )

                switch (toggle.axis) {
                  case Axis.X:
                    dimensions.x = 0.2
                    break
                  case Axis.Y:
                    dimensions.y = 0.2
                    break
                  case Axis.Z:
                    dimensions.z = 0.2
                    break
                }

                return dimensions
              }, false),
              material: new ColorMaterialProperty(boxMaterialCallback),
            },
          })
        })
      })

      boxDisplayed.current = true
    }
  })

  const updateAttributes = useCallback(async () => {
    // Memoize the pointgrab transform
    let transform = pointcloudTransform.current
    if (!transform) {
      pointcloudTransform.current = await getTilesetTransform(1)
      transform = pointcloudTransform.current

      if (!transform) {
        return
      }
    }

    if (!centerPointRef.current) {
      setAttributes({
        dimension: dimensionsRef.current,
        position: null,
        rotation: rotationRef.current,
        volume: Math.abs(
          dimensionsRef.current.x * dimensionsRef.current.y * dimensionsRef.current.z
        ),
      })
    } else {
      const invTransform = Matrix4.inverse(transform, new Matrix4())
      const position = Matrix4.multiplyByPoint(
        invTransform,
        centerPointRef.current,
        new Cartesian3()
      )

      setAttributes({
        dimension: dimensionsRef.current,
        position,
        rotation: rotationRef.current,
        volume: Math.abs(
          dimensionsRef.current.x * dimensionsRef.current.y * dimensionsRef.current.z
        ),
      })
    }
  }, [getTilesetTransform])

  const updateDimensions = useCallback(
    (
      xDelta: number,
      yDelta: number,
      zDelta: number,
      unidirectional: boolean = false,
      scalarDirection: number = 1
    ) => {
      xDelta =
        dimensionsRef.current.x + xDelta < MINIMUM_DIMENSION
          ? MINIMUM_DIMENSION - dimensionsRef.current.x
          : xDelta
      yDelta =
        dimensionsRef.current.y + yDelta < MINIMUM_DIMENSION
          ? MINIMUM_DIMENSION - dimensionsRef.current.y
          : yDelta
      zDelta =
        dimensionsRef.current.z + zDelta < MINIMUM_DIMENSION
          ? MINIMUM_DIMENSION - dimensionsRef.current.z
          : zDelta

      if (unidirectional) {
        // Expands the bounding box while only moving a single face
        const modelUnitVec = getTilesetCoordUnitVector()

        if (!centerPointRef.current || !modelUnitVec) {
          return
        }

        const offset = new Cartesian3(
          (scalarDirection * xDelta) / 2,
          (scalarDirection * yDelta) / 2,
          (scalarDirection * zDelta) / 2
        )

        const angleRadians = (rotationRef.current / 180) * Math.PI
        const rotatedOffset = new Cartesian3(
          offset.x * Math.cos(angleRadians) + offset.y * Math.sin(angleRadians),
          offset.x * Math.sin(angleRadians) - offset.y * Math.cos(angleRadians),
          offset.z
        )

        const dX = Cartesian3.multiplyByScalar(modelUnitVec.uX, rotatedOffset.x, new Cartesian3())
        const dY = Cartesian3.multiplyByScalar(modelUnitVec.uY, rotatedOffset.y, new Cartesian3())
        const dZ = Cartesian3.multiplyByScalar(modelUnitVec.uZ, rotatedOffset.z, new Cartesian3())

        centerPointRef.current = Cartesian3.add(centerPointRef.current, dX, new Cartesian3())
        centerPointRef.current = Cartesian3.add(centerPointRef.current, dY, new Cartesian3())
        centerPointRef.current = Cartesian3.add(centerPointRef.current, dZ, new Cartesian3())
      }

      dimensionsRef.current.x += xDelta
      dimensionsRef.current.y += yDelta
      dimensionsRef.current.z += zDelta

      viewersForEach((viewer: Viewer) => viewer.scene.requestRender())
      updateAttributes()
    },
    [getTilesetCoordUnitVector, updateAttributes, viewersForEach]
  )

  const updatePosition = useCallback(
    (xDelta: number, yDelta: number, zDelta: number) => {
      const modelUnitVec = getTilesetCoordUnitVector()

      if (!centerPointRef.current || !modelUnitVec) {
        return
      }

      const dX = Cartesian3.multiplyByScalar(modelUnitVec.uX, xDelta, new Cartesian3())
      const dY = Cartesian3.multiplyByScalar(modelUnitVec.uY, yDelta, new Cartesian3())
      const dZ = Cartesian3.multiplyByScalar(modelUnitVec.uZ, zDelta, new Cartesian3())

      centerPointRef.current = Cartesian3.add(centerPointRef.current, dX, new Cartesian3())
      centerPointRef.current = Cartesian3.add(centerPointRef.current, dY, new Cartesian3())
      centerPointRef.current = Cartesian3.add(centerPointRef.current, dZ, new Cartesian3())

      viewersForEach((viewer: Viewer) => viewer.scene.requestRender())
      updateAttributes()
    },
    [getTilesetCoordUnitVector, updateAttributes, viewersForEach]
  )

  const updateRotation = useCallback(
    (delta: number) => {
      const newRotation = (rotationRef.current + delta) % 360.0
      rotationRef.current = newRotation >= 0 ? newRotation : newRotation + 360

      viewersForEach((viewer: Viewer) => viewer.scene.requestRender())
      updateAttributes()
    },
    [updateAttributes, viewersForEach]
  )

  const onDragHandler = useCallback(
    (event: CesiumDragEvent) => {
      if (!centerPointRef.current) {
        return false
      }

      const camera = event.viewer.camera

      switch (event.dragEvent) {
        case DragEvent.DragStart: {
          const pickedList = event.drillPick()

          for (const object of pickedList) {
            if (object.id && object.id._id && object.id._id.startsWith("resize_toggle:")) {
              const suffix = object.id._id.split(":")

              selectedToggle.current = idSuffixToToggle(suffix[1])
              mouseStartPosition.current = event.position.clone()

              return true
            }
          }

          return false
        }
        case DragEvent.MouseMove: {
          const modelUnitVec = getTilesetCoordUnitVector()

          if (!mouseStartPosition.current || !selectedToggle.current || !modelUnitVec) {
            return false
          }

          const toCenter = Cartesian3.subtract(
            centerPointRef.current,
            camera.position,
            new Cartesian3()
          )

          const toCenterProj = Cartesian3.multiplyByScalar(
            camera.direction,
            Cartesian3.dot(camera.direction, toCenter),
            new Cartesian3()
          )

          const distance = Cartesian3.magnitude(toCenterProj)

          const dX = -1 * (mouseStartPosition.current.x - event.position.x)
          const dY = mouseStartPosition.current.y - event.position.y

          const pixelSize = camera.frustum.getPixelDimensions(
            viewer!.scene.drawingBufferWidth,
            viewer!.scene.drawingBufferHeight,
            distance,
            viewer!.scene.pixelRatio,
            new Cartesian2()
          )

          const vY = Cartesian3.multiplyByScalar(camera.up, pixelSize.y * dY, new Cartesian3())
          const vX = Cartesian3.multiplyByScalar(camera.right, pixelSize.x * dX, new Cartesian3())
          const v = Cartesian3.add(vY, vX, new Cartesian3())

          switch (selectedToggle.current.axis) {
            case Axis.X: {
              const amount = Cartesian3.dot(v, modelUnitVec.uX)
              updateDimensions(
                amount * selectedToggle.current.scalarDirection,
                0,
                0,
                true,
                selectedToggle.current.scalarDirection
              )
              break
            }
            case Axis.Y: {
              const amount = Cartesian3.dot(v, modelUnitVec.uY)
              updateDimensions(
                0,
                -1 * amount * selectedToggle.current.scalarDirection,
                0,
                true,
                selectedToggle.current.scalarDirection
              )
              break
            }
            case Axis.Z: {
              const amount = Cartesian3.dot(v, modelUnitVec.uZ)
              updateDimensions(
                0,
                0,
                amount * selectedToggle.current.scalarDirection,
                true,
                selectedToggle.current.scalarDirection
              )
              break
            }
          }

          mouseStartPosition.current = event.position.clone()
          break
        }
        case DragEvent.DragEnd:
          mouseStartPosition.current = null
          selectedToggle.current = null
          return true
      }

      return false
    },
    [getTilesetCoordUnitVector, updateDimensions, viewer]
  )

  useCesiumOnDrag(onDragHandler)

  const clearArea = useCallback(() => {
    centerPointRef.current = null
    dimensionsRef.current = new Cartesian3(1.0, 1.0, 1.0)
    rotationRef.current = 0
    updateAttributes()
  }, [updateAttributes])

  return { attributes, updateDimensions, updatePosition, updateRotation, clearArea }
}
