import { createContext, useEffect, useState } from "react"
import { useLocalStorageNumber } from "../hooks/useLocalStorage"
import { useApiModelGroups, useApiModels, useApiSitePins, useApiSites } from "../api/ApiHooks"
import { IModel, IModelGroup, IPin, ISite } from "../interfaces"

export enum ModelQuality {
  MaximumSpeed = 128,
  Balanced = 50,
  MaximumQuality = 20,
}

export enum ModelProjectionType {
  UTM,
  WGS84,
}

export const ModelsContext = createContext({
  selectedSiteId: 0,
  // tslint:disable-next-line:no-empty
  setSelectedSiteId: (siteId: number) => {},
  selectedSite: null as ISite | null,
  selectedModelGroup: null as IModelGroup | null,
  models: [] as IModel[],
  sites: [] as ISite[],
  sitePins: [] as IPin[],
  modelQuality: ModelQuality.Balanced,
  // tslint:disable-next-line:no-empty
  setModelQuality: (quality: ModelQuality) => {},
  modelProjectionType: ModelProjectionType.WGS84,
  // tslint:disable-next-line:no-empty
  setModelProjectionType: (value: ModelProjectionType) => {},
})

export const ModelsContextProvider = (props: any) => {
  const [selectedSiteId, setSelectedSiteId] = useLocalStorageNumber("selected-site", 0)
  const [selectedSite, setSelectedSite] = useState(null as ISite | null)
  const [selectedModelGroup, setSelectedModelGroup] = useState(null as IModelGroup | null)
  const [modelQuality, setModelQuality] = useLocalStorageNumber(
    "modelQuality",
    ModelQuality.Balanced
  )
  const [modelProjectionType, setModelProjectionType] = useLocalStorageNumber(
    "modelProjectionType",
    ModelQuality.Balanced
  )

  const [models, setModels] = useState([] as Array<IModel>)
  const [modelsLoading, setModelsLoading] = useState(true)

  const [modelGroups, setModelsGroups] = useState([] as Array<IModelGroup>)
  const [modelGroupsLoading, setModelsGroupsLoading] = useState(true)

  // const { modelGroups, modelGroupsLoading } = useApiModelGroups()

  const [sites, setSites] = useState([] as Array<ISite>)
  const [sitesLoading, setSitesLoading] = useState(true)
  // const { sites, sitesLoading } = useApiSites()
  const { sitePins } = useApiSitePins()

  useApiModels(setModels, setModelsLoading)
  useApiModelGroups(setModelsGroups, setModelsGroupsLoading)
  useApiSites(setSites, setSitesLoading)

  useEffect(() => {
    if (sitesLoading || modelGroupsLoading || modelsLoading) return

    const site = findSite(sites, selectedSiteId)
    setSelectedSite(site || null)

    const modelGroup = modelGroups.find(mg => mg.siteId === selectedSiteId)
    setSelectedModelGroup(modelGroup || null)
  }, [sitesLoading, modelGroupsLoading, modelsLoading, selectedSiteId, sites, modelGroups])

  // tslint:disable-next-line:no-empty
  useEffect(() => {}, [selectedSite])

  return (
    <ModelsContext.Provider
      value={{
        models,
        sites,
        selectedSiteId,
        setSelectedSiteId,
        selectedSite,
        selectedModelGroup,
        sitePins,
        modelQuality,
        setModelQuality,
        modelProjectionType,
        setModelProjectionType,
      }}
    >
      {props.children}
    </ModelsContext.Provider>
  )
}

export function findSite(sites: ISite[], selectedSiteId: number) {
  if (sites === undefined) return
  return sites.find(m => m.id === selectedSiteId)
}
