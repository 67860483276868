import * as React from "react"
import { useLocalStorageNumber } from "../../hooks/useLocalStorage"
import { CesiumStageViewer } from "./CesiumStageViewer"
import { ModelType } from "../../interfaces"
import { NearbyImagesStageViewer } from "./NearbyImagesStageViewer"
import { Fragment } from "react"

export const StageViewer = (props: any) => {
  const { index } = props
  const [selectedModelType, setSelectedModelType] = useLocalStorageNumber(
    `selectedModel-${index}`,
    3
  )

  return (
    <Fragment>
      {selectedModelType !== ModelType.NearbyPhotos ? (
        <CesiumStageViewer
          index={index}
          selectedModelType={selectedModelType}
          setSelectedModelType={setSelectedModelType}
        />
      ) : (
        <NearbyImagesStageViewer
          index={index}
          selectedModelType={selectedModelType}
          setSelectedModelType={setSelectedModelType}
        />
      )}
    </Fragment>
  )
}
