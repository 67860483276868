import { IPosition } from "../../interfaces"
import * as Cesium from "cesium"

export class CesiumClickEvent {
  viewer: Cesium.Viewer
  event: any
  pickedPointCache: any

  constructor(viewer: Cesium.Viewer, event: any, pickedPointCache: any) {
    this.viewer = viewer
    this.event = event
    this.pickedPointCache = pickedPointCache
  }

  pickPosition(): IPosition | null {
    if (this.pickedPointCache.current.pickCache != null) {
      return this.pickedPointCache.current.pickCache
    }

    const cartesian = this.viewer.scene.pickPosition(this.event.position)
    if (!cartesian) {
      return null
    }
    const cartographic = Cesium.Cartographic.fromCartesian(cartesian)
    const altitude = cartographic.height
    const longitude = Cesium.Math.toDegrees(cartographic.longitude)
    const latitude = Cesium.Math.toDegrees(cartographic.latitude)
    const { x, y } = this.event.position

    this.pickedPointCache.current.pickCache = {
      cartesian3: Cesium.Cartesian3.fromDegrees(longitude, latitude, altitude),
      altitude,
      longitude,
      latitude,
      x,
      y,
    }
    return this.pickedPointCache.current.pickCache
  }

  drillPick(limit = 10) {
    this.pickPosition()
    if (this.pickedPointCache.current.drillPickCache != null) {
      return this.pickedPointCache.current.drillPickCache
    }

    this.pickedPointCache.current.drillPickCache = this.viewer.scene.drillPick(
      this.event.position,
      limit
    )
    return this.pickedPointCache.current.drillPickCache
  }

  clearCaches() {
    this.pickedPointCache.current.pickCache = null
    this.pickedPointCache.current.drillPickCache = null
  }
}
