import { useState, useContext, useCallback, useEffect } from "react"
import {
  apiHostname,
  apiMocsPois,
  apiMocsAreaCreate,
  apiMocsArea,
  filesAttachmentsArchive,
  apiMoc,
  apiMocsAll,
  apiMocDelete,
  apiMocsAreaDelete,
} from "../constants"
import { useAsyncEffect } from "../../lib/UseAsyncEffect"
import { api, isError, isAuthError, APIError } from "../api"
import { ViewerContext, ModelsContext, MocsContext } from "../../context"
import { IMOC, IPolygon, IAttachment, IPosition } from "../../interfaces"
import { getBookmark } from "../../helpers/viewer"
import { toast } from "react-toastify"
import axios from "axios"

export const useApiMocs = () => {
  const { selectedSiteId } = useContext(ModelsContext)
  const [mocs, setMocs] = useState([] as IMOC[])
  const [mocsLoading, setLoading] = useState(true)

  const token: string = localStorage.getItem("auth")
  const url: string = apiHostname + apiMocsPois(selectedSiteId)

  const refreshMocs = useCallback(() => {
    if (selectedSiteId === 0)
      // No site selected, we can skip fetching mocs
      return

    setLoading(true)

    axios.defaults.withCredentials = true
    axios
      .get(url, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .then(response => {
        setMocs(
          response.data.mocs.map((moc: IMOC) => {
            if (moc.data.polygons) {
              const polygons = Object.keys(moc.data.polygons).map(key => moc.data.polygons[key])
              return { ...moc, polygons }
            } else {
              return moc
            }
          })
        )
      })
      .catch(error => {
        toast.error("There was a problem fetching MOCs")
      })

    setLoading(false)
  }, [selectedSiteId])

  useAsyncEffect(refreshMocs, [selectedSiteId])

  const addAreaToMoc = useCallback(async (moc: IMOC, polygon: IPolygon, name: string) => {
    const payload = { ...polygon, name }
    const response = await api.post(apiMocsAreaCreate(moc.siteId, moc.id), payload)

    refreshMocs()
  }, [])

  const archiveMocArea = useCallback(async (moc: IMOC, polygon: IPolygon) => {
    if (!polygon.uuid) {
      return
    }

    const response = await api.get(apiMocsAreaDelete(moc.siteId, moc.id, polygon.uuid))

    refreshMocs()
  }, [])

  const archiveMocAttachment = useCallback(
    async (attachment: IAttachment) => {
      if (!attachment.uuid) {
        return
      }
      const response = await api.post(filesAttachmentsArchive(attachment.uuid), {})

      if (isAuthError(response) || isError(response)) {
        toast.error("There was a problem fetching MOCs")
      } else {
        await refreshMocs()
      }
    },
    [refreshMocs]
  )

  const archiveMoc = useCallback(
    async (moc: IMOC) => {
      const response = await api.get(apiMocDelete(moc.siteId, moc.id))

      refreshMocs()
    },
    [mocs]
  )

  return {
    mocs,
    mocsLoading,
    refreshMocs,
    addAreaToMoc,
    archiveMocArea,
    archiveMocAttachment,
    archiveMoc,
  }
}

export const useApiMoc = (mocID?: number) => {
  const { viewer } = useContext(ViewerContext)
  const { selectedSiteId } = useContext(ModelsContext)
  const { mocs, refreshMocs } = useContext(MocsContext)

  const [moc, setMoc] = useState(() => {
    if (mocID != null) {
      // Find the exisitng MOC from the context
      const existingMoc = mocs.find(moc => moc.id === Number(mocID))

      if (existingMoc != null) {
        return existingMoc
      }
    }

    return {
      name: "",
      description: "",
      documentNumber: "",
      state: "proposed",
    } as IMOC
  })

  const createMoc = async (
    name: string,
    documentNumber: string,
    state: string,
    description: string,
    attachmentUUIDs: string[],
    position: IPosition
  ) => {
    const bookmark = getBookmark(viewer!, name, description)

    const poi = {
      x: position.x,
      y: position.y,
      z: position.z,
      latitude: position.latitude,
      longitude: position.longitude,
      altitude: position.altitude,
      data: {},
    }

    const moc = {
      name,
      description,
      documentNumber,
      state,
      data: { bookmark },
      siteID: selectedSiteId,
    }

    const payload = { moc, poi, attachmentUUIDs }
    const response: any | APIError = await api.post(apiMocsAll(selectedSiteId), payload)

    refreshMocs()
    return true
  }

  const saveMoc = async (
    name: string,
    documentNumber: string,
    state: string,
    description: string,
    attachmentUUIDs: string[],
    position?: IPosition
  ) => {
    if (!mocID) {
      return await createMoc(name, documentNumber, state, description, attachmentUUIDs, position!)
    }

    let data
    if (position) {
      data = Object.assign({}, moc.data, { bookmark: getBookmark(viewer!, name, description) })
    } else {
      data = moc.data
    }

    const updatedMoc = Object.assign({}, moc, {
      name,
      documentNumber,
      description,
      state,
      data,
    })

    const payload = { moc: updatedMoc, attachmentUUIDs }
    if (position) {
      // @ts-ignore
      payload["poi"] = {
        x: position.x,
        y: position.y,
        z: position.z,
        latitude: position.latitude,
        longitude: position.longitude,
        altitude: position.altitude,
        data: {},
      }
    }

    const response: any | APIError = await api.post(apiMoc(selectedSiteId, mocID), payload)

    refreshMocs()
    return true
  }

  return { moc, saveMoc }
}
