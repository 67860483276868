import {
  CallbackProperty,
  Cartesian3,
  CustomDataSource,
  NearFarScalar,
  VerticalOrigin,
  Viewer,
} from "cesium"
import { useEffect, useContext } from "react"
import SitePin from "../../../assets/img/sitepin.svg"
import SitePinGrey from "../../../assets/img/sitepin_grey.svg"
import { ModelsContext } from "../../../context"

export const drawSitePins = (viewer: Viewer | null) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks,@typescript-eslint/no-unused-vars
  const { sitePins, setSelectedSiteId } = useContext(ModelsContext)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!viewer) {
      return
    }

    const datasource = new CustomDataSource()
    viewer.dataSources.add(datasource)

    for (const pin of sitePins) {
      const position = Cartesian3.fromDegrees(pin.longitude, pin.latitude, 100)
      datasource.entities.add({
        id: `sitepin-${pin.site_id}`,
        position,
        billboard: {
          image: pin.has_model ? SitePin : SitePinGrey,
          verticalOrigin: VerticalOrigin.BOTTOM,
          scale: 1,
          scaleByDistance: new NearFarScalar(5e5, 1, 1e7, 0.6),
          // Below disabled due to error
          // ---------------------------
          // show: new CallbackProperty(() => {
          //   const v = Cartesian3.subtract(position, viewer.camera.position, new Cartesian3())
          //   const d = Cartesian3.magnitude(v)
          //   return d > 4000
          // }),
        },
      })
    }

    viewer.scene.requestRender()

    return () => {
      datasource.entities.removeAll()
      viewer.dataSources.remove(datasource, true)
    }
  }, [viewer, sitePins])

  // TODO: Fly to site when a site pin is clicked
}
