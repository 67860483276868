import { useState, useContext, useCallback, useEffect } from "react"
import { IPointCloud, IPointCloudDownload } from "../../interfaces"
import {
  apiPointgrabGet,
  apiPointgrabDownloads,
  apiPointgrabCrop,
  filesPointgrabDownloadsDelete,
  apiHostname,
} from "../constants"
import { useAsyncEffect } from "../../lib/UseAsyncEffect"
import { api, isError, APIError, isAuthError } from "../api"
import { toast } from "react-toastify"
import { ModelsContext } from "../../context"
import { ISelectedAreaAttributes } from "../../hooks/useAreaSelect"

export interface IPointcloudSubmission {
  pointcloud: IPointCloud
  description: string
  notifyEmail: string
  notify: boolean
  formats: string[]
  density: string
  selectedArea: ISelectedAreaAttributes
}

export const useSitePointcloud = () => {
  const { selectedSite } = useContext(ModelsContext)
  const [pointcloud, setPointcloud] = useState(null as IPointCloud | null)
  const [pointcloudLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    if (!selectedSite) {
      setPointcloud(null)
      setLoading(false)
      return
    }

    const payload = { siteSlug: selectedSite.slug }

    api
      .post(apiPointgrabGet, payload)
      .then(response => {
        setPointcloud(response.pointcloud)
      })
      .catch(() => {
        toast.error("There was a problem checking for available point clouds")
      })
    // const response: any | APIError = await api.post(apiPointgrabGet, payload)
    //
    // if (isError(response) || isAuthError(response)) {
    //   toast.error("There was a problem checking for available point clouds")
    // } else {
    //   setPointcloud(response.pointcloud)
    // }

    setLoading(false)
  }, [selectedSite])

  return { pointcloud, pointcloudLoading }
}

export const useSitePointgrabDownloads = () => {
  const { selectedSite } = useContext(ModelsContext)
  const [pointgrabDownloads, setPointgrabDownloads] = useState([] as IPointCloudDownload[])
  const [pointgrabDownloadsLoading, setLoading] = useState(true)

  const refreshPointgrabDownloads = useCallback(async () => {
    if (!selectedSite) {
      setPointgrabDownloads([])
      setLoading(false)
      return
    }

    const payload = { siteSlug: selectedSite.slug }
    await api
      .post(apiPointgrabDownloads, payload)
      .then(response => {
        setPointgrabDownloads(response.downloads)
      })
      .catch(() => {
        toast.error("There was a problem fetching point grab downloads")
      })

    setLoading(false)
  }, [selectedSite])

  useAsyncEffect(refreshPointgrabDownloads, [])

  const removePointgrabDownload = async (uuid: string) => {
    const payload = { uuid }

    await api
      .post(filesPointgrabDownloadsDelete, payload)
      .then(() => {
        toast.success("Delete successful")
      })
      .catch(() => {
        toast.error("Error deleting download")
      })

    // const response: {} | APIError = await api.post(filesPointgrabDownloadsDelete, payload)
    //
    // // eslint-disable-next-line no-mixed-operators
    // if (isAuthError(response) || isError(response) && response.status !== 200) {
    //   toast.error("Error deleting download")
    // } else {
    //   toast.success("Delete successful")
    // }

    await refreshPointgrabDownloads()
  }

  return {
    pointgrabDownloads,
    removePointgrabDownload,
    pointgrabDownloadsLoading,
    refreshPointgrabDownloads,
  }
}

export const usePointGrabCreate = () => {
  const { selectedSite } = useContext(ModelsContext)
  const [submissionLoading, setLoading] = useState(false)

  const createPointGrabRequest = async (submissionData: IPointcloudSubmission) => {
    setLoading(true)

    const data = {
      pointCloudID: submissionData.pointcloud.uuid,
      siteSlug: selectedSite!.slug,
      density: submissionData.density,

      description: submissionData.description,
      notifyEmail: submissionData.notifyEmail,
      notify: submissionData.notify,
      formats: submissionData.formats.toString(),

      centerX: submissionData.selectedArea.position!.x.toString(),
      centerY: submissionData.selectedArea.position!.y.toString(),
      centerZ: submissionData.selectedArea.position!.z.toString(),
      sizeX: submissionData.selectedArea.dimension.x.toString(),
      sizeY: submissionData.selectedArea.dimension.y.toString(),
      sizeZ: submissionData.selectedArea.dimension.z.toString(),
      degrees: (-1 * submissionData.selectedArea.rotation).toString(), // need to check correct definition for sign on angle w.r.t. pointgrabber executable
    }

    const response = await api
      .post(apiPointgrabCrop, data)
      .then(response => {
        toast.success("Your point cloud export request was successfully submitted")
        return response
      })
      .catch(() => {
        toast.error("There was a problem submitting your point cloud export request")
        setLoading(false)
      })

    // const response = await api.post(apiPointgrabCrop, data)
    //
    // if (isError(response) || isAuthError(response)) {
    //   toast.error("There was a problem submitting your point cloud export request")
    //   setLoading(false)
    //   return null
    // }

    // toast.success("Your point cloud export request was successfully submitted")
    setLoading(false)
    return response
  }

  return { submissionLoading, createPointGrabRequest }
}
