import { ChangeEvent, FormEvent, useCallback } from "react"
import { withRouter } from "react-router"
import { Button, Field, Input } from "trunx"
import { Loading } from "../Loading"
import { useForm, isRequired } from "../../hooks/useForm"
import { usePointGrabCreate, IPointcloudSubmission } from "../../api/Hooks/PointgrabHooks"
import { Fragment } from "react"
import { Label } from "@mui/icons-material"
import {
  InputLabel,
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  NativeSelect,
} from "@mui/material"

function densityToString(density: string) {
  return (parseFloat(density) * 1000).toString() + "mm"
}

export const PointGrabSubmitForm = withRouter((props: any) => {
  const { pointcloud, onClose, selectedArea } = props
  const { submissionLoading, createPointGrabRequest } = usePointGrabCreate()
  const { formValues, formErrors, setFormValue, isFormValid } = useForm({
    description: {
      defaultValue: "",
      validators: [isRequired],
    },
    density: {
      defaultValue: pointcloud.densities[0],
      validators: [isRequired],
    },
    notify: {
      defaultValue: false,
      validators: [isRequired],
    },
    email: {
      defaultValue: "",
    },
    outputs: {
      defaultValue: ["E57"],
      validators: [isRequired],
    },
  })

  const submitForm = useCallback(async () => {
    const submission: IPointcloudSubmission = {
      description: formValues["description"],
      notifyEmail: formValues["email"],
      notify: formValues["notify"],
      formats: formValues["outputs"],
      density: formValues["density"],
      pointcloud,
      selectedArea,
    }

    const result = await createPointGrabRequest(submission)

    if (result) {
      onClose(true)
    }
  }, [createPointGrabRequest, formValues, onClose, pointcloud, selectedArea])

  if (submissionLoading) {
    return <Loading />
  }

  return (
    <Fragment>
      <header className="modal-card-head">
        <p className="modal-card-title">Export PointCloud</p>
      </header>
      <section className="modal-card-body">
        <form
          className={"pointgrab-export-form"}
          /* tslint:disable-next-line:jsx-no-lambda */
          onSubmit={(e: FormEvent) => {
            e.preventDefault()
            submitForm()
          }}
        >
          <Field className={"export-pointcloud"}>
            <InputLabel shrink={true} htmlFor="description-input">
              Description
            </InputLabel>
            <TextField
              fullWidth={true}
              id="description-input"
              /* tslint:disable-next-line:jsx-no-lambda */
              onChange={(e: any) => setFormValue("description", e.target.value)}
            />
          </Field>

          <Field
            isHorizontal={true}
            aria-label={"Point Cloud Density"}
            aria-errormessage={formErrors["density"]}
            className={"export-pointcloud"}
          >
            <InputLabel shrink={true} htmlFor="point-cloud-density">
              Point Cloud Density
            </InputLabel>

            <NativeSelect
              id="point-cloud-density"
              /* tslint:disable-next-line:jsx-no-lambda */
              onChange={(e: any) => setFormValue("density", e.target.value)}
              defaultValue={"2.5mm"}
            >
              {pointcloud.densities.map((densityValue: string, i: number) => (
                <option value={densityValue} key={i} style={{ color: "black" }}>
                  {densityToString(densityValue)}
                </option>
              ))}
            </NativeSelect>
          </Field>

          <Field
            isHorizontal={true}
            aria-label={"Output file format(s)"}
            aria-errormessage={formErrors["outputs"]}
            className={"export-pointcloud"}
          >
            <InputLabel shrink={true} htmlFor="output-file-format">
              Output File Format
            </InputLabel>

            <div id="output-file-format">
              <FormControlLabel
                control={
                  <Checkbox
                    aria-label={"E57"}
                    value={"E57"}
                    checked={formValues["outputs"].includes("E57")}
                    /* tslint:disable-next-line:jsx-no-lambda */
                    onChange={() => {
                      const values = formValues["outputs"].slice(0)
                      values.includes("E57")
                        ? values.splice(values.indexOf("E57"), 1)
                        : values.push("E57")
                      setFormValue("outputs", values)
                    }}
                  />
                }
                label="E57"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    aria-label={"PTS"}
                    value={"PTS"}
                    checked={formValues["outputs"].includes("PTS")}
                    /* tslint:disable-next-line:jsx-no-lambda */
                    onChange={() => {
                      const values = formValues["outputs"].slice(0)
                      values.includes("PTS")
                        ? values.splice(values.indexOf("PTS"), 1)
                        : values.push("PTS")
                      setFormValue("outputs", values)
                    }}
                  />
                }
                label="PTS"
              />
            </div>
          </Field>

          <Field
            isHorizontal={true}
            aria-label={"Email"}
            aria-errormessage={formErrors["email"]}
            className={"export-pointcloud"}
          >
            <InputLabel shrink={true} htmlFor="email-input">
              Email
            </InputLabel>

            <TextField
              fullWidth={true}
              id={"email-input"}
              type={"email"}
              /* tslint:disable-next-line:jsx-no-lambda */
              onChange={(e: ChangeEvent<HTMLInputElement>) => setFormValue("email", e.target.value)}
              disabled={!formValues["notify"]}
            />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    aria-label={
                      "Please notify the email provided below when the download is ready."
                    }
                    value={"notify"}
                    checked={formValues["notify"]}
                    /* tslint:disable-next-line:jsx-no-lambda */
                    onChange={() => setFormValue("notify", !formValues["notify"])}
                  />
                }
                label="Please notify the email provided below when the download is ready."
              />
            </div>
          </Field>

          <div className="submit-container">
            <Button type={"submit"} isActive={isFormValid}>
              Submit
            </Button>
          </div>
        </form>
      </section>
    </Fragment>
  )
})
