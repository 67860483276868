import axios from "axios"
import { apiHostname } from "./constants"
import { IForgotPasswordState } from "../controllers/security"
import { APIError } from "./api"

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_LOCAL
} else {
  if (window.location.hostname === process.env.REACT_APP_DOMAIN_PROD) {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_PROD
  } else {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_TEST
  }
}

// const BASEURL = "http://localhost"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ALLUSERS = "/users/all"
const SIGNIN = apiHostname + "/api/auth/sign_in"
const SIGNOUT = apiHostname + "/api/auth/sign_out"

export interface IPasswordResetResponse {
  error?: boolean
  success?: boolean
  cancelled?: boolean
}

export interface IForgotPasswordResponse {
  error?: boolean
  success?: boolean
  cancelled?: boolean
}

export const fetchData = async (
  user: string,
  password: string,
  query: string,
  fetchMethod: string = "POST",
  fetchURL: string = SIGNIN
): Promise<any> => {
  const token: string = localStorage.getItem("auth")

  axios.defaults.withCredentials = true
  let response = null

  // @ts-ignore
  response = await axios({
    method: fetchMethod,
    url: fetchURL,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    data: {
      email: user,
      password,
    },
  }).then(response => {
    return response
  })
  // }
  return { ...response }
}

export function auth(user: string): any {
  const response = localStorage.getItem(user)

  return response
}

export function createCookie(cookie: string) {
  storeToken("auth", cookie)
}

export async function login(user: string, password: string) {
  const response = fetchData(user.toLowerCase(), password, SIGNIN)

  const res = await response
    .then(response => {
      if (typeof response.data.user !== "undefined") {
        const cookie = setCookie()
        createCookie(cookie)
        const token = response.data.token.replace("_monocular_session=", "")
        localStorage.setItem("auth", token)
        return response
      }
    })
    .catch(error => {
      return error.response
    })

  return res
}

function setCookie() {
  const cookies = document.cookie.split(";")
  let cookie = ""
  const nameEQ = "_monocular_session" + "="
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < cookies.length; i++) {
    let c = cookies[i]
    while (c.charAt(0) === " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) cookie = c.substring(nameEQ.length, c.length)
  }
  return cookie
}

export async function logout(user: string): Promise<boolean> {
  const response = await fetchData(user, "", "", "POST", SIGNOUT)
  localStorage.removeItem("auth")

  if (typeof response.data.error === "undefined") {
    return true
  }

  return false
}

export async function passwordReset(user: string): Promise<IPasswordResetResponse> {
  if (user === "fred") {
    return {
      error: false,
      success: true,
    }
  }
  return {
    error: true,
    success: false,
  }
}

function storeToken(user: string, value: string) {
  localStorage.setItem(user, value)
}
