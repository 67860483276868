import * as React from "react"
import { ModelsContextProvider } from "./Models"
import { PermissionContextProvider } from "./Permissions"
import { ViewerContextProvider } from "./Viewer"
import { MocsContextProvider } from "./Mocs"
import { ModalContextProvider } from "./Modal"
import { AppContextProvider } from "./App"
import { NearbyImagesContextProvider } from "./NearbyImages"

export const ContextProvider = (props: any) => (
  <AppContextProvider>
    <ModelsContextProvider>
      <ViewerContextProvider>
        <NearbyImagesContextProvider>
          <PermissionContextProvider>
            <MocsContextProvider>
              <ModalContextProvider>{props.children}</ModalContextProvider>
            </MocsContextProvider>
          </PermissionContextProvider>
        </NearbyImagesContextProvider>
      </ViewerContextProvider>
    </ModelsContextProvider>
  </AppContextProvider>
)
