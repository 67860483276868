/* tslint:disable:jsx-no-lambda */
import { Fragment, useContext, useMemo } from "react"
import { Button } from "trunx"
import { prettyPrintCoords } from "../../helpers/projections"
import { FontAwesomeIcon } from "../../helpers/icon"
import { VizTransition } from "../VizTransition"
import { useTargetMode } from "../../hooks/useTargetMode"
import { ModelsContext } from "../../context"

export const TargetMode = (props: any) => {
  const { modelProjectionType } = useContext(ModelsContext)
  const { points, setPoints, setHoveredPoint } = useTargetMode()

  const content = useMemo(() => {
    if (points.length === 0) {
      return (
        <VizTransition>
          <div className="card-content">
            <p>Click on the model to create a location target.</p>
          </div>
        </VizTransition>
      )
    }

    return points.map((point, idx) => (
      <div
        className="panel-block"
        key={"target-path-" + idx}
        onMouseOver={() => setHoveredPoint(point)}
        onMouseOut={() =>
          setHoveredPoint(currentPoint => (currentPoint === point ? null : currentPoint))
        }
      >
        <span className="panel-icon">
          <FontAwesomeIcon icon={"map-marker"} />
        </span>

        <VizTransition>
          <span
            dangerouslySetInnerHTML={{
              __html: prettyPrintCoords(modelProjectionType, point.position.cartesian3, {
                lineBreaks: false,
                isHTML: true,
              }),
            }}
          />
        </VizTransition>
        <Button isText={true} onClick={() => setPoints(points.filter(p => p !== point))}>
          <FontAwesomeIcon icon={"times"} />
        </Button>
      </div>
    ))
  }, [modelProjectionType, points, setHoveredPoint, setPoints])

  return <Fragment>{content}</Fragment>
}
