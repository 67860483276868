import { Cartesian3, Matrix4 } from "cesium"
import { applyTransformToPointAbstraction } from "../../helpers/cesiumAbstractions"
import { CADPosition, Dimensions } from "../../helpers/cadMetadataHelper"

const WORLD_POSITION_ZERO = [0, 0, 0]
const MAX_DIMENSION_ZERO = 0
const DIMENSION_OFFSET = 2

function parametersAreNotZero(parameters: { worldPosition: number[]; maxDimension: number }) {
  return (
    parameters.worldPosition !== WORLD_POSITION_ZERO &&
    parameters.maxDimension !== MAX_DIMENSION_ZERO
  )
}

export async function flyToCADModel(
  modelTransform: Matrix4,
  position: string,
  flyToCartesian3: (destination: Cartesian3, distanceAway: Number, modelTransform: Matrix4) => void
) {
  const genericTransform = Matrix4.toArray(modelTransform)

  const parameters = getFlyToPosition(JSON.parse(position), genericTransform)
  if (parametersAreNotZero(parameters)) {
    flyToCartesian3(
      new Cartesian3(...parameters.worldPosition),
      parameters.maxDimension,
      modelTransform
    )
  }
}

function hasCorrectPositionParameters(position: CADPosition) {
  return position && position.center && position.dimensions
}

export function getFlyToPosition(position: CADPosition, transform: Array<number>) {
  let worldPosition = WORLD_POSITION_ZERO
  let maxDimension = MAX_DIMENSION_ZERO

  if (hasCorrectPositionParameters(position) && transform) {
    const dimensions: Dimensions = { x: position.dimensions[0], y: position.dimensions[1] }

    maxDimension =
      DIMENSION_OFFSET + Math.sqrt(Math.pow(dimensions.x, 2) + Math.pow(dimensions.y, 2))
    worldPosition = applyTransformToPointAbstraction(transform, position.center)
  }

  return { worldPosition, maxDimension }
}
