import { useCallback, useContext, useEffect, useState } from "react"
// @ts-ignore
import ReactDOM from "react-dom"

import { StageViewerContext } from "../CesiumViewer/CesiumStageViewer"

function copyStylesFromParent(externalWindow: Window) {
  const stylesheets = Array.from(document.styleSheets)
  stylesheets.forEach(stylesheet => {
    const css = stylesheet as CSSStyleSheet

    if (stylesheet.href) {
      const newStyle = document.createElement("link")
      newStyle.rel = "stylesheet"
      newStyle.href = stylesheet.href
      externalWindow.document.head.appendChild(newStyle)
    } else if (css && css.cssRules && css.cssRules.length > 0) {
      const newStyleElement = document.createElement("style")
      Array.from(css.cssRules).forEach(rule =>
        newStyleElement.appendChild(document.createTextNode(rule.cssText))
      )
      externalWindow.document.head.appendChild(newStyleElement)
    }
  })
}

export const PopoutWindow = (props: any) => {
  const { poppedOut, onClose } = props

  const [portalEl, setPortalEl] = useState(null as null | HTMLDivElement)
  const { currentWindow, setCurrentWindow } = useContext(StageViewerContext)

  const closeHandler = useCallback(
    (e: any) => {
      onClose()
      return null
    },
    [onClose]
  )

  useEffect(() => {
    if (!poppedOut) {
      setCurrentWindow(window)
      return
    }

    const externalWindow = window.open("", "", "width=1200,height=800,left=200,top=200")
    if (!externalWindow) {
      return
    }

    const el = externalWindow.document.createElement("div")
    el.className = "external-stage"
    externalWindow.document.body.appendChild(el)

    setPortalEl(el)
    copyStylesFromParent(externalWindow)

    externalWindow.addEventListener("beforeunload", closeHandler)

    return () => {
      externalWindow.close()
      externalWindow.removeEventListener("beforeunload", closeHandler)
      setPortalEl(null)
    }

    return
  }, [closeHandler, poppedOut, setCurrentWindow])

  return poppedOut && portalEl ? ReactDOM.createPortal(props.children, portalEl) : props.children
}
