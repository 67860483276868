import * as React from "react"
import CSSTransition from "react-transition-group/CSSTransition"

interface IProps {
  in?: boolean
  timeout?: number
  classNames?: string
  key__?: string
  children: JSX.Element | any
}

export const VizTransition = (props: IProps) => {
  const timeout = props.timeout == null ? 100 : props.timeout
  const _in = props.in == null ? true : props.in
  const classNames = "viz-transition"

  return (
    <CSSTransition in={_in} classNames={classNames} timeout={timeout} appear={true}>
      {props.children}
    </CSSTransition>
  )
}
