/* tslint:disable:jsx-no-lambda */
import { useCallback, useContext, useMemo, useState } from "react"
import { Button } from "trunx"
import { FontAwesomeIcon } from "../../helpers/icon"
import { ISite, ISnapshot } from "../../interfaces"
import { TakeSnapshot } from "../ViewerControls/takeSnapshot"
import { SharePlaces } from "./PlacesShare"
import { Loading } from "../Loading"
import { useApiSites, useApiSnapshots } from "../../api/ApiHooks"
import { useLocalStorageBoolean } from "../../hooks/useLocalStorage"
import { PlaceItem } from "./PlaceItem"
import { VizTransition } from "../VizTransition"
import { PlaceFilter } from "./PlaceFilter"
import { ModalContext, ModelsContext } from "../../context"

export const PlacesCard = () => {
  const { selectedSiteId } = useContext(ModelsContext)
  const { showModal, hideModal } = useContext(ModalContext)

  const [sites, setSites] = useState([] as Array<ISite>)
  const [sitesLoading, setLoading] = useState(true)

  const [expanded, setExpanded] = useLocalStorageBoolean("places-card-expanded", false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sharedSnapshot, setSharedSnapshot] = useState(null as ISnapshot | null)
  const {
    snapshots,
    snapshotsLoading,
    removeSnapshot,
    createSnapshot,
    updateSnapshot,
  } = useApiSnapshots()
  useApiSites(setSites, setLoading)

  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded, setExpanded])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clearSharedSnapshot = useCallback(() => setSharedSnapshot(null), [setSharedSnapshot])
  // show All places for All sites, by default only show current site
  const [showAll, setShowAll] = useLocalStorageBoolean("places-show-all", false)

  const shareSnapshot = useCallback(
    (snapshot: ISnapshot) => {
      showModal(() => <SharePlaces snapshot={snapshot} onClose={hideModal} />)
    },
    [hideModal, showModal]
  )

  const snapshotItems = useMemo(() => {
    if (snapshotsLoading) {
      return (
        <div className="loading-centered">
          <Loading />
        </div>
      )
    }

    if (snapshots.length === 0) {
      return `You haven't created any places yet`
    }

    return snapshots
      .filter(s => s != null)
      .filter(snap => showAll || snap.siteId === selectedSiteId)
      .map(snap => (
        <PlaceItem
          key={snap.id}
          snapshot={snap}
          site={sites.length > 0 ? sites.find(site => site.id === snap.siteId) : null}
          onShare={() => shareSnapshot(snap)}
          updateSnapshot={updateSnapshot}
          removeSnapshot={removeSnapshot}
        />
      ))
  }, [
    snapshotsLoading,
    snapshots,
    showAll,
    selectedSiteId,
    sites,
    updateSnapshot,
    removeSnapshot,
    shareSnapshot,
  ])

  return (
    <VizTransition timeout={300}>
      <div className="card-container two-cards">
        <header className="card-header" onClick={toggleExpanded}>
          <div className="card-header-title">
            Your Places
            <span className={"places-tools-container"}>
              <PlaceFilter showAll={showAll} setShowAll={setShowAll} />

              <TakeSnapshot createSnapshot={createSnapshot} />

              <Button isText={true} onClick={toggleExpanded}>
                <span className="icon">
                  <FontAwesomeIcon icon={expanded ? "chevron-up" : "chevron-down"} />
                </span>
              </Button>
            </span>
          </div>
        </header>
        {expanded && (
          <div className={"places-results"}>
            <div className="card-content">{snapshotItems}</div>
          </div>
        )}
      </div>
    </VizTransition>
  )
}
