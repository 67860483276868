/* tslint:disable:jsx-no-lambda */
import { useCallback, useState } from "react"
import ContentEditable from "react-contenteditable"
import { pasteAsPlainText } from "../../helpers"

export const PlaceName = (props: any) => {
  const { snapshot, updateSnapshot } = props

  let currentSnapshotName = snapshot.name
  // editable on double click
  const [isEditable, setIsEditable] = useState(false)

  const handleKeyDown = useCallback(
    (event: any) => {
      switch (event.key) {
        case "Enter":
          event.target.blur()
          break
        case "Escape":
          // restore name to what it was
          snapshot.name = currentSnapshotName
          event.target.blur()
          break
      }
    },
    [currentSnapshotName, snapshot]
  )

  return (
    <ContentEditable
      html={snapshot.name}
      disabled={!isEditable}
      className={isEditable ? "editable" : "non-editable"}
      onDoubleClick={() => {
        // user double-click on name, make it editable and put cursor on start / end word
        setIsEditable(true)
        setTimeout(() => {
          // setTimeout needed, otherwise cursor not shown and user has to single-click again

          // double-click select a word
          const selection = window.getSelection()

          if (!selection) {
            return
          }
          // create a collapsed range so cursor is displayed and user is clear they can edit
          const range = document.createRange()

          // @ts-ignore
          range.setStart(selection.baseNode, selection.baseOffset)
          range.collapse(true)
          selection.removeAllRanges()
          selection.addRange(range)
        }, 60)
      }}
      onChange={e => {
        const newName = e.target.value
        snapshot.name = newName
      }}
      onBlur={() => {
        if (currentSnapshotName !== snapshot.name) {
          updateSnapshot(snapshot.id, snapshot)
          currentSnapshotName = snapshot.name
        }
        setIsEditable(false)
      }}
      onKeyDown={handleKeyDown}
      onPaste={pasteAsPlainText}
    />
  )
}
