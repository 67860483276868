import { imagify } from "../helpers"

const thickCrosshairSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="72.18" height="72.18" viewBox="0 0 72.18 72.18">
  <defs>
    <style>
      .cls-1 {
        fill: #272727;
      }

      .cls-2, .cls-3 {
        fill: #faa21b;
      }

      .cls-3 {
        opacity: 0.75;
      }
    </style>
  </defs>
  <title>Asset 1</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <g>
          <rect class="cls-1" x="34.59" width="3" height="31.91"/>
          <rect class="cls-1" x="34.59" y="39.91" width="3" height="32.27"/>
        </g>
        <g>
          <rect class="cls-1" y="34.59" width="32.09" height="3"/>
          <rect class="cls-1" x="40.09" y="34.59" width="32.09" height="3"/>
        </g>
        <path class="cls-1" d="M62.78,9.4V62.78H9.4V9.4H62.78m3-3H6.4V65.78H65.78V6.4Z"/>
        <g>
          <rect class="cls-2" x="34.59" width="3" height="23.33"/>
          <rect class="cls-2" x="34.59" y="48" width="3" height="24.18"/>
        </g>
        <g>
          <rect class="cls-2" y="34.59" width="23.65" height="3"/>
          <rect class="cls-2" x="48.32" y="34.59" width="23.86" height="3"/>
        </g>
        <polygon class="cls-3" points="37.51 34.59 37.51 32.78 34.51 32.78 34.51 34.59 32.7 34.59 32.7 37.59 34.51 37.59 34.51 39.4 37.51 39.4 37.51 37.59 39.32 37.59 39.32 34.59 37.51 34.59"/>
      </g>
    </g>
  </g>
</svg>`

export const ThickCrosshairSVG = imagify(thickCrosshairSVG)

export const circle =
  "data:image/svg+xml," +
  '<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">' +
  '<circle cx="25" cy="25" r="24" stroke="white" stroke-width="2" fill="rgb(250,162,27)" />' +
  "</svg>"

export const hoverCircle =
  "data:image/svg+xml," +
  '<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">' +
  '<circle cx="25" cy="25" r="24" stroke="white" stroke-width="2" fill="white" />' +
  "</svg>"

export const blueCircle =
  "data:image/svg+xml," +
  '<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">' +
  '<circle cx="25" cy="25" r="24" stroke="white" stroke-width="2" fill="blue" />' +
  "</svg>"

const exclSVGYellow = `<svg width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="5539 8266 576 512"><defs><style>.a{stroke:#707070;}.b{fill:#eed202;}.c{stroke:none;}.d{fill:none;}</style></defs><g class="a" transform="translate(5755 8388)"><rect class="c" width="142" height="351"/><rect class="d" x="0.5" y="0.5" width="141" height="350"/></g><path class="b" d="M569.517,440.013C587.975,472.007,564.806,512,527.94,512H48.054c-36.937,0-60-40.055-41.577-71.987L246.423,23.985c18.467-32.009,64.72-31.951,83.154,0l239.94,416.028ZM288,354a46,46,0,1,0,46,46A46,46,0,0,0,288,354ZM244.327,188.654l7.418,136A12,12,0,0,0,263.727,336h48.546a12,12,0,0,0,11.982-11.346l7.418-136A12,12,0,0,0,319.691,176H256.308a12,12,0,0,0-11.981,12.654Z" transform="translate(5539 8266)"/></svg>`

const exclSVGGreen = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.314 168.279">
  <defs>
    <style>
      .cls-1 {
        fill: #fff;
        stroke: #707070;
      }

      .cls-2 {
        fill: #31a722;
      }

      .cls-3 {
        stroke: none;
      }

      .cls-4 {
        fill: none;
      }
    </style>
  </defs>
  <g id="excl" transform="translate(-5538.999 -8266)">
    <g id="Rectangle_284" data-name="Rectangle 284" class="cls-1" transform="translate(5609.993 8306.098)">
      <rect class="cls-3" width="46.671" height="115.363"/>
      <rect class="cls-4" x="0.5" y="0.5" width="45.671" height="114.363"/>
    </g>
    <path id="Path_688" data-name="Path 688" class="cls-2" d="M187.183,144.619a15.785,15.785,0,0,1-13.665,23.66H15.793a15.785,15.785,0,0,1-13.665-23.66L80.992,7.883a15.786,15.786,0,0,1,27.33,0l78.861,136.736ZM94.657,116.35a15.119,15.119,0,1,0,15.119,15.119A15.119,15.119,0,0,0,94.657,116.35ZM80.3,62.005l2.438,44.7a3.944,3.944,0,0,0,3.938,3.729h15.956a3.944,3.944,0,0,0,3.938-3.729l2.438-44.7a3.944,3.944,0,0,0-3.938-4.159H84.241A3.944,3.944,0,0,0,80.3,62.005Z" transform="translate(5539 8266)"/>
  </g>
</svg>
`

const exclSVGRed = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.314 168.279">
  <defs>
    <style>
      .cls-1 {
        fill: #fff;
        stroke: #707070;
      }

      .cls-2 {
        fill: #f4493a;
      }

      .cls-3 {
        stroke: none;
      }

      .cls-4 {
        fill: none;
      }
    </style>
  </defs>
  <g id="excl" transform="translate(-5538.999 -8266)">
    <g id="Rectangle_284" data-name="Rectangle 284" class="cls-1" transform="translate(5609.993 8306.098)">
      <rect class="cls-3" width="46.671" height="115.363"/>
      <rect class="cls-4" x="0.5" y="0.5" width="45.671" height="114.363"/>
    </g>
    <path id="Path_688" data-name="Path 688" class="cls-2" d="M187.183,144.619a15.785,15.785,0,0,1-13.665,23.66H15.793a15.785,15.785,0,0,1-13.665-23.66L80.992,7.883a15.786,15.786,0,0,1,27.33,0l78.861,136.736ZM94.657,116.35a15.119,15.119,0,1,0,15.119,15.119A15.119,15.119,0,0,0,94.657,116.35ZM80.3,62.005l2.438,44.7a3.944,3.944,0,0,0,3.938,3.729h15.956a3.944,3.944,0,0,0,3.938-3.729l2.438-44.7a3.944,3.944,0,0,0-3.938-4.159H84.241A3.944,3.944,0,0,0,80.3,62.005Z" transform="translate(5539 8266)"/>
  </g>
</svg>
`

export const ExclSVGYellow = imagify(exclSVGYellow)
export const ExclSVGRed = imagify(exclSVGRed)
export const ExclSVGGreen = imagify(exclSVGGreen)
