import {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { ViewerContext } from "../../context"
import { ModelSelector } from "../ModelSelector"
import { ProgressBar } from "./ProgressBar"
import { BackForwardControls } from "./BackForwardControls"
import { drawMocPinsEffect } from "./CesiumStageViewerEffects/DrawMocPins"
import { drawMocAreasEffect } from "./CesiumStageViewerEffects/DrawMocAreas"
import { drawSitePins } from "./CesiumStageViewerEffects/DrawSitePins"
import { useKeyboardControls } from "./CesiumStageViewerEffects/UseKeyboardControls"
import { ITileState, LoadTileset } from "./CesiumStageViewerEffects/LoadTileset"
import { ICameraHistory, useCameraHistory } from "./CesiumStageViewerEffects/UseCameraHistory"
import { useForwardCuttingPlane } from "./CesiumStageViewerEffects/UseForwardCuttingPlane"
import { Viewer } from "cesium"
import { useGlobeDisplay } from "./CesiumStageViewerEffects/UseGlobeDisplay"

export const StageViewerContext = createContext({
  selectedModelType: 0,
  // tslint:disable-next-line:no-empty
  setSelectedModelType: (modelTypeId: number) => {},
  tileState: {} as ITileState,
  cameraHistory: {} as ICameraHistory,
  // tslint:disable-next-line:no-empty
  cameraGoBack: () => {},
  // tslint:disable-next-line:no-empty
  cameraGoForward: () => {},
  currentWindow: window as Window,
  // tslint:disable-next-line:no-empty
  setCurrentWindow: (window: Window) => {},
})

export const CesiumStageViewer = (props: any) => {
  const { index, selectedModelType, setSelectedModelType } = props
  const divId = useRef(`cesiumContainer-${index}`)
  const [viewerInstance, setViewerInstance] = useState(null as Viewer)
  const [currentWindow, setCurrentWindow] = useState(window as Window)
  const {
    createViewer,
    removeViewer,
    setActiveViewer,
    globalCameraHistory,
    setGlobalCameraHistory,
    imageryEnabled,
    backgrounded,
    activeViewer,
    viewerCount,
  } = useContext(ViewerContext)

  useEffect(() => {
    const viewer = createViewer(index, divId.current)
    setViewerInstance(viewer)
    return () => removeViewer(index)
  }, [viewerCount, selectedModelType])

  const setViewerAsActive = useCallback(() => {
    if (!viewerInstance) {
      return
    }
    setActiveViewer(viewerInstance)
  }, [setActiveViewer, viewerInstance, viewerCount, selectedModelType])

  const { tileState } = LoadTileset(viewerInstance, selectedModelType, index)
  useForwardCuttingPlane(viewerInstance, selectedModelType)
  useGlobeDisplay(viewerInstance)
  drawMocPinsEffect(viewerInstance)
  drawMocAreasEffect(viewerInstance)
  drawSitePins(viewerInstance)
  useKeyboardControls(viewerInstance, !backgrounded && viewerInstance === activeViewer)
  const { cameraHistory, cameraGoBack, cameraGoForward } = useCameraHistory(
    viewerInstance,
    globalCameraHistory,
    setGlobalCameraHistory
  )

  return (
    <Fragment>
      <div
        id={divId.current}
        className={"cesium-stage-viewer"}
        data-testid="cesium-stage-viewer"
        onMouseOver={setViewerAsActive}
      >
        <StageViewerContext.Provider
          value={{
            selectedModelType,
            setSelectedModelType,
            tileState,
            cameraHistory,
            cameraGoBack,
            cameraGoForward,
            currentWindow,
            setCurrentWindow,
          }}
        >
          <ModelSelector
            selectedModelType={selectedModelType}
            setSelectedModelType={setSelectedModelType}
          />
          <ProgressBar />
          <BackForwardControls />
        </StageViewerContext.Provider>
      </div>
    </Fragment>
  )
}
