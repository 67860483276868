import { useContext, useEffect, useState, useCallback } from "react"
import { ModalContext, PermissionContext } from "../../context"
import { Loading } from "../Loading"
import { Button } from "@mui/material"
// import { UseAcceptDisclaimer } from "../../api/Hooks/PermissionHooks";
import axios from "axios"
import { apiHostname, apiAuthAcceptdisclaimer } from "../../api/constants"
import { toast } from "react-toastify"

const DisclaimerForm = (props: any) => {
  // const { acceptDisclaimer } = props
  const { response, setResponse } = props

  const submitForm = async () => {
    const response = UseAcceptDisclaimer()
    if (response) setResponse(true)
  }

  return (
    <>
      <div className="modal is-active">
        <div className="modal-background">
          <div className="modal-card disclaimer">
            <p>
              Airscope Visualisation is a representation of the <em>as-built</em> condition of
              existing spaces and should be used in conjunction with other sound industry practices
              for verification of this condition, including but not limited to: site visits, manual
              field measurements and verifications of scan data, to ensure its applicability prior
              to proposed works.
            </p>
            <br />
            <p>
              Because this data can be used for various applications and disciplines outside the
              scope of the original application and beyond our control, we cannot guarantee that the
              data is accurate for these uses.
            </p>
            <br />
            <div className="disclaimer-footer">
              <Button
                variant="outlined"
                type={"submit"}
                data-testid={"accept-disclaimer"}
                onClick={submitForm}
              >
                Accept
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const Disclaimer = () => {
  const { showModal, hideModal } = useContext(ModalContext)
  const { currentUser, acceptDisclaimer } = useContext(PermissionContext)
  const [response, setResponse] = useState(false)

  if (acceptDisclaimer) return <div />
  return <DisclaimerForm response={response} setResponse={setResponse} />
}

async function UseAcceptDisclaimer() {
  const token: string = localStorage.getItem("auth")
  const url: string = apiHostname + "/api" + apiAuthAcceptdisclaimer
  const result = false

  const response = await axios({
    method: "POST",
    url,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    withCredentials: true,
  }).then(response => {
    window.location.assign("/")
    return response
  })
  // }
  return { ...response }
}
