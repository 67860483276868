import { useEffect, useContext } from "react"
import { airscopeColor, airscopeDashed } from "../helpers/colours"
import { createArcLine } from "../helpers/viewer"
import { ViewerContext } from "../context"
import { circle } from "../assets"
import { useEntityGroups } from "./viewer/useEntityGroups"
import { usePointSelector } from "./viewer/usePointSelector"
import { usePointDistanceCursor } from "./viewer/usePointDistanceCursor"
import * as Cesium from "cesium"

interface IAngleLabel {
  text: string
  position: Cesium.Cartesian3
  offsetPosition?: Cesium.Cartesian2
  isAngleLabel?: boolean
}

export const usePerpendicularAngleMeasure = () => {
  const { viewersForEach, getTilesetCoordUnitVector } = useContext(ViewerContext)
  const { datasources } = useEntityGroups()
  const { points, setPoints, clearPoints } = usePointSelector(2)
  usePointDistanceCursor(points)

  useEffect(() => {
    const lines: Cesium.Cartesian3[][] = []
    const labels: IAngleLabel[] = []
    const modelUnitVec = getTilesetCoordUnitVector() // TODO: Memoize this

    if (points.length === 2 && modelUnitVec) {
      const uZ = modelUnitVec.uZ

      const vDiff = Cesium.Cartesian3.subtract(
        points[1].position.cartesian3,
        points[0].position.cartesian3,
        new Cesium.Cartesian3()
      )

      const compZ = Cesium.Cartesian3.dot(vDiff, uZ)

      const angle =
        (Math.acos(Math.abs(compZ) / Cesium.Cartesian3.magnitude(vDiff)) / Math.PI) * 180

      const p3 = Cesium.Cartesian3.add(
        points[0].position.cartesian3,
        Cesium.Cartesian3.multiplyByScalar(uZ, compZ, new Cesium.Cartesian3()),
        new Cesium.Cartesian3()
      )

      const p4 = Cesium.Cartesian3.add(
        points[1].position.cartesian3,
        Cesium.Cartesian3.multiplyByScalar(uZ, -1 * compZ, new Cesium.Cartesian3()),
        new Cesium.Cartesian3()
      )

      const v03 = Cesium.Cartesian3.subtract(
        p3,
        points[0].position.cartesian3,
        new Cesium.Cartesian3()
      )
      const m03 = Cesium.Cartesian3.magnitude(v03)
      const u03 = Cesium.Cartesian3.normalize(v03, new Cesium.Cartesian3())
      const v13 = Cesium.Cartesian3.subtract(
        p3,
        points[1].position.cartesian3,
        new Cesium.Cartesian3()
      )
      const u01 = Cesium.Cartesian3.normalize(vDiff, new Cesium.Cartesian3())
      const m01 = Cesium.Cartesian3.magnitude(vDiff)

      const v04 = Cesium.Cartesian3.subtract(
        p4,
        points[0].position.cartesian3,
        new Cesium.Cartesian3()
      )
      const u04 = Cesium.Cartesian3.normalize(v04, new Cesium.Cartesian3())
      const v14 = Cesium.Cartesian3.subtract(
        p4,
        points[1].position.cartesian3,
        new Cesium.Cartesian3()
      )
      const m14 = Cesium.Cartesian3.magnitude(v14)

      let scaleLength
      if (m03 >= m01) {
        scaleLength = 0.2 * m01
      } else {
        scaleLength = 0.2 * m03
      }
      if (scaleLength > 0.5) {
        scaleLength = 0.5
      }

      const a0 = Cesium.Cartesian3.add(
        points[0].position.cartesian3,
        Cesium.Cartesian3.multiplyByScalar(u03, scaleLength, new Cesium.Cartesian3()),
        new Cesium.Cartesian3()
      )
      const a1 = Cesium.Cartesian3.add(
        points[0].position.cartesian3,
        Cesium.Cartesian3.multiplyByScalar(u01, scaleLength, new Cesium.Cartesian3()),
        new Cesium.Cartesian3()
      )

      if (m14 >= m01) {
        scaleLength = 0.2 * m01
      } else {
        scaleLength = 0.2 * m03
      }
      if (scaleLength > 0.5) {
        scaleLength = 0.5
      }

      const a3 = Cesium.Cartesian3.subtract(
        points[1].position.cartesian3,
        Cesium.Cartesian3.multiplyByScalar(u04, scaleLength, new Cesium.Cartesian3()),
        new Cesium.Cartesian3()
      )
      const a4 = Cesium.Cartesian3.subtract(
        points[1].position.cartesian3,
        Cesium.Cartesian3.multiplyByScalar(u01, scaleLength, new Cesium.Cartesian3()),
        new Cesium.Cartesian3()
      )

      lines.push([points[0].position.cartesian3, points[1].position.cartesian3])
      lines.push([points[0].position.cartesian3, p3])
      lines.push([points[1].position.cartesian3, p3])
      lines.push([points[1].position.cartesian3, p3])

      lines.push(createArcLine(a0, a1, points[0].position.cartesian3, (2.0 / 180) * Math.PI))
      lines.push(createArcLine(a3, a4, points[1].position.cartesian3, (2.0 / 180) * Math.PI))

      // Angle labels
      labels.push({
        text: angle.toFixed(1) + "°",
        position: a1,
        isAngleLabel: true,
      })
      labels.push({
        text: (90 - angle).toFixed(1) + "°",
        position: a4,
        isAngleLabel: true,
      })

      // Distance labels
      labels.push({
        text: Cesium.Cartesian3.magnitude(vDiff).toFixed(2) + " m",
        position: Cesium.Cartesian3.add(
          points[0].position.cartesian3,
          Cesium.Cartesian3.multiplyByScalar(vDiff, 0.5, new Cesium.Cartesian3()),
          new Cesium.Cartesian3()
        ),
      })
      labels.push({
        text: Cesium.Cartesian3.magnitude(v03).toFixed(2) + " m",
        position: Cesium.Cartesian3.add(
          points[0].position.cartesian3,
          Cesium.Cartesian3.multiplyByScalar(v03, 0.5, new Cesium.Cartesian3()),
          new Cesium.Cartesian3()
        ),
      })
      labels.push({
        text: Cesium.Cartesian3.magnitude(v13).toFixed(2) + " m",
        position: Cesium.Cartesian3.add(
          points[1].position.cartesian3,
          Cesium.Cartesian3.multiplyByScalar(v13, 0.5, new Cesium.Cartesian3()),
          new Cesium.Cartesian3()
        ),
        offsetPosition: new Cesium.Cartesian2(0, -10),
      })
    }

    viewersForEach((viewer: Cesium.Viewer, viewerIdx: number) => {
      points.forEach(point => {
        // @ts-ignore
        const targetObj: Cesium.EntityOptions = {
          id: point.id + "-target",
          position: point.position.cartesian3,
          billboard: {
            image: circle,
            width: 10,
            height: 10,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            show: true,
            color: new Cesium.Color(1.0, 1.0, 1.0, 0.9),
          },
        }
        datasources.current[viewerIdx].entities.add(targetObj)
      })

      lines.forEach(positions => {
        const dMaterial = positions.length > 2 ? airscopeColor : airscopeDashed

        // @ts-ignore
        const targetObj: Cesium.EntityOptions = {
          polyline: {
            positions,
            width: 2,
            material: airscopeColor,
            followSurface: false,
            depthFailMaterial: dMaterial,
          },
        }
        datasources.current[viewerIdx].entities.add(targetObj)
      })

      labels.forEach(label => {
        // @ts-ignore
        const labelObj: Cesium.EntityOptions = {
          position: label.position,
          label: {
            text: label.text,
            font: "14px sans-serif",
            showBackground: true,
            pixelOffset: label.offsetPosition,
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
        }

        if (label.isAngleLabel) {
          Object.assign(labelObj.label, {
            fillColor: Cesium.Color.BLACK,
            backgroundColor: Cesium.Color.LIGHTGREY,
            pixelOffset: new Cesium.Cartesian2(20, 5),
          })
        }

        datasources.current[viewerIdx].entities.add(labelObj)
      })

      viewer.scene.requestRender()
    })

    return () => {
      viewersForEach((viewer: Cesium.Viewer, viewerIdx: number) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        datasources.current[viewerIdx].entities.removeAll()
        viewer.scene.requestRender()
      })
    }
  }, [viewersForEach, points, getTilesetCoordUnitVector, datasources])

  return { points, setPoints, clearPoints }
}
