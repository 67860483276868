import * as Cesium from "cesium"

export function getCartesian3AsArray(cartesian: Cesium.Cartesian3): [number, number, number] {
  return [cartesian.x, cartesian.y, cartesian.z]
}

export function getMatrix4AsArray(worldSpaceTransform: Cesium.Matrix4) {
  return Object.keys(worldSpaceTransform).map(key => worldSpaceTransform[key])
}

export function applyTransformToDirectionAbstraction(
  transform: Array<number>,
  direction: [number, number, number]
): [number, number, number] {
  const typedTransform = Cesium.Matrix4.fromArray(transform)
  const typedDirection = Cesium.Cartesian3.fromElements(...direction)

  const calculatedDirection = Cesium.Matrix4.multiplyByPointAsVector(
    typedTransform,
    typedDirection,
    typedDirection
  )

  return [calculatedDirection.x, calculatedDirection.y, calculatedDirection.z]
}

export function applyTransformToPointAbstraction(
  transform: Array<number>,
  point: [number, number, number]
): [number, number, number] {
  const typedTransform = Cesium.Matrix4.fromArray(transform)
  const typedClick = Cesium.Cartesian3.fromElements(...point)

  const localPoint = Cesium.Matrix4.multiplyByPoint(typedTransform, typedClick, typedClick)

  return [localPoint.x, localPoint.y, localPoint.z]
}

export function transformToInverseAbstraction(transform: Array<number>) {
  const typedTransform = Cesium.Matrix4.fromArray(transform)

  const invertedTransform = Cesium.Matrix4.inverse(typedTransform, typedTransform)
  return Object.keys(invertedTransform).map(key => invertedTransform[key])
}
