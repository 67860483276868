import { ModelType } from "../../interfaces"

export function defaultToAvailableModelType(selectedModelType, models, selectedModelGroup) {
  if (models.length !== 0 && selectedModelGroup != null && selectedModelType !== -1) {
    const selectedModels = models.filter(m => m.modelgroupId === selectedModelGroup.id)
    if (
      selectedModels.length !== 0 &&
      !selectedModels.find(m => m.modeltypeId === selectedModelType)
    )
      return selectedModels[0].modeltypeId
  }
  return selectedModelType
}
