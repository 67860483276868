// @ts-ignore
import React from "react"
import { useLocalStorageArray, useLocalStorageBoolean } from "../hooks/useLocalStorage"
import { useApiMocs } from "../api/ApiHooks"
import { IAttachment, IMOC, IPolygon } from "../interfaces"

export const MocsContext = React.createContext({
  mocs: [] as IMOC[],
  mocsLoading: false,
  showMocs: false,
  // tslint:disable-next-line:no-empty
  setShowMocs: (show: boolean) => {},
  hiddenMocsIDs: [] as number[],
  // tslint:disable-next-line:no-empty
  addToHiddenMocsIDs: (newValue: number | undefined) => {},
  // tslint:disable-next-line:no-empty
  removeFromHiddenMocsIDs: (valueToRemove: number | undefined) => {},
  // tslint:disable-next-line:no-empty
  refreshMocs: () => {},
  // tslint:disable-next-line:no-empty
  addAreaToMoc: (moc: IMOC, polygon: IPolygon, name: string) => {},
  // tslint:disable-next-line:no-empty
  archiveMocArea: (moc: IMOC, polygon: IPolygon) => {},
  // tslint:disable-next-line:no-empty
  archiveMocAttachment: (attachment: IAttachment) => {},
  // tslint:disable-next-line:no-empty
  archiveMoc: (moc: IMOC) => {},
})

export const MocsContextProvider = (props: any) => {
  const {
    mocs,
    mocsLoading,
    refreshMocs,
    addAreaToMoc,
    archiveMocArea,
    archiveMocAttachment,
    archiveMoc,
  } = useApiMocs()
  const [showMocs, setShowMocs] = useLocalStorageBoolean("mocs-site-show", false)
  const [hiddenMocsIDs, addToHiddenMocsIDs, removeFromHiddenMocsIDs] = useLocalStorageArray<number>(
    "mocs-site-hidden"
  )

  return (
    <MocsContext.Provider
      value={{
        mocs,
        mocsLoading,
        refreshMocs,
        addAreaToMoc,
        archiveMocArea,
        archiveMocAttachment,
        archiveMoc,
        showMocs,
        setShowMocs,
        hiddenMocsIDs,
        addToHiddenMocsIDs,
        removeFromHiddenMocsIDs,
      }}
    >
      {props.children}
    </MocsContext.Provider>
  )
}
