import { useEffect, useRef, useContext } from "react"
import { IMOCPoint } from "../../../interfaces"
import { mocStateToColor } from "../../../helpers"
import { MocsContext } from "../../../context"
import * as Cesium from "cesium"

export const drawMocAreasEffect = (viewer: Cesium.Viewer | null) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mocs, showMocs, hiddenMocsIDs } = useContext(MocsContext)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const polygons = useRef(new Cesium.CustomDataSource("polygons"))

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!viewer) {
      return
    }

    viewer.dataSources.add(polygons.current)

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      viewer.dataSources.remove(polygons.current, true)
    }
  }, [viewer])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!viewer || !mocs || !showMocs) {
      return
    }

    mocs
      .filter(moc => moc.polygons && moc.polygons.length > 0 && !hiddenMocsIDs.includes(moc.id))
      .forEach(moc => {
        const colorString = mocStateToColor(moc.state)

        moc.polygons
          .filter(polygon => !polygon.archived)
          .forEach((polygon, idx) => {
            const color = Cesium.Color.fromCssColorString(colorString).withAlpha(0.5)
            const positions: number[] = []

            polygon.points.forEach((point: IMOCPoint) => {
              const cartesianPoint = new Cesium.Cartesian3(point.x, point.y, point.z)
              const cartographicPoint = Cesium.Cartographic.fromCartesian(cartesianPoint)
              positions.push(cartographicPoint.longitude, cartographicPoint.latitude)
            })

            polygons.current.entities.add({
              id: `polygon-moc-${moc.id}-${idx}`,
              polygon: new Cesium.PolygonGraphics({
                height: polygon.bottomHeight,
                extrudedHeight: polygon.topHeight,
                closeTop: polygon.capped,
                closeBottom: polygon.capped,
                material: color,
                outline: true,
                outlineColor: Cesium.Color.BLACK,
              }),
            })
          })
      })

    viewer.scene.requestRender()

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      polygons.current.entities.removeAll()
      viewer.scene.requestRender()
    }
  }, [viewer, mocs, showMocs, hiddenMocsIDs])
}
