import { useState, useEffect, useContext, useCallback } from "react"
import { ViewerContext } from "../context"
import { IPosition } from "../interfaces"
import { useCesiumOnLeftClick } from "./useCesiumOnClick"

export const useCesiumSniper = (isEnabled: boolean) => {
  const [sniperEnabled, setSniperEnabled] = useState(isEnabled)
  const [selectedSniperPoint, setSelectedPoint] = useState(null as IPosition | null)
  const { viewer } = useContext(ViewerContext)

  useEffect(() => {
    if (!viewer) {
      return
    }

    if (sniperEnabled) {
      viewer.canvas.classList.add("sniper-cursor")
    }

    return () => viewer.canvas.classList.remove("sniper-cursor")
  }, [viewer, sniperEnabled])

  useCesiumOnLeftClick((event: any) => {
    const position: IPosition | null = event.pickPosition()
    const pickedList = event.drillPick()

    for (const object of pickedList) {
      if (object.id && object.id._id) {
        if (object.id._id.startsWith("draw-point-") || object.id._id.startsWith("polygon-")) {
          // Don't create a new point if the user clicked a polygon or existing point marker
          return
        }
      }
    }

    setSelectedPoint(position)
    setSniperEnabled(false)
  })

  const enableSniper = useCallback(() => {
    setSelectedPoint(null)
    setSniperEnabled(true)
  }, [])

  return { selectedSniperPoint, sniperEnabled, enableSniper }
}
