/* tslint:disable:jsx-no-lambda */
import * as React from "react"
import { Button, Panel } from "trunx"
import { FontAwesomeIcon } from "../../helpers/icon"
import { Sidebar } from "./sidebar"
import { SettingsPane } from "../SettingsPane"
import { Constants } from "../../constants"
import { RenderIfPermission } from "../../helpers/Permissions"
import { logout } from "../../api/security"
import { styled } from "@mui/material/styles"
import { orange } from "@mui/material/colors"

const StyledButton = styled(Button)(() => ({
  color: "White",
  backgroundColor: "Orange",
  "&:hover": {
    backgroundColor: orange[600],
    boxShadow: "none",
  },
  width: "100%",
}))

const SettingsSidebar = () => {
  return (
    <Sidebar render={() => <SettingsPane />}>
      <p>Visualize v2.7.0</p>
      <Panel>
        <Panel.Heading>Profile Settings</Panel.Heading>
        <Panel.Block href={"/settings/profile/security"}>
          <span className="panel-icon">
            <FontAwesomeIcon icon={"lock"} />
          </span>
          Security
        </Panel.Block>
        <Panel.Block href={"/settings/profile/account"}>
          <span className="panel-icon">
            <FontAwesomeIcon icon={"user"} />
          </span>
          Account
        </Panel.Block>
        {Constants.Settings.showNotificationSettings && (
          <Panel.Block href={"/settings/profile/notifications"}>
            <span className="panel-icon">
              <FontAwesomeIcon icon={"bullhorn"} />
            </span>
            Notifications
          </Panel.Block>
        )}
      </Panel>
      {Constants.Settings.showOrganisationSettings && (
        <nav className="panel">
          <Panel.Heading>Organisation Settings</Panel.Heading>
          <Panel.Block href={"/settings/organisation/security"}>
            <span className="panel-icon">
              <FontAwesomeIcon icon={"lock"} />
            </span>
            Organisation Security
          </Panel.Block>
          <Panel.Block>
            <span className="panel-icon">
              <FontAwesomeIcon icon={"credit-card"} />
            </span>
            Billing
          </Panel.Block>
          <Panel.Block>
            <span className="panel-icon">
              <FontAwesomeIcon icon={"puzzle-piece"} />
            </span>
            External Integrations
          </Panel.Block>
          <Panel.Block>
            <span className="panel-icon">
              <FontAwesomeIcon icon={"history"} />
            </span>
            Audit Log
          </Panel.Block>
        </nav>
      )}
      {Constants.Settings.showSiteSettings && (
        <nav className="panel">
          <Panel.Heading>Site Settings</Panel.Heading>
          <Panel.Block>
            <span className="panel-icon">
              <FontAwesomeIcon icon={"user"} />
            </span>
            Display
          </Panel.Block>
          <Panel.Block>
            <span className="panel-icon">
              <FontAwesomeIcon icon={"bullhorn"} />
            </span>
            Notifications
          </Panel.Block>
        </nav>
      )}

      <RenderIfPermission
        token={["USERS:VIEW", "TEAMS:VIEW", "ROLES:VIEW", "LICENSING:VIEW"]}
        mode={"OR"}
      >
        <nav className="panel">
          <Panel.Heading>System Management</Panel.Heading>
          {Constants.Settings.showInstanceSettings && (
            <Panel.Block href={"/settings/instance"}>
              <span className="panel-icon">
                <FontAwesomeIcon icon={"toolbox"} />
              </span>
              Instance Administration
            </Panel.Block>
          )}

          <RenderIfPermission token={"USERS:VIEW"}>
            <Panel.Block href={"/settings/security/users"}>
              <span className="panel-icon">
                <FontAwesomeIcon icon={"user"} />
              </span>
              Manage Users
            </Panel.Block>
          </RenderIfPermission>
          <RenderIfPermission token={"TEAMS:VIEW"}>
            <Panel.Block href={"/settings/security/teams"}>
              <span className="panel-icon">
                <FontAwesomeIcon icon={"users"} />
              </span>
              Manage Teams
            </Panel.Block>
          </RenderIfPermission>
          <RenderIfPermission token={"ROLES:VIEW"}>
            <Panel.Block href={"/settings/security/roles"}>
              <span className="panel-icon">
                <FontAwesomeIcon icon={"lock"} />
              </span>
              Manage Roles
            </Panel.Block>
          </RenderIfPermission>
        </nav>
      </RenderIfPermission>

      {Constants.Settings.showAdminSettings && (
        <nav className="panel">
          <Panel.Heading>Admin Settings</Panel.Heading>
          <Panel.Block>
            <span className="panel-icon">
              <FontAwesomeIcon icon={"lock"} />
            </span>
            Metrics
          </Panel.Block>
        </nav>
      )}

      <div>
        <StyledButton isPrimary={true} data-testid="logout" onClick={handleClick()}>
          Logout
        </StyledButton>
      </div>
    </Sidebar>
  )
}

export { SettingsSidebar }

function handleClick(): React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> {
  return () =>
    logout("auth").then(() => {
      window.location.assign("/")
    })
}
