import Moment from "react-moment"
import React from "react"

// Moment.js Locale aware formats
// LLLL => Day of week, month name, day of month, year, time
// LLLL => Thursday, September 4 1986 8:30 PM
const DateTimeFormat = "LLLL"

interface IProps {
  date: Date | null
  emptyText?: string
}

export const StandardDate = (props: IProps) => {
  return (
    <>
      {props.date == null ? (
        props.emptyText === undefined ? (
          ""
        ) : (
          props.emptyText
        )
      ) : (
        <Moment date={props.date} format={DateTimeFormat} />
      )}
    </>
  )
}
