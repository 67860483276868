import { useContext, useEffect, useRef, useState } from "react"
import { ModelsContext, ViewerContext } from "../../../context"
import { computeTransform, getTilesetPosition, Position } from "./Transformation"
import * as Cesium from "cesium"
import { getCesiumSettings, SetPointSize } from "../../../helpers/sitesHelpers"
import { apiHostname } from "../../../api/constants"
import { getModelsFromJson, positionTileset } from "./LoadTilesetHelper"
import { modelsFileName, tilesetFileName } from "../../../constants"
import { IModel, ISite } from "../../../interfaces"
import { Cesium3DTileset } from "cesium"
import { ModelQuality } from "../../../context/Models"

export interface ITileState {
  numberProcessing: number
  tilesLoaded: number
  allLoaded: boolean
}

function AddModels(
  setTilesetState: (value: ((prevState: ITileState) => ITileState) | ITileState) => void,
  model: IModel,
  viewer: Cesium.Viewer,
  modelQuality: ModelQuality,
  updateTilesets: (
    viewerIdx: number,
    newTileset: Cesium3DTileset | null,
    newModelType: number | null
  ) => void,
  viewerIdx: number,
  selectedModelType: number
) {
  const tilesetList: Cesium.PrimitiveCollection[] = []

  const allTilesLoadedHandler = () =>
    setTilesetState(() => {
      return {
        tilesLoaded: 0,
        allLoaded: true,
      } as ITileState
    })

  getModelsFromJson(model.filepath).then(models => {
    for (const model of models) {
      const cesiumOptions = model.CesiumOptions
      cesiumOptions.url = apiHostname + cesiumOptions.url
      const tileset = viewer.scene.primitives.add(new Cesium.Cesium3DTileset({ ...cesiumOptions }))
      const style = {
        pointSize: SetPointSize(modelQuality),
      }
      tilesetList.push(tileset)
      tileset.style = new Cesium.Cesium3DTileStyle(style)

      tileset.allTilesLoaded.addEventListener(allTilesLoadedHandler)

      tileset.readyPromise.then(async tileset => {
        if (tileset) {
          if (model.position !== undefined) {
            await positionTileset(tileset, model.position, model.orientation)
          }
          if (!viewer.scene.primitives.contains(tileset)) viewer.scene.primitives.add(tileset)
          updateTilesets(viewerIdx, tileset, selectedModelType)
        }
      })
    }
  })

  return () => {
    for (const tileset of tilesetList) {
      viewer.scene.primitives.remove(tileset)
    }
    updateTilesets(viewerIdx, null, selectedModelType)
  }
}

function AddModel(
  model: IModel,
  selectedSite: ISite,
  selectedModelType: number,
  modelQuality: ModelQuality,
  setTilesetState: (value: ((prevState: ITileState) => ITileState) | ITileState) => void,
  viewer: Cesium.Viewer,
  updateTilesets: (
    viewerIdx: number,
    newTileset: Cesium3DTileset | null,
    newModelType: number | null
  ) => void,
  viewerIdx: number
) {
  const options = getCesiumSettings(
    model.modeltypeId,
    model.filepath,
    selectedSite?.slug,
    selectedModelType,
    modelQuality
  )

  const tileset = new Cesium.Cesium3DTileset(options)

  const style = {
    pointSize: SetPointSize(modelQuality),
  }

  tileset.style = new Cesium.Cesium3DTileStyle(style)

  const tileLoadHandler = () =>
    setTilesetState(state => {
      return {
        tilesLoaded: state.tilesLoaded + 1,
        allLoaded: false,
      } as ITileState
    })
  const allTilesLoadedHandler = () =>
    setTilesetState(() => {
      return {
        tilesLoaded: 0,
        allLoaded: true,
      } as ITileState
    })

  tileset.tileLoad.addEventListener(tileLoadHandler)
  tileset.allTilesLoaded.addEventListener(allTilesLoadedHandler)

  tileset.readyPromise.then(tileset => {
    getTilesetPosition(tileset.basePath + tilesetFileName).then(async value => {
      if (value !== undefined) {
        const position: Position = value
        tileset.root.transform = Cesium.Matrix4.IDENTITY
        const transform = await computeTransform(position)
        tileset.modelMatrix = transform
      }

      if (!viewer.scene.primitives.contains(tileset)) viewer.scene.primitives.add(tileset)

      updateTilesets(viewerIdx, tileset, selectedModelType)
    })
  })

  return () => {
    viewer.scene.primitives.remove(tileset)
    tileset.tileLoad.removeEventListener(tileLoadHandler)
    tileset.allTilesLoaded.removeEventListener(allTilesLoadedHandler)
    updateTilesets(viewerIdx, null, selectedModelType)
  }
}

export const LoadTileset = (
  viewer: Cesium.Viewer | null,
  selectedModelType: number,
  viewerIdx: number
) => {
  const { selectedSite, selectedModelGroup, models, modelQuality } = useContext(ModelsContext)
  const { updateTilesets } = useContext(ViewerContext)
  const [tileState, setTilesetState] = useState({
    tilesLoaded: 0,
    allLoaded: true,
  } as ITileState)

  useEffect(() => {
    if (!viewer || !selectedModelGroup) {
      return
    }

    const model = models.find(
      m => m.modelgroupId === selectedModelGroup.id && m.modeltypeId === selectedModelType
    )
    if (!model) return

    if (model.filepath.endsWith(modelsFileName)) {
      return AddModels(
        setTilesetState,
        model,
        viewer,
        modelQuality,
        updateTilesets,
        viewerIdx,
        selectedModelType
      )
    }
    return AddModel(
      model,
      selectedSite,
      selectedModelType,
      modelQuality,
      setTilesetState,
      viewer,
      updateTilesets,
      viewerIdx
    )
  }, [viewer, selectedModelType, selectedModelGroup, models, selectedSite, modelQuality, viewerIdx])

  return { tileState }
}
