import { FontAwesomeIcon as FA, Props } from "@fortawesome/react-fontawesome"
import { IconPrefix, IconName } from "@fortawesome/fontawesome-svg-core"

const FAPREFIX: IconPrefix = "fas"

interface IProps extends Props {
  icon: IconName
}

const FontAwesomeIcon = (props: IProps) => <FA {...props} icon={[FAPREFIX, props.icon]} />

export { FontAwesomeIcon }
