import * as React from "react"
// @ts-ignore
import MultiToggle from "react-multi-toggle"

enum PermissionValue {
  Allow,
  Deny,
  Inherit,
}

interface IFeaturePermissionToggleProps {
  setPermissionValue: (value: boolean | null) => void
  permissionValue: boolean | null
  label?: string
}

interface IFeaturePermissionToggleState {
  selectedOption: PermissionValue
}

class FeaturePermissionToggle extends React.Component<
  IFeaturePermissionToggleProps,
  IFeaturePermissionToggleState
> {
  constructor(props: IFeaturePermissionToggleProps) {
    super(props)

    let value: PermissionValue = PermissionValue.Inherit

    switch (props.permissionValue) {
      case true:
        value = PermissionValue.Allow
        break
      case false:
        value = PermissionValue.Deny
        break
    }

    this.state = { selectedOption: value }
  }

  render() {
    return (
      <div className={"feature-permission-toggle-container"}>
        <MultiToggle
          options={[
            { displayName: "Allow", value: PermissionValue.Allow, optionClass: "allow" },
            { displayName: "Inherit", value: PermissionValue.Inherit, optionClass: "inherit" },
            { displayName: "Deny", value: PermissionValue.Deny, optionClass: "deny" },
          ]}
          selectedOption={this.state.selectedOption}
          onSelectOption={this.setOption}
          className={"feature-permission-toggle"}
        />
      </div>
    )
  }

  setOption = (value: PermissionValue) => {
    let permissionValue: boolean | null = null

    switch (value) {
      case PermissionValue.Allow:
        permissionValue = true
        break
      case PermissionValue.Deny:
        permissionValue = false
        break
    }

    this.setState({ selectedOption: value })
    this.props.setPermissionValue(permissionValue)
  }
}

export { FeaturePermissionToggle }
