import { useEffect, useContext } from "react"
import { ViewerContext } from "../../context"

export const useSetViewerBackgrounded = () => {
  const { setViewerBackgrounded } = useContext(ViewerContext)

  useEffect(() => {
    setViewerBackgrounded(true)

    return () => setViewerBackgrounded(false)
  }, [setViewerBackgrounded])
}
