import { useCallback, useContext, useEffect, useRef } from "react"
import { useCesiumOnLeftClick } from "./useCesiumOnClick"
import { CesiumClickEvent } from "./models/CesiumClickEvent"
import {
  NearbyImageHeightMode,
  NearbyImageMode,
  NearbyImageSearchRadius,
} from "../context/NearbyImages"
import { ModelsContext, ViewerContext } from "../context"
import { useApiNearbyImages } from "../api/Hooks/SnapshotHooks"
import { useEntityGroups } from "./viewer/useEntityGroups"
import { airscopeColor } from "../helpers/colours"
import * as Cesium from "cesium"
import { apiHostname } from "../api/constants"
import axios from "axios"
import {
  applyTransformToDirectionAbstraction,
  applyTransformToPointAbstraction,
  getCartesian3AsArray,
  getMatrix4AsArray,
  transformToInverseAbstraction,
} from "../helpers/cesiumAbstractions"
import { siteIsInLocalCoordinates, siteList } from "../helpers/siteSettings"

const DESIRED_IMAGE_COUNT: number = 20
const MINIMUM_MAGNITUDE: number = 5.0
const LINE_WIDTH: number = 1.5
const BILLBOARD_PROPERTIES = {
  height: 15,
  width: 10,
  scale: 0.1,
  sizeInMeters: true,
  color: Cesium.Color.WHITE,
}

export async function billboardSnapshotsCall(snapshotName: string) {
  const url = `${apiHostname}/files/snapshots/${snapshotName}/thumb`
  axios.defaults.withCredentials = true
  const response = axios({
    method: "GET",
    url,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    responseType: "blob",
  })
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
  return response
}

export function getCoordinatesInDesiredSpace(
  slug: string,
  transform: Array<number>,
  click: [number, number, number],
  cameraDirection: [number, number, number]
) {
  let coordinates = { position: click, direction: cameraDirection }

  if (siteIsInLocalCoordinates(slug, siteList())) {
    const modelSpaceTransform = transformToInverseAbstraction(transform)
    const position = applyTransformToPointAbstraction(modelSpaceTransform, click)
    const direction = applyTransformToDirectionAbstraction(modelSpaceTransform, cameraDirection)
    coordinates = { position, direction }
  }
  return coordinates
}

export function getPointInDesiredSpace(
  slug: string,
  transformToArray: any[],
  poi: [number, number, number]
) {
  let point = [...poi]
  if (siteIsInLocalCoordinates(slug, siteList())) {
    point = applyTransformToPointAbstraction(transformToArray, [...poi])
  }
  return point
}

export function useNearbyImages(
  nearbyToolActive: boolean,
  activeViewers: number,
  mode: NearbyImageMode,
  heightMode: NearbyImageHeightMode[],
  searchRadius: NearbyImageSearchRadius,
  billboardsEnabled: boolean,
  getNearbyPhotosTransform: Function
) {
  const { viewer, viewersForEach } = useContext(ViewerContext)
  const { selectedSite } = useContext(ModelsContext)
  const { nearbyImagesFetch, nearbyImagesLoading, nearbyImages } = useApiNearbyImages()
  const { datasources } = useEntityGroups()

  const clickedPosition = useRef<Cesium.Cartesian3>(null)

  useEffect(() => {
    if (billboardsEnabled && nearbyToolActive) {
      viewersForEach(async (viewer: Cesium.Viewer, viewerIdx: number) => {
        if (!nearbyImages) return

        getNearbyPhotosTransform().then(worldSpaceTransform => {
          if (!worldSpaceTransform) return

          const transformToArray = getMatrix4AsArray(worldSpaceTransform)

          nearbyImages.slice(0, DESIRED_IMAGE_COUNT).forEach(snapshot => {
            const poi = snapshot.pois[Object.keys(snapshot.pois)[0]]

            const localisedPosition = getPointInDesiredSpace(
              selectedSite && selectedSite.slug,
              transformToArray,
              [poi.longitude, poi.latitude, poi.altitude]
            )
            const position = new Cesium.Cartesian3(...localisedPosition)

            billboardSnapshotsCall(snapshot.filename)
              .then(response => {
                datasources.current[viewerIdx].entities.add({
                  id: `snapshot:${snapshot.id}:${snapshot.filename}`,
                  position,
                  billboard: new Cesium.BillboardGraphics({
                    ...BILLBOARD_PROPERTIES,
                    image: URL.createObjectURL(new Blob([response.data])),
                    show: new Cesium.CallbackProperty(() => {
                      const billboardMagnitude = Cesium.Cartesian3.magnitude(
                        Cesium.Cartesian3.subtract(
                          position,
                          viewer.camera.position,
                          new Cesium.Cartesian3()
                        )
                      )

                      return billboardMagnitude > MINIMUM_MAGNITUDE
                    }, false),
                  }),
                })
                if (clickedPosition.current && mode === NearbyImageMode.Click) {
                  const positions: Array<Cesium.Cartesian3> = [position, clickedPosition.current]

                  datasources.current[viewerIdx].entities.add({
                    polyline: {
                      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(1),
                      positions,
                      width: LINE_WIDTH,
                      material: airscopeColor,
                      show: new Cesium.CallbackProperty(() => {
                        const lineMagnitude = Cesium.Cartesian3.magnitude(
                          Cesium.Cartesian3.subtract(
                            positions[0],
                            viewer.camera.position,
                            new Cesium.Cartesian3()
                          )
                        )
                        return lineMagnitude > MINIMUM_MAGNITUDE
                      }, false),
                    },
                  })
                }
              })
              .catch(err => console.log(err))
          })
          viewer.scene.requestRender()
        })
      })

      return () => {
        viewersForEach((viewer: Cesium.Viewer, viewerIdx: number) => {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          datasources.current[viewerIdx].entities.removeAll()
          viewer.scene.requestRender()
        })
      }
    }
  }, [nearbyImages, datasources, viewersForEach, billboardsEnabled, getNearbyPhotosTransform, mode])

  const calculateNearbyImagesParameters = (position: Cesium.Cartesian3) => {
    if (!position) return

    getNearbyPhotosTransform().then(worldSpaceTransform => {
      if (!worldSpaceTransform) return

      const modelTransform = getMatrix4AsArray(worldSpaceTransform)
      const clickedPoint = getCartesian3AsArray(position)
      const cameraDirection = getCartesian3AsArray(viewer.camera.direction)

      const localCoordinates = getCoordinatesInDesiredSpace(
        selectedSite && selectedSite.slug,
        modelTransform,
        clickedPoint,
        cameraDirection
      )
      nearbyImagesFetch(
        localCoordinates.position,
        localCoordinates.direction,
        mode,
        heightMode,
        searchRadius
      ).then(() => viewer.scene.requestRender())
    })
  }

  const handleClick = useCallback(
    async (event: CesiumClickEvent) => {
      if (
        !viewer ||
        !nearbyToolActive ||
        activeViewers === 0 ||
        mode !== NearbyImageMode.Click ||
        nearbyImagesLoading
      )
        return

      const pickedPosition = event.pickPosition()
      if (pickedPosition) {
        clickedPosition.current = pickedPosition.cartesian3
        calculateNearbyImagesParameters(clickedPosition.current)
      }
    },
    [
      viewer,
      nearbyToolActive,
      activeViewers,
      mode,
      nearbyImagesLoading,
      getNearbyPhotosTransform,
      nearbyImagesFetch,
      heightMode,
      searchRadius,
    ]
  )

  useCesiumOnLeftClick(handleClick)

  const handleCameraMove = useCallback(async () => {
    if (!viewer) return
    getNearbyPhotosTransform().then(worldSpaceTransform => {
      if (!worldSpaceTransform) return

      const modelTransform = getMatrix4AsArray(worldSpaceTransform)
      const cameraPoint = getCartesian3AsArray(viewer.camera.position)
      const cameraDirection = getCartesian3AsArray(viewer.camera.direction)

      const localCoordinates = getCoordinatesInDesiredSpace(
        selectedSite && selectedSite.slug,
        modelTransform,
        cameraPoint,
        cameraDirection
      )
      nearbyImagesFetch(
        localCoordinates.position,
        localCoordinates.direction,
        mode,
        heightMode,
        searchRadius
      )
    })
  }, [viewer, mode, getNearbyPhotosTransform, nearbyImagesFetch, heightMode, searchRadius])

  useEffect(() => {
    if (nearbyToolActive && viewer && mode === NearbyImageMode.Camera) {
      handleCameraMove().then()
      viewer.camera.moveEnd.addEventListener(handleCameraMove)
      return () => viewer.camera.moveEnd.removeEventListener(handleCameraMove)
    }
    // tslint:disable-next-line:no-empty
    return () => {}
  }, [viewer, mode, nearbyToolActive, handleCameraMove])

  useEffect(() => {
    if (mode === NearbyImageMode.Camera) handleCameraMove().then()
    else calculateNearbyImagesParameters(clickedPosition.current)
  }, [handleCameraMove, heightMode])

  return { nearbyImagesLoading, nearbyImages }
}
