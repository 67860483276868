/* tslint:disable:jsx-no-lambda */
import { Fragment, useContext } from "react"
import { Controls } from "./controls"
import { FontAwesomeIcon } from "../../helpers/icon"
import { VisualizeBrand } from "../VisualizeBrand"
import { AppContext } from "../../context"

const ViewerControls = () => {
  const { controlsHidden, setControlsHidden } = useContext(AppContext)

  return (
    <Fragment>
      <div className={"viewer-controls" + (controlsHidden ? "" : " controls-expanded")}>
        <Controls />
        <div className={"viewer-controls-footer"}>
          {!controlsHidden && (
            <div className={"visualize-brand-container"}>
              <VisualizeBrand />
            </div>
          )}
          <div className={"expand-or-hide"} onClick={() => setControlsHidden(!controlsHidden)}>
            <FontAwesomeIcon icon={controlsHidden ? "chevron-down" : "chevron-up"} />
            {controlsHidden ? <span>Expand</span> : <span>Hide</span>}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export { ViewerControls }
