import { useEffect } from "react"

/**
 * Like useEffect but works with async functions and makes sure that errors will be reported
 */
function useAsyncEffect(effect: () => Promise<any>, inputs?: any[] | undefined) {
  useEffect(() => {
    effect()
  }, [effect])
}

export { useAsyncEffect }
