import { useEffect, useState } from "react"
import { IPermissionsMap, IRole, ITeam, IUser } from "../../interfaces"
import {
  apiHostname,
  apiPermissionsRole,
  apiPermissionsRoleDelete,
  apiPermissionsRoles,
  apiPermissionsRoleUpdate,
} from "../constants"
import { api, APIError, isAuthError, isError } from "../api"
import { toast } from "react-toastify"
import { useConfirmationModal } from "../../hooks/useConfirmationModal"
import axios from "axios"

export const useApiRoles = (onlyActive: boolean = true, onlyVisible: boolean = true) => {
  const [roles, setRoles] = useState([] as IRole[])
  const [rolesLoading, setLoading] = useState(true)
  const { getConfirmation } = useConfirmationModal()

  const token: string = localStorage.getItem("auth")

  useEffect(() => {
    axios.defaults.withCredentials = true
    axios
      .get(apiHostname + "/api" + apiPermissionsRoles, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          // "Cookie": `_monocular_session=${token}`
        },
        withCredentials: true,
      })
      .then(response => {
        setRoles(response.data.roles)
      })
      .catch(error => {
        toast.error("There was a problem fetching roles")
      })

    setLoading(false)
  }, [])

  const removeRole = async (id: string) => {
    const confirmed = await getConfirmation(
      "Delete role",
      "Are you sure you want to delete this role?"
    )
    if (!confirmed) {
      return
    }

    const response: any | APIError = await RemoveRole(id)
    if (isAuthError(response) && isError(response)) {
      toast.error("There was a problem deleting the role")
      return false
    } else {
      toast.success("Role was successfully deleted")
      const updatedRoles = roles.filter(role => role.id !== id)
      setRoles(updatedRoles)
      return true
    }
  }

  return { roles, rolesLoading, removeRole }
}

export async function RemoveRole(id: string) {
  return await api.delete(apiPermissionsRoleDelete(id))
}

export const useApiRole = (id?: string) => {
  const [role, setRole] = useState({
    displayName: "",
    description: "",
    userList: [] as IUser[],
    teamList: [] as ITeam[],
    explicitPermissions: {} as IPermissionsMap,
  } as IRole)

  const [roleLoading, setLoading] = useState(id !== undefined)

  useEffect(() => {
    if (id) {
      const response: any | APIError = api
        .get(apiPermissionsRole(id))
        .then(response => {
          // @ts-ignore
          setRole(Object.assign({}, role, response.role))
          setLoading(false)
        })
        .catch(error => {
          toast.error("There was a problem fetching the role")
        })
    }
  }, [])

  const saveRole = async () => {
    const data = {
      displayName: role.displayName,
      description: role.description,
      userList: role.userList.map(user => user.id),
      teamList: role.teamList.map(team => team.id),
      explicitPermissions: role.explicitPermissions,
    }

    let response: any | APIError
    if (id !== undefined) {
      response = await api.post(apiPermissionsRoleUpdate(role.id), data)
    } else {
      response = await api.post(apiPermissionsRoles, data)
    }

    return !isError(response)
  }

  return { role, setRole, saveRole, roleLoading }
}
