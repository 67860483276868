export const apiHostname = `https://api.${window.location.hostname}:443`
export const apiAuthAcceptdisclaimer = "/auth/acceptdisclaimer"
export const apiAuthUpdateAccount = "/auth/update_account"
export const apiAuthSetPassword = "/auth/set_password"
export const apiUpdateSecuritySettings = "/auth/update_security_settings"
export const apiUpdateNotificationSettings = "/auth/update_notification_settings"
export const apiAuthCheck = "/auth/check"
export const apiAuthSignIn = "/auth/sign_in"
export const apiAuthSignOut = "/auth/sign_out"
export const apiGroupsAll = "/groups/all"
export const apiGroupsCreate = "/groups/create"
export const apiMetaCachingservers = "/meta/cachingservers"
export const apiMetaHealth = "/meta/health"
export const apiMetaPrices = "/meta/prices"
export const apiMetaSidebar = "/meta/sidebar"
export const apiMetaVersion = "/meta/version"
export const apiMetricsSave = "/metrics/save"

export const apiMocsAll = (siteId: number) => `/sites/${siteId}/mocs`
export const apiMoc = (siteId: number, mocId: number) => `/sites/${siteId}/mocs/${mocId}`
export const apiMocDelete = (siteId: number, mocId: number) =>
  `/sites/${siteId}/mocs/delete/${mocId}`
export const apiMocsPois = (siteId: number) => `/api/sites/${siteId}/mocs/pois`
export const apiMocsAreaCreate = (siteId: number, mocId: number) =>
  `/sites/${siteId}/mocs/${mocId}/area`
export const apiMocsArea = (siteId: number, mocId: number, polyId: string) =>
  `/sites/${siteId}/mocs/${mocId}/area/delete/${polyId}`
export const apiMocsAreaDelete = (siteId: number, mocId: number, polyId: string) =>
  `/sites/${siteId}/mocs/${mocId}/area/delete/${polyId}`

export const apiModelgroupsAll = "/api/modelgroups/all"
export const apiModelgroupsCreate = "/modelgroups/create"
export const apiModelsAll = "/api/models/all"
export const apiModelsCreate = "/models/create"
export const apiModeltypesAll = "/modeltypes/all"
export const apiNotifyEmail = "/notify/email"
export const apiPermissionsRole = (id: string) => {
  return `/permissions/roles/${id}`
}
export const apiPermissionsRoleUpdate = (id: string) => {
  return `/permissions/roles/put/${id}`
}

export const apiPermissionsRoleDelete = (id: string) => {
  return `/permissions/roles/delete/${id}`
}

export const apiPermissionsRoles = "/permissions/roles"
export const apiPermissionsTeams = "/permissions/teams"
export const apiPermissionsTeam = (id: string) => {
  return `/permissions/teams/${id}`
}
export const apiPermissionsTeamUpdate = (id: string) => {
  return `/permissions/teams/put/${id}`
}
export const apiPermissionsTeamDelete = (id: string) => {
  return `/permissions/teams/delete/${id}`
}
export const apiPermissionsDefaults = `/permissions/default`
export const apiPointgrabGet = "/pointgrab/get"
export const apiPointgrabAll = "/pointgrab/all"
export const apiPointgrabCrop = "/pointgrab/crop"
export const apiPointgrabDownloads = "/pointgrab/downloads"
export const apiPoisAll = "/pois/all"
export const apiPoisCreate = "/pois/create"
export const apiProjectLayerAll = "/project_layer/all"
export const apiProjectLayerCreate = "/project_layer/create"
export const apiRegistryClear = "/registry/clear"
export const apiRegistryCreate = "/registry/create"
export const apiSettingsAll = "/settings/all"
export const apiSettingsDel = "/settings/del"
export const apiSettingsGet = "/settings/get"
export const apiSettingsPut = "/settings/put"
export const apiSitesAll = "/api/sites/all"
export const apiSitesCreate = "/sites/create"
export const apiSitesPins = "/api/sites/pins"
export const apiSnapshotsAll = "/snapshots/all"
export const apiSnapshot = (id: number) => `/snapshots/${id}`
export const apiSnapshotDelete = (id: number) => `/snapshots/delete/${id}`
export const apiSnapshotsBymd5 = "/snapshots/bymd5"
export const apiSnapshotsCreate = "/snapshots"
export const apiSnapshotsBookmarkShare = "/snapshots/bookmark/share"
export const apiSnapshotsNearby = "/snapshots/nearby"
export const apiSnapshotsShare = "/snapshots/share"
export const apiSnapshotsUser = "/snapshots/user"
export const apiTagsAll = "/tags/all"
export const apiUsersAll = "/users/all"
export const apiUsersCreate = "/users/create"
export const apiUsersFeedbackform = "/users/feedbackform"
export const apiUsersForgotpassword = "/users/forgotpassword"
export const apiUsersPasswordreset = "/users/passwordreset"
export const apiUsersUnsuspend = "/users/unsuspend"
export const apiUsersSetLicense = "/users/set_license"
export const apiUserEnable = (id: number) => `/users/${id}/enable`
export const apiUserDisable = (id: number) => `/users/${id}/disable`
export const apiUserArchive = (id: number) => `/users/${id}/archive`
export const apiUserClearSSO = (id: number) => `/users/${id}/clearSSO`
export const apiUser = (id: number) => `/users/${id}`
export const apiUserDelete = (id: number) => `/users/delete/${id}`
export const apiUserUpdate = (id: number) => `/users/put/${id}`
export const apiUsers = `/users`
export const apiWs = "/ws/"
export const filesAttachmentsUpload = "/files/attachments/upload"
export const filesAttachmentsArchive = (uuid: string) => `/files/attachments/archive/${uuid}`
export const filesPointgrabDownloadsDelete = "/files/pointgrab/downloads/delete"
export const filesSnapshotsZip = "/files/snapshots/zip"

export const apiMetadataRoot = (siteID: number) => `/sites/${siteID}/cad/tree/root`
export const apiMetadataChild = (siteID: number, path: string) =>
  `/sites/${siteID}/cad/tree?path=${encodeURIComponent(path)}`
export const apiMetadataItem = (siteID: number, id: string) => `/sites/${siteID}/cad/tree/${id}`
export const apiMetadataExternalQuery = (siteID: number, sourceId: string, tag: string) =>
  `/sites/${siteID}/cad/external_metadata/${sourceId}?query=${tag}`
