import { useState, useContext, useCallback } from "react"
import { useCesiumOnMouseMove } from "../../hooks/useCesiumMouseMove"
import { useCesiumOnLeftClick } from "../../hooks/useCesiumOnClick"
import { CesiumMoveEvent } from "../../hooks/models/CesiumMoveEvent"
import { CesiumClickEvent } from "../../hooks/models/CesiumClickEvent"
import { ViewerContext } from "../../context"
import { Cesium3DTileFeature } from "cesium"

export const CadPicker = (props: any) => {
  const { onHover, onClick } = props
  const { viewer } = useContext(ViewerContext)
  const [selectedItem, setSelectedItem] = useState(null as any)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const mouseMoveHandler = useCallback(
    (e: CesiumMoveEvent) => {
      const feature = e.pick()

      if (feature instanceof Cesium3DTileFeature) {
        if (!viewer) {
          return
        }

        // @ts-ignore
        const cesiumContainer = viewer._element
        if (!cesiumContainer) {
          return
        }

        const parentContainer = cesiumContainer.offsetParent

        setPosition({
          // @ts-ignore
          x: e.position.x + parentContainer!.offsetLeft + 10,
          //  @ts-ignore
          y: e.position.y + parentContainer!.offsetTop + 15,
        })

        const pathId = feature.getProperty("pathId")

        if (!pathId) {
          return
        }

        if (!selectedItem || selectedItem.pathId !== pathId) {
          const tags = feature.getProperty("tags")
          const id = feature.getProperty("id")

          setSelectedItem({ pathId, tags, id })

          if (onHover) {
            onHover(pathId)
          }
        }
      } else {
        setSelectedItem(null)
        onHover(null)
      }
    },
    [viewer, onHover, selectedItem]
  )

  useCesiumOnMouseMove(mouseMoveHandler)

  useCesiumOnLeftClick((e: CesiumClickEvent) => {
    if (selectedItem) {
      onClick({
        id: selectedItem.id,
        batchId: selectedItem.pathId,
      })
    } else {
      onClick(null)
    }
  })

  if (!selectedItem) {
    return null
  }

  return (
    <div className="cadPickerDetail" style={{ top: `${position.y}px`, left: `${position.x}px` }}>
      {Object.keys(selectedItem.tags).map((sourceName: string, i: number) => {
        return (
          <span key={i}>
            <b>{sourceName}:</b> {selectedItem.tags[sourceName]}
          </span>
        )
      })}
    </div>
  )
}
