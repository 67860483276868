import * as React from "react"
import { IExternalProps, InjectedProps } from "./index"

interface IValidatorState {
  isValid: boolean
  dirty: { [key: string]: boolean }
  errors: { [key: string]: string }
  hasErrors: boolean
  checkedValid: boolean
}

export interface IValidatorProps {
  validation?: { isValid(): boolean } & IValidatorState
}

export type Validator<TModel> = (
  model: TModel,
  errors: { [key: string]: string }
) => { [key: string]: string }

function HasNoErrors(errors: { [key: string]: string }): boolean {
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}

const Validate = <TModel extends {}, TOriginalProps extends {}>(
  ...validators: Validator<TModel>[]
): any => (
  Component: React.ComponentType<TOriginalProps & IValidatorProps & InjectedProps<TModel>>
) => {
  type ResultProps = TOriginalProps &
    IExternalProps<TModel> &
    InjectedProps<TModel> &
    IValidatorProps
  return class ValidatingComponent extends React.Component<ResultProps, IValidatorState> {
    // Define how your HOC is shown in ReactDevTools
    static insideName = Component.displayName || Component.name
    // static displayName = `Validator(${ValidatingComponent.insideName})`
    static displayName = `Validator(${Component.displayName || Component.name})`
    public state: IValidatorState = {
      errors: {},
      dirty: {},
      isValid: false,
      checkedValid: false,
      hasErrors: false,
    }
    private initialModel: TModel | undefined

    componentDidMount() {
      this.initialModel = this.props.model
    }

    componentDidUpdate(
      prevProps: Readonly<ResultProps>,
      prevState: Readonly<IValidatorState>,
      snapshot?: any
    ): void {
      if (!this.props.model || !this.initialModel) {
        return
      }
      const im = this.initialModel
      const { model } = this.props
      if (!model) {
        return
      }

      if (prevProps.model !== this.props.model && !this.state.checkedValid) {
        let flagChange = false
        const dirty = { ...this.state.dirty }
        Object.keys(this.props.model).forEach((key: string) => {
          if (this.state.dirty[key]) {
            return
          }
          // @ts-ignore
          if (im[key] !== model[key]) {
            dirty[key] = true
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            flagChange = true
          }
        })
        let checkErrors: { [key: string]: string }
        checkErrors = {}
        for (let i = 0; i <= validators.length; i++) {
          if (typeof validators[i] !== "function") {
            continue
          }

          validators[i](model as TModel, checkErrors)
        }

        let errors: { [key: string]: string }
        errors = {}
        Object.keys(dirty).forEach((key: string) => {
          if (checkErrors[key]) {
            errors[key] = checkErrors[key]
          }
        })
        const hasErrors = !HasNoErrors(errors)
        this.setState({ dirty, errors, hasErrors })
      } else if (prevProps.model !== this.props.model && this.state.checkedValid) {
        this.isValid()
      }
    }

    render() {
      return <Component validation={{ ...this.state, isValid: this.isValid }} {...this.props} />
    }

    private isValid = (): boolean => {
      const { model } = this.props
      if (!model) {
        throw new Error("No model provided")
      }
      let errors: { [key: string]: string }
      errors = {}

      for (let i = 0; i <= validators.length; i++) {
        if (typeof validators[i] !== "function") {
          continue
        }
        validators[i](model as TModel, errors)
      }
      const hasNoErrors: boolean = HasNoErrors(errors)
      this.setState({ errors, checkedValid: true, hasErrors: !hasNoErrors })
      return hasNoErrors
    }
  }
}

export const IsRequired = <TModel extends object>(requiredObj: { [key: string]: string }) => (
  model: TModel,
  errors: { [key: string]: string }
): { [key: string]: string } => {
  Object.keys(requiredObj).forEach((key: string) => {
    // @ts-ignore
    if (!model[key] || model[key] === "" || model[key].length === 0) {
      errors[key] = requiredObj[key]
    }
  })
  return errors
}

export const IsMatching = <TModel extends object>(key1: string, key2: string) => (
  model: TModel,
  errors: { [key: string]: string }
): { [key: string]: string } => {
  // @ts-ignore
  if (model[key1] !== model[key2]) {
    errors[key1] = "Fields need to match"
  }
  console.log(errors)
  return errors
}

export const isValidEmailString = (email: string): boolean => {
  if (email.indexOf("@") < 0) {
    return false
  }
  const fqdn = email.split("@")[1]
  if (fqdn.indexOf("@") >= 0 || fqdn.endsWith(".")) {
    return false
  }
  const parts = fqdn.split(".")
  if (parts.length < 2) {
    return false
  }

  return true
}

export { Validate }
