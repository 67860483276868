import { createContext, useCallback, useContext, useState } from "react"
import { NavisworksTree } from "./NavisworksTree"
import { MetadataViewer } from "./MetadataViewer"
import { CadFilterMenu } from "./CadFilterMenu"
import { CadPicker } from "./CadPicker"
import { ViewerContext } from "../../context"
import { flyToCADModel } from "./flyToCADModel"

export const CadMetadataContext = createContext({
  selectedItem: null,
  // tslint:disable-next-line:no-empty
  setSelectedItem: (item: any) => {},
  hoveredPath: null,
  // tslint:disable-next-line:no-empty
  setHoveredPath: (path: any) => {},
  // tslint:disable-next-line:no-empty
  flyToMetadataItem: (item: any) => {},
})

export const CadMetadata = (props: any) => {
  const { getTilesetTransform, flyToCartesian3 } = useContext(ViewerContext)

  const [selectedItem, setSelectedItem] = useState(props.item)
  const [hoveredPath, setHoveredPath] = useState(null as any)

  const flyToMetadataItem = useCallback(
    async (item: any) => {
      const modelTransform = await getTilesetTransform(4)
      if (!modelTransform || !item || !item.position) {
        return
      }

      return flyToCADModel(modelTransform, item.position, flyToCartesian3)
    },
    [flyToCartesian3, getTilesetTransform]
  )

  return (
    <CadMetadataContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        hoveredPath,
        setHoveredPath,
        flyToMetadataItem,
      }}
    >
      <CadPicker onHover={setHoveredPath} onClick={setSelectedItem} />
      <CadFilterMenu item={selectedItem} hoveredPath={hoveredPath} />
      <MetadataViewer item={selectedItem} />
      <NavisworksTree onSelect={setSelectedItem} selectedItem={selectedItem} />
    </CadMetadataContext.Provider>
  )
}
