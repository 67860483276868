/* tslint:disable:jsx-no-lambda */
import { useCallback, useContext, useEffect, useRef } from "react"
import L, { LatLngExpression, TileEvent, TileLayer } from "leaflet"
import "leaflet-rotatedmarker"
import "leaflet-plugins/layer/tile/Bing.js"
import "leaflet.tilelayer.fallback"
import { airscopeColorHex } from "../../helpers/colours"
import { Button } from "trunx"
import { FontAwesomeIcon } from "../../helpers/icon"
import { ModelsContext, ViewerContext } from "../../context"
import { useLocalStorageBoolean } from "../../hooks/useLocalStorage"
import * as Cesium from "cesium"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { TooltipConfigs } from "../../constants"

const MinZoom = 0
const MaxZoom = 20
const MaxNativeZoom = 18
const ArcGISAerialUrl =
  "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}?blankTile=false"
const cameraManIcon = L.icon({
  iconUrl: "/assets/icons/littleman.png",
  iconAnchor: [42, 42],
})
const minimapCircleIcon = L.icon({
  iconUrl: "/assets/icons/minimapdot.png",
  iconAnchor: [42, 42],
})
const initialPosition = {
  latitude: -25.16517336866393,
  longitude: 132.89062500000003,
  zoom: 2,
}

export const MiniMap = () => {
  const { viewer } = useContext(ViewerContext)
  const { selectedSite } = useContext(ModelsContext)
  const [hidden, setHidden] = useLocalStorageBoolean("minimap-hidden", false)

  const mapRef = useRef(null as any)
  const cameraPolygon = useRef(null as L.Polygon<any> | null)
  const cameraCircle = useRef(null as L.Marker<any> | null)
  const cameraMan = useRef(null as L.Marker<any> | null)

  const updateMapPosition = useCallback(viewer => {
    const map = mapRef.current

    if (!viewer) {
      const { latitude, longitude, zoom } = initialPosition

      map.panTo(L.latLng(latitude, longitude), { animate: false })
      map.setZoom(zoom)
      map.setMinZoom(MinZoom)
      map.setMaxBounds([
        [latitude - 0.02, longitude - 0.02],
        [latitude + 0.02, longitude + 0.02],
      ])
    } else {
      const cartPosition = viewer.camera.positionCartographic
      const zoom = Math.min(22 - Math.log(cartPosition.height), 17)

      const toDeg = Cesium.Math.toDegrees

      if (!isNaN(zoom)) {
        const latitude = toDeg(cartPosition.latitude)
        const longitude = toDeg(cartPosition.longitude)

        map.panTo(L.latLng(latitude, longitude), { animate: false })
        map.setZoom(zoom)
        map.setMinZoom(MinZoom)
        map.setMaxBounds([
          [latitude - 0.02, longitude - 0.02],
          [latitude + 0.02, longitude + 0.02],
        ])
      }
    }
  }, [])

  const updateObjects = useCallback((viewer: any) => {
    if (!viewer) {
      return
    }

    const camera = viewer.camera
    const toDeg = Cesium.Math.toDegrees
    const rectangle = viewer.scene.camera.computeViewRectangle(viewer.scene.globe.ellipsoid)
    const cameraPoint = L.latLng(
      toDeg(camera.positionCartographic.latitude),
      toDeg(camera.positionCartographic.longitude)
    )

    if (toDeg(viewer.camera.pitch) < -45) {
      if (!rectangle) {
        return
      }

      if (cameraMan.current) {
        mapRef.current.removeLayer(cameraMan.current)
        cameraMan.current = null
      }

      const ne = Cesium.Rectangle.northeast(rectangle)
      const se = Cesium.Rectangle.southeast(rectangle)
      const sw = Cesium.Rectangle.southwest(rectangle)
      const nw = Cesium.Rectangle.northwest(rectangle)

      const points: LatLngExpression[] = [
        [toDeg(ne.latitude), toDeg(ne.longitude)],
        [toDeg(se.latitude), toDeg(se.longitude)],
        [toDeg(sw.latitude), toDeg(sw.longitude)],
        [toDeg(nw.latitude), toDeg(nw.longitude)],
      ]

      if (cameraPolygon.current && cameraCircle.current) {
        cameraPolygon.current.setLatLngs(points)
        cameraCircle.current.setLatLng(cameraPoint)
      } else {
        cameraPolygon.current = L.polygon(points, {
          color: airscopeColorHex,
          fillColor: airscopeColorHex,
          fillOpacity: 0.2,
        }).addTo(mapRef.current)

        cameraCircle.current = L.marker(cameraPoint, {
          icon: minimapCircleIcon,
          rotationAngle: Cesium.Math.toDegrees(camera.heading),
          rotationOrigin: "center center",
        }).addTo(mapRef.current)
      }
    } else {
      if (cameraPolygon.current) {
        mapRef.current.removeLayer(cameraPolygon.current)
        mapRef.current.removeLayer(cameraCircle.current)

        cameraPolygon.current = null
        cameraCircle.current = null
      }

      if (cameraMan.current) {
        cameraMan.current
          .setRotationAngle(toDeg(camera.heading))
          .setLatLng(cameraPoint)
          .addTo(mapRef.current)
      } else {
        cameraMan.current = L.marker(cameraPoint, {
          icon: cameraManIcon,
          rotationAngle: Cesium.Math.toDegrees(camera.heading),
          rotationOrigin: "center center",
        }).addTo(mapRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (hidden) {
      return
    }

    mapRef.current = L.map("minimap", {
      scrollWheelZoom: true,
      touchZoom: true,
      keyboard: false,
      doubleClickZoom: false,
      zoomSnap: 1,
    })

    const map = mapRef.current
    updateMapPosition(viewer)

    let maxNativeZoom = MaxNativeZoom
    if (selectedSite && selectedSite.data && selectedSite.data["minimapMaxNativeZoom"]) {
      // console.log("found a maxnativezoom "+ selectedSite.data["minimapMaxNativeZoom"]);
      maxNativeZoom = selectedSite.data["minimapMaxNativeZoom"]
    }

    const tl: TileLayer = L.tileLayer(ArcGISAerialUrl, {
      maxNativeZoom,
      maxZoom: MaxZoom,
    })
    tl.addTo(map)

    // tslint:disable-next-line:only-arrow-functions
    tl.on("tileerror", function(e: TileEvent) {
      if (e.coords.z >= map.getZoom()) {
        // console.log("zooming out to find minimap tile " + e.coords.z +" / "+ map.getZoom() );
        map.stop()
        map.zoomOut()

        tl.options.maxNativeZoom = e.coords.z - 1
      }
    })

    return () => {
      mapRef.current.off()
      mapRef.current.remove()
      mapRef.current = null

      cameraPolygon.current = null
      cameraCircle.current = null
      cameraMan.current = null
    }
  }, [hidden, selectedSite, updateMapPosition, viewer])

  useEffect(() => {
    if (!viewer || hidden) {
      return
    }

    const updateHandler = () => {
      updateObjects(viewer)
      updateMapPosition(viewer)
    }

    // This fixes a bug on first run where the map is offset from the camera position
    updateHandler()
    updateHandler()

    viewer.camera.changed.addEventListener(updateHandler)

    return () => {
      viewer.camera.changed.removeEventListener(updateHandler)

      if (mapRef.current) {
        if (cameraPolygon.current) {
          mapRef.current.removeLayer(cameraPolygon.current)
          cameraPolygon.current = null
        }
        if (cameraCircle.current) {
          mapRef.current.removeLayer(cameraCircle.current)
          cameraCircle.current = null
        }
        if (cameraMan.current) {
          mapRef.current.removeLayer(cameraMan.current)
          cameraMan.current = null
        }
      }
    }
  }, [viewer, hidden, updateObjects, updateMapPosition])

  return (
    <div id={"minimap-container"} className={hidden ? "hidden" : ""}>
      <div id="minimap-wrapper">
        <div id="minimap" />
      </div>
      <Tooltip
        title={<Typography fontSize={TooltipConfigs.FontSize}>Show/Hide Mini Map</Typography>}
        placement="right"
        enterDelay={TooltipConfigs.Delay}
        enterNextDelay={TooltipConfigs.Delay}
      >
        <div>
          <Button isText={true} onClick={() => setHidden(!hidden)} className={"minimap-toggle"}>
            <FontAwesomeIcon icon={hidden ? "chevron-down" : "chevron-up"} />
          </Button>
        </div>
      </Tooltip>
    </div>
  )
}
