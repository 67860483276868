import { useContext } from "react"
import { StageViewerContext } from "./CesiumStageViewer"

export const ProgressBar = () => {
  const { tileState } = useContext(StageViewerContext)

  let totalTiles = 15.0
  while (tileState.tilesLoaded > 0.8 * totalTiles) {
    totalTiles += 90.0
  }

  const percentage = tileState.allLoaded ? 100 : (100.0 * tileState.tilesLoaded) / totalTiles

  return (
    <div
      className={"progress-bar-container"}
      style={{
        opacity: !tileState.allLoaded ? 1 : 0,
      }}
    >
      <div
        className={"progress-bar"}
        style={{
          width: Math.round(percentage) + "%",
        }}
      />
    </div>
  )
}
