import * as Cesium from "cesium"

export enum MOCState {
  Proposed = "proposed",
  Approved = "approved",
  Completed = "completed",
}

export enum ModelType {
  PointCloud = 1,
  Shaded = 2,
  Textured = 3,
  CAD = 4,
  NearbyPhotos = -1,
}

export enum Axis {
  X,
  Y,
  Z,
}

export interface IMOC {
  id: number
  name: string
  description: string
  documentNumber: string
  data: {
    bookmark: IBookmark
    polygons: { [key: string]: IPolygon }
  }
  archived: false
  archivedOn: null
  createdAt: string
  siteId: number
  state: MOCState
  pois: IPOI[]
  attachments: IAttachment[]
  polygons: IPolygon[]
}
export interface IAttachment {
  id: number
  filename: string
  uuid: string
}

export interface IRecipient {
  email: string
  firstName: string
  lastName: string
}

export interface IUser {
  id: number
  firstName: string
  lastName: string
  email: string
  password: string
  passwordConfirmation?: string
  role: string
  sessionToken: string
  data: {
    hasLicense: boolean
    meta: object
    avatar: string
    agreedToDisclaimer: boolean
    latestReadChangelog: string
    recipients?: IRecipient[]
    signInAttempts: null
    lastActive: Date | null
    lastSignInCountry: string
    security: {
      enable2FA: boolean
    }
    subscriptions: {
      featuresEmail: boolean
      featuresSMS: boolean
      pointGrabEmail: boolean
      pointGrabSMS: boolean
    }
  }
  disabled: boolean
  disabledOn: Date | null
  archived: boolean
  archivedOn: Date | null
  createdAt: Date
  passwordResetToken: string
  suspended: boolean
  unsuspendToken: string
  lastLoginAt?: Date | null
  teamList: ITeam[]
  roleList: IRole[]
}

export interface ITeam {
  id: string
  displayName: string
  description: string
  created: Date
  lastModified: Date
  membershipLastModified: boolean
  archivedOn: Date | null
  ArchivedOn: Date | null
  membershipCount: number
  userList: IUser[]
  roleList: IRole[]
}

export interface IOutgoingMessageType {
  command: string
  requestID?: string
  timeout?: number
  afterSend?: (arg: any) => void
  data: any
}

export interface ITarget {
  longitude: number
  latitude: number
  altitude: number
}

export interface ITargetJSON {
  longitude: number
  latitude: number
  altitude: number
  x: number
  y: number
  z: number
}

export interface ISite {
  id: number
  name: string
  slug: string
  description: string
  data?: {
    meta?: object
    avatar?: string
  }
  archived: boolean
  archivedOn: null | Date
  createdAt: Date
}

export interface IModelType {
  id: number
  name: string
  key: string
  archived: boolean
  archivedOn: Date | null
  createdAt: Date
}

export interface IModel {
  id: number
  name: string
  description: string
  filepath: string
  modelgroupId: number
  modeltypeId: ModelType
  isDefault: true
  data: {
    meta: object | null
    avatar: string
    location: object
    icon: string
    latitude: number
    longitude: number
  }
  archived: boolean
  archivedOn?: Date | null
  createdAt: Date
}

export interface IModelGroup {
  id: number
  name: string
  description: string
  siteId: number
  data: {
    meta?: object | null
    avatar: string
  }
  archived: boolean
  archivedOn: Date | null
  createdAt: Date
}

export interface ICachingServers {
  servers: {
    [key: string]: {
      hostname: string
      key: string
    }
  }
}

export interface IVector {
  x: number
  y: number
  z: number
}

export interface LongLat {
  longitude: number
  latitude: number
}

export interface IUTM {
  easting: number
  northing: number
  zone: number
}

export interface IPosition {
  cartesian3: Cesium.Cartesian3
  longitude: number
  latitude: number
  altitude: number
  x: number
  y: number
  z?: number
}

export interface ICartesianMeasurement {
  x: Cesium.Cartesian3
  y: Cesium.Cartesian3
  z: Cesium.Cartesian3
}

export interface IPoint {
  id: string
  name: string
  position: IPosition
  color?: Cesium.Color
  outline?: boolean
  collection?: Cesium.DataSource | null
  entity?: Cesium.Entity | null
  distance?: number | null
  cartesianDistance?: ICartesianMeasurement | null
}

export interface IModeControllerInterface {
  mode: string
  viewer: Cesium.Viewer
  setup(props: any): void

  teardown(props: any): void

  handleKeyboardEvent(props: any): void

  handleDoubleClick(props: any): void

  handleClick(click: any): boolean

  handleObjectClick(click: any, id: string): boolean

  handlePropUpdate(prevProps: any, props: any): boolean // returns true to trigger an immediate viewer render

  handleMove(movement: any): void
}

export interface OutgoingMessageType {
  command: string
  requestID?: string
  timeout?: number
  afterSend?: (...args: any) => void
  data: any
}

export interface ISnapshot {
  id?: number
  siteId: number
  filename?: string
  imageHash?: string
  imageType?: string
  name: string
  description: string
  takenBy: number
  data: { bookmark: IBookmark }
}

export interface IPOI {
  altitude: number
  archived: boolean
  archivedOn: Date | null
  createdAt: Date
  data: { [key: string]: any }
  id: number
  latitude: number
  longitude: number
}
export interface INearbySnapshot {
  archived: boolean
  archivedOn: Date | null
  createdAt: Date
  data: { meta: object; bookmark: IBookmark }
  description: string
  filename: string
  height: number
  heightType: string
  id: number
  imageHash: string
  imageType: string
  name: string
  pois: { [key: number]: IPOI }
  siteId: number
  tags: string[]
  takenBy: number
  width: number
  orderScore?: number
  distance?: number
  leftRightOrderScore?: number
}

export interface IRole {
  id: string
  displayName: string
  description: string
  metadata?: IRoleMetaData
  created: Date
  lastModified: Date
  membershipLastModified: Date
  archived?: Date | null
  userCount: number
  teamCount: number
  userList: IUser[]
  teamList: ITeam[]
  explicitPermissions: IPermissionsMap
}

interface IRoleMetaData {
  readonly?: boolean
  hidden?: boolean
}

export interface IPermissionFieldDefault {
  token: string
  displayName: string
  systemDefault: string
  category: string
  sortOrder: number
  siteSpecific: boolean
}

export interface IModelCoords {
  origin: Cesium.Cartesian3
  uX: Cesium.Cartesian3
  uY: Cesium.Cartesian3
  uZ: Cesium.Cartesian3
}

export interface IMOCFormValues {
  name: string
  documentNumber: string
  state: string
  description: string
  attachments: File[]
}

export interface IPolygon {
  archived: boolean
  bottomHeight: number
  capped: boolean
  createdAt: Date
  name: string
  points: IMOCPoint[]
  topHeight: number
  type: string
  uuid?: string
}

export interface IMOCPoint extends IPoint {
  x: number
  y: number
  z: number
}

export interface IPin {
  latitude: number
  longitude: number
  site_id: number
  has_model: true
}

export interface IPointGrab {
  sizeX: number
  sizeY: number
  sizeZ: number
  point: Cesium.Cartesian3
  angleXY: number
}

export interface IPointGrabModel {
  description: string
  density: string | null
  outputs: Array<string>
  email: string
  notify: boolean
  siteSlug: string
}

interface IPointGrabDescription {
  corners: Array<Cesium.Cartesian3>
  center: Cesium.Cartesian3
  sizeX: number
  sizeY: number
  sizeZ: number
  angle: number
}
export interface IPointGrabVolume {
  earthRef: IPointGrabDescription
  modelRef: IPointGrabDescription
  pointCloudRef: IPointGrabDescription
}

export interface IPointCloud {
  uuid: string
  siteSlug: string
  archived: boolean
  archivedOn: Date | null
  createdAt: Date
  densities: Array<string>
}

export interface IPointCloudDownload {
  uuid: string
  requestId: string
  description: string
  filename: string
  message: string
  status: string
  expiresOn: Date
  density: string
  siteSlug: string
  archived: boolean
  archivedOn: Date | null
  createdAt: Date
}

export interface IPointCloudDownloadResponse {
  downloads: Array<IPointCloudDownload>
}

export interface ICreateUserRequest {
  firstName: string
  lastName: string
  email: string
  password: string
  role: string
}

export interface IUpdateUserRequest {
  firstName: string
  lastName: string
  email: string
}

export interface IPermissionsMap {
  [site_id: number]: IEffectiveSitePermissions
}

export interface IEffectiveSitePermissions {
  [token: string]: boolean | null
}

export interface IBookmark {
  name: string
  description: string
  position: Cesium.Cartesian3
  direction: Cesium.Cartesian3
  up: Cesium.Cartesian3
  right: Cesium.Cartesian3
  transform: Cesium.Matrix4
  frustum:
    | Cesium.PerspectiveFrustum
    | Cesium.OrthographicFrustum
    | Cesium.PerspectiveOffCenterFrustum
}
