/* tslint:disable:jsx-no-lambda */
import { Fragment, useEffect } from "react"
import { TargetMode } from "./Target"
import { RenderIfPermission } from "../../helpers/Permissions"
import { CadMetadata } from "../CadMetadata"
import { FontAwesomeIcon } from "../../helpers/icon"
import { ToolContainer } from "../ToolContainer"
import { Measure } from "./Measure"
import { Angle } from "./Angle"
import { useLocalStorageNumber } from "../../hooks/useLocalStorage"

enum ExpandedTool {
  None,
  Target,
  Measure,
  Angle,
  CadMetadata,
}

export const Tools = () => {
  const [expandedTool, setExpandedTool] = useLocalStorageNumber(
    "selected-tool",
    ExpandedTool.Measure as ExpandedTool
  )

  // tslint:disable-next-line:no-empty
  useEffect(() => {}, [expandedTool])

  return (
    <div className={"cards-container-portal"}>
      <RenderIfPermission token={"TOOLS:TARGET"} siteSpecific={true}>
        <ToolContainer
          expanded={expandedTool === ExpandedTool.Target}
          onExpand={expanded => setExpandedTool(expanded ? ExpandedTool.None : ExpandedTool.Target)}
          title={
            <Fragment>
              <FontAwesomeIcon icon={"location"} title={"Target Mode"} />
              <span className="title-text">Target Mode</span>
            </Fragment>
          }
        >
          <TargetMode />
        </ToolContainer>
      </RenderIfPermission>

      <RenderIfPermission token={"TOOLS:MEASURE"} siteSpecific={true}>
        <ToolContainer
          expanded={expandedTool === ExpandedTool.Measure}
          onExpand={expanded =>
            setExpandedTool(expanded ? ExpandedTool.None : ExpandedTool.Measure)
          }
          title={
            <Fragment>
              <FontAwesomeIcon icon={"ruler-horizontal"} title={"Target Mode"} />
              <span className="title-text">Measure Mode</span>
            </Fragment>
          }
        >
          <Measure />
        </ToolContainer>
      </RenderIfPermission>

      <RenderIfPermission token={"TOOLS:ANGLE"} siteSpecific={true}>
        <ToolContainer
          expanded={expandedTool === ExpandedTool.Angle}
          onExpand={expanded => setExpandedTool(expanded ? ExpandedTool.None : ExpandedTool.Angle)}
          title={
            <Fragment>
              <FontAwesomeIcon icon={"angle-left"} title={"Angle Measure Mode"} />
              <span className="title-text">Angle Measure Mode</span>
            </Fragment>
          }
        >
          <Angle />
        </ToolContainer>
      </RenderIfPermission>

      <ToolContainer
        expanded={expandedTool === ExpandedTool.CadMetadata}
        onExpand={expanded =>
          setExpandedTool(expanded ? ExpandedTool.None : ExpandedTool.CadMetadata)
        }
        title={
          <Fragment>
            <FontAwesomeIcon icon={"location"} title={"Target Mode"} />
            <span className="title-text">Metadata</span>
          </Fragment>
        }
      >
        <CadMetadata />
      </ToolContainer>
    </div>
  )
}
