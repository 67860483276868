import {
  BingMapsImageryProvider,
  BingMapsStyle,
  BoundingSphere,
  Cartesian3,
  Color,
  HeadingPitchRange,
  Rectangle,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Viewer
} from "cesium";
import { AusRect } from "../../helpers/viewer";
import CesiumNavigation from "cesium-navigation-es6";

// Below is only used for unit tests
// @ts-ignore
window.CESIUM_BASE_URL = "/"

const CesiumConfig = {
  imageryProvider: new BingMapsImageryProvider({
    url: "https://dev.virtualearth.net",
    key: "AkRU_2vuNOoZxtDCTE8Fx1MTKHd5qq8MbjmpOADljR84p5pQpikw7yZoMHwB8pFQ",
    mapStyle: BingMapsStyle.AERIAL_WITH_LABELS,
  }),
  vrButton: false,
  fullscreenButton: false,
  navigationHelpButton: false,
  requestRenderMode: true,
  maximumRenderTimeChange: 0.5,
  scene3DOnly: true,
  homeButton: false,
  infoBox: true,
  sceneModePicker: false,
  geocoder: false,
  baseLayerPicker: false,
  selectionIndicator: true,
  timeline: false,
  animation: false,
  showRenderLoopErrors: false,
  contextOptions: {
    webgl: {
      preserveDrawingBuffer: true,
    },
  },
}

function doubleClickZoom(newViewer: Viewer) {
  const onDoubleClick = (click: any) => {
    let cartesian: Cartesian3
    cartesian = newViewer.scene.pickPosition(click.position)
    if (!cartesian) {
      return
    }
    try {
      const distance = Cartesian3.distance(cartesian, newViewer.camera.position) * 0.25
      newViewer.camera.flyToBoundingSphere(new BoundingSphere(cartesian, 0.1), {
        offset: new HeadingPitchRange(newViewer.camera.heading, newViewer.camera.pitch, distance),
        duration: 0.5,
      })
    } catch (error) {
      console.log(error)
      return
    }
  }
  return onDoubleClick
}

export const createCesiumViewer = (containerId: string, isShowMap: boolean) => {
  const newViewer = new Viewer(containerId, CesiumConfig)
  if (!isShowMap) {
    newViewer.scene.imageryLayers.removeAll()
  }
  newViewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
  newViewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK)
  newViewer.scene.globe.baseColor = Color.fromCssColorString("#03030c")
  newViewer.scene.screenSpaceCameraController.inertiaSpin = 0
  newViewer.scene.screenSpaceCameraController.inertiaTranslate = 0
  newViewer.scene.screenSpaceCameraController.inertiaZoom = 0

  const options = {
    resetTooltip: "Reset View",
    zoomInTooltip: "Zoom In",
    zoomOutTooltip: "Zoom Out",
    defaultResetView: Rectangle.fromDegrees(10, -70, -120, 20),
  }
  // tslint:disable-next-line:no-unused-expression
  new CesiumNavigation(newViewer, options) // This the compass/gyro

  const handler = new ScreenSpaceEventHandler(newViewer.canvas)
  handler.setInputAction(doubleClickZoom(newViewer), ScreenSpaceEventType.LEFT_DOUBLE_CLICK)

  newViewer.camera.setView({ destination: AusRect })
  return newViewer
}
