/* tslint:disable:jsx-no-lambda */
import { ChangeEvent, Fragment, useCallback, useContext, useMemo, useState } from "react"
import { Loading } from "../Loading"
import { Field, Input } from "trunx"
import { api, APIError, isAuthError, isError } from "../../api"
import { toast } from "react-toastify"
import ReactQuill from "react-quill"
import CreatableSelect from "react-select/lib/Creatable"
import { getDomainFromEmail, getShareURL, validateEmail } from "../../helpers"
import { useApiUsers } from "../../api/ApiHooks"
import { PermissionContext } from "../../context"
import { apiSnapshotsBookmarkShare } from "../../api/constants"

const BLANK_ERRORS = {
  subject: "",
  recipients: "",
}

export const SharePlaces = (props: any) => {
  const { onClose, snapshot } = props
  const { users, usersLoading } = useApiUsers()
  const { currentUser } = useContext(PermissionContext)
  const [formData, setFormData] = useState({
    subject: "",
    body: "",
    recipients: [],
  })
  const [errors, setErrors] = useState({
    recipients: "",
    subject: "",
  })
  const submitForm = useCallback(async () => {
    const errors = {}

    if (formData.recipients.length === 0) {
      errors["recipients"] = "You must enter at least one recipient"
    }

    if (formData.subject.length === 0) {
      errors["subject"] = "You must enter a subject"
    }

    if (Object.keys(errors).length !== 0) {
      setErrors(Object.assign({}, BLANK_ERRORS, errors))
      return
    }

    const payload = {
      sender: currentUser.email,
      subject: formData.subject,
      body: formData.body,
      recipients: formData.recipients.map((r: any) => r.value),
      shareURL: getShareURL(snapshot),
    }
    const response = await api.post<APIError>(apiSnapshotsBookmarkShare, payload)

    if (isAuthError(response) || isError(response)) {
      toast.error("There was a problem sending email.")
    } else {
      toast.success("Email sent.")
    }
    onClose()
  }, [currentUser.email, formData.body, formData.recipients, formData.subject, onClose, snapshot])

  const options = useMemo(() => {
    const userDomain = getDomainFromEmail(currentUser.email)
    const userOptions = users
      .filter(u => getDomainFromEmail(u.email) === userDomain)
      .map(u => {
        return { value: u.email, label: `${u.firstName} ${u.lastName} <${u.email}>` }
      })
    return !currentUser.data.recipients
      ? userOptions
      : userOptions.concat(
          currentUser.data.recipients.map(r => {
            return { value: r.email, label: `${r.firstName} ${r.lastName} <${r.email}>` }
          })
        )
  }, [currentUser.data.recipients, currentUser.email, users])

  const shareUrl = `https://${window.location.host}/bookmark/${snapshot.siteId}/${snapshot.id}`

  return (
    <Fragment>
      <header className="modal-card-head">
        <p className="modal-card-title">Share Place Bookmark</p>
      </header>
      <section className="modal-card-body">
        <div style={{ marginBottom: "10px" }}>
          <p>
            Use the form below to email a link to this bookmark. Alternatively you may share it with
            the following URL:
          </p>
          <a href={shareUrl}>{shareUrl}</a>
        </div>
        <Field isLarge={true}>
          <Field.Label>{"Share with"}</Field.Label>
          <Field.Label>{errors.recipients}</Field.Label>
          <Field.Body>
            {usersLoading ? (
              <Loading />
            ) : (
              <CreatableSelect
                isClearable={true}
                onChange={(newValues: any) => {
                  const recipients = newValues.filter((r: any) => validateEmail(r.value))
                  setFormData(Object.assign({}, formData, { recipients }))
                }}
                options={options}
                classNamePrefix={"pgd"}
                isMulti={true}
                formatCreateLabel={(inputValue: string) => `Add email address: ${inputValue}`}
                noOptionsMessage={() => "No saved recipients"}
                styles={{
                  container: styles => ({ ...styles, width: "100%" }),
                  menuPortal: styles => ({ ...styles, zIndex: 100 }), //  >= dialog's z-index
                }}
              />
            )}
          </Field.Body>
        </Field>
        <Field isLarge={true}>
          <Field.Label>{"Subject"}</Field.Label>
          <Field.Label>{errors.subject}</Field.Label>
          <Field.Body>
            <Input
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFormData(Object.assign({}, formData, { subject: e.currentTarget.value }))
              }}
              name={"subject"}
            />
          </Field.Body>
        </Field>
        <Field style={{ marginBottom: "30px" }} isLarge={true}>
          <Field.Label>{"Message"}</Field.Label>
          <Field.Body>
            <ReactQuill
              onChange={(value: string) => {
                setFormData(Object.assign({}, formData, { body: value }))
              }}
            />
          </Field.Body>
        </Field>
      </section>
      <footer className="modal-card-foot">
        <button className="button" onClick={onClose}>
          Cancel
        </button>
        <button className="button" onClick={submitForm}>
          Send
        </button>
      </footer>
    </Fragment>
  )
}
