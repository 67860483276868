import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react"
import { existingViewer, getBoundingSphere, transformBSphere } from "../helpers/viewer"
import { IBookmark, IModelCoords, IPosition, ModelType } from "../interfaces"
import { useLocalStorageBoolean, useLocalStorageNumber } from "../hooks/useLocalStorage"
import { createCesiumViewer } from "./Viewer/create"
import { ModelsContext } from "./Models"
import { ICameraHistory } from "../components/CesiumViewer/CesiumStageViewerEffects/UseCameraHistory"
import { Cartesian3, Cartographic, Cesium3DTileset, Matrix4, Viewer } from "cesium"
import { piOverTwo, radiansToDegrees } from "../helpers/mathFunctions"
import axios from "axios"
import { apiHostname } from "../api/constants"
import { computeTransform } from "../components/CesiumViewer/CesiumStageViewerEffects/Transformation"

interface IPickedPointCache {
  pickCache: IPosition | null
  drillPickCache: any[] | null
}

export const ViewerContext = createContext({
  viewer: null as null | Viewer,
  activeViewer: null as null | Viewer,
  lastClickedViewer: null as null | Viewer,
  // tslint:disable-next-line:no-empty
  setActiveViewer: (viewer: Viewer) => {},
  tilesets: {} as { [id: number]: null | Cesium3DTileset },
  modelTypes: {} as { [id: number]: null | number },
  createViewer: (viewerIndex: number, divId: string): Viewer => new Viewer(divId),
  // tslint:disable-next-line:no-empty
  removeViewer: (viewerIndex: number) => {},
  // tslint:disable-next-line:no-empty
  flyTo: (bookmark: IBookmark, transform?: Matrix4) => {},
  // tslint:disable-next-line:no-empty
  flyToCartesian3: (destination: Cartesian3, distanceAway: Number, modelTransform: Matrix4) => {},
  updateTilesets: (
    viewerIdx: number,
    newTileset: null | Cesium3DTileset,
    newModelType: null | number
    // tslint:disable-next-line:no-empty
  ) => {},
  viewerCount: 1,
  // tslint:disable-next-line:no-empty
  setViewerCount: (count: number) => {},
  viewerCountLimit: 4,
  // tslint:disable-next-line:no-empty
  setViewerCountLimit: (count: number) => {},
  syncViewers: true,
  // tslint:disable-next-line:no-empty
  setSyncViewers: (value: boolean) => {},
  // tslint:disable-next-line:no-empty
  flyToHome: (tileset: null | Cesium3DTileset = null) => {},
  // tslint:disable-next-line:no-empty
  setPendingFlyHome: (value: boolean) => {},
  viewers: {} as { [id: number]: null | Viewer },
  // tslint:disable-next-line:no-empty
  viewersForEach: (func: any) => {},
  getTilesetCoordUnitVector: (): IModelCoords | null => null,
  getTilesetTransform: async (modelType?: number): Promise<Matrix4 | null> => null,
  getActiveTilesetTransform: (): Matrix4 | null => null,
  pickedPointCache: {} as any,
  globalCameraHistory: {} as ICameraHistory,
  setGlobalCameraHistory: {} as any,
  backgrounded: false,
  // tslint:disable-next-line:no-empty
  setViewerBackgrounded: (value: boolean) => {},
  imageryEnabled: true,
  // tslint:disable-next-line:no-empty
  setImageryEnabled: (value: boolean) => {},
  globeEnabled: true,
  // tslint:disable-next-line:no-empty
  setGlobeEnabled: (value: boolean) => {},
  cuttingPlaneDistance: 1,
  // tslint:disable-next-line:no-empty
  setCuttingPlaneDistance: (value: number) => {},
  // tslint:disable-next-line:no-empty
  ensureNearbyImagesIsOpen: () => {},
  customModelQuality: 0,
  // tslint:disable-next-line:no-empty
  setCustomModelQuality: (value: number) => {},
  tilesetLoadState: 1,
  // tslint:disable-next-line:no-empty
  setTilesetLoadState: {} as any,
})

export const ViewerContextProvider = (props: any) => {
  const [activeViewer, setActiveViewer] = useState(null as null | Viewer)
  const [lastClickedViewer, setLastClickedViewer] = useState(null as null | Viewer)
  const pendingFlyHome = useRef(false)
  const pickedPointCache = useRef({ pickCache: null, drillPickCache: null } as IPickedPointCache)

  const [syncViewers, setSyncViewers] = useLocalStorageBoolean("viewer-sync", true)
  const [viewerCount, setViewerCount] = useLocalStorageNumber("viewer-count", 1)
  const [viewerCountLimit, setViewerCountLimit] = useState(4)
  const [viewers, setViewers] = useState({} as { [id: number]: null | Viewer })
  const [tilesets, setTilesets] = useState({} as { [id: number]: null | Cesium3DTileset })
  const [modelTypes, setModelTypes] = useState({} as { [id: number]: null | number })
  const { selectedSite, selectedModelGroup, models } = useContext(ModelsContext)
  const [globalCameraHistory, setGlobalCameraHistory] = useState({
    cameraHistory: [],
    historyOffset: 0,
  } as ICameraHistory)
  const [backgrounded, setViewerBackgrounded] = useState(false)
  const [imageryEnabled, setImageryEnabled] = useLocalStorageBoolean("enable-map-imagery", true)
  const [globeEnabled, setGlobeEnabled] = useLocalStorageBoolean("enable-globe", true)
  const [cuttingPlaneDistance, setCuttingPlaneDistance] = useLocalStorageNumber(
    "cutting-plane-distance",
    1
  )
  const [tilesetLoadState, setTilesetLoadState] = useState(null)

  const createViewer = useCallback(
    (viewerIndex, divId): Viewer => {
      let newViewer
      if (!existingViewer(viewers[viewerIndex]))
        newViewer = createCesiumViewer(divId, imageryEnabled)
      else newViewer = viewers[viewerIndex]

      newViewer.scene.renderError.addEventListener((scene: any, errorDetails: any) => {
        console.error("Visualize WebGL renderError", errorDetails)
      })

      if (!activeViewer) {
        // First viewer created, fetch the last camera position from local storage
        const cameraPosString = localStorage.getItem("camera-position")
        if (cameraPosString) {
          try {
            const cameraPos = JSON.parse(cameraPosString)
            newViewer.camera.setView(cameraPos)
          } catch (e) {
            console.error("Invalid initial camera position")
          }
        }

        const loading = document.getElementById("app-loading")
        if (loading && loading.parentNode) {
          loading.parentNode.removeChild(loading)
        }

        setLastClickedViewer(newViewer)
        setActiveViewer(newViewer)
      } else {
        // Set the current camera position to the active viewer position
        const viewer = lastClickedViewer

        if (viewer) {
          const camera = viewer.camera
          const newView = {
            destination: camera.position,
            orientation: {
              direction: camera.direction,
              up: camera.up,
              right: camera.right,
              transform: camera.transform,
              frustum: camera.frustum,
            },
          }
          newViewer.camera.setView(newView)
          newViewer.scene.requestRender()
        }
      }

      setViewers(viewers => {
        const newViewers = Object.assign({}, viewers)
        newViewers[viewerIndex] = newViewer
        return newViewers
      })

      return newViewer
    },
    [imageryEnabled, activeViewer, lastClickedViewer, viewers]
  )

  const removeViewer = useCallback(
    (viewerIndex: number) =>
      setViewers(viewers => {
        const newViewers = Object.assign({}, viewers)
        delete newViewers[viewerIndex]
        return newViewers
      }),
    []
  )

  const getActiveTilesetTransform = (): Matrix4 | null => {
    const tileset = tilesets[0]

    // @ts-ignore
    if (!tileset || !tileset._root || !tileset._root.computedTransform) {
      return null
    }

    const transform: Matrix4 = Matrix4.fromArray(tileset._root.computedTransform)
    return transform
  }

  /**
   * @param modelType - 1: pointcloud, 2: shaded, 3: textured, 4: cad
   */
  const getTilesetTransform = useCallback(
    async (modelType?: number): Promise<Matrix4 | null> => {
      let tileset: Cesium3DTileset | null = null

      if (modelType != null) {
        for (const id in modelTypes) {
          if (modelTypes[id] === modelType) {
            tileset = tilesets[id]
            break
          }
        }

        // model isn't open by any of the viewers, need to fetch the tileset.json and pull the transform out
        if (!selectedModelGroup) {
          console.log(`modelgroup is null`)
          return null
        }

        const model = models.find(
          m => m.modelgroupId === selectedModelGroup.id && m.modeltypeId === modelType
        )
        if (!model) {
          console.log(`can't find model with type ${modelType}`)
          return null
        }

        const tilepath = apiHostname + model.filepath
        console.log("loading tileset...")

        const token = localStorage.getItem("auth")

        const response = await axios.get(tilepath, {
          headers: {
            "Content-Type": "application/json",
          },
        })

        try {
          const tilesetJson = await response.data
          if (tilesetJson.position !== undefined) {
            const transform: Matrix4 = computeTransform(tilesetJson.position)
            return transform
          }

          const transform: Matrix4 = Matrix4.fromArray(tilesetJson.root.transform)
          return transform
        } catch (err) {
          return null
        }
      } else {
        tileset = tilesets[0]
      }

      if (!tileset || !tileset.root || !tileset.root.computedTransform) {
        return null
      }

      // @ts-ignore
      // const transform: Matrix4 = Matrix4.fromArray(tileset._root.computedTransform)
      return transform
    },
    [modelTypes, tilesets, selectedModelGroup, models]
  )

  const flyToMultiview = useCallback(
    (options: any) => {
      return new Promise(resolve => {
        if (syncViewers && activeViewer) {
          activeViewer.camera.flyTo({
            complete: resolve,
            ...options,
          })
        } else {
          const promises = Object.keys(viewers).map(viewerIdx => {
            const viewer = viewers[viewerIdx]

            return new Promise(resolve => {
              viewer.camera.flyTo({
                complete: resolve,
                ...options,
              })
            })
          })

          Promise.all(promises).then(resolve)
        }
      })
    },
    [activeViewer, viewers, syncViewers]
  )

  const flyTo = useCallback(
    (bookmark: IBookmark, transform?: Matrix4) => {
      if (!activeViewer) {
        return
      }

      let bs = getBoundingSphere(activeViewer, bookmark)

      if (transform && !transform.equals(Matrix4.IDENTITY)) {
        bs = transformBSphere(bs, transform)
      }

      return flyToMultiview({
        destination: bs.position,
        orientation: {
          direction: bs.direction,
          up: bs.up,
          right: bs.right,
          transform: bs.transform,
          frustum: bs.frustum,
        },
      })
    },
    [activeViewer, flyToMultiview]
  )

  const flyToCartesian3 = useCallback(
    async (destination: Cartesian3, distanceAway: number, modelTransform: Matrix4) => {
      if (!activeViewer) {
        return
      }

      const cameraPosition = activeViewer.camera.position.clone()
      const cameraDelta = Cartesian3.subtract(destination, cameraPosition, new Cartesian3())
      const direction = Cartesian3.normalize(cameraDelta, new Cartesian3())
      const destinationOffset = Cartesian3.multiplyByScalar(
        direction,
        distanceAway,
        new Cartesian3()
      )
      const finalCameraPosition = Cartesian3.subtract(
        destination,
        destinationOffset,
        new Cartesian3()
      )

      const zUp = Cartesian3.fromArray([0, 0, 1])
      const upVec = Matrix4.multiplyByPoint(modelTransform, zUp, new Cartesian3())

      activeViewer.camera.flyTo({
        destination: finalCameraPosition,
        orientation: {
          direction,
          up: upVec,
        },
      })
    },
    [activeViewer]
  )

  const flyToHome = useCallback(
    (tileset: null | Cesium3DTileset = null) => {
      if (!activeViewer) {
        return
      }

      // Fly to the bookmark if we can
      if (selectedSite && selectedSite.data && selectedSite.data.meta) {
        const bookmark = selectedSite.data.meta["homeBookmark"]
        if (bookmark) {
          return flyTo(bookmark)
        }
      }

      const activeViewerIdx = Object.keys(viewers).find(
        viewerIdx => viewers[viewerIdx] === activeViewer
      )
      if (!activeViewerIdx) {
        return
      }

      if (!tileset) {
        tileset = tilesets[activeViewerIdx]

        if (!tileset) {
          return
        }
      }

      const cartographic = Cartographic.fromCartesian(tileset.boundingSphere.center)
      const position = Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 150)

      return flyToMultiview({
        destination: position,
        orientation: {
          heading: radiansToDegrees(0.0),
          pitch: -piOverTwo(),
          roll: 0.0,
        },
      })
    },
    [activeViewer, selectedSite, viewers, flyToMultiview, flyTo, tilesets]
  )

  const setPendingFlyHome = useCallback((value: boolean) => {
    pendingFlyHome.current = value
  }, [])

  const updateTilesets = async (
    viewerIdx: number,
    newTileset: null | Cesium3DTileset,
    newModelType: null | number
  ) => {
    setTilesets(previousTilesets => {
      const newTilesets = Object.assign({}, previousTilesets)
      newTilesets[viewerIdx] = newTileset

      if (newTileset) {
        newTileset.readyPromise.then(() => {
          if (pendingFlyHome.current) {
            pendingFlyHome.current = false
            flyToHome(newTileset)
          }
        })
      }

      return newTilesets
    })

    setModelTypes(oldModelTypes => {
      const newModelTypes = Object.assign({}, oldModelTypes)
      newModelTypes[viewerIdx] = newModelType
      return newModelTypes
    })

    if (selectedSite && newModelType !== null) {
      let modelType = "Unknown"
      switch (newModelType) {
        case ModelType.Textured:
          modelType = "Textured"
          break
        case ModelType.Shaded:
          modelType = "Shaded"
          break
        case ModelType.PointCloud:
          modelType = "PointCloud"
          break
        case ModelType.CAD:
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          modelType = "CAD"
          break
        default:
      }
    }
  }

  const getTilesetCoordUnitVector = () => {
    const tileset = tilesets[0]
    if (!tileset || !tileset._root || !tileset._root.computedTransform) {
      return null
    }

    // @ts-ignore
    const transform: Matrix4 = Matrix4.fromArray(tileset._root.computedTransform)

    const p0 = Matrix4.multiplyByPoint(transform, new Cartesian3(0, 0, 0), new Cartesian3())
    const p1 = Matrix4.multiplyByPoint(transform, new Cartesian3(0, 0, 1), new Cartesian3())
    const p2 = Matrix4.multiplyByPoint(transform, new Cartesian3(0, 1, 0), new Cartesian3())
    const p3 = Matrix4.multiplyByPoint(transform, new Cartesian3(1, 0, 0), new Cartesian3())

    const uZ = Cartesian3.normalize(Cartesian3.subtract(p1, p0, new Cartesian3()), new Cartesian3())
    const uY = Cartesian3.normalize(Cartesian3.subtract(p2, p0, new Cartesian3()), new Cartesian3())
    const uX = Cartesian3.normalize(Cartesian3.subtract(p3, p0, new Cartesian3()), new Cartesian3())

    const coords: IModelCoords = {
      origin: p0,
      uX,
      uY,
      uZ,
    }

    return coords
  }

  const viewersForEach = useCallback(
    (func: (viewer: Viewer, viewerIdx: number) => void) => {
      Object.keys(viewers).forEach(viewerIdx => {
        const viewer = viewers[viewerIdx]
        if (viewer) {
          func(viewer, Number(viewerIdx))
        }
      })
    },
    [viewers]
  )

  useEffect(() => {
    if (!activeViewer) {
      return
    }

    const updateHandler = (shouldUpdateLastClicked = true) => {
      if (shouldUpdateLastClicked) {
        setLastClickedViewer(activeViewer)
      }

      if (syncViewers) {
        const camera = activeViewer.camera
        const newView = {
          destination: camera.position,
          orientation: {
            direction: camera.direction,
            up: camera.up,
            right: camera.right,
            transform: camera.transform,
            frustum: camera.frustum,
          },
        }

        Object.keys(viewers).forEach(slaveViewerIdx => {
          const slaveViewer = viewers[slaveViewerIdx]
          if (slaveViewer === activeViewer) {
            return
          }

          slaveViewer.camera.setView(newView)
          slaveViewer.scene.requestRender()
        })
      }
    }

    activeViewer.camera.percentageChanged = 0.001
    activeViewer.camera.changed.addEventListener(updateHandler)

    // Sync the camera on enable
    if (syncViewers && lastClickedViewer) {
      const camera = lastClickedViewer.camera
      const newView = {
        destination: camera.position,
        orientation: {
          direction: camera.direction,
          up: camera.up,
          right: camera.right,
          transform: camera.transform,
          frustum: camera.frustum,
        },
      }

      Object.keys(viewers).forEach(slaveViewerIdx => {
        const slaveViewer = viewers[slaveViewerIdx]
        if (slaveViewer === lastClickedViewer) {
          return
        }

        slaveViewer.camera.setView(newView)
        slaveViewer.scene.requestRender()
      })
    }

    return () => {
      activeViewer.camera.changed.removeEventListener(updateHandler)
    }
  }, [syncViewers, activeViewer, lastClickedViewer, viewers])

  useEffect(() => {
    if (!activeViewer) {
      return
    }

    const postRenderHandler = () => {
      pickedPointCache.current.pickCache = null
      pickedPointCache.current.drillPickCache = null
    }
    activeViewer.scene.postRender.addEventListener(postRenderHandler)

    return () => {
      activeViewer.scene.postRender.removeEventListener(postRenderHandler)
    }
  }, [activeViewer])

  const ensureNearbyImagesIsOpen = useCallback(() => {
    // TODO: test to ensure we've started up
    for (let viewerIdx = 0; viewerIdx < viewerCount; viewerIdx++) {
      if (
        modelTypes[viewerIdx] === -1 ||
        localStorage.getItem(`selectedModel-${viewerIdx}`) === "-1"
      ) {
        return
      }
    }

    const lsKey = `selectedModel-${viewerCount}`
    localStorage.setItem(lsKey, "-1")
    setViewerCount(viewerCount + 1)
  }, [modelTypes, viewerCount, setViewerCount])

  return (
    <ViewerContext.Provider
      value={{
        activeViewer,
        lastClickedViewer,
        viewer: activeViewer,
        setActiveViewer,
        createViewer,
        removeViewer,
        flyTo,
        flyToHome,
        flyToCartesian3,
        setPendingFlyHome,
        tilesets,
        modelTypes,
        updateTilesets,
        getTilesetCoordUnitVector,
        getTilesetTransform,
        getActiveTilesetTransform,
        viewerCount,
        setViewerCount,
        viewerCountLimit,
        setViewerCountLimit,
        syncViewers,
        setSyncViewers,
        viewers,
        viewersForEach,
        pickedPointCache,
        globalCameraHistory,
        setGlobalCameraHistory,
        backgrounded,
        setViewerBackgrounded,
        imageryEnabled,
        setImageryEnabled,
        globeEnabled,
        setGlobeEnabled,
        cuttingPlaneDistance,
        setCuttingPlaneDistance,
        ensureNearbyImagesIsOpen,
        tilesetLoadState,
        setTilesetLoadState,
      }}
    >
      {props.children}
    </ViewerContext.Provider>
  )
}
