import * as React from "react"
import { ViewerControls } from "../ViewerControls"
import { Route, Switch } from "react-router"
import { Changelog } from "../Changelog"
import { BottomBar } from "../BottomBar"
import { SettingsSidebar } from "../Sidebar/settings"
import { SitesSidebar } from "../Sidebar/sites"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { Feedback } from "../Feedback"
import { RenderIfPermission } from "../../helpers/Permissions"
import { CesiumStage } from "../CesiumViewer/CesiumStage"
import { Disclaimer } from "../Disclaimer"
import { Help } from "../Help"
import { ContextProvider } from "../../context/ContextProvider"

export const Home = () => {
  const loc = {
    pathname: window.location.pathname,
    state: { fromDashboard: true },
    search: window.location.search,
    hash: window.location.hash,
  }

  let key: string = "app"
  if (window.location.pathname.startsWith("/settings")) {
    key = "settings"
  } else if (window.location.pathname.startsWith("/sites")) {
    key = "sites"
  }

  return (
    <ContextProvider>
      <Disclaimer />
      <div className={"visualize"}>
        <div className={"app-content"}>
          <ViewerControls />
          <CesiumStage />
        </div>
        <RenderIfPermission token="FEATURE:PRODUCTFEEDBACK" siteSpecific={true}>
          <Feedback />
        </RenderIfPermission>
        <Help />
        <BottomBar />
        <TransitionGroup>
          <CSSTransition key={key} timeout={330} classNames="sidebar-anim">
            <Switch location={loc}>
              <Route path={"/sites"} exact={false} component={SitesSidebar} />
              <Route path={"/settings"} exact={false} component={SettingsSidebar} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </ContextProvider>
  )
}
