/* tslint:disable:jsx-no-lambda */
import { useContext } from "react"
import { Button } from "trunx"
import { FontAwesomeIcon } from "../../helpers/icon"
import { ModelsContext, ViewerContext } from "../../context"
import { PlaceName } from "./PlaceName"
import { apiHostname } from "../../api/constants"

const EDIT_HINT = "\n\n(Double click to edit, press ENTER to save)"

export const PlaceItem = (props: any) => {
  const { snapshot, updateSnapshot, removeSnapshot, onShare, site } = props
  const { flyTo } = useContext(ViewerContext)
  const { selectedSiteId, setSelectedSiteId } = useContext(ModelsContext)

  const flyToCallback = () => {
    if (snapshot.siteId !== selectedSiteId) {
      setSelectedSiteId(snapshot.siteId)
    }
    flyTo(snapshot.data.bookmark)
  }

  return (
    <div className={"place-line"}>
      <figure className="image is-48x48" onClick={flyToCallback}>
        <img src={`${apiHostname}/files/snapshots/${snapshot.filename}/thumb`} alt={""} />
      </figure>
      <span className="title is-6" title={snapshot.name + EDIT_HINT}>
        <span className={"name"}>
          <PlaceName snapshot={snapshot} updateSnapshot={updateSnapshot} />
        </span>
        <span className={"site-name"}>{site && site.name}</span>
      </span>
      <div className={"actions"}>
        <span title={"Fly to"}>
          <Button isText={true} onClick={flyToCallback}>
            <FontAwesomeIcon icon={"paper-plane"} />
          </Button>
        </span>
        <span title={"Share"}>
          <Button isText={true} onClick={onShare}>
            <FontAwesomeIcon icon={"share-alt"} />
          </Button>
        </span>
        <span title={"Archive"}>
          <Button isText={true} onClick={() => removeSnapshot(snapshot.id)}>
            <FontAwesomeIcon icon={"times"} />
          </Button>
        </span>
      </div>
    </div>
  )
}
