/* tslint:disable:jsx-no-lambda */
import { useCallback, useContext } from "react"
import { ViewerContext } from "../../context"
import { FontAwesomeIcon } from "../../helpers/icon"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"
import { TooltipConfigs } from "../../constants"

const ScreenButton = (props: any) => {
  const { viewerCount, setViewerCount, viewerCountLimit } = useContext(ViewerContext)
  const { screenCount } = props
  const disabled = screenCount > viewerCountLimit

  const clickHandler = useCallback(() => {
    if (disabled) {
      return
    }

    setViewerCount(screenCount)
  }, [screenCount])

  let tooltipLabel = "Single screen"
  switch (screenCount) {
    case 2:
      tooltipLabel = "Dual screen"
      break
    case 3:
      tooltipLabel = "Triple screen"
      break
  }

  return (
    <Tooltip
      title={<Typography fontSize={TooltipConfigs.FontSize}>{tooltipLabel}</Typography>}
      placement="bottom"
      enterDelay={TooltipConfigs.Delay}
      enterNextDelay={TooltipConfigs.Delay}
    >
      <div
        className={`stage-button${viewerCount === screenCount ? " selected" : ""}${
          disabled ? " disabled" : ""
        }`}
        onClick={clickHandler}
      >
        <img src={`/assets/icons/${screenCount}-screen.svg`} alt="" />
      </div>
    </Tooltip>
  )
}

const LinkButton = () => {
  const { syncViewers, setSyncViewers } = useContext(ViewerContext)

  return (
    <Tooltip
      title={<Typography fontSize={TooltipConfigs.FontSize}>Sync screens</Typography>}
      placement="bottom"
      enterDelay={TooltipConfigs.Delay}
      enterNextDelay={TooltipConfigs.Delay}
    >
      <div
        className={`stage-button ${syncViewers ? " selected" : ""}`}
        onClick={() => setSyncViewers(!syncViewers)}
      >
        <img src={`/assets/icons/link.svg`} alt="" />
      </div>
    </Tooltip>
  )
}

const GlobeButton = () => {
  const { globeEnabled, setGlobeEnabled } = useContext(ViewerContext)

  return (
    <Tooltip
      title={<Typography fontSize={TooltipConfigs.FontSize}>Toggle globe</Typography>}
      placement="bottom"
      enterDelay={TooltipConfigs.Delay}
      enterNextDelay={TooltipConfigs.Delay}
    >
      <div
        className={`stage-button ${globeEnabled ? " selected" : ""}`}
        onClick={() => setGlobeEnabled(!globeEnabled)}
      >
        <FontAwesomeIcon icon={"globe-asia"} size={"2x"} color={"#faa21b"} />
      </div>
    </Tooltip>
  )
}

const CuttingPlaneButton = () => {
  const { cuttingPlaneDistance, setCuttingPlaneDistance } = useContext(ViewerContext)

  return (
    <Tooltip
      title={<Typography fontSize={TooltipConfigs.FontSize}>Forward cutting plane</Typography>}
      placement="bottom"
      enterDelay={TooltipConfigs.Delay}
      enterNextDelay={TooltipConfigs.Delay}
    >
      <div className="stage-button stage-tooltip">
        <img src="/assets/icons/cuttingplane.svg" alt="" />
        <div className="stage-tooltip-content cutting-plane-tooltip">
          <input
            type="range"
            min="1"
            max="10"
            step="1"
            value={cuttingPlaneDistance}
            onChange={e => setCuttingPlaneDistance(Number(e.target.value))}
          />
          <br />
          {cuttingPlaneDistance} m
        </div>
      </div>
    </Tooltip>
  )
}

export const StageControls = () => (
  <div className={`cesium-stage-controls`}>
    <div className={"inner"}>
      <ScreenButton screenCount={1} />
      <ScreenButton screenCount={2} />
      <ScreenButton screenCount={3} />
      <LinkButton />
      <span className="divider" />
      <GlobeButton />
      <CuttingPlaneButton />
    </div>
  </div>
)
