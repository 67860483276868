/* tslint:disable:jsx-no-lambda */
import * as React from "react"
import { Fragment, useContext } from "react"
import { SiteSelectorButton } from "../SiteSelectorButton"
import { FontAwesomeIcon } from "../../helpers/icon"
import { Link, Route, RouteComponentProps } from "react-router-dom"
import { FlyToBookmark } from "./flyToBookmark"
import { MiniMap } from "../MiniMap"
import { RenderIfPermission } from "../../helpers/Permissions"
import { Tools } from "../Tools"
import { Mocs } from "../Mocs"
import { PointGrab } from "../PointGrab"
import { NearbyPhotoTool } from "../NearbyPhotos"
import { AppContext } from "../../context"
import { SplitMode } from "../../context/App"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { TooltipConfigs } from "../../constants"

const Controls = () => {
  const { setSplitMode, splitMode } = useContext(AppContext)

  return (
    <div className={"sidebar-content"}>
      <SiteSelectorButton />
      <MiniMap />

      <nav className={"tools"}>
        <Fragment>
          <RenderIfPermission
            token={["TOOLS:TARGET", "TOOLS:ANGLE", "TOOLS:MEASURE"]}
            siteSpecific={true}
            mode={"OR"}
          >
            <Tooltip
              title={<Typography fontSize={TooltipConfigs.FontSize}>Tools</Typography>}
              placement="bottom"
              enterDelay={TooltipConfigs.Delay}
              enterNextDelay={TooltipConfigs.Delay}
            >
              <div>
                <Link
                  className={
                    "button is-text" +
                    (window.location.pathname.startsWith("/tools") ? " is-active" : "")
                  }
                  to={window.location.pathname.startsWith("/tools") ? "/" : "/tools"}
                >
                  <FontAwesomeIcon icon={"ruler-triangle"} size={"lg"} />
                </Link>
              </div>
            </Tooltip>
          </RenderIfPermission>
          <Tooltip
            title={<Typography fontSize={TooltipConfigs.FontSize}>Asset Management</Typography>}
            placement="bottom"
            enterDelay={TooltipConfigs.Delay}
            enterNextDelay={TooltipConfigs.Delay}
          >
            <div>
              <Link
                className={
                  "button is-text" +
                  (window.location.pathname.startsWith("/mocs") ? " is-active" : "")
                }
                to={window.location.pathname.startsWith("/mocs") ? "/" : "/mocs"}
              >
                <FontAwesomeIcon icon={"tasks"} size={"lg"} />
              </Link>
            </div>
          </Tooltip>
        </Fragment>

        <RenderIfPermission
          token={["POINTGRAB:CREATE", "POINTGRAB:DOWNLOAD", "POINTGRAB:DELETE"]}
          siteSpecific={true}
          mode={"OR"}
        >
          <Tooltip
            title={<Typography fontSize={TooltipConfigs.FontSize}>Point Cloud Export</Typography>}
            placement="bottom"
            enterDelay={TooltipConfigs.Delay}
            enterNextDelay={TooltipConfigs.Delay}
          >
            <div>
              <Link
                className={
                  "button is-text" +
                  (window.location.pathname.startsWith("/export") ? " is-active" : "")
                }
                to={window.location.pathname.startsWith("/export") ? "/" : "/export"}
              >
                <FontAwesomeIcon icon={"cloud-download"} size={"lg"} />
              </Link>
            </div>
          </Tooltip>
        </RenderIfPermission>

        <RenderIfPermission token={"FEATURE:NEARBYSNAPSHOTS"} siteSpecific={true}>
          <Tooltip
            title={<Typography fontSize={TooltipConfigs.FontSize}>Nearby Photos</Typography>}
            placement="bottom"
            enterDelay={TooltipConfigs.Delay}
            enterNextDelay={TooltipConfigs.Delay}
          >
            <div>
              <Link
                className={
                  "button is-text" +
                  (window.location.pathname.startsWith("/nearby") ? " is-active" : "")
                }
                onClick={() => {
                  setSplitMode(SplitMode.Hidden)
                }}
                to={window.location.pathname.startsWith("/nearby") ? "/" : "/nearby"}
              >
                <FontAwesomeIcon icon={"images"} size={"lg"} />
              </Link>
            </div>
          </Tooltip>
        </RenderIfPermission>
      </nav>

      <Route path={"/tools"} render={() => <Tools />} />
      <Route path={"/mocs"} render={() => <Mocs />} />
      <Route path={"/export"} render={() => <PointGrab />} />
      <Route path={"/nearby"} render={() => <NearbyPhotoTool />} />
      <Route
        path={"/bookmark/:siteid/:id"}
        render={(props: RouteComponentProps<{ id?: string | undefined }>) => (
          <FlyToBookmark {...props} />
        )}
      />
    </div>
  )
}

export { Controls }
