import { useState, useEffect, useContext, useCallback } from "react"
import { useCesiumOnRightClick } from "./useCesiumOnClick"
import { useCesiumOnDrag } from "./useCesiumOnDrag"
import { CesiumDragEvent, DragEvent } from "./models/CesiumDragEvent"
import { CesiumClickEvent } from "./models/CesiumClickEvent"
import * as Cesium from "cesium"
import { IPoint } from "../interfaces"
import { ViewerContext } from "../context"
import { circle, hoverCircle } from "../assets"
import { useEntityGroups } from "./viewer/useEntityGroups"
import { usePointSelector } from "./viewer/usePointSelector"

export const usePolygonDraw = (defaultHeight: number = 5, colorString: string = "#FAA21B") => {
  const { viewer, viewersForEach } = useContext(ViewerContext)
  const [height, setHeight] = useState(defaultHeight)
  const [capped, setCapped] = useState(true)
  const [selectedDragPoint, setSelectedDragPoint] = useState(null as IPoint | null)
  const { datasources } = useEntityGroups()
  const { points, setPoints, clearPoints } = usePointSelector()

  const onRightClickHandler = useCallback(
    (event: CesiumClickEvent) => {
      setPoints((points: IPoint[]) => {
        const objects = event.drillPick()
        let updatedPoints = points

        objects.forEach((object: any) => {
          if (!object.id || !object.id._id || !object.id._id.startsWith("point-")) {
            return
          }
          const id = object.id._id
          updatedPoints = updatedPoints.filter(point => point.id !== id)
        })

        return updatedPoints
      })
    },
    [setPoints]
  )

  const onDragHandler = useCallback(
    (event: CesiumDragEvent) => {
      if (!viewer) {
        return false
      }

      switch (event.dragEvent) {
        case DragEvent.DragStart: {
          const pickedList = event.drillPick()

          for (const object of pickedList) {
            if (object.id && object.id._id && object.id._id.startsWith("point-")) {
              // set selected point for dragging
              const newPoint = points.find((point: IPoint) => point.id === object.id._id) || null
              setSelectedDragPoint(newPoint)
              viewer.scene.requestRender()

              return true
            }
          }
          return false
        }
        case DragEvent.MouseMove: {
          if (selectedDragPoint) {
            const pickedPosition = event.pickPosition()
            if (pickedPosition) {
              selectedDragPoint.position = pickedPosition
              viewer.scene.requestRender()
            }
          }
          return true
        }
        case DragEvent.DragEnd: {
          setSelectedDragPoint(null)
          return false
        }
      }
    },
    [points, selectedDragPoint, viewer]
  )

  useCesiumOnRightClick(onRightClickHandler)
  useCesiumOnDrag(onDragHandler)

  useEffect(() => {
    viewersForEach((viewer: Cesium.Viewer, viewerIdx: number) => {
      points.forEach((point: IPoint, idx: number) => {
        const entObj: Cesium.EntityOptions = {
          id: point.id,
          position: new Cesium.CallbackProperty(() => point.position.cartesian3, false),
          billboard: {
            image: new Cesium.CallbackProperty(() => {
              if (idx === points.length - 1) {
                return hoverCircle
              }
              return circle
            }, false),
            width: 10,
            height: 10,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            color: new Cesium.Color(1.0, 1.0, 1.0, 0.9), // Alpha 0.9. Transparency required for pick position to pass through entity.
            show: true,
          },
        }
        datasources.current[viewerIdx].entities.add(entObj)
      })

      if (points.length >= 3) {
        const positions = points.flatMap((point: IPoint) => [
          point.position.longitude,
          point.position.latitude,
        ])
        const bottomHeight = points.reduce(
          (acc: number, point: IPoint) =>
            acc < point.position.altitude ? acc : point.position.altitude,
          9000
        )
        const pointHeight = points[0].position.altitude
        const material = Cesium.Color.fromCssColorString(colorString).withAlpha(0.5)

        datasources.current[viewerIdx].entities.add({
          id: "polygon-drawing-1",
          polygon: new Cesium.PolygonGraphics({
            hierarchy: Cesium.Cartesian3.fromDegreesArray(positions),
            height: pointHeight,
            extrudedHeight: height + bottomHeight,
            closeTop: !capped,
            closeBottom: !capped,
            material,
            outline: true,
            outlineColor: Cesium.Color.BLACK.withAlpha(0.9), // Alpha 0.9. Transparency required for pick position to pass through entity.
          }),
        })
      }

      viewer.scene.requestRender()
    })

    return () => {
      viewersForEach((viewer: Cesium.Viewer, viewerIdx: number) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        datasources.current[viewerIdx].entities.removeAll()
        viewer.scene.requestRender()
      })
    }
  }, [viewersForEach, points, selectedDragPoint, height, capped, colorString, datasources])

  return { points, clearPoints, capped, setCapped, height, setHeight }
}
