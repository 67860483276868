import { useEffect, useRef, useContext } from "react"
import { IMOC, MOCState } from "../../../interfaces"
import { ExclSVGRed, ExclSVGYellow, ExclSVGGreen } from "../../../assets"
import { MocsContext } from "../../../context"
import * as Cesium from "cesium"

export const drawMocPinsEffect = (viewer: Cesium.Viewer | null) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mocs, showMocs, hiddenMocsIDs } = useContext(MocsContext)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pins = useRef(new Cesium.CustomDataSource("pins"))

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!viewer) {
      return
    }

    const datasources = new Cesium.DataSourceCollection()
    datasources.add(pins.current)

    const dataSourceDisplay = new Cesium.DataSourceDisplay({
      scene: viewer.scene,
      dataSourceCollection: datasources,
    })

    const update = (scene: Cesium.Scene, time: Cesium.JulianDate) => dataSourceDisplay.update(time)
    viewer.scene.preRender.addEventListener(update)

    return () => {
      viewer.scene.preRender.removeEventListener(update)
    }
  }, [viewer])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!mocs || !viewer || !showMocs) {
      return
    }

    mocs
      .filter(moc => !hiddenMocsIDs.includes(moc.id))
      .map((moc: IMOC) => {
        const selected = false

        if (moc.pois.length === 0) return
        const poi = moc.pois[0]
        const mocPosition = Cesium.Cartesian3.fromDegrees(poi.longitude, poi.latitude, poi.altitude)
        const color = new Cesium.Color(1, 1, 1, selected ? 1 : 0.6)

        let image = ExclSVGRed
        if (moc.state === MOCState.Approved) {
          image = ExclSVGYellow
        } else if (moc.state === MOCState.Completed) {
          image = ExclSVGGreen
        }

        return {
          id: `moc-pin-${moc.id}`,
          position: mocPosition,
          eyeOffset: new Cesium.CallbackProperty(() => {
            const cameraPosition = viewer.camera.position
            const distanceToCamera = Cesium.Cartesian3.distance(cameraPosition, mocPosition)

            return new Cesium.Cartesian3(0, 0, distanceToCamera < 2 ? 0 : -distanceToCamera + 2)
          }, false),
          billboard: {
            color,
            image,
            width: 30,
            height: 30,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          },
        }
      })
      .forEach(pin => pins.current.entities.add(pin))
    viewer.scene.requestRender()

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      pins.current.entities.removeAll()
      viewer.scene.requestRender()
    }
  }, [viewer, mocs, showMocs, hiddenMocsIDs])
}
