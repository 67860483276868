/* tslint:disable:jsx-no-lambda */
import { useCallback, useContext, useMemo, useState } from "react"
import { useApiCadExternalMetadata, useApiCadMetadata } from "../../api/Hooks/CadHooks"
import { Loading } from "../Loading"
import { useLocalStorageString } from "../../hooks/useLocalStorage"
import { onKeyPress } from "../../hooks/useKeyPress"
import { CadMetadataContext } from "./CadMetadata"

const NavisworksTabs = (props: any) => {
  const { name, metadata } = props

  if (!metadata || typeof metadata !== "object") {
    return null
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const tabs = useMemo(() => Object.keys(metadata), [metadata])
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [lastClickedTab, setLastClickedTab] = useLocalStorageString(
    "metadata-navisworks-tab",
    tabs[0]
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedTab, setSelectedTab] = useState(() => {
    if (tabs.includes(lastClickedTab)) {
      return lastClickedTab
    } else {
      return tabs[0]
    }
  })
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const tabClickHandler = useCallback(
    (name: string) => {
      setSelectedTab(name)
      setLastClickedTab(name)
    },
    [setSelectedTab, setLastClickedTab]
  )

  return (
    <div className={"metadata-tabs"}>
      <h3>{name}</h3>

      <div className={"tab-container"}>
        {tabs.map((tab, i) => (
          <span
            key={i}
            className={selectedTab === tab ? "selected" : ""}
            onClick={() => tabClickHandler(tab)}
          >
            {tab}
          </span>
        ))}
      </div>
      <div className={"data-container"}>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Value</th>
            </tr>
            {metadata[selectedTab] &&
              Object.keys(metadata[selectedTab]).map((key, i) => (
                <tr key={i}>
                  <td>{key}</td>
                  <td>{metadata[selectedTab][key]}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const ExternalMetadataContent = (props: any) => {
  const { externalTag } = props
  const { metadata, metadataLoading } = useApiCadExternalMetadata(externalTag)

  if (metadataLoading) {
    return <Loading />
  }

  return (
    <div className={"metadata-tabs"}>
      <h3>{externalTag.tag}</h3>

      <div className={"data-container"}>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Value</th>
            </tr>
            {metadata &&
              Object.keys(metadata).map((key, i) => (
                <tr key={i}>
                  <td>{key}</td>
                  <td>{metadata[key]}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export const MetadataViewer = (props: any) => {
  const { item } = props
  const { fullItem, fullItemLoading } = useApiCadMetadata(item)
  const [currentTab, setCurrentTab] = useState("navisworks")
  const { flyToMetadataItem } = useContext(CadMetadataContext)

  const flyToHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.type !== "keydown" || !item) {
        return
      }

      flyToMetadataItem(item)
    },
    [item, flyToMetadataItem]
  )

  onKeyPress("f", flyToHandler)

  if (!item) {
    return null
  }

  if (fullItemLoading) {
    return (
      <div className={"metadata-viewer"}>
        <Loading />
      </div>
    )
  }

  const tabs = ["Navisworks"]

  if (fullItem && fullItem.externalTags) {
    fullItem.externalTags.forEach((externalTag: any) => {
      tabs.push(externalTag.sourceName)
    })
  }

  return (
    <div className={"metadata-viewer"} data-testid={"metadata-viewer"}>
      <div className={"tabs"}>
        {tabs.map((tabName, idx) => {
          const lName = tabName.toLowerCase()
          return (
            <span
              className={"vertical-tab" + (lName === currentTab ? " selected" : "")}
              key={idx}
              onClick={() => setCurrentTab(lName)}
            >
              {tabName}
            </span>
          )
        })}
      </div>
      <div className={"content"}>
        {currentTab === "navisworks" && fullItem && (
          <NavisworksTabs name={fullItem.name} metadata={fullItem.metadata} />
        )}
        {currentTab !== "navisworks" && fullItem.externalTags && (
          <ExternalMetadataContent
            externalTag={fullItem.externalTags.find(
              (tag: any) => tag.sourceName.toLowerCase() === currentTab
            )}
          />
        )}
      </div>
    </div>
  )
}
