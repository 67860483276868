import { useCallback, useContext, useEffect, useState } from "react"
import { NearbyImagesContext } from "../../context"
import { Loading } from "../Loading"
import { INearbySnapshot } from "../../interfaces"
import { PhotoViewer } from "./PhotoViewer"
import { PhotoControls } from "./PhotoControls"
import { NearbyImageFooter } from "./NearbyImageFooter"

export const NearbyPhotos = (props: any) => {
  const { setPoppedOut, poppedOut } = props
  const { nearbyImages, nearbyImagesLoading } = useContext(NearbyImagesContext)

  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0)
  const [currentPhoto, setCurrentPhoto] = useState(null as null | INearbySnapshot)
  const [zoomEnabled, setZoomEnabled] = useState(false)
  const [photoRotation, setPhotoRotation] = useState(0)

  useEffect(() => {
    if (nearbyImages && nearbyImages.length > 0) {
      setCurrentPhotoIndex(0)
      setCurrentPhoto(nearbyImages[0])
    } else {
      setCurrentPhoto(null)
    }
  }, [nearbyImages])

  useEffect(() => {
    if (currentPhoto === null || nearbyImages == null) setCurrentPhotoIndex(0)
    else setCurrentPhotoIndex(nearbyImages.indexOf(currentPhoto))
  }, [currentPhoto, nearbyImages])

  const nextImage = useCallback(
    (direction: number) => {
      if (!nearbyImages || nearbyImages.length === 0) return

      let nextIndex = currentPhotoIndex + direction

      if (nextIndex >= nearbyImages.length) nextIndex -= nearbyImages.length
      else if (nextIndex < 0) nextIndex += nearbyImages.length

      setCurrentPhoto(nearbyImages[nextIndex])
    },
    [nearbyImages, currentPhotoIndex]
  )

  return (
    <div className="nearby-image">
      {nearbyImagesLoading && (
        <div className="nearbyLoadingOverlay">
          <div className="loadingContainer">
            <Loading />
          </div>
        </div>
      )}
      <PhotoViewer
        photo={currentPhoto}
        waitingOnClick={nearbyImages == null}
        zoomEnabled={zoomEnabled}
        photoRotation={photoRotation}
      />
      <PhotoControls
        currentPhoto={currentPhoto}
        zoomEnabled={zoomEnabled}
        setZoomEnabled={setZoomEnabled}
        nextImage={nextImage}
        setPhotoRotation={setPhotoRotation}
        poppedOut={poppedOut}
        setPoppedOut={setPoppedOut}
      />
      <div className={"nearbyControls"}>
        <NearbyImageFooter
          nearbyImages={nearbyImages}
          currentPhotoIndex={currentPhotoIndex}
          currentPhoto={currentPhoto}
          setCurrentPhoto={setCurrentPhoto}
        />
      </div>
    </div>
  )
}
