import { useState, useEffect, useCallback } from "react"

export function useLocalStorageBoolean(key: string, defaultValue: boolean): [boolean, any] {
  const [value, setValue] = useState(() => {
    const value = JSON.parse(localStorage.getItem(key)!)
    return value == null ? defaultValue : Boolean(value)
  })

  useEffect(() => localStorage.setItem(key, JSON.stringify(value)), [key, value])
  return [value, setValue]
}

export function useLocalStorageNumber(key: string, defaultValue: number): [number, any] {
  const [value, setValue] = useState(() => {
    const value = JSON.parse(localStorage.getItem(key)!)
    return value == null ? defaultValue : Number(value)
  })

  useEffect(() => localStorage.setItem(key, JSON.stringify(value)), [key, value])
  return [value, setValue]
}

export function useLocalStorageString(key: string, defaultValue: string): [string, any] {
  const [value, setValue] = useState(() => {
    const value = localStorage.getItem(key)
    return value == null ? defaultValue : value
  })

  useEffect(() => localStorage.setItem(key, value), [key, value])
  return [value, setValue]
}

export function useLocalStorageEnum<T>(key: string, defaultValue: T): [T, any] {
  const [value, setValue] = useState(() => {
    const value = localStorage.getItem(key)
    return value == null ? defaultValue : JSON.parse(value)
  })

  useEffect(() => localStorage.setItem(key, JSON.stringify(value)), [key, value])
  return [value, setValue]
}

export function useLocalStorageArray<T>(
  key: string,
  initialValue?: Array<T>
): [Array<T>, any, any] {
  const [value, setValue] = useState(
    (): Array<T> => {
      const value = JSON.parse(localStorage.getItem(key)!)
      return value == null ? initialValue || [] : value
    }
  )

  useEffect(() => localStorage.setItem(key, JSON.stringify(value)), [key, value])

  const addToArray = useCallback((newValue: T) => {
    setValue(value => {
      return value.includes(newValue) ? value : value.concat([newValue])
    })
  }, [])

  const removeFromArray = useCallback((valueToRemove: T) => {
    setValue(value => value.filter(v => v !== valueToRemove))
  }, [])

  return [value, addToArray, removeFromArray]
}
