import * as React from "react"
import { useContext, useMemo } from "react"
import { ViewerContext } from "../../context"
import { StageViewer } from "./StageViewer"
import { StageControls } from "./StageControls"

export const CesiumStage = () => {
  const { viewerCount } = useContext(ViewerContext)

  const viewerComponents = useMemo(() => {
    const components = []
    for (let i = 0; i < viewerCount; i++) {
      components.push(<StageViewer index={i} key={i} />)
    }

    return components
  }, [viewerCount])

  return (
    <div className="cesium-stage">
      {viewerComponents}
      <StageControls />
    </div>
  )
}
