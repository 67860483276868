import { useEffect, useContext, useRef } from "react"
import { ViewerContext } from "../context"
import { CesiumMoveEvent } from "./models/CesiumMoveEvent"
import * as Cesium from "cesium"

export const useCesiumOnMouseMove = (handler: (e: CesiumMoveEvent) => void) => {
  const { viewer, pickedPointCache } = useContext(ViewerContext)
  const eventHandler = useRef(null as Cesium.ScreenSpaceEventHandler | null)

  useEffect(() => {
    if (!viewer) {
      return
    }

    if (eventHandler.current === null) {
      eventHandler.current = new Cesium.ScreenSpaceEventHandler(viewer.canvas)
    }

    eventHandler.current.setInputAction((e: Cesium.MoveEvent) => {
      const event = new CesiumMoveEvent(viewer, e.endPosition, pickedPointCache)
      handler(event)
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    return () => {
      if (eventHandler.current !== null) {
        eventHandler.current.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE)
        eventHandler.current = null
      }
    }
  }, [viewer, handler, pickedPointCache])
}
