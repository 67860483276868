/* tslint:disable:jsx-no-lambda */
import { Fragment, useCallback, useMemo, useState } from "react"
import { Button, Field, Input, Radio } from "trunx"
import { withRouter } from "react-router"
import Dropzone from "react-dropzone"
import classNames from "classnames"
import { FontAwesomeIcon } from "../../helpers/icon"
import { fileSizeToHuman, latitudeDecimalToString, longitudeDecimalToString } from "../../helpers"
import ReactQuill from "react-quill"
import { Loading } from "../Loading"
import { useCesiumSniper } from "../../hooks/useCesiumSniper"
import { useKeyPress } from "../../hooks/useKeyPress"
import { isRequired, useForm } from "../../hooks/useForm"
import { useApiAttachment, useApiMoc } from "../../api/ApiHooks"
import { toast } from "react-toastify"
import { useSetViewerBackgrounded } from "../../hooks/viewer/useSetViewerBackgrounded"

export const MocCreator = withRouter((props: any) => {
  const isEditMode = "id" in props.match.params && props.match.params.id !== undefined

  const { history } = props
  const [attachments, setAttachments] = useState([] as File[])
  const [loading, setLoading] = useState(false)
  const { createAttachment } = useApiAttachment()
  const { moc, saveMoc } = useApiMoc(props.match.params.id)
  console.log("moc = ", moc)
  const { selectedSniperPoint, sniperEnabled, enableSniper } = useCesiumSniper(!isEditMode)
  const { formValues, formErrors, setFormValue, onFieldChange, isFormValid } = useForm({
    name: {
      defaultValue: moc.name,
      validators: [isRequired],
    },
    documentNumber: {
      defaultValue: moc.documentNumber,
      validators: [isRequired],
    },
    state: {
      defaultValue: moc.state,
    },
    description: {
      defaultValue: moc.description,
    },
  })
  useSetViewerBackgrounded()

  const close = useCallback(() => history.push("/mocs"), [history])
  const submit = useCallback(async () => {
    // if (!isFormValid) {
    //   return
    // }

    setLoading(true)
    const attachmentResults = await Promise.all(
      attachments.map(attachment => createAttachment(attachment))
    )
    const attachmentUUIDs = attachmentResults.map(attachment => attachment.uuid)
    const result = await saveMoc(
      formValues["name"],
      formValues["documentNumber"],
      formValues["state"],
      formValues["description"],
      attachmentUUIDs,
      selectedSniperPoint!
    )
    setLoading(false)

    if (result) {
      toast.success(`MOC has been successfully ${isEditMode ? "updated" : "created"}`)
      close()
    } else {
      toast.error(`There was an error ${isEditMode ? "updating" : "saving"} your MOC`)
    }
  }, [
    attachments,
    close,
    createAttachment,
    formValues,
    isEditMode,
    isFormValid,
    saveMoc,
    selectedSniperPoint,
  ])

  const onFileDrop = useCallback(
    (acceptedFiles: File[]) => {
      setAttachments([...attachments, ...acceptedFiles])
    },
    [attachments]
  )

  const removeAttachment = useCallback(
    (file: File) => {
      setAttachments(attachments.filter(attachment => attachment !== file))
    },
    [attachments]
  )

  const attachmentItems = useMemo(
    () =>
      attachments.map(file => (
        <div className={"file-upload-file"} key={"file-upload-moc-" + file.name}>
          <span>
            <FontAwesomeIcon icon={"file"} />
            <span className={"filename"}>{file.name}</span>
            <small>({fileSizeToHuman(file.size)})</small>
          </span>
          <span>
            <Button
              isText={true}
              onClick={(e: MouseEvent<HTMLElement>) => {
                e.stopPropagation()
                removeAttachment(file)
              }}
            >
              <FontAwesomeIcon icon={"times"} />
            </Button>
          </span>
        </div>
      )),
    [attachments, removeAttachment]
  )

  if (useKeyPress("Escape")) {
    close()
  }

  // const node = document.getElementById("root")

  // if (!node) {
  //   return null
  // }

  // Wait for the user to select a point using the sniper tool
  if (sniperEnabled) {
    return (
      <div className="call-to-action">
        <div className={"title"}>Add MOC</div>
        Click a point on the model to create a MOC at that location
      </div>
    )
  }

  const mocPosition = selectedSniperPoint || moc.pois[0]
  let content

  if (loading) {
    content = <Loading />
  } else {
    content = (
      <Fragment>
        <header className="modal-card-head">
          <p className="modal-card-title">{isEditMode ? "Edit" : "Create"} Management of Change</p>
          <button className="delete" aria-label="close" onClick={close} />
        </header>
        <section className="modal-card-body">
          <Field isLarge={true} style={{ textAlign: "left" }}>
            <Field.Label>{"Name"}</Field.Label>
            <Field.Label>{formErrors["name"]}</Field.Label>
            <Field.Body>
              <Input name={"name"} value={formValues["name"]} onChange={onFieldChange} />
            </Field.Body>
          </Field>
          <Field isLarge={true} style={{ textAlign: "left" }}>
            <Field.Label>{"MOC Number"}</Field.Label>
            <Field.Label>{formErrors["documentNumber"]}</Field.Label>
            <Field.Body>
              <Input
                name={"documentNumber"}
                value={formValues["documentNumber"]}
                onChange={onFieldChange}
              />
            </Field.Body>
          </Field>
          <Field isLarge={true} style={{ textAlign: "left" }}>
            <Field.Label>{"State"}</Field.Label>
            <Field.Body>
              <Radio
                name="proposed"
                checked={formValues["state"] === "proposed"}
                onClick={() => setFormValue("state", "proposed")}
              >
                Proposed
              </Radio>
              <Radio
                name="approved"
                checked={formValues["state"] === "approved"}
                onClick={() => setFormValue("state", "approved")}
              >
                Approved
              </Radio>
              <Radio
                name="completed"
                checked={formValues["state"] === "completed"}
                onClick={() => setFormValue("state", "completed")}
              >
                Completed
              </Radio>
            </Field.Body>
          </Field>
          <Field isLarge={true} style={{ textAlign: "left" }}>
            <Field.Label>{"Description"}</Field.Label>
            <Field.Body>
              <ReactQuill
                value={formValues["description"]}
                className={"quillContainer"}
                onChange={(value: string) => setFormValue("description", value)}
              />
            </Field.Body>
          </Field>
          <Field isLarge={true} style={{ textAlign: "left" }}>
            <Field.Label>{"Attachments"}</Field.Label>
            <Field.Body>
              <Dropzone onDrop={onFileDrop}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div
                      {...getRootProps()}
                      className={classNames("dropzone", { "dropzone--isActive": isDragActive })}
                    >
                      <div className={"file-upload"}>
                        <div className={"file-upload-inner"}>
                          {attachments.length === 0 && (
                            <div style={{ padding: 10 }}>
                              <FontAwesomeIcon icon={"upload"} size={"4x"} />
                            </div>
                          )}
                          {attachmentItems}
                          {isDragActive ? (
                            <p>Drop your files here...</p>
                          ) : (
                            <Fragment>
                              <p>
                                Drag and drop your files here, or click to select files to upload.
                              </p>
                              <Button>Select Files</Button>
                            </Fragment>
                          )}
                        </div>
                      </div>
                      <input {...getInputProps()} />
                    </div>
                  )
                }}
              </Dropzone>
            </Field.Body>
          </Field>
          <Field isLarge={true} style={{ textAlign: "left" }}>
            <Field.Label>{"MOC Position"}</Field.Label>
            <Field.Body>
              {latitudeDecimalToString(mocPosition.latitude)}{" "}
              {longitudeDecimalToString(mocPosition.longitude)} {mocPosition.altitude.toFixed(2)}m
            </Field.Body>
          </Field>
        </section>
        <footer className="modal-card-foot">
          <Button onClick={enableSniper} style={{ marginRight: "auto" }}>
            Change position
          </Button>
          <Button onClick={close}>Cancel</Button>
          <Button onClick={submit} isActive={isFormValid}>
            Done
          </Button>
        </footer>
      </Fragment>
    )
  }

  return (
    <div className="modal is-active create-moc">
      <div className="modal-background" onClick={close} />
      <div className="modal-card">{content}</div>
    </div>
  )
})
