import { useCallback, useContext, useEffect, useState } from "react"
import {
  apiHostname,
  apiSnapshot,
  apiSnapshotDelete,
  apiSnapshotsCreate,
  apiSnapshotsNearby,
  apiSnapshotsUser,
} from "../constants"
import { api, APIError, isAuthError, isError } from "../api"
import { ModelsContext, ViewerContext } from "../../context"
import { INearbySnapshot, ISnapshot } from "../../interfaces"
import { useConfirmationModal } from "../../hooks/useConfirmationModal"
import {
  NearbyImageHeightMode,
  NearbyImageMode,
  NearbyImageSearchRadius,
} from "../../context/NearbyImages"

import { toast } from "react-toastify"
import { Cartesian3 } from "cesium"
import axios from "axios"
import { validateModel } from "../../helpers/validateModelHelper"

export const useApiSnapshots = () => {
  const { selectedSiteId } = useContext(ModelsContext)
  const [snapshots, setSnapshots] = useState([] as ISnapshot[])
  const [snapshotsLoading, setLoading] = useState(true)
  const { getConfirmation } = useConfirmationModal()

  const sortAndSetSnapshots = (snapshotsIn: ISnapshot[]) => {
    snapshotsIn.sort((a, b) => Math.sign(b.id! - a.id!))
    setSnapshots(snapshotsIn)
  }

  const token: string = localStorage.getItem("auth")
  const url: string = apiHostname + "/api" + apiSnapshotsUser

  useEffect(() => {
    axios.defaults.withCredentials = true
    axios
      .get(url, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Cookie: `_monocular_session=${token}`,
        },
        withCredentials: true,
      })
      .then(response => {
        setSnapshots(response.data.snapshots)
      })
      .catch(() => {
        toast.error("There was a problem fetching snapshots")
      })

    setLoading(false)
  }, [selectedSiteId, token, url])

  const updateSnapshot = async (id: number, snapshot: ISnapshot) => {
    const snapshotUpdated: any | APIError = await api.post(apiSnapshot(id), snapshot)
    if (isAuthError(snapshotUpdated) || isError(snapshotUpdated)) {
      toast.error("There was a problem updating the place")
      return false
    } else {
      toast.success("Place was successfully updated")
      sortAndSetSnapshots([...snapshots.filter(snapshot => snapshot.id !== id), snapshotUpdated])
      return true
    }
  }

  const removeSnapshot = async (id: number) => {
    const confirmed = await getConfirmation(
      "Archive Place",
      "Are you sure you want to archive this place?"
    )
    if (!confirmed) {
      // @ts-ignore
      return
    }

    const response: any | APIError = await api.get(apiSnapshotDelete(id))
    if (isAuthError(response) || isError(response)) {
      toast.error("There was a problem archiving the place")
      return false
    } else {
      toast.success("Place was successfully archived")
      sortAndSetSnapshots(snapshots.filter(snapshot => snapshot.id !== id))
      return true
    }
  }

  const createSnapshot = async (
    siteID: number,
    snapshot: ISnapshot,
    blob: Blob,
    imageType?: string
  ): Promise<ISnapshot> => {
    setLoading(true)

    try {
      const formData = new FormData()
      formData.append("photo", blob)

      const token: string = localStorage.getItem("auth")
      const url: string = `${apiHostname}/api/files/snapshots/upload/${siteID}/snapshot`
      axios.defaults.withCredentials = true

      const res = await axios(url, {
        method: "POST",
        headers: {
          Cookie: `_monocular_session=${token}`,
        },
        data: formData,
      })
        .then(response => {
          return response.data
        })
        .catch(err => {
          return {
            error: err,
            shortMessage: "JSON Error",
            longMessage: "Server Error",
            variables: {},
            tags: {},
            status: err.status,
            statusText: err.statusText,
          }
        })

      // Then create image in db
      snapshot.siteId = siteID
      snapshot.filename = res.uuid
      snapshot.imageHash = res.imageHash
      snapshot.imageType = imageType || "snapshot"

      const snapshotResponse = await axios(apiHostname + "/api" + apiSnapshotsCreate, {
        method: "POST",
        headers: {
          Cookie: `_monocular_session=${token}`,
        },
        data: JSON.stringify(snapshot),
      })
        .then(response => {
          return response.data
        })
        .catch(err => {
          return {
            error: err,
            shortMessage: "JSON Error",
            longMessage: "Server Error",
            variables: {},
            tags: {},
            status: err.status,
            statusText: err.statusText,
          }
        })

      if (snapshotResponse.status !== "success") {
        toast.error("There was a problem creating the Place")
      } else {
        sortAndSetSnapshots([...snapshots, snapshotResponse.snapshot])
        toast.success("Place was successfully created")
      }
    } catch (e) {
      toast.error(e.message)
    }

    setLoading(false)

    return snapshot
  }

  return { snapshots, snapshotsLoading, removeSnapshot, createSnapshot, updateSnapshot }
}

export const useApiNearbyImages = () => {
  const { selectedSiteId } = useContext(ModelsContext)
  const { getTilesetTransform } = useContext(ViewerContext)

  const [nearbyImagesLoading, setLoading] = useState(false)
  const [nearbyImages, setNearbyImages] = useState(null as null | INearbySnapshot[])

  const nearbyImagesFetch = useCallback(
    async (
      untypedPosition: [number, number, number],
      untypedCameraDirection: [number, number, number],
      nearbyMode: NearbyImageMode,
      heightType: NearbyImageHeightMode[],
      nearbySearchRadius: NearbyImageSearchRadius
    ) => {
      const position = new Cartesian3(...untypedPosition)
      const cameraDirection = new Cartesian3(...untypedCameraDirection)

      setLoading(true)
      const params = {
        longitude: position.x,
        latitude: position.y,
        altitude: position.z,
        heightType,
        nearbySearchRadius,
        siteID: selectedSiteId,
        nearbyMode,
        x: position.x,
        y: position.y,
        z: position.z,
        cameraDirectionX: cameraDirection.x,
        cameraDirectionY: cameraDirection.y,
        cameraDirectionZ: cameraDirection.z,
      }
      const nearbyResponse: any | APIError = await api.post(apiSnapshotsNearby, params)

      let worldSpaceTransform = await getTilesetTransform(3)
      if (validateModel(worldSpaceTransform)) worldSpaceTransform = await getTilesetTransform(1)

      setLoading(false)

      if (isAuthError(nearbyResponse) || isError(nearbyResponse)) {
        toast.error("There was a problem searching for nearby images")
        return false
      }

      if (!worldSpaceTransform) {
        toast.error("There was a problem searching for nearby images")
        console.dir(worldSpaceTransform)
        return false
      }

      const snapshots = nearbyResponse.snapshots
        .map((snapshot: INearbySnapshot) => {
          const bookmark = snapshot.data.bookmark
          const bookmarkPosition = bookmark.position
          const cameraToPoint = Cartesian3.normalize(
            Cartesian3.subtract(position, bookmarkPosition, new Cartesian3()),
            new Cartesian3()
          )

          snapshot.orderScore = Cartesian3.dot(cameraDirection, cameraToPoint)

          return snapshot
        })
        .sort((a: INearbySnapshot, b: INearbySnapshot) => b.orderScore! - a.orderScore!)
      setNearbyImages(snapshots)

      return true
    },
    [selectedSiteId, getTilesetTransform]
  )

  return { nearbyImagesFetch, nearbyImagesLoading, nearbyImages }
}

export function getSnapshots(photo) {
  const url = `${apiHostname}/files/snapshots/${photo.filename}/large`
  return axios.get(url, { responseType: "blob" }).then(response => {
    return response
  })
}
