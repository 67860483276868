import { useContext } from "react"
import SVG from "react-inlinesvg"
import Logo from "../../assets/img/logo.svg"
import { AppContext } from "../../context"

export const VisualizeBrand = () => {
  const { controlsHidden } = useContext(AppContext)

  return !controlsHidden ? null : (
    <div className={"visualize-brand"}>
      <div className={"logo"}>
        <SVG src={Logo} />
      </div>
    </div>
  )
}
