/* tslint:disable:jsx-no-lambda */
import { VizTransition } from "../VizTransition"
import { FontAwesomeIcon } from "../../helpers/icon"
import { Button } from "trunx"

export interface IToolContainerProps {
  onExpand: (expanded: boolean) => void
  expanded: boolean
  title: JSX.Element
  children: JSX.Element
}

export const ToolContainer = (props: IToolContainerProps) => {
  const { onExpand, expanded, title, children } = props

  return (
    <VizTransition>
      <div className="card-container three-cards" key="target-card-container">
        <header
          key={"target-card-header"}
          className="card-header"
          onClick={() => onExpand(expanded)}
        >
          <p className="card-header-title">
            <span>{title}</span>
            <Button isText={true}>
              <span className="icon">
                <FontAwesomeIcon icon={expanded ? "chevron-up" : "chevron-down"} />
              </span>
            </Button>
          </p>
        </header>
        {expanded ? children : null}
      </div>
    </VizTransition>
  )
}
