export enum siteProperty {
  modelSettings = "modelSettings",
  nearbyImagesType = "nearbyImagesType",
}

export enum modelSettings {
  legacySettings = 1,
  defaultSettings = 0,
}

export enum nearbyImagesType {
  localOffset = 1,
  georeferenced = 0,
}

export function siteList() {
  return {
    perch: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    dolphin: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "tuna-south-main-deck": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "tuna-north-main-deck": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "tuna-flare": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    tuna: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "tuna-subcellar-deck": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "tuna-south-cellar-deck": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "tuna-sea-deck": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "tuna-north-cellar-deck": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "tuna-main-deck": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "tuna-wellheads": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "tuna-middle-cellar-deck": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "whiting-v1": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    whiting: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    gp2: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    tuna_dtlight: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    whiting_dtlight: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    marlin: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    liberdade: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    silo2022: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    tafe: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    munster_tafe: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "Munster-Tafe-Demo": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "gre-pipeline": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "gre-pipeline-1": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "gre-pipeline2": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "wheatstone-intermediate": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "meg-sub-cellar-nw": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "meg-cellar-ne": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "meg-cellar-nw": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "meg-cellar-central-eastern": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "meg-cellar-and-intermediate-west": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "meg-intermediate-east": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "flare-tower": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    sgs: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "meg-sub-cellar-ne": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "gorgon-jetty": {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    arkona: {
      modelSettings: modelSettings.legacySettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    kingfisha: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    kingfishb: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    floundera: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    breama: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    breamb: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    mackerel: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    fortescue: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    cobia: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    halibut: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    silo: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    talison: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "west-kingfish": {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    twickenham: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.georeferenced,
    },
    campbell: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "bambra-pole": {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "harriet-alpha-flare": {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    "glng-aerial": {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
    snapper: {
      modelSettings: modelSettings.defaultSettings,
      nearbyImagesType: nearbyImagesType.localOffset,
    },
  }
}

export function siteIsLegacy(siteName: string, siteList: Object) {
  if (Object.keys(siteList).indexOf(siteName) !== -1)
    return siteList[siteName]["modelSettings"] === modelSettings.legacySettings

  return false
}

export function siteIsInLocalCoordinates(siteName: string, siteList: Object) {
  if (!siteName) return true
  if (Object.keys(siteList).indexOf(siteName) !== -1)
    return siteList[siteName]["nearbyImagesType"] === nearbyImagesType.localOffset

  return true
}
