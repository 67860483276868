import * as React from "react"
import { Fragment, useContext } from "react"
import { MenuItem, Select } from "@mui/material"
import { ModelQuality, ModelsContext } from "../../context/Models"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { TooltipConfigs } from "../../constants"

export const QualitySelector = () => {
  const { modelQuality, setModelQuality } = useContext(ModelsContext)

  const handleChange = event => {
    const value = event.target.value
    setModelQuality(Number(value))
  }

  const style = {
    color: "white",
    borderBottom: "1px solid orange",
    height: 30,
  }

  return (
    <Fragment>
      <Tooltip
        title={<Typography fontSize={TooltipConfigs.FontSize}>Quality Selector</Typography>}
        placement="left-end"
        enterDelay={TooltipConfigs.Delay}
        enterNextDelay={TooltipConfigs.Delay}
      >
        <Select
          name={"quality-selector"}
          style={style}
          defaultValue={ModelQuality.Balanced}
          onChange={handleChange}
          role="combobox"
        >
          <MenuItem role="option" value={ModelQuality.MaximumQuality}>
            Maximum Quality
          </MenuItem>
          <MenuItem role="option" value={ModelQuality.Balanced}>
            Balanced Quality
          </MenuItem>
          <MenuItem role="option" value={ModelQuality.MaximumSpeed}>
            Maximum Speed
          </MenuItem>
        </Select>
      </Tooltip>
    </Fragment>
  )
}
