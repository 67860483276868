import { useEffect, useState, useCallback, useRef, useContext } from "react"
import { ViewerContext } from "../../../context"
import * as Cesium from "cesium"

interface ICameraOrientation {
  direction: Cesium.Cartesian3
  up: Cesium.Cartesian3
  right: Cesium.Cartesian3
  transform: Cesium.Matrix4
  frustum:
    | Cesium.OrthographicFrustum
    | Cesium.PerspectiveFrustum
    | Cesium.PerspectiveOffCenterFrustum
}

interface ICameraPosition {
  destination: Cesium.Cartesian3
  orientation: ICameraOrientation
}

export interface ICameraHistory {
  cameraHistory: ICameraPosition[]
  historyOffset: number
}

export const useCameraHistory = (
  viewer: Cesium.Viewer | null,
  sharedCameraHistory: ICameraHistory,
  setSharedCameraHistory: (value: ICameraHistory) => ICameraHistory
) => {
  const [cameraHistory, setCameraHistory] = useState({
    cameraHistory: [],
    historyOffset: 0,
  } as ICameraHistory)
  const isCameraFlying = useRef(false)
  const { syncViewers, activeViewer } = useContext(ViewerContext)

  useEffect(() => {
    if (!viewer) {
      return
    }

    const cameraChangeHandler = () => {
      // Required to skip the update generated by flyTo when going back or forward
      if (isCameraFlying.current) {
        isCameraFlying.current = false
        return
      }

      if (syncViewers && viewer !== activeViewer) {
        return
      }

      const setHandler = syncViewers ? setSharedCameraHistory : setCameraHistory

      setHandler(
        (currentCameraHistory: ICameraHistory): ICameraHistory => {
          const camera = viewer.camera
          const prevPosition =
            currentCameraHistory.cameraHistory[currentCameraHistory.historyOffset]

          if (prevPosition) {
            const diffVector = Cesium.Cartesian3.subtract(
              prevPosition.destination,
              camera.position,
              new Cesium.Cartesian3()
            )
            const moveDistance = Cesium.Cartesian3.magnitude(diffVector)

            if (moveDistance < 0.001) {
              return currentCameraHistory
            }
          }

          const newView = {
            destination: camera.position.clone(),
            orientation: {
              direction: camera.direction.clone(),
              up: camera.up.clone(),
              right: camera.right.clone(),
              transform: camera.transform.clone(),
              // @ts-ignore
              frustum: camera.frustum.clone(),
            },
          }

          const newHistory: ICameraHistory = {
            cameraHistory: currentCameraHistory.cameraHistory.slice(
              0,
              currentCameraHistory.historyOffset + 1
            ),
            historyOffset: 0,
          }

          newHistory.cameraHistory.push(newView)
          newHistory.historyOffset = newHistory.cameraHistory.length - 1

          localStorage.setItem("camera-position", JSON.stringify(newView))

          return newHistory
        }
      )
    }

    viewer.camera.moveEnd.addEventListener(cameraChangeHandler)

    return () => {
      viewer.camera.moveEnd.removeEventListener(cameraChangeHandler)
    }
  }, [viewer, activeViewer, syncViewers, setSharedCameraHistory])

  const cameraGoBack = useCallback(() => {
    if (!viewer) {
      return
    }

    const setHandler = syncViewers ? setSharedCameraHistory : setCameraHistory

    setHandler(currentHistory => {
      if (!currentHistory.cameraHistory[currentHistory.historyOffset - 1]) {
        return currentHistory
      }

      const newHistory: ICameraHistory = {
        cameraHistory: currentHistory.cameraHistory.slice(0),
        historyOffset: currentHistory.historyOffset - 1,
      }

      isCameraFlying.current = true
      viewer.camera.flyTo({
        ...newHistory.cameraHistory[newHistory.historyOffset],
      })

      return newHistory
    })
  }, [viewer, syncViewers, setSharedCameraHistory])

  const cameraGoForward = useCallback(() => {
    if (!viewer) {
      return
    }

    const setHandler = syncViewers ? setSharedCameraHistory : setCameraHistory

    setHandler(currentHistory => {
      if (!currentHistory.cameraHistory[currentHistory.historyOffset + 1]) {
        return currentHistory
      }

      const newHistory: ICameraHistory = {
        cameraHistory: currentHistory.cameraHistory.slice(0),
        historyOffset: currentHistory.historyOffset + 1,
      }

      isCameraFlying.current = true
      viewer.camera.flyTo({
        ...newHistory.cameraHistory[newHistory.historyOffset],
      })

      return newHistory
    })
  }, [viewer, syncViewers, setSharedCameraHistory])

  useEffect(() => {
    if (syncViewers) {
      const newHistory: ICameraHistory = {
        cameraHistory: sharedCameraHistory.cameraHistory,
        historyOffset: sharedCameraHistory.historyOffset,
      }

      setCameraHistory(newHistory)
    }
  }, [syncViewers, sharedCameraHistory])

  return { cameraHistory, cameraGoBack, cameraGoForward }
}
