/* tslint:disable:jsx-no-lambda */
import { useCallback, useContext, useMemo, useState } from "react"
import { withRouter } from "react-router"
import { Button } from "trunx"
import { FontAwesomeIcon } from "../../helpers/icon"
import { RenderIfPermission } from "../../helpers/Permissions"
import { IAttachment, IPolygon } from "../../interfaces"
import { CreateArea } from "./CreateArea"
import { MocsContext, ViewerContext } from "../../context"
import { mocStateToColor } from "../../helpers"
import { useConfirmationModal } from "../../hooks/useConfirmationModal"
import { apiHostname } from "../../api/constants"

const MocPolygon = (props: any) => {
  const { polygon, onArchive } = props
  const { getConfirmation } = useConfirmationModal()

  return (
    <div className={"moc-area-line"} key={"polygon-area-" + polygon.uuid}>
      <span>
        <span style={{ marginRight: 10 }}>
          <FontAwesomeIcon icon={"layer-group"} />
        </span>
        {polygon.name}
      </span>
      <Button
        isText={true}
        onClick={async () => {
          const confirm = await getConfirmation(
            `Delete area - ${polygon.name}`,
            "Are you sure you want to delete this area?"
          )

          if (confirm) {
            onArchive()
          }
        }}
      >
        <FontAwesomeIcon icon={"trash"} />
      </Button>
    </div>
  )
}

const MocAttachment = (props: any) => {
  const { attachment, onArchive } = props
  const { getConfirmation } = useConfirmationModal()

  return (
    <div className={"moc-area-line"} key={"polygon-attachment-" + attachment.uuid}>
      <span>
        <span style={{ marginRight: 10 }}>
          <FontAwesomeIcon icon={"file"} />
        </span>
        <a href={`${apiHostname}/files/attachments/${attachment.uuid}`}>{attachment.filename}</a>
      </span>
      <Button
        isText={true}
        onClick={async () => {
          const confirm = await getConfirmation(
            `Delete attachment - ${attachment.filename}`,
            "Are you sure you want to delete this attachment?"
          )

          if (confirm) {
            onArchive()
          }
        }}
      >
        <FontAwesomeIcon icon={"trash"} />
      </Button>
    </div>
  )
}

export const ShowMocCard = withRouter((props: any) => {
  const { moc, onDeselect, history } = props
  const [createArea, setCreateArea] = useState(false)
  const { addAreaToMoc, archiveMocArea, archiveMocAttachment, archiveMoc } = useContext(MocsContext)
  const { flyTo } = useContext(ViewerContext)
  const { getConfirmation, getTextConfirmation } = useConfirmationModal()

  const attachmentItems = useMemo(
    () =>
      (moc.attachments || []).map((attachment: IAttachment) => (
        <MocAttachment
          key={`moc-attachment-${attachment.id}`}
          attachment={attachment}
          onArchive={() => archiveMocAttachment(attachment)}
        />
      )),
    [archiveMocAttachment, moc.attachments]
  )

  const polygonItems = useMemo(
    () =>
      (moc.polygons || [])
        .filter((polygon: IPolygon) => !polygon.archived)
        .map((polygon: IPolygon) => (
          <MocPolygon
            key={`moc-polygon-${polygon.uuid}`}
            polygon={polygon}
            onArchive={() => archiveMocArea(moc, polygon)}
          />
        )),
    [archiveMocArea, moc]
  )

  const saveArea = useCallback(
    (polygon: IPolygon, name: string) => {
      addAreaToMoc(moc, polygon, name)
      setCreateArea(false)
    },
    [addAreaToMoc, moc]
  )

  const flyToMoc = useCallback(() => {
    flyTo(moc.data.bookmark)
  }, [flyTo, moc.data.bookmark])

  return (
    <div className="card-container">
      <header className="card-header">
        <p className="card-header-title moc-card-header">
          <span>MOC: {moc.name}</span>
          <span>
            {moc.state === "completed" && <span className="tag is-success">Completed</span>}
            {moc.state === "approved" && <span className="tag is-warning">Approved</span>}
            {moc.state === "proposed" && <span className="tag is-danger">Proposed</span>}
            <Button isText={true} onClick={onDeselect}>
              <span className="icon">
                <FontAwesomeIcon icon={"times"} />
              </span>
            </Button>
          </span>
        </p>
      </header>
      <div className="card-content">
        {moc.description.trim().length > 0 && (
          <div className="content" dangerouslySetInnerHTML={{ __html: moc.description }} />
        )}
        {attachmentItems.length + polygonItems.length > 0 && (
          <div className={"moc-areas"}>
            {polygonItems}
            {attachmentItems}
          </div>
        )}
      </div>
      <RenderIfPermission token={["MOC:DELETE", "MOC:UPDATE"]} siteSpecific={true} mode={"OR"}>
        <footer className="card-footer">
          <RenderIfPermission token={"MOC:DELETE"} siteSpecific={true}>
            <Button
              isDanger={true}
              onClick={async () => {
                const confirm = await getConfirmation(
                  `Archive MOC - ${moc.name}`,
                  "Are you sure you want to archive this MOC?"
                )

                if (confirm) {
                  archiveMoc(moc)
                  onDeselect()
                }
              }}
            >
              <span style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={"archive"} />
              </span>
              Archived
            </Button>
          </RenderIfPermission>
          <RenderIfPermission token={"MOC:UPDATE"} siteSpecific={true}>
            <Button onClick={() => setCreateArea(true)}>
              <span style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={"layer-plus"} />
              </span>
              Add Area
            </Button>
            <Button onClick={() => history.push(`/mocs/edit/${moc.id}`)}>
              <span style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={"pencil"} />
              </span>
              Edit
            </Button>
          </RenderIfPermission>
          <Button onClick={flyToMoc}>
            <span style={{ marginRight: 10 }}>
              <FontAwesomeIcon icon={"paper-plane"} />
            </span>
            Fly To
          </Button>
        </footer>
      </RenderIfPermission>
      {createArea && (
        <CreateArea
          title={`Add area to ${moc.name}`}
          areaColor={mocStateToColor(moc.state)}
          onClose={() => setCreateArea(false)}
          onDone={async (polygon: IPolygon) => {
            const name = await getTextConfirmation("Name the new MOC area", "", {
              buttonText: "OK",
            })
            if (name) {
              saveArea(polygon, name)
            }
          }}
        />
      )}
    </div>
  )
})
