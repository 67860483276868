/* tslint:disable:jsx-no-lambda */
import { Fragment, useContext, useState } from "react"
import { ToolContainer } from "../ToolContainer"
import { PointGrabTab, PointGrabTabs } from "./PointGrabTabs"
import { PointGrabTool } from "./PointGrabTool"
import { PointGrabDownloads } from "./PointGrabDownloads"
import { useSitePointcloud } from "../../api/Hooks/PointgrabHooks"
import { Loading } from "../Loading"
import { ModelsContext } from "../../context"

export const PointGrab = () => {
  const [expanded, setExpanded] = useState(true)
  const [selectedTab, setSelectedTab] = useState(PointGrabTab.Tool)
  const { pointcloud, pointcloudLoading } = useSitePointcloud()
  const { selectedSite } = useContext(ModelsContext)

  let content
  if (!selectedSite) {
    content = <p className="pgError">Please select a site to use the Point Grab tool.</p>
  } else if (pointcloudLoading) {
    content = (
      <div className="loading-centered">
        <Loading />
      </div>
    )
  } else if (!pointcloud) {
    content = (
      <div className="pgError">
        <p>
          Point grab is currently unavailable or there are no point clouds available for the
          selected site.
        </p>
      </div>
    )
  } else {
    content = (
      <Fragment>
        <PointGrabTabs selectedTab={selectedTab} onTabSelect={t => setSelectedTab(t)} />

        {selectedTab === PointGrabTab.Tool && (
          <PointGrabTool pointcloud={pointcloud} setSelectedTab={setSelectedTab} />
        )}
        {selectedTab === PointGrabTab.Downloads && <PointGrabDownloads />}
      </Fragment>
    )
  }

  return (
    <div className={"cards-container-portal"}>
      <ToolContainer
        expanded={expanded}
        onExpand={() => setExpanded(!expanded)}
        title={<span className="title-text">PointGrab Tool</span>}
      >
        {content}
      </ToolContainer>
    </div>
  )
}
