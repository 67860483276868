import { useState } from "react"
// @ts-ignore
import React from "react"
import { ToggleButtonGroup, Typography } from "@mui/material"
import MuiToggleButton from "@mui/material/ToggleButton"
import { PerpendicularAngleMode } from "./AngleModes/PerpendicularAngleMode"
import { ThreePointAngleMode } from "./AngleModes/ThreePointAngleMode"
import { Fragment } from "react"
import styled from "@emotion/styled"
import SquareFootIcon from "@mui/icons-material/SquareFoot"
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory"

enum AngleMode {
  Perpendicular,
  ThreePoints,
}

export const Angle = () => {
  const [mode, setMode] = useState(AngleMode.Perpendicular)
  const [measurement, setMeasurement] = React.useState("RightAngle")

  const handleChange = (event, value) => {
    if (value === "RightAngle") {
      setMode(AngleMode.Perpendicular)
    } else {
      setMode(AngleMode.ThreePoints)
    }

    setMeasurement(value)
  }

  const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#faa21b",
    },
  })

  return (
    <Fragment>
      <div className="card-header-title">
        <ToggleButtonGroup
          color="standard"
          value={measurement}
          exclusive={true}
          onChange={handleChange}
          className={"angle-toggle"}
        >
          <ToggleButton value="RightAngle" className={"toggle-button"}>
            <SquareFootIcon sx={{ mr: 1 }} />
            Right Angle
          </ToggleButton>
          <ToggleButton value="3PointTriangle" className={"toggle-button"}>
            <ChangeHistoryIcon sx={{ mr: 1 }} />3 Point Triangle
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {mode === AngleMode.Perpendicular && <PerpendicularAngleMode />}
      {mode === AngleMode.ThreePoints && <ThreePointAngleMode />}
    </Fragment>
  )
}
