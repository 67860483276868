import { useContext } from "react"
import { ISnapshot } from "../../interfaces"
import { api, isError, isAuthError } from "../../api"
import { toast } from "react-toastify"
import { RouteComponentProps } from "react-router"
import { ModelsContext, ViewerContext } from "../../context"
import { useAsyncEffect } from "../../lib/UseAsyncEffect"

interface IBookmarkRouteProps {
  id?: string | undefined
  siteid?: string | undefined
}

export const FlyToBookmark = (props: RouteComponentProps<IBookmarkRouteProps>) => {
  const { setSelectedSiteId } = useContext(ModelsContext)
  const { flyTo } = useContext(ViewerContext)
  const { history } = props
  const { id, siteid } = props.match.params

  useAsyncEffect(async () => {
    if (siteid) {
      const parsedId = parseInt(siteid, 10)
      setSelectedSiteId(parsedId)
    }
    if (id) {
      const response = await api.get<ISnapshot>("/snapshots/" + id)

      if (isAuthError(response) || isError(response)) {
        toast.error("There was a problem finding bookmark.")
      } else if (response.data && response.data.bookmark) {
        flyTo(response.data.bookmark)
        history.push("/")
      } else {
        toast.error("There was a problem loading bookmark.")
      }
    }
  }, [])

  return null
}
