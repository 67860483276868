/* tslint:disable:jsx-no-lambda */
import { useCallback, useEffect, useState } from "react";
import { apiHostname } from "../../api/constants";
import { INearbySnapshot } from "../../interfaces";
import { Button, ButtonGroup } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { TooltipConfigs } from "../../constants";

const PAGE_SIZE = 5

const iconStyle = {
  color: "white",
  width: 10,
  height: 30,
  borderColor: "#faa21b",
  backgroundColor: "#2c332c",
  minWidth: 5,
}

export const NearbyImageFooter = (props: any) => {
  const { nearbyImages, currentPhoto, currentPhotoIndex, setCurrentPhoto } = props
  const [currentPage, setCurrentPage] = useState(0)

  // tslint:disable-next-line:no-empty
  useEffect(() => {}, [currentPage])

  useEffect(() => {
    const newPageNumber = Math.floor(currentPhotoIndex / PAGE_SIZE)
    setCurrentPage(newPageNumber)
  }, [currentPhotoIndex])

  const handleClick = useCallback(
    (index: number) => {
      setCurrentPhoto(nearbyImages[index])
    },
    [nearbyImages, setCurrentPhoto]
  )

  if (!nearbyImages || nearbyImages.length === 0) {
    return null
  }

  const min = 0
  const max = 4

  // Clamp number between two values with the following line:
  const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

  const imageNodes = nearbyImages.map((image: INearbySnapshot, i: number) => {
    const selected = currentPhoto === image
    const isPanosphere = image && image.heightType === "panosphere"

    return (
      <div
        className={`snapshot-thumb ${selected ? "selected" : ""}`}
        key={i}
        onClick={() => handleClick(i)}
      >
        <img src={`${apiHostname}/files/snapshots/${image.filename}/thumb`} alt="" />
        {isPanosphere && <span className="snapshotIcon">360°</span>}
      </div>
    )
  })

  return (
    <div className={"snapshot-strip"}>
      <div className={"snapshot-strip-container"}>
        {imageNodes.slice(currentPage * PAGE_SIZE, currentPage * PAGE_SIZE + PAGE_SIZE)}
      </div>
      <div className={"snapshot-strip-selector"}>
        <Tooltip
          title={<Typography fontSize={TooltipConfigs.FontSize}>Previous Page</Typography>}
          placement="top-end"
          enterDelay={TooltipConfigs.Delay}
          enterNextDelay={TooltipConfigs.Delay}
        >
          <Button
            variant={"outlined"}
            sx={{ ...iconStyle }}
            onClick={() => setCurrentPage(clamp(currentPage - 1, min, max))}
          >
            <ChevronLeftIcon sx={{ color: "white" }} />
          </Button>
        </Tooltip>
        <ButtonGroup variant={"outlined"} className={"pageButtons"}>
          <Tooltip
            title={<Typography fontSize={TooltipConfigs.FontSize}>Page 1</Typography>}
            placement="top"
            enterDelay={TooltipConfigs.Delay}
            enterNextDelay={TooltipConfigs.Delay}
          >
            <Button sx={{ ...iconStyle }} onClick={() => setCurrentPage(0)}>
              1
            </Button>
          </Tooltip>
          <Tooltip
            title={<Typography fontSize={TooltipConfigs.FontSize}>Page 2</Typography>}
            placement="top"
            enterDelay={TooltipConfigs.Delay}
            enterNextDelay={TooltipConfigs.Delay}
          >
            <Button sx={{ ...iconStyle }} onClick={() => setCurrentPage(1)}>
              2
            </Button>
          </Tooltip>
          <Tooltip
            title={<Typography fontSize={TooltipConfigs.FontSize}>Page 3</Typography>}
            placement="top"
            enterDelay={TooltipConfigs.Delay}
            enterNextDelay={TooltipConfigs.Delay}
          >
            <Button sx={{ ...iconStyle }} onClick={() => setCurrentPage(2)}>
              3
            </Button>
          </Tooltip>
          <Tooltip
            title={<Typography fontSize={TooltipConfigs.FontSize}>Page 4</Typography>}
            placement="top"
            enterDelay={TooltipConfigs.Delay}
            enterNextDelay={TooltipConfigs.Delay}
          >
            <Button sx={{ ...iconStyle }} onClick={() => setCurrentPage(3)}>
              4
            </Button>
          </Tooltip>
          <Tooltip
            title={<Typography fontSize={TooltipConfigs.FontSize}>Page 5</Typography>}
            placement="top"
            enterDelay={TooltipConfigs.Delay}
            enterNextDelay={TooltipConfigs.Delay}
          >
            <Button sx={{ ...iconStyle }} onClick={() => setCurrentPage(4)}>
              5
            </Button>
          </Tooltip>
        </ButtonGroup>
        <Tooltip
          title={<Typography fontSize={TooltipConfigs.FontSize}>Next Page</Typography>}
          placement="top-start"
          enterDelay={TooltipConfigs.Delay}
          enterNextDelay={TooltipConfigs.Delay}
        >
          <Button
            variant={"outlined"}
            sx={{ ...iconStyle }}
            onClick={() => setCurrentPage(clamp(currentPage + 1, min, max))}
          >
            <ChevronRightIcon sx={{ color: "white" }} />
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}
