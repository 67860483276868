/* tslint:disable:jsx-no-lambda */
// @ts-ignore
import React from "react"
import { MocCreator } from "../MocCreator"
import { PlacesCard } from "../Cards/Places"
import { MocsCard } from "./mocsCards"
import { Route } from "react-router-dom"
import { RenderIfPermission } from "../../helpers/Permissions"

export const Mocs = () => {
  return (
    <div className={"cards-container-portal"}>
      <Route path={"/mocs/(edit|add)/:id?"} exact={false} render={() => <MocCreator />} />
      <PlacesCard />
      <RenderIfPermission token={"MOC:VIEW"} siteSpecific={true}>
        <MocsCard />
      </RenderIfPermission>
    </div>
  )
}
