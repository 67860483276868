import * as React from "react"
import { Box, Field, Button, Input } from "trunx"
import SecurityController from "../../../controllers/security"
import { useContext } from "react"
import { SecurityContext } from "../../../context/SecurityProvider"
import { api } from "../../../api"
import { IUser } from "../../../interfaces"
import { apiAuthUpdateAccount } from "../../../api/constants"
import { toast } from "react-toastify"
import { TextField } from "@mui/material"

interface IProfileAccountModel {
  firstName: string
  lastName: string
}

interface IProps {
  updateAccount: SecurityController["updateAccount"]
}

interface IState {
  sending: boolean
  fetching: boolean
}

const textFieldStyle = {
  color: "white",
  borderColour: "white",
  mt: 2,
  "& .MuiInputLabel-root": { color: "white" },
  "& .MuiOutlinedInput-input": { color: "white" },
}

export const ProfileAccountSettings = () => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.currentTarget.name as keyof IProfileAccountModel

    userData[key] = e.target.value
  }
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    console.debug("Submitting", userData)
    if (!userData) {
      console.error("no userData in form")
      return
    }
    console.log(userData)
    const response = await api.post<IUser>(apiAuthUpdateAccount, userData)
    toast.success("Account updated.")

    setUserData(userData)
    localStorage.setItem("userData", JSON.stringify(userData))
  }

  const { userData, setUserData } = useContext(SecurityContext)

  return (
    <React.Fragment>
      <h2>Profile Account Settings</h2>
      <Box>
        <form onSubmit={onSubmit}>
          {/*<h3>First Name</h3>*/}
          <Field defaultValue={"First Name"} isLarge={true} style={{ textAlign: "left" }}>
            <TextField
              inputProps={{ "data-testid": "firstName" }}
              onChange={onChange}
              name={"firstName"}
              defaultValue={userData.firstName}
              label={"First Name"}
              sx={{ ...textFieldStyle }}
            />
          </Field>
          {/*<h3>Last Name</h3>*/}
          <Field defaultValue={"Last Name"} isLarge={true} style={{ textAlign: "left" }}>
            <TextField
              inputProps={{ "data-testid": "lastName" }}
              onChange={onChange}
              name={"lastName"}
              defaultValue={userData.lastName || ""}
              label={"Last Name"}
              sx={{ ...textFieldStyle }}
            />
          </Field>
          <div>
            <Button
              style={{ marginTop: 14 }}
              isLarge={false}
              type={"submit"}
              data-testid={"submit"}
            >
              Update
            </Button>
          </div>
        </form>
      </Box>
    </React.Fragment>
  )
}
