import { useEffect } from "react"
import * as Cesium from "cesium"
import { Viewer } from "cesium"

function getFlagForKeyCode(key: string) {
  if (key === undefined || key == null) {
    return null
  }
  switch (key.toUpperCase()) {
    case "W":
      return "moveForward"
    case "S":
      return "moveBackward"
    case "Q":
      return "moveUp"
    case "E":
      return "moveDown"
    case "D":
      return "moveRight"
    case "A":
      return "moveLeft"
    case "SHIFT":
      return "moveFast"
    case "ARROWLEFT":
      return "lookLeft"
    case "ARROWRIGHT":
      return "lookRight"
    case "ARROWUP":
      return "lookUp"
    case "ARROWDOWN":
      return "lookDown"
    case "PAGEUP":
      return "moveUp"
    case "PAGEDOWN":
      return "moveDown"
    default:
      return null
  }
}

export const useKeyboardControls = (viewer: Viewer | null, active: boolean) => {
  useEffect(() => {
    if (!viewer || !active) {
      return
    }
    const { camera } = viewer
    const ellipsoid = viewer.scene.globe.ellipsoid
    const flags = {
      moveForward: false,
      moveBackward: false,
      moveUp: false,
      moveDown: false,
      moveRight: false,
      moveLeft: false,
      lookLeft: false,
      lookRight: false,
      lookUp: false,
      lookDown: false,
      moveFast: false,
    }
    let running = false
    let keysDown = 0

    const onTick = () => {
      const cameraHeight = ellipsoid.cartesianToCartographic(camera.position).height
      const moveRate = flags.moveFast ? cameraHeight / 10.0 : cameraHeight / 150.0

      if (flags.moveForward) {
        camera.moveForward(moveRate)
      }
      if (flags.moveBackward) {
        camera.moveBackward(moveRate)
      }
      if (flags.moveUp) {
        camera.moveUp(moveRate)
      }
      if (flags.moveDown) {
        camera.moveDown(moveRate)
      }
      if (flags.moveLeft) {
        camera.moveLeft(moveRate)
      }
      if (flags.moveRight) {
        camera.moveRight(moveRate)
      }
      if (flags.lookLeft) {
        const result = new Cesium.Cartesian3()
        Cesium.Ellipsoid.WGS84.geocentricSurfaceNormal(camera.position, result)
        camera.look(result, -0.04)
      }
      if (flags.lookRight) {
        const result = new Cesium.Cartesian3()
        Cesium.Ellipsoid.WGS84.geocentricSurfaceNormal(camera.position, result)
        camera.look(result, 0.04)
      }
      if (flags.lookUp) {
        camera.lookUp(0.04)
      }
      if (flags.lookDown) {
        camera.lookDown(0.04)
      }
    }

    const onKeyDown = (e: KeyboardEvent) => {
      const flag = getFlagForKeyCode(e.key)
      if (!flag || flags[flag] === true) {
        return
      }
      flags[flag] = true
      keysDown++

      if (!running) {
        running = true
        viewer.clock.onTick.addEventListener(onTick)
      }
    }
    const onKeyUp = (e: KeyboardEvent) => {
      const flag = getFlagForKeyCode(e.key)

      if (!flag || flags[flag] === false) {
        return
      }
      flags[flag] = false
      keysDown--

      if (keysDown === 0) {
        running = false
        viewer.clock.onTick.removeEventListener(onTick)
      }
    }

    window.addEventListener("keydown", onKeyDown, false)
    window.addEventListener("keyup", onKeyUp, false)

    return () => {
      window.removeEventListener("keydown", onKeyDown, false)
      window.removeEventListener("keyup", onKeyUp, false)

      if (running) {
        viewer.clock.onTick.removeEventListener(onTick)
      }
    }
  }, [viewer, active])
}
