import { useEffect, useContext } from "react"
import { ViewerContext } from "../context"
import { CesiumClickEvent } from "./models/CesiumClickEvent"
import { ScreenSpaceEventHandler, ScreenSpaceEventType } from "cesium"

export const useCesiumOnLeftClick = (handler: (e: any) => void) => {
  const { viewer, pickedPointCache } = useContext(ViewerContext)

  useEffect(() => {
    if (!viewer) {
      return
    }

    const callback = (e: any) => handler(new CesiumClickEvent(viewer, e, pickedPointCache))
    const eventHandler = new ScreenSpaceEventHandler(viewer.canvas)
    eventHandler.setInputAction(callback, ScreenSpaceEventType.LEFT_CLICK)

    return () => eventHandler.destroy()
  }, [viewer, handler, pickedPointCache])
}

export const useCesiumOnRightClick = (handler: (e: any) => void) => {
  const { viewer, pickedPointCache } = useContext(ViewerContext)

  useEffect(() => {
    if (!viewer) {
      return
    }

    const callback = (e: any) => handler(new CesiumClickEvent(viewer, e, pickedPointCache))
    const eventHandler = new ScreenSpaceEventHandler(viewer.canvas)
    eventHandler.setInputAction(callback, ScreenSpaceEventType.RIGHT_CLICK)

    return () => eventHandler.destroy()
  }, [viewer, handler, pickedPointCache])
}
