import * as React from "react"
import { useState } from "react"
import { Button, Content } from "trunx"
import { Link, Redirect } from "react-router-dom"
import { FontAwesomeIcon } from "../../helpers/icon"
import { Loading } from "../Loading"
import { toast } from "react-toastify"
import { apiHostname, apiUsersForgotpassword } from "../../api/constants"
import axios from "axios"
import { IconButton, TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { ValidateEmail } from "../../helpers/securityHelpers"

export async function ForgotPasswordCall(email: string) {
  const url = apiHostname + "/api" + apiUsersForgotpassword
  axios.defaults.withCredentials = true
  const response = await axios({
    method: "POST",
    url,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    data: { email },
  })
    .then(response => {
      toast.success(
        "Password reset sent, if you have a registered account with Visualize, a password reset email has been sent to you."
      )
      return response
    })
    .catch(error => {
      return error.response
    })
  return response
}

export const ForgotPassword = (props: any) => {
  const [email, setEmail] = useState("")
  const [modalState, setModalState] = useState("")

  const textFieldStyle = {
    width: 350,
    color: "white",
    borderColour: "white",
    ml: 4,
    mr: 2,
    mb: 1,
    "& .MuiInputLabel-root": { color: "grey" },
    "& .MuiOutlinedInput-input": { color: "black" },
    "& label.Mui-focused": { color: "white" },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "orange",
      },
      "&.Mui-focused fieldset": {
        borderColor: "orange",
      },
    },
  }
  const errorTextFieldStyle = {
    width: 350,
    color: "white",
    borderColour: "white",
    ml: 4,
    mr: 2,
    mb: 1,
    "& .MuiOutlinedInput-input": { color: "black" },
  }
  const closeButtonStyle = {
    color: "black",
    ml: 13,
  }

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  function EnterPressed(e) {
    if (e.key === "Enter") {
      onClick()
    }
  }

  const onClick = async () => {
    console.log("Sending password reset email")
    if (!ValidateEmail(email)) {
      setModalState("error")
      return
    }
    setModalState("loading")
    const response = await ForgotPasswordCall(email)
    if (response) setModalState("success")
    else setModalState("failed")
  }

  const EmailTextField = (stateString: string) => {
    return (
      <span>
        {stateString === "error" ? (
          <TextField
            onKeyPress={EnterPressed}
            error={true}
            id="outlined-error-helper-text"
            variant={"outlined"}
            sx={{ ...errorTextFieldStyle }}
            type={"email"}
            placeholder="your@email.address"
            onChange={onChangeEmail}
            helperText={"Please enter a valid e-mail address"}
          />
        ) : (
          <TextField
            onKeyPress={EnterPressed}
            autoFocus={true}
            variant={"outlined"}
            sx={{ ...textFieldStyle }}
            type={"email"}
            placeholder="your@email.address"
            onChange={onChangeEmail}
          />
        )}
      </span>
    )
  }

  return modalState === "failed" ? (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card" style={{ width: "500px" }} data-testid={"forgotPasswordModal"}>
        <header className="modal-card-head" style={{ backgroundColor: "whitesmoke" }}>
          <p
            className="modal-card-title"
            style={{ color: "black", fontSize: 30 }}
            data-testid={"forgotPasswordFailed"}
          >
            System Error
          </p>
          <Link to={"/"}>
            <IconButton
              aria-label={"close"}
              sx={{
                color: "black",
                ml: 30,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Link>
        </header>
        <section className="modal-card-body" style={{ backgroundColor: "white" }}>
          <Content>
            <div className="columns is-centered">
              <span style={{ marginTop: "10px", color: "black", fontSize: 20 }}>
                There was an error completing your request. Please contact Airscope for more
                information.
              </span>
            </div>
            <div
              className="columns is-centered"
              style={{ marginTop: "14px", marginLeft: "95px" }}
            />
          </Content>
        </section>
        <footer
          style={{ backgroundColor: "white", borderTopStyle: "none" }}
          className="modal-card-foot"
        />
      </div>
    </div>
  ) : modalState === "success" ? (
    <Redirect to={"/"} />
  ) : (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card" style={{ width: "500px" }} data-testid={"forgotPasswordModal"}>
        <header
          className="modal-card-head"
          style={{
            backgroundColor: "whitesmoke",
          }}
        >
          <p className="modal-card-title" style={{ color: "black", fontSize: 30 }}>
            Forgot your password ?
          </p>
          <Link to={"/"}>
            <IconButton aria-label={"close"} sx={{ ...closeButtonStyle }}>
              <CloseIcon />
            </IconButton>
          </Link>
        </header>
        <section className="modal-card-body" style={{ backgroundColor: "white" }}>
          <Content>
            <div className="columns is-centered">
              <span style={{ paddingTop: "5px", marginRight: "5px", color: "red" }}>* </span>
              <span style={{ marginTop: "10px", color: "black" }}>E-mail: </span>
              {EmailTextField(modalState)}
            </div>
            {modalState === "loading" ? (
              <div className={"forgot-password-loading"}>
                <Loading />
              </div>
            ) : (
              <div
                className="columns is-centered"
                style={{ marginTop: "14px", marginLeft: "95px" }}
              >
                <Button
                  className="column is-half"
                  style={{ backgroundColor: "orange", color: "white", borderRadius: "5px" }}
                >
                  <div
                    style={{ lineHeight: "12px" }}
                    onClick={onClick}
                    data-testid={"submitPasswordButton"}
                  >
                    <FontAwesomeIcon style={{ paddingBottom: "1px" }} icon={"upload"} /> Send
                    password reset email
                  </div>
                </Button>
              </div>
            )}
          </Content>
        </section>
        <footer
          style={{ backgroundColor: "white", borderTopStyle: "none" }}
          className="modal-card-foot"
        />
      </div>
    </div>
  )
}
