import { apiHostname } from "../api/constants"
import { ModelQuality } from "../context/Models"
import { ModelType } from "../interfaces"
import { siteIsLegacy, siteList } from "./siteSettings"

const { MaximumSpeed, Balanced, MaximumQuality } = ModelQuality
const { Shaded, PointCloud, CAD, Textured } = ModelType

const DEFAULT_MAXIMUM_MEMORY_USAGE = 2048

export function customSiteList() {
  return [
    "twickenham",
    "campbell",
    "gibson",
    "wonnich",
    "talison",
    "western-power",
    "double-island",
    "harriet-alpha",
    "victoria",
    "simpson-a",
    "simpson-b",
    "agincourt",
    "linda",
    "harriet-bravo",
    "harriet-charlie",
    "snapper",
    "glng-aerial",
    "bambra-pole",
    "harriet-alpha-flare",
  ]
}

interface Settings {
  url: string
  maximumMemoryUsage: number
  maximumScreenSpaceError: number
  debugColorizeTiles: boolean
  debugShowBoundingVolume: boolean
  debugShowRenderingStatistics: boolean
  dynamicScreenSpaceError: boolean
  dynamicScreenSpaceErrorDensity: number
  dynamicScreenSpaceErrorFactor: number
  dynamicScreenSpaceErrorHeightFalloff: number
  baseScreenSpaceError: number
  skipScreenSpaceErrorFactor: number
  debugShowGeometricError: boolean
  cullWithChildrenBounds: boolean
  progressiveResolutionHeightFraction: number
  foveatedConeSize: number
  foveatedTimeDelay: number
  immediatelyLoadDesiredLevelOfDetail: boolean
  loadSiblings: boolean
  skipLevels: number
  skipLevelOfDetail: boolean
  preferLeaves: boolean
}

function retrieveMemorySettings(siteSlug: string, modelQuality) {
  const memorySettings = {
    twickenham: {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    campbell: {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    wonnich: {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    gibson: {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    linda: {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    "bambra-pole": {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    "harriet-bravo": {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    "harriet-charlie": {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    "double-island": {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    "glng-aerial": {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    "harriet-alpha": {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    "harriet-alpha-flare": {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    victoria: {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    "simpson-a": {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    "simpson-b": {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    agincourt: {
      [MaximumQuality]: 4024,
      [Balanced]: 4024,
      [MaximumSpeed]: 4024,
    },
    talison: {
      [MaximumQuality]: 1024,
      [Balanced]: 1024,
      [MaximumSpeed]: 1024,
    },
    "western-power": {
      [MaximumQuality]: 1024,
      [Balanced]: 1024,
      [MaximumSpeed]: 1024,
    },
    snapper: {
      [MaximumQuality]: 6024,
      [Balanced]: 4024,
      [MaximumSpeed]: 2024,
    },
  }

  return memorySettings[siteSlug][modelQuality]
}

function retrieveSettings(
  siteSlug: string,
  filepath: string,
  maximumScreenSpaceError: number,
  maximumMemoryUsage: number,
  apiHostname: string,
  dynamicScreenSpaceError: boolean
): Settings {
  const settingsList = {
    twickenham: {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    "bambra-pole": {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    campbell: {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    wonnich: {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    gibson: {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    linda: {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    "harriet-bravo": {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    "harriet-charlie": {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    "double-island": {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    "harriet-alpha": {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    "harriet-alpha-flare": {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    "glng-aerial": {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    victoria: {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    "simpson-a": {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    "simpson-b": {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    agincourt: {
      url: apiHostname + filepath,
      maximumMemoryUsage: 4024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    talison: {
      url: apiHostname + filepath,
      maximumMemoryUsage: 1024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    "western-power": {
      url: apiHostname + filepath,
      maximumMemoryUsage: 1024,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    snapper: {
      url: apiHostname + filepath,
      maximumMemoryUsage,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError: true,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
    default: {
      url: apiHostname + filepath,
      maximumMemoryUsage: 2048,
      maximumScreenSpaceError,
      debugColorizeTiles: false,
      debugShowBoundingVolume: false,
      debugShowRenderingStatistics: false,
      dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: 0.00278,
      dynamicScreenSpaceErrorFactor: 4.0,
      dynamicScreenSpaceErrorHeightFalloff: 0.25,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      debugShowGeometricError: false,
      cullWithChildrenBounds: true,
      progressiveResolutionHeightFraction: 0.3,
      foveatedConeSize: 0.1,
      foveatedTimeDelay: 0.2,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      skipLevels: 1,
      skipLevelOfDetail: true,
      preferLeaves: true,
    },
  }
  return settingsList[siteSlug]
}

function retrieveQualitySettings(siteSlug: string, modelType, modelQuality) {
  const qualitySettings = {
    twickenham: {
      [Textured]: {
        [MaximumQuality]: 4,
        [Balanced]: 6,
        [MaximumSpeed]: 10,
      },
      [Shaded]: {
        [MaximumQuality]: 2,
        [Balanced]: 6,
        [MaximumSpeed]: 8,
      },
    },
    "bambra-pole": {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
      [PointCloud]: {
        [MaximumQuality]: 50,
        [Balanced]: 100,
        [MaximumSpeed]: 200,
      },
      [CAD]: {
        [MaximumQuality]: 10,
        [Balanced]: 17,
        [MaximumSpeed]: 33,
      },
    },
    campbell: {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
    },
    wonnich: {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
      [PointCloud]: {
        [MaximumQuality]: 50,
        [Balanced]: 100,
        [MaximumSpeed]: 200,
      },
    },
    gibson: {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
      [PointCloud]: {
        [MaximumQuality]: 50,
        [Balanced]: 100,
        [MaximumSpeed]: 200,
      },
    },
    linda: {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
    },
    "glng-aerial": {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
      [PointCloud]: {
        [MaximumQuality]: 50,
        [Balanced]: 100,
        [MaximumSpeed]: 200,
      },
      [CAD]: {
        [MaximumQuality]: 10,
        [Balanced]: 17,
        [MaximumSpeed]: 33,
      },
    },
    "harriet-bravo": {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
    },
    "harriet-charlie": {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
    },
    "harriet-alpha-flare": {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
      [PointCloud]: {
        [MaximumQuality]: 50,
        [Balanced]: 100,
        [MaximumSpeed]: 200,
      },
      [CAD]: {
        [MaximumQuality]: 10,
        [Balanced]: 17,
        [MaximumSpeed]: 33,
      },
    },
    "double-island": {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
    },
    "harriet-alpha": {
      [PointCloud]: {
        [MaximumQuality]: 50,
        [Balanced]: 100,
        [MaximumSpeed]: 200,
      },
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
      [CAD]: {
        [MaximumQuality]: 10,
        [Balanced]: 17,
        [MaximumSpeed]: 33,
      },
    },
    victoria: {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
    },
    "simpson-a": {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
    },
    "simpson-b": {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
    },
    agincourt: {
      [Textured]: {
        [MaximumQuality]: 6,
        [Balanced]: 10,
        [MaximumSpeed]: 14,
      },
      [Shaded]: {
        [MaximumQuality]: 4,
        [Balanced]: 8,
        [MaximumSpeed]: 12,
      },
    },
    talison: {
      [Textured]: {
        [MaximumQuality]: 12,
        [Balanced]: 16,
        [MaximumSpeed]: 28,
      },
      [Shaded]: {
        [MaximumQuality]: 13,
        [Balanced]: 19.5,
        [MaximumSpeed]: 29,
      },
      [PointCloud]: {
        [MaximumQuality]: 50,
        [Balanced]: 100,
        [MaximumSpeed]: 200,
      },
      [CAD]: {
        [MaximumQuality]: 10,
        [Balanced]: 17,
        [MaximumSpeed]: 33,
      },
    },
    "western-power": {
      [Textured]: {
        [MaximumQuality]: 12,
        [Balanced]: 16,
        [MaximumSpeed]: 28,
      },
      [Shaded]: {
        [MaximumQuality]: 13,
        [Balanced]: 19.5,
        [MaximumSpeed]: 29,
      },
      [PointCloud]: {
        [MaximumQuality]: 50,
        [Balanced]: 100,
        [MaximumSpeed]: 200,
      },
      [CAD]: {
        [MaximumQuality]: 10,
        [Balanced]: 17,
        [MaximumSpeed]: 33,
      },
    },
    snapper: {
      [Textured]: {
        [MaximumQuality]: 12,
        [Balanced]: 14,
        [MaximumSpeed]: 16,
      },
      [Shaded]: {
        [MaximumQuality]: 12,
        [Balanced]: 14,
        [MaximumSpeed]: 16,
      },
      [PointCloud]: {
        [MaximumQuality]: 50,
        [Balanced]: 100,
        [MaximumSpeed]: 200,
      },
    },
  }

  return qualitySettings[siteSlug][modelType][modelQuality]
}

export function getCesiumSettings(
  modelTypeId: number,
  filepath: string,
  siteSlug: string,
  modelType: ModelType,
  modelQuality: ModelQuality
): Settings {
  let dynamicScreenSpaceError = true

  if (modelTypeId === 1) dynamicScreenSpaceError = false

  let maximumMemoryUsage = DEFAULT_MAXIMUM_MEMORY_USAGE
  let maximumScreenSpaceError = siteIsLegacy(siteSlug, siteList())
    ? LegacySse[modelType][modelQuality]
    : DefaultSse[modelType][modelQuality]

  if (customSiteList().indexOf(siteSlug) !== -1) {
    maximumScreenSpaceError = retrieveQualitySettings(siteSlug, modelType, modelQuality)
    maximumMemoryUsage = retrieveMemorySettings(siteSlug, modelQuality)
    return retrieveSettings(
      siteSlug,
      filepath,
      maximumScreenSpaceError,
      maximumMemoryUsage,
      apiHostname,
      dynamicScreenSpaceError
    )
  }

  return retrieveSettings(
    "default",
    filepath,
    maximumScreenSpaceError,
    maximumMemoryUsage,
    apiHostname,
    dynamicScreenSpaceError
  )
}

export const LegacySse = {
  [Textured]: {
    [MaximumQuality]: 90,
    [Balanced]: 150,
    [MaximumSpeed]: 220,
  },
  [Shaded]: {
    [MaximumQuality]: 70,
    [Balanced]: 100,
    [MaximumSpeed]: 150,
  },
  [PointCloud]: {
    [MaximumQuality]: 90,
    [Balanced]: 150,
    [MaximumSpeed]: 220,
  },
  [CAD]: {
    [MaximumQuality]: 10,
    [Balanced]: 17,
    [MaximumSpeed]: 33,
  },
}

export const DefaultSse = {
  [Textured]: {
    [MaximumQuality]: 15,
    [Balanced]: 22,
    [MaximumSpeed]: 35,
  },
  [Shaded]: {
    [MaximumQuality]: 13,
    [Balanced]: 19.5,
    [MaximumSpeed]: 29,
  },
  [PointCloud]: {
    [MaximumQuality]: 50,
    [Balanced]: 100,
    [MaximumSpeed]: 200,
  },
  [CAD]: {
    [MaximumQuality]: 10,
    [Balanced]: 17,
    [MaximumSpeed]: 33,
  },
}

export function SetPointSize(modelQuality: ModelQuality) {
  if (modelQuality === ModelQuality.MaximumQuality) return 2.4
  if (modelQuality === ModelQuality.MaximumSpeed) return 3.2
  return 2.7
}
