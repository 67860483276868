/* tslint:disable:jsx-no-lambda */
import * as React from "react"
import { Fragment, useCallback, useContext, useState } from "react"
import { NearbyImageHeightMode, NearbyImagesContext } from "../../context/NearbyImages"
import { ButtonGroup, Checkbox } from "@mui/material"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { TooltipConfigs } from "../../constants"

const DropdownEntry = (props: any) => {
  const { isSelected, label } = props
  const onClick = () => {
    props.onChange(!isSelected)
  }

  return (
    <span className="entry" onClick={onClick}>
      <input type="checkbox" checked={isSelected} />
      {label}
    </span>
  )
}

const GetLabel = (nearbyImageFilter: NearbyImageHeightMode[]) => {
  const airborneEnabled = nearbyImageFilter.includes(NearbyImageHeightMode.Airborne)
  const groundEnabled = nearbyImageFilter.includes(NearbyImageHeightMode.Ground)
  const panosphereEnabled = nearbyImageFilter.includes(NearbyImageHeightMode.Panosphere)

  const labels = []

  if (airborneEnabled) {
    labels.push("Aerial")
  }

  if (groundEnabled) {
    labels.push("Ground")
  }

  if (panosphereEnabled) {
    labels.push("360°")
  }

  if (labels.length === 0) {
    return "None"
  }

  return labels.join("/")
}

function toggle(value) {
  return !value
}

const photoTypeStyle = {
  color: "white",
  "&.Mui-checked": { color: "white" },
}

export const PhotoTypesSelector = (props: any) => {
  const { nearbyImageFilter, addNearbyImageFilter, removeNearbyImageFilter } = useContext(
    NearbyImagesContext
  )
  const [isOpen, setIsOpen] = useState(false)

  const updateFilter = useCallback(
    (state: boolean, key: NearbyImageHeightMode) => {
      if (!state) {
        addNearbyImageFilter(key)
      } else {
        removeNearbyImageFilter(key)
      }
    },
    [addNearbyImageFilter, removeNearbyImageFilter]
  )

  return (
    <Fragment>
      <div
        className={"nearby-phototype-selector"}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <div className={"phototype-dropdown-menu"}>
          <ButtonGroup variant="text" orientation="vertical">
            <Tooltip
              title={<Typography fontSize={TooltipConfigs.FontSize}>Aerial Images</Typography>}
              placement="left"
              enterDelay={TooltipConfigs.Delay}
              enterNextDelay={TooltipConfigs.Delay}
            >
              <span>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ ...photoTypeStyle }}
                      checked={nearbyImageFilter.includes(NearbyImageHeightMode.Airborne)}
                      onChange={() =>
                        updateFilter(
                          nearbyImageFilter.includes(NearbyImageHeightMode.Airborne),
                          NearbyImageHeightMode.Airborne
                        )
                      }
                    />
                  }
                  label={"Aerial"}
                />
              </span>
            </Tooltip>
            <Tooltip
              title={<Typography fontSize={TooltipConfigs.FontSize}>Ground Images</Typography>}
              placement="left"
              enterDelay={TooltipConfigs.Delay}
              enterNextDelay={TooltipConfigs.Delay}
            >
              <span>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ ...photoTypeStyle }}
                      checked={nearbyImageFilter.includes(NearbyImageHeightMode.Ground)}
                      onChange={() =>
                        updateFilter(
                          nearbyImageFilter.includes(NearbyImageHeightMode.Ground),
                          NearbyImageHeightMode.Ground
                        )
                      }
                    />
                  }
                  label={"Ground"}
                />
              </span>
            </Tooltip>
            <Tooltip
              title={<Typography fontSize={TooltipConfigs.FontSize}>Panosphere Images</Typography>}
              placement="left"
              enterDelay={TooltipConfigs.Delay}
              enterNextDelay={TooltipConfigs.Delay}
            >
              <span>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ ...photoTypeStyle }}
                      checked={nearbyImageFilter.includes(NearbyImageHeightMode.Panosphere)}
                      onChange={() =>
                        updateFilter(
                          nearbyImageFilter.includes(NearbyImageHeightMode.Panosphere),
                          NearbyImageHeightMode.Panosphere
                        )
                      }
                    />
                  }
                  label={"360°"}
                />
              </span>
            </Tooltip>
          </ButtonGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ color: "white" }}
                  checked={nearbyImageFilter.includes(NearbyImageHeightMode.Airborne)}
                  onChange={() =>
                    updateFilter(
                      nearbyImageFilter.includes(NearbyImageHeightMode.Airborne),
                      NearbyImageHeightMode.Airborne
                    )
                  }
                />
              }
              label="Aerial"
            />

            <FormControlLabel
              control={
                <Checkbox
                  sx={{ color: "white" }}
                  checked={nearbyImageFilter.includes(NearbyImageHeightMode.Ground)}
                  onChange={() =>
                    updateFilter(
                      nearbyImageFilter.includes(NearbyImageHeightMode.Ground),
                      NearbyImageHeightMode.Ground
                    )
                  }
                />
              }
              label="Ground"
            />

            <FormControlLabel
              control={
                <Checkbox
                  sx={{ color: "white" }}
                  checked={nearbyImageFilter.includes(NearbyImageHeightMode.Panosphere)}
                  onChange={() =>
                    updateFilter(
                      nearbyImageFilter.includes(NearbyImageHeightMode.Panosphere),
                      NearbyImageHeightMode.Panosphere
                    )
                  }
                />
              }
              label="360°"
            />
          </FormGroup>
        </div>
      </div>
    </Fragment>
  )
}
