export function radiansToDegrees(radians: number): number {
  const pi = Math.PI
  return radians * (180 / pi)
}

export function degreesToRadians(degrees: number): number {
  return degrees * (Math.PI / 180)
}

export function piOverTwo(): number {
  return Math.PI / 2
}

export function getClampNumber(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}
