import { useState, useEffect, useCallback } from "react"

export function useKeyPress(targetKey: string) {
  const [keyPressed, setKeyPressed] = useState(false)

  const downHandler = useCallback(
    (e: any) => {
      if (e.key === targetKey) {
        setKeyPressed(true)
      }
    },
    [targetKey]
  )

  const upHandler = useCallback(
    (e: any) => {
      if (e.key === targetKey) {
        setKeyPressed(false)
      }
    },
    [targetKey]
  )

  useEffect(() => {
    window.addEventListener("keydown", downHandler)
    window.addEventListener("keyup", upHandler)

    return () => {
      window.removeEventListener("keydown", downHandler)
      window.removeEventListener("keyup", upHandler)
    }
  }, [downHandler, upHandler])

  return keyPressed
}

export function onKeyPress(targetKey: string, handler: (e: KeyboardEvent) => void) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const downHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === targetKey) {
        handler(e)
      }
    },
    [handler, targetKey]
  )

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const upHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === targetKey) {
        handler(e)
      }
    },
    [handler, targetKey]
  )

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    window.addEventListener("keydown", downHandler)
    window.addEventListener("keyup", upHandler)

    return () => {
      window.removeEventListener("keydown", downHandler)
      window.removeEventListener("keyup", upHandler)
    }
  }, [upHandler, downHandler])
}
