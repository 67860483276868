/* tslint:disable:jsx-no-lambda */
import { useState } from "react"
// import {Button} from "trunx"
import { Button } from "@mui/material"

function delay(t: number) {
  return new Promise(res => setTimeout(res, t))
}

function setAllModalVisibility(visibility: boolean) {
  const modals = document.getElementsByClassName("modal")
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < modals.length; i++) {
    // @ts-ignore
    modals[i].style.display = visibility ? "block" : "none"
  }
}

export const ScreenshotTool = (props: any) => {
  const [currentScreenshot, setScreenshot] = useState("")

  const takeScreenshot = async () => {
    // @ts-ignore
    const stream = await navigator.mediaDevices.getDisplayMedia({ video: true })
    setAllModalVisibility(false)

    const video = document.createElement("video")
    video.srcObject = stream
    await video.play()
    await delay(500)
    const canvas = document.createElement("canvas")
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight

    const context = canvas.getContext("2d")
    context!.drawImage(video, 0, 0, canvas.width, canvas.height)
    const url = canvas.toDataURL("image/png")

    stream.getTracks().forEach((track: any) => track.stop())

    setAllModalVisibility(true)
    setScreenshot(url)
    props.onChange(url)
  }

  if (currentScreenshot) {
    return (
      <div className="screenshot-preview">
        <button
          className="delete"
          aria-label="close"
          onClick={() => {
            setScreenshot("")
            props.onChange(null)
          }}
        />
        <img src={currentScreenshot} alt="" />
        <input type="hidden" value={currentScreenshot} />
      </div>
    )
  } else {
    return (
      <Button
        variant={"outlined"}
        sx={{ borderColor: "white", color: "white" }}
        onClick={() => takeScreenshot()}
      >
        Take Screenshot
      </Button>
    )
  }
}
