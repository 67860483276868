/* tslint:disable:jsx-no-lambda */
import * as React from "react"
import { Link, Redirect } from "react-router-dom"
import Logo from "../../assets/img/logo.svg"

import SVG from "react-inlinesvg"
import { FontAwesomeIcon } from "../../helpers/icon"
import { Button } from "trunx"

interface IProps {
  children?: React.ReactNode
  render?: () => React.ReactNode
  className?: string
}

interface IState {
  active: boolean
  panelActive: boolean
  redirect: boolean
}

class Sidebar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      active: false,
      panelActive: false,
      redirect: false,
    }
  }

  componentDidMount() {
    window.setTimeout(() => this.setState({ active: true, panelActive: !!this.props.render }), 10)
  }

  // when user clicks outside form, on sidebar-panel, go back to App (Redirect to "/")
  // sidebar renders settingsPane by calling props.render()
  render() {
    const { render, children } = this.props
    if (this.state.redirect) {
      return <Redirect to={"/"} />
    }
    return (
      <div
        className={
          "sidebar" +
          (this.state.panelActive ? " panel-active" : "") +
          (this.state.active ? " active" : "") +
          (this.props.className ? " " + this.props.className : "")
        }
      >
        <div className={"sidebar-inner" + (render ? " active" : "")}>
          <div className={"sidebar-header"}>
            <div className={"logo"}>
              <SVG src={Logo} />
            </div>
            <div className={"collapse-sidebar"}>
              <Link to={"/"}>
                <Button isText={true}>
                  <FontAwesomeIcon icon={"chevron-double-left"} />
                </Button>
              </Link>
            </div>
          </div>
          <div className={"sidebar-content"}>{children}</div>
        </div>
        {render && (
          <div
            className="sidebar-panel"
            onClick={(e: any) => {
              if (e.currentTarget === e.target) {
                this.setState({ active: false, panelActive: false }, () => {
                  window.setTimeout(() => {
                    this.setState({ redirect: true })
                  }, 330)
                })
              }
            }}
          >
            {render()}
          </div>
        )}
      </div>
    )
  }
}

export { Sidebar }
