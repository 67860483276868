import { useContext, useCallback } from "react"
import { FontAwesomeIcon } from "../../helpers/icon"
import { Button } from "trunx"
import { ModelsContext } from "../../context"
import { useConfirmationModal } from "../../hooks/useConfirmationModal"
import { useTakeScreenshot } from "../../hooks/useTakeScreenshot"

const TakeSnapshot = (props: any) => {
  const { selectedSiteId } = useContext(ModelsContext)
  const { getTextConfirmation } = useConfirmationModal()
  const { takeScreenshot } = useTakeScreenshot()

  const handleCreatePlace = useCallback(
    async (e: React.FormEvent) => {
      e.stopPropagation()

      if (selectedSiteId === 0) {
        return
      }
      const name = await getTextConfirmation("Create a new Place", "", {
        buttonText: "Save",
        fieldLabel: "Please give your place a name.",
      })

      if (name) {
        const { payload, blob } = await takeScreenshot(name)
        await props.createSnapshot(payload.siteId, payload, blob)
      }
    },
    [getTextConfirmation, props, selectedSiteId, takeScreenshot]
  )

  return (
    <span title={"Add New Place"}>
      <Button isText={true} onClick={handleCreatePlace}>
        <FontAwesomeIcon icon={"bookmark"} size={"lg"} />
      </Button>
    </span>
  )
}

export { TakeSnapshot }
