import { createContext, useEffect } from "react"
import { IPermissionsMap, IUser } from "../interfaces"
import { useCurrentUser, UseAcceptDisclaimer } from "../api/ApiHooks"

export const PermissionContext = createContext({
  loading: false,
  permissions: [] as IPermissionsMap,
  currentUser: {} as IUser,
  acceptDisclaimer: true,
})

export const PermissionContextProvider = (props: any) => {
  const {
    currentUserPermissions,
    currentUser,
    currentUserPermissionsLoading,
    acceptDisclaimer,
  } = useCurrentUser()
  // const { acceptDisclaimer } = UseAcceptDisclaimer()

  // useEffect(() => {
  // }, [currentUser, acceptDisclaimer])

  return (
    <PermissionContext.Provider
      value={{
        permissions: currentUserPermissions,
        loading: currentUserPermissionsLoading,
        currentUser,
        acceptDisclaimer,
      }}
    >
      {props.children}
    </PermissionContext.Provider>
  )
}
