import * as React from "react"
import { useState } from "react"
import { Content } from "trunx"
import { Link, Redirect } from "react-router-dom"
import { toast } from "react-toastify"
import axios from "axios"
import {
  Button,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { apiHostname, apiUsersPasswordreset } from "../../api/constants"
import { ValidateEmail } from "../../helpers/securityHelpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye } from "@fortawesome/free-solid-svg-icons"
import { styled } from "@mui/material/styles"

const eye = <FontAwesomeIcon icon={faEye} />

export async function ResetPasswordCall(
  email: string,
  password: string,
  passwordResetToken: string
) {
  const url = apiHostname + "/api" + apiUsersPasswordreset
  axios.defaults.withCredentials = true
  const response = await axios({
    method: "POST",
    url,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    data: {
      email,
      password,
      passwordResetToken,
    },
  })
    .then(response => {
      toast.success("Your password has been reset, you may now log in.")
      return response
    })
    .catch(error => {
      console.log(error)
      return error.response
    })
  return response
}

export function getTokenFromURL(url: string) {
  const strings = url.split("/")
  return strings[3]
}

export const PasswordReset = (props: any) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [modalState, setModalState] = useState("")
  const [passwordError, setPasswordError] = useState(false)

  const textFieldStyle = {
    width: 365,
    color: "white",
    borderColour: "white",
    mr: 2,
    mb: 1,
    "& .MuiInputLabel-root": { color: "grey" },
    "& .MuiOutlinedInput-input": { color: "black" },
    "& label.Mui-focused": { color: "white" },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "orange",
      },
      "&.Mui-focused fieldset": {
        borderColor: "orange",
      },
    },
  }
  const errorTextFieldStyle = {
    width: 365,
    color: "white",
    borderColour: "white",
    mr: 2,
    mb: 1,
    "& .MuiOutlinedInput-input": { color: "black" },
  }

  const styles = {
    boxTransparent: {
      "background-color": "# fff",
      "border-radius": "6px",
      border: "1px solid",
      "border-color": "#CCCCCC",
      "padding-bottom": "20px",
      marginLeft: "auto",
      marginRight: "auto",
      width: 433,
    },
    boxWhite: {
      color: "#000000",
      "background-color": "#ffff",
      "border-radius": "6px",
      border: "2px solid",
      "border-color": "#CCCCCC",
      "padding-top": "20px",
      "padding-bottom": "20px",
      width: 400,
    },
    mainBackground: {
      background: "linear-gradient(135deg, #f48461 0%, #fbbb5b 50%, #f48461 100%)",
      height: "100vh",
    },
    logo: { marginLeft: "35px" },
  }

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const [passwordShown, setPasswordShown] = useState(false)
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }

  const StyledButton = styled(Button)(() => ({
    color: "gray",
    backgroundColor: "white",
    borderColor: "gray",
    "&:hover": {
      color: "white",
      backgroundColor: "Orange",
      boxShadow: "none",
      borderColor: "gray",
    },
    height: 30,
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
  }))

  function EnterPressed(e) {
    if (e.key === "Enter") {
      onClick()
    }
  }

  const onClick = async () => {
    if (password === "") {
      setPasswordError(true)
    }
    if (!ValidateEmail(email) || passwordError === true) {
      setModalState("error")
      return
    }
    setModalState("loading")
    const response = await ResetPasswordCall(
      email,
      password,
      getTokenFromURL(document.location.pathname)
    )
    if (response) setModalState("success")
    else setModalState("failed")
  }

  const EmailTextField = (stateString: string) => {
    return (
      <span>
        {stateString === "error" ? (
          <TextField
            onKeyPress={EnterPressed}
            error={true}
            id="outlined-error-helper-text"
            variant={"outlined"}
            sx={{ ...errorTextFieldStyle }}
            type={"email"}
            placeholder="Current Email Address"
            onChange={onChangeEmail}
            helperText={"Please enter a valid e-mail address"}
          />
        ) : (
          <TextField
            onKeyPress={EnterPressed}
            autoFocus={true}
            variant={"outlined"}
            sx={{ ...textFieldStyle }}
            type={"email"}
            placeholder="Enter Current Email Address"
            onChange={onChangeEmail}
          />
        )}
      </span>
    )
  }

  const PasswordTextField = (isError: boolean) => {
    return (
      <span>
        {isError === true ? (
          <TextField
            onKeyPress={EnterPressed}
            error={true}
            id="outlined-error-helper-text"
            variant={"outlined"}
            sx={{ ...errorTextFieldStyle }}
            type={passwordShown ? "text" : "password"}
            placeholder="Enter New Password"
            onChange={onChangePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment variant="filled" position="end">
                  <IconButton onClick={togglePasswordVisibility}>{eye}</IconButton>
                </InputAdornment>
              ),
            }}
            helperText={"Password is required"}
          />
        ) : (
          <TextField
            onKeyPress={EnterPressed}
            variant={"outlined"}
            sx={{ ...textFieldStyle }}
            type={passwordShown ? "text" : "password"}
            placeholder="Enter New Password"
            onChange={onChangePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment variant="filled" position="end">
                  <IconButton onClick={togglePasswordVisibility}>{eye}</IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </span>
    )
  }

  return modalState === "failed" ? (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card" style={{ width: "500px" }}>
        <header className="modal-card-head" style={{ backgroundColor: "whitesmoke" }}>
          <p
            className="modal-card-title"
            style={{ color: "black", fontSize: 30 }}
            data-testid={"forgotPasswordFailed"}
          >
            System Error
          </p>
          <Link to={"/"}>
            <IconButton
              aria-label={"close"}
              sx={{
                color: "black",
                ml: 30,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Link>
        </header>
        <section className="modal-card-body" style={{ backgroundColor: "white" }}>
          <Content>
            <div className="columns is-centered">
              <span style={{ marginTop: "10px", color: "black", fontSize: 20 }}>
                There was an error completing your request. Please contact Airscope for more
                information.
              </span>
            </div>
            <div
              className="columns is-centered"
              style={{ marginTop: "14px", marginLeft: "95px" }}
            />
          </Content>
        </section>
        <footer
          style={{ backgroundColor: "white", borderTopStyle: "none" }}
          className="modal-card-foot"
        />
      </div>
    </div>
  ) : modalState === "success" ? (
    <Redirect to={"/"} />
  ) : (
    <Grid
      container={true}
      spacing={0}
      alignItems="center"
      wrap="nowrap"
      style={{ ...styles.mainBackground }}
    >
      <Grid item={true} xs={true} />
      <Grid item={true} xs={4}>
        <Paper style={{ ...styles.boxTransparent }}>
          <a href="https://www.airscope.com">
            <div style={{ ...styles.logo }}>
              <span className="logo logo-main" />
            </div>
          </a>
          <Grid container={true} spacing={2} alignItems="center" wrap="nowrap">
            <Grid item={true} xs={true} />
            <Grid item={true} xs={12}>
              <Paper style={{ ...styles.boxWhite }} elevation={1}>
                <Grid container={true} spacing={2} alignItems="center" wrap="nowrap">
                  <Grid item={true} xs={true} />
                  <Grid item={true} xs={12}>
                    <form onSubmit={onClick}>
                      <Grid container={true} spacing={2}>
                        <Grid item={true} xs={12}>
                          <FormGroup>
                            <FormLabel>Email Address</FormLabel>
                            {EmailTextField(modalState)}
                          </FormGroup>
                        </Grid>
                        <Grid item={true} xs={12}>
                          <FormGroup>
                            <FormLabel htmlFor="password">Password</FormLabel>
                            {PasswordTextField(passwordError)}
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <Grid container={true} direction="row">
                        <Grid item={true}>
                          <StyledButton
                            variant="outlined"
                            onClick={onClick}
                            data-tip={"Reset your password"}
                            data-testid={"resetPasswordButton"}
                          >
                            Reset Password
                          </StyledButton>
                        </Grid>
                      </Grid>
                      <div style={{ clear: "both" }} />
                    </form>
                  </Grid>
                  <Grid item={true} xs={true} />
                </Grid>
              </Paper>
            </Grid>
            <Grid item={true} xs={true} />
          </Grid>
        </Paper>
      </Grid>
      <Grid item={true} xs={true} />
    </Grid>
  )
}
