/* tslint:disable:jsx-no-lambda */
import { Fragment, useState } from "react"
import { Box } from "trunx"
import { Link } from "react-router-dom"
import { ITeam } from "../../../interfaces"
import { useApiTeams } from "../../../api/ApiHooks"
import { Loading } from "../../Loading"
import { RenderIfPermission } from "../../../helpers/Permissions"
import { StandardDate } from "../../StandardDate"
import { styled } from "@mui/material/styles"
import { Button } from "@mui/material"
import { orange } from "@mui/material/colors"

const AddButton = styled(Button)(({ theme }) => ({
  color: "White",
  backgroundColor: "Orange",
  "&:hover": {
    backgroundColor: orange[600],
    boxShadow: "none",
  },
  height: 40,
}))

interface ITeamRowProps {
  team: ITeam
  onDelete: () => void
}

const TeamRow = (props: ITeamRowProps) => {
  const [expanded, setExpanded] = useState(false)
  const { team, onDelete } = props

  return (
    <div>
      <div
        className={"list-row" + (expanded ? " list-row-selected" : "")}
        onClick={() => setExpanded(!expanded)}
      >
        <div className={"roles-list-item displayname"}>{team.displayName}</div>
        <div className={"roles-list-item displayname"}>{team.description}</div>
        <div className={"roles-list-item count"}>{team.membershipCount}</div>
        <div className={"roles-list-item created"}>
          <StandardDate date={team.created} />
        </div>
      </div>
      {expanded && (
        <div className={"list-row-controls-panel"}>
          <div className={"controls-container"}>
            <div style={{ marginRight: "10px" }}>Actions:</div>
            <RenderIfPermission token={"TEAMS:ARCHIVE"}>
              <div className={"action"} onClick={onDelete}>
                Delete
              </div>
            </RenderIfPermission>

            <RenderIfPermission token={"TEAMS:UPDATE"}>
              <div className={"action"}>
                <Link to={`/settings/security/teams/${team.id}/edit`}>Edit</Link>
              </div>
            </RenderIfPermission>
          </div>
        </div>
      )}
    </div>
  )
}

export const TeamsManagement = () => {
  const { teams, teamsLoading, removeTeam } = useApiTeams()

  return (
    <Box className={"teams-management"}>
      <h4 className={"is-4 title"}>Teams</h4>
      {teamsLoading && <Loading />}
      {!teamsLoading && (
        <Fragment>
          <RenderIfPermission token={"TEAMS:CREATE"}>
            <Link to={`/settings/security/teams/new`}>
              <AddButton style={{ marginBottom: "40px" }}>Add New Team</AddButton>
            </Link>
          </RenderIfPermission>

          <div className={"list-container"}>
            <div className={"list-row header"}>
              <div className={"roles-list-item header"}>Team Name</div>
              <div className={"roles-list-item header"}>Description</div>
              <div className={"roles-list-item header"}>Number of Users</div>
              <div className={"roles-list-item header"}>Created</div>
            </div>
            {teams?.length === 0 && (
              <div className={"list-empty-warning"}>There are no teams setup yet</div>
            )}
            {teams?.map((team: ITeam) => (
              <TeamRow key={team.id} team={team} onDelete={() => removeTeam(team.id)} />
            ))}
          </div>
        </Fragment>
      )}
    </Box>
  )
}
