/* tslint:disable:jsx-no-lambda */
import { useCallback } from "react"
import { usePolygonDraw } from "../../hooks/usePolygonDraw"
import { Button, Field } from "trunx"
import { FormControlLabel, Slider, Switch } from "@mui/material"

import { FontAwesomeIcon } from "../../helpers/icon"
import { IMOCPoint, IPolygon } from "../../interfaces"

interface IProps {
  title: string
  areaColor: string
  onClose: () => void
  onDone: (polygon: IPolygon) => void
}

export const CreateArea = (props: IProps) => {
  const { title, onClose, onDone, areaColor } = props

  const { points, clearPoints, capped, setCapped, height, setHeight } = usePolygonDraw(5, areaColor)
  const handleChange = (event, newValue) => {
    setHeight(newValue)
  }
  const doneCallback = useCallback(() => {
    if (points.length < 3) {
      return
    }

    const bottomHeight = points.reduce(
      (acc, point) => (acc < point.position.altitude ? acc : point.position.altitude),
      9000
    )
    const mocPoints: IMOCPoint[] = points.map(point => {
      return {
        id: point.id,
        name: point.name,
        position: point.position,
        x: point.position.cartesian3.x,
        y: point.position.cartesian3.y,
        z: point.position.cartesian3.z,
      }
    })
    const polygon: IPolygon = {
      archived: false,
      bottomHeight,
      capped,
      createdAt: new Date(),
      name: title,
      points: mocPoints,
      topHeight: bottomHeight + height,
      type: "polygon",
    }

    onDone(polygon)
  }, [points, capped, title, height, onDone])

  return (
    <div>
      <header className="card-header">
        <p className="card-header-title">
          Draw Area: {title}
          <Button isText={true} onClick={onClose}>
            <span className="icon">
              <FontAwesomeIcon icon={"times"} />
            </span>
          </Button>
        </p>
      </header>
      <div className="card-content">
        <div className="content">
          {points.length === 0 && <p>Click on the model to start drawing</p>}
          <FormControlLabel
            control={<Switch name={"capped"} onChange={() => setCapped(!capped)} value={capped} />}
            label={"Capped"}
          />
          <Field isLarge={true} style={{ textAlign: "left" }}>
            <Field.Label>{"Area height: " + height + "m"}</Field.Label>
            <Field.Body>
              <Slider
                name={"radius-search"}
                max={20}
                min={-20}
                step={1}
                style={{ width: "100%" }}
                value={height}
                onChange={handleChange}
              />
            </Field.Body>
          </Field>
        </div>
      </div>
      <footer className="card-footer">
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={clearPoints} isActive={points.length !== 0}>
          Clear
        </Button>
        <Button onClick={doneCallback} isActive={!(points.length < 3)}>
          Done
        </Button>
      </footer>
    </div>
  )
}
