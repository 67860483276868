/* tslint:disable:jsx-no-lambda */
import { useState } from "react"
import { withRouter } from "react-router"
import { Box } from "trunx"
import { toast } from "react-toastify"
import { styled } from "@mui/material/styles"
import { useApiRoles, useApiTeams, useApiUser } from "../../../api/ApiHooks"
import { Loading } from "../../Loading"
import { DualListBox, IListBoxItem, roleListTransform, teamListTransform } from "../DualListBox"
import { Button, TextField } from "@mui/material"
import { grey, orange } from "@mui/material/colors"
import { ValidateEmail } from "../../../helpers/securityHelpers"

const BLANK_ERRORS = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
}

const textFieldStyle = {
  color: "white",
  borderColour: "white",
  mt: 2,
  mr: 2,
  mb: 2,
  "& .MuiInputLabel-root": { color: "white" },
  "& .MuiOutlinedInput-input": { color: "white" },
  "& label.Mui-focused": { color: "white" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "orange",
    },
    "&.Mui-focused fieldset": {
      borderColor: "orange",
    },
  },
}
const errorTextFieldStyle = {
  color: "white",
  borderColour: "white",
  mt: 2,
  mr: 2,
  mb: 2,
  "& .MuiOutlinedInput-input": { color: "white" },
}

const SaveButton = styled(Button)(({ theme }) => ({
  color: "White",
  backgroundColor: "Orange",
  "&:hover": {
    backgroundColor: orange[600],
    boxShadow: "none",
  },
  height: 40,
  marginTop: 30,
  marginRight: 10,
}))

const CancelButton = styled(Button)(({ theme }) => ({
  color: "White",
  backgroundColor: "#2b2b2b",
  "&:hover": {
    backgroundColor: grey[900],
  },
  height: 40,
  marginTop: 30,
}))

export const CreateEditUser = withRouter((props: any) => {
  const isEditMode = "id" in props.match.params

  const [firstNameErrorOpen, setFirstNameErrorOpen] = useState(false)
  const [lastNameErrorOpen, setLastNameErrorOpen] = useState(false)
  const [emailErrorOpen, setEmailErrorOpen] = useState(false)
  const [passErrorOpen, setPassErrorOpen] = useState(false)
  const [passConfirmErrorOpen, setPassConfirmErrorOpen] = useState(false)

  const { user, setUser, saveUser, userLoading } = useApiUser(props.match.params.id)
  const { roles, rolesLoading } = useApiRoles()
  const { teams, teamsLoading } = useApiTeams()

  const [errors, setErrors] = useState(BLANK_ERRORS)

  const [values] = useState({
    password: "",
    showPassword: false,
  })

  if (userLoading || rolesLoading || teamsLoading) {
    return (
      <Box className={"userss-management"}>
        <div className={"edit-user-form"}>
          <h4>{isEditMode ? "Edit" : "Create"} User</h4>
          <Loading />
        </div>
      </Box>
    )
  }

  const performSaveUser = async () => {
    const saveErrors = {}

    if (user.firstName.length === 0) {
      saveErrors["firstName"] = "Please enter a given name"
      setFirstNameErrorOpen(true)
    }

    if (user.lastName.length === 0) {
      saveErrors["lastName"] = "Please enter a last name"
      setLastNameErrorOpen(true)
    }

    if (user.email.length === 0) {
      saveErrors["email"] = "Please enter an e-mail address"
      setEmailErrorOpen(true)
    } else if (!ValidateEmail(user.email)) {
      saveErrors["email"] = "Please enter a valid e-mail address"
      setEmailErrorOpen(true)
    }

    if (!isEditMode) {
      if (user.password.length === 0) {
        setPassErrorOpen(true)
        saveErrors["password"] = "Please enter a password"
      } else if (user.password !== user.passwordConfirmation) {
        saveErrors["password"] = "Password must match confirmation"
        setPassConfirmErrorOpen(true)
      } else if (user.password.length < 6) {
        saveErrors["password"] = "Password must be atleast 6 characters long"
        setPassErrorOpen(true)
      }
    } else if (user.password.length > 0 && user.password !== user.passwordConfirmation) {
      saveErrors["password"] = "Password must match confirmation"
      setPassConfirmErrorOpen(true)
    }

    if (Object.keys(saveErrors).length > 0) {
      setErrors(Object.assign({}, BLANK_ERRORS, saveErrors))
      return
    }

    const saveSuccess = await saveUser()
    if (!saveSuccess) {
      toast.error("There was a problem saving this user")
    } else {
      toast.success(`User has been ${isEditMode ? "updated" : "created"}`)
      props.history.push("/settings/security/users")
    }
  }

  const updateRoleList = (selectedItems: Array<IListBoxItem>) => {
    const roleList = roles.filter(role => selectedItems.find(item => item.id === role.id))

    setUser(Object.assign({}, user, { roleList }))
  }

  const updateTeamList = (selectedItems: Array<IListBoxItem>) => {
    const teamList = teams.filter(team => selectedItems.find(item => item.id === team.id))

    setUser(Object.assign({}, user, { teamList }))
  }

  return (
    <Box className={"users-management"}>
      <div className={"edit-role-form"}>
        <h4>{isEditMode ? "Edit" : "Create"} User</h4>

        <div className={"edit-role-form-general"}>
          {firstNameErrorOpen ? (
            <TextField
              error={true}
              id="outlined-error-helper-text"
              label="Error"
              variant={"outlined"}
              sx={{ ...errorTextFieldStyle }}
              value={user.firstName}
              defaultValue={user.firstName}
              onChange={e => setUser(Object.assign({}, user, { firstName: e.currentTarget.value }))}
              helperText="Given name cannot be empty."
            />
          ) : (
            <TextField
              aria-label={"givenName"}
              inputProps={{ "data-testid": "givenName" }}
              label={"Given Name"}
              variant={"outlined"}
              sx={{ ...textFieldStyle }}
              value={user.firstName}
              defaultValue={"Given Name"}
              onChange={e => setUser(Object.assign({}, user, { firstName: e.currentTarget.value }))}
            />
          )}
          {lastNameErrorOpen ? (
            <TextField
              error={true}
              id="outlined-error-helper-text"
              label="Error"
              variant={"outlined"}
              sx={{ ...errorTextFieldStyle }}
              value={user.lastName}
              onChange={e => setUser(Object.assign({}, user, { lastName: e.currentTarget.value }))}
              helperText="Last name cannot be empty."
            />
          ) : (
            <TextField
              label={"Last Name"}
              variant={"outlined"}
              sx={{ ...textFieldStyle }}
              value={user.lastName}
              onChange={e => setUser(Object.assign({}, user, { lastName: e.currentTarget.value }))}
            />
          )}

          {emailErrorOpen ? (
            <TextField
              error={true}
              id="outlined-error-helper-text"
              label="Error"
              variant={"outlined"}
              sx={{ ...errorTextFieldStyle }}
              type={"email"}
              value={user.email}
              onChange={e => setUser(Object.assign({}, user, { email: e.currentTarget.value }))}
              helperText={errors.email}
            />
          ) : (
            <TextField
              label={"Email"}
              variant={"outlined"}
              sx={{ ...textFieldStyle }}
              type={"email"}
              value={user.email}
              onChange={e => setUser(Object.assign({}, user, { email: e.currentTarget.value }))}
            />
          )}

          {passErrorOpen ? (
            <TextField
              error={true}
              id="outlined-error-helper-text"
              label="Error"
              variant={"outlined"}
              sx={{ ...errorTextFieldStyle }}
              type={"password"}
              value={user.password}
              onChange={e => setUser(Object.assign({}, user, { password: e.currentTarget.value }))}
              helperText={errors.password}
            />
          ) : (
            <TextField
              label={"Password"}
              variant={"outlined"}
              sx={{ ...textFieldStyle }}
              type={"password"}
              value={user.password}
              onChange={e => setUser(Object.assign({}, user, { password: e.currentTarget.value }))}
            />
          )}

          {passConfirmErrorOpen ? (
            <TextField
              error={true}
              id="outlined-error-helper-text"
              label="Error"
              variant={"outlined"}
              sx={{ ...errorTextFieldStyle }}
              type={"password"}
              value={user.passwordConfirmation}
              onChange={e =>
                setUser(Object.assign({}, user, { passwordConfirmation: e.currentTarget.value }))
              }
              helperText={errors.password}
            />
          ) : (
            <TextField
              label={"Password Confirmation"}
              variant={"outlined"}
              sx={{ ...textFieldStyle }}
              type={"password"}
              value={user.passwordConfirmation}
              onChange={e =>
                setUser(Object.assign({}, user, { passwordConfirmation: e.currentTarget.value }))
              }
            />
          )}
        </div>
        <div className={"edit-role-form-membership"}>
          <div>
            <div className={"edit-role-form-section-header"}>Role Membership</div>
            <DualListBox
              listItems={roles.map(roleListTransform)}
              selectedItems={user.roleList.map(roleListTransform)}
              onSelectedItemsChange={updateRoleList}
              loading={rolesLoading}
              leftHeader={"Remaining Roles"}
              rightHeader={"Assigned Roles"}
            />
          </div>
          <div>
            <div className={"edit-role-form-section-header"}>Team Membership</div>
            <DualListBox
              listItems={teams.map(teamListTransform)}
              selectedItems={user.teamList.map(teamListTransform)}
              onSelectedItemsChange={updateTeamList}
              loading={teamsLoading}
              leftHeader={"Remaining Teams"}
              rightHeader={"Assigned Teams"}
            />
          </div>
        </div>
        <div>
          <SaveButton onClick={performSaveUser} data-testid={"saveUser"}>
            Save User
          </SaveButton>
          <CancelButton onClick={() => props.history.push("/settings/security/users")}>
            Cancel
          </CancelButton>
        </div>
      </div>
    </Box>
  )
})
