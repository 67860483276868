import { useContext, useMemo } from "react"
import { SiteBox } from "./siteBox"
import { Sidebar } from "./sidebar"
import { ModelsContext, PermissionContext } from "../../context"
import { IPermissionsMap, ISite } from "../../interfaces"

const REQUIRED_SITE_PERMISSIONS = ["VIEW:CAD", "VIEW:POINTCLOUD", "VIEW:SHADED", "VIEW:TEXTURED"]

const SitesSidebar = () => {
  const { sites, selectedSiteId } = useContext(ModelsContext)
  const { permissions } = useContext(PermissionContext)

  const siteComponents = useMemo(() => generateSites(sites, permissions, selectedSiteId), [
    sites,
    selectedSiteId,
    permissions,
  ])

  return (
    <Sidebar className={"sites-sidebar"}>
      <div className={"site-selector"}>{siteComponents}</div>
    </Sidebar>
  )
}

export { SitesSidebar }

function generateSites(
  sitesObject: ISite[],
  permissions: IPermissionsMap,
  selectedSiteId: number
): JSX.Element[] {
  const sites = Object.values(sitesObject)
  if (sites.length > 0) {
    return sites
      .filter(site => {
        const sitePermissions = permissions[site.id]
        return REQUIRED_SITE_PERMISSIONS.reduce((acc, value) => {
          return sitePermissions[value] ? true : acc
        }, false)
      })
      .map((site, i) => <SiteBox key={i} site={site} selected={site.id === selectedSiteId} />)
  }
}
