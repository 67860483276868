import * as React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { Home } from "../Home"
import { ErrorBoundary } from "../ErrorBoundary"
import { Tooltips } from "../Tooltips/tooltips"
import { SecureConsumer } from "../../../src/context/SecurityProvider"
import "../../../src/assets/scss/dark.scss"

const App = () => (
  <ErrorBoundary>
    <div className={"app"} id={"app"}>
      <Router>
        <SecureConsumer>
          <Home />
        </SecureConsumer>
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        draggable={true}
        pauseOnHover={true}
      />
      <Tooltips />
    </div>
  </ErrorBoundary>
)

export { App }
