import { useContext } from "react"
import { PermissionContext, ModelsContext } from "../context"

export interface IRenderIfPermissionProps {
  token: string | Array<string>
  children: any
  mode: string
  siteSpecific: boolean
}

export const RenderIfPermission = (props: IRenderIfPermissionProps) => {
  const { permissions } = useContext(PermissionContext)
  const { selectedSiteId } = useContext(ModelsContext)

  const { siteSpecific, token, children, mode } = props
  const siteId = siteSpecific && selectedSiteId ? selectedSiteId : 0

  let hasPermission = false

  if (Array.isArray(token)) {
    const isAnd = mode === "AND"
    hasPermission = token.reduce((a, t) => {
      const hasPermission = Boolean(permissions[siteId] !== undefined && permissions[siteId][t])
      return isAnd ? a && hasPermission : a || hasPermission
    }, isAnd)
  } else {
    hasPermission = Boolean(permissions[siteId] !== undefined && permissions[siteId][token])
  }

  return hasPermission ? children : null
}

RenderIfPermission.defaultProps = {
  mode: "AND",
  siteSpecific: false,
}
