import * as React from "react"
import { useContext, useState } from "react"
import reformed from "../../lib/reformed"
import { Button, FormGroup, FormLabel, Grid, Paper, TextField, InputAdornment } from "@mui/material"
import { Loading } from "../Loading"
import { IsRequired, Validate } from "../../lib/reformed/validate"
import { MicrosoftSSO } from "./microsoftSSO"
import { SecurityContext } from "../../context/SecurityProvider"
import { login } from "../../api/security"
import "../../../src/App.css"
import { Link, Route, Switch } from "react-router-dom"
import { ForgotPassword } from "../ForgotPassword"
import { IUser } from "../../interfaces"
import { styled } from "@mui/material/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye } from "@fortawesome/free-solid-svg-icons"
import IconButton from "@mui/material/IconButton"
import { PasswordReset } from "../PasswordReset"

const eye = <FontAwesomeIcon icon={faEye} />

export interface ILoginModel {
  email: string
  password: string
  remember: string
  isForgotPassword?: boolean
}

function Login() {
  const { setToken, setUserData } = useContext(SecurityContext)
  const [loggingIn] = useState()
  const [errorEmail, setErrorEmail] = useState("")
  const [errorPassword, setErrorPassword] = useState("")
  const [error, setError] = useState(false)
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [password, setPassword] = useState("")
  const onSubmit = authenticate(
    email,
    setErrorEmail,
    password,
    setErrorPassword,
    setToken,
    setUserData,
    setErrorMessage
  )

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    if (typeof email.toLowerCase() === "string") {
      setErrorEmail("")
    } else {
      setErrorEmail("Email address is required")
      setError(true)
    }
  }

  function onChangePassword(e: any) {
    setPassword(e.target.value)
    typeof password.toLowerCase() === "string"
      ? setErrorPassword("")
      : setErrorPassword("Password is required")
  }

  const [passwordShown, setPasswordShown] = useState(false)
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }

  const ssorErroDesc = ""

  const styles = {
    boxTransparent: {
      "background-color": "# fff",
      "border-radius": "6px",
      border: "1px solid",
      "border-color": "#CCCCCC",
      "padding-bottom": "20px",
      marginLeft: "auto",
      marginRight: "auto",
      width: 433,
    },
    boxWhite: {
      color: "#000000",
      "background-color": "#ffff",
      "border-radius": "6px",
      border: "2px solid",
      "border-color": "#CCCCCC",
      "padding-top": "20px",
      "padding-bottom": "20px",
      width: 400,
    },
    mainBackground: {
      background: "linear-gradient(135deg, #f48461 0%, #fbbb5b 50%, #f48461 100%)",
      height: "100vh",
    },
    logo: { marginLeft: "35px" },
  }

  const StyledButton = styled(Button)(() => ({
    color: "gray",
    backgroundColor: "white",
    borderColor: "gray",
    "&:hover": {
      color: "white",
      backgroundColor: "Orange",
      boxShadow: "none",
      borderColor: "gray",
    },
    height: 30,
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
  }))

  const textFieldStyle = {
    backgroundColor: "#F5F5F5",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "gray",
      },
      "&:hover fieldset": {
        borderColor: "orange",
      },
      "&.Mui-focused fieldset": {
        borderColor: "orange",
      },
    },
    input: {
      backgroundColor: "#F5F5F5",
      "-webkit-text-fill-color": "black !important",
    },
    maxWait: 400,
  }

  return (
    <Switch>
      <Route exact={true} path={"/"}>
        <Grid
          container={true}
          spacing={0}
          alignItems="center"
          wrap="nowrap"
          style={{ ...styles.mainBackground }}
        >
          <Grid item={true} xs={true} />
          <Grid item={true} xs={4}>
            <Paper style={{ ...styles.boxTransparent }}>
              <a href="https://www.airscope.com">
                <div style={{ ...styles.logo }}>
                  <span className="logo logo-main" />
                </div>
              </a>
              <Grid container={true} spacing={2} alignItems="center" wrap="nowrap">
                <Grid item={true} xs={true} />
                <Grid item={true} xs={12}>
                  {loggingIn && (
                    <div>
                      <Loading />
                    </div>
                  )}
                  {!loggingIn && (
                    <Paper style={{ ...styles.boxWhite }} elevation={1}>
                      <Grid container={true} spacing={2} alignItems="center" wrap="nowrap">
                        <Grid item={true} xs={true} />
                        <Grid item={true} xs={12}>
                          <form onSubmit={onSubmit}>
                            <Grid container={true} spacing={2}>
                              <Grid item={true} xs={12}>
                                <FormGroup>
                                  <FormLabel>Email Address</FormLabel>
                                  <TextField
                                    sx={{ ...textFieldStyle }}
                                    error={error}
                                    size="small"
                                    defaultValue={""}
                                    inputProps={{
                                      "data-testid": "email",
                                    }}
                                    variant="outlined"
                                    name="email"
                                    placeholder={"email@example.com"}
                                    autoFocus={true}
                                    onChange={onChangeEmail}
                                    color="primary"
                                    helperText={errorEmail}
                                  />
                                </FormGroup>
                              </Grid>
                              <Grid item={true} xs={12}>
                                <FormGroup>
                                  <FormLabel htmlFor="password">Password</FormLabel>
                                  <TextField
                                    sx={{ ...textFieldStyle }}
                                    size="small"
                                    defaultValue={""}
                                    onChange={onChangePassword}
                                    inputProps={{ "data-testid": "password" }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment variant="filled" position="end">
                                          <IconButton onClick={togglePasswordVisibility}>
                                            {eye}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    name={"password"}
                                    type={passwordShown ? "text" : "password"}
                                    placeholder={"Enter your password"}
                                    helperText={errorPassword}
                                  />
                                </FormGroup>
                              </Grid>
                            </Grid>
                            {error && <div className="login-error">{error}</div>}
                            <label style={{ color: "red" }}>{errorMessage}</label>
                            {ssorErroDesc && <div className="login-error">{ssorErroDesc}</div>}
                            <Grid container={true} direction="row">
                              <Grid item={true}>
                                <StyledButton
                                  variant="outlined"
                                  type={"submit"}
                                  data-testid="login"
                                >
                                  Login
                                </StyledButton>
                              </Grid>
                              <Grid item={true}>
                                <Link to={"/forgotpassword"}>
                                  <StyledButton
                                    variant="outlined"
                                    data-tip={"Forgot your password ?"}
                                    data-testid={"forgotPasswordButton"}
                                  >
                                    Forgot Password
                                  </StyledButton>
                                </Link>
                              </Grid>
                            </Grid>
                            <div style={{ clear: "both" }} />
                            <hr />
                            <MicrosoftSSO />
                          </form>
                        </Grid>
                        <Grid item={true} xs={true} />
                      </Grid>
                    </Paper>
                  )}
                </Grid>
                <Grid item={true} xs={true} />
              </Grid>
            </Paper>
          </Grid>
          <Grid item={true} xs={true} />
        </Grid>
      </Route>
      <Route path={"/forgotpassword"}>
        <ForgotPassword />
      </Route>
      <Route path={"/users/passwordreset/:passwordResetToken"} exact={false}>
        <PasswordReset />
      </Route>
    </Switch>
  )
}

export default reformed({
  initialModel: {
    email: "",
    password: "",
  },
})(
  Validate<any, IProps>(
    IsRequired<ILoginModel>({
      email: "Email address is required",
      password: "You can't login without a password",
    })
  )(Login)
)

export function authenticate(
  email: string,
  setErrorEmail: React.Dispatch<React.SetStateAction<string>>,
  password: string,
  setErrorPassword: React.Dispatch<React.SetStateAction<string>>,
  setToken: (value: string) => void,
  setUserData: (value: IUser) => void,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
) {
  return async (evt: any) => {
    evt.preventDefault()

    if (!email) {
      setErrorEmail("Email address is required")
    }
    if (!password) {
      setErrorPassword("Password is required")
    } else {
      const response = await login(email, password)
      if (typeof response.data["error"] !== "undefined") {
        console.error("Error response = ", response)
        setErrorMessage(response.data["error"])
      } else {
        setToken(response.toString())
        setUserData(response.data.user)
        localStorage.setItem("userData", JSON.stringify(response.data.user))
        // localStorage.setItem("auth", response.data.token)
      }
    }
  }
}
