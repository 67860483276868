import * as React from "react"
import { Box, Field, Button, Radio } from "trunx"
import reformed, { InjectedProps } from "../../../lib/reformed"
import { Validate } from "../../../lib/reformed/validate"
import { Loading } from "../../Loading"
import SecurityController from "../../../controllers/security"

interface IProfileNotificationModel {
  featuresEmail: boolean
  featuresSMS: boolean
  pointGrabEmail: boolean
  pointGrabSMS: boolean
}
interface IProps {
  updateNotificationSettings: SecurityController["updateNotificationSettings"]
}
interface IState {
  sending: boolean
  fetching: boolean
}
class ProfileNotificationSettings extends React.Component<
  InjectedProps<IProfileNotificationModel> & IProps,
  IState
> {
  state: IState = {
    sending: false,
    fetching: false,
  }
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.currentTarget.name as keyof IProfileNotificationModel
    const value = !this.props.model![key]

    this.props.setProperty!(key, value)
  }
  onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const { model } = this.props

    console.debug("Submitting", model)
    if (!model) {
      console.error("no model in form")
      return
    }
    await this.props.updateNotificationSettings(
      model.featuresEmail,
      model.featuresSMS,
      model.pointGrabEmail,
      model.pointGrabSMS
    )
  }
  render() {
    const { model } = this.props
    if (this.state.fetching || this.state.sending) {
      return <Loading />
    }
    return (
      <React.Fragment>
        <h2>Profile Notification Settings</h2>
        <Box>
          <form onSubmit={this.onSubmit}>
            <Field
              defaultValue={"New features and updates"}
              isLarge={true}
              style={{ textAlign: "left" }}
            >
              <div className={"switch-container"}>
                <div className={"switch-item"}>
                  <Radio
                    name={"featuresEmail"}
                    checked={model!.featuresEmail}
                    onChange={this.onChange}
                  >
                    {"Email"}
                  </Radio>
                </div>
                <div className={"switch-item"}>
                  <Radio name={"featuresSMS"} checked={model!.featuresSMS} onChange={this.onChange}>
                    {"SMS"}
                  </Radio>
                </div>
              </div>
            </Field>
            <Field
              defaultValue={"PointGrab status updates"}
              isLarge={true}
              style={{ textAlign: "left" }}
            >
              <div className={"switch-container"}>
                <div className={"switch-item"}>
                  <Radio
                    name={"pointGrabEmail"}
                    onChange={this.onChange}
                    checked={model!.pointGrabEmail}
                  >
                    {"Email"}
                  </Radio>
                </div>
                <div className={"switch-item"}>
                  <Radio
                    name={"pointGrabSMS"}
                    onChange={this.onChange}
                    checked={model!.pointGrabSMS}
                  >
                    {"SMS"}
                  </Radio>
                </div>
              </div>
            </Field>

            <div>
              <Button style={{ marginTop: 14 }} isLarge={false} type={"submit"}>
                Update
              </Button>
            </div>
          </form>
        </Box>
      </React.Fragment>
    )
  }
}
const Reformed = reformed({
  initialModel: {
    featuresEmail: false,
    featuresSMS: false,
    pointGrabEmail: false,
    pointGrabSMS: false,
  },
})(Validate<IProfileNotificationModel, IProps>()(ProfileNotificationSettings))

export { Reformed as ProfileNotificationSettings }
