import { useEffect, useContext } from "react"
import { ViewerContext } from "../../../context"
import * as Cesium from "cesium"

export const useGlobeDisplay = (viewer: Cesium.Viewer | null) => {
  const { globeEnabled } = useContext(ViewerContext)

  useEffect(() => {
    if (!viewer) {
      return
    }

    const scene = viewer.scene
    scene.globe.show = globeEnabled
    scene.skyBox.show = globeEnabled
    scene.skyAtmosphere.show = globeEnabled
    scene.sun.show = globeEnabled
    scene.moon.show = globeEnabled
    scene.backgroundColor = Cesium.Color.WHITE.clone()

    scene.requestRender()
  }, [viewer, globeEnabled])
}
