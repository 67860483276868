// @ts-ignore
import React, { createContext, useEffect, useState } from "react"
import { IUser } from "../../src/interfaces"
import { Login } from "../../src/components/Login"
import { useCurrentUser } from "../api/Hooks/PermissionHooks"
import { Route, Switch } from "react-router"

export const SecurityContext = createContext({
  token: "" as string,
  // tslint:disable-next-line:no-empty
  setToken: (value: string) => {},
  userData: {} as IUser,
  // tslint:disable-next-line:no-empty
  setUserData: (value: IUser) => {},
})

function SetAuth(props) {
  localStorage.setItem("auth", props.match.params.token)
  window.location.href = "/"
  return <p>Logging in...</p>
}

export const SecureConsumer = (props: any) => {
  const [token, setToken] = useState(localStorage.getItem("auth"))
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")))
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    setToken(localStorage.getItem("auth"))
  }, [token, userData])

  if (!token || token === "") {
    return (
      <SecurityContext.Provider value={{ token, setToken, userData, setUserData }}>
        <Switch>
          <Route path={"/auth/:id/:token"} component={SetAuth} />
          <Route>
            <Login />
          </Route>
        </Switch>
      </SecurityContext.Provider>
    )
  }

  return (
    <SecurityContext.Provider value={{ token, setToken, userData, setUserData }}>
      {props.children}
    </SecurityContext.Provider>
  )
}
