import { IBookmark } from "../interfaces"
import { Quaternion } from "cesium"
import { Matrix3 } from "cesium"
import { Cartesian3 } from "cesium"
import { Rectangle, BoundingSphere } from "cesium"
import * as Cesium from "cesium"

export const AusCoords = {
  west: 1.553411116493411,
  south: -0.8049372366190383,
  east: 3.0482405014262004,
  north: 0.08601520535880729,
}

export const AusRect = new Rectangle(
  AusCoords.west,
  AusCoords.south,
  AusCoords.east,
  AusCoords.north
)
export const home = new BoundingSphere(
  Cartesian3.fromDegrees(115.7588121, -32.09470039, -9.966539549),
  357.0759731
)

export const screenshot = (viewer: Cesium.Viewer) => {
  if (!viewer) {
    return
  }
  viewer.render()
  return viewer.canvas.toDataURL()
}

export const existingViewer = (object: any) => {
  if (object) return true
  return false
}

export const lookAt = (viewer: Cesium.Viewer, obj: IBookmark, complete?: Function) => {
  const bs: IBSphere = getBoundingSphere(viewer, obj)
  viewer.camera.flyTo({
    complete: () => {
      if (typeof complete === "function") {
        complete()
      }
    },
    destination: bs.position,
    orientation: {
      direction: bs.direction,
      up: bs.up,
      right: bs.right,
      transform: bs.transform,
      frustum: bs.frustum,
    },
  })
}

export const resetModelView = (viewer: Cesium.Viewer, boundingSphere: Cesium.BoundingSphere) => {
  if (!boundingSphere) {
    console.error("No bounding sphere")
    return
  }

  const cartographic = Cesium.Cartographic.fromCartesian(boundingSphere.center)
  const position = Cesium.Cartesian3.fromRadians(
    cartographic.longitude,
    cartographic.latitude,
    1000
  )
  // debugger
  viewer.camera.flyTo({
    destination: position,
    orientation: {
      heading: Cesium.Math.toDegrees(0.0),
      pitch: -Cesium.Math.PI_OVER_TWO,
      roll: 0.0,
    },
  })
}

export const getMapCenter = (viewer: Cesium.Viewer): { longitude: number; latitude: number } => {
  const windowPosition = new Cesium.Cartesian2(
    viewer.container.clientWidth / 2,
    viewer.container.clientHeight / 2
  )
  const pickRay = viewer.scene.camera.getPickRay(windowPosition)
  const pickPosition = viewer.scene.globe.pick(pickRay, viewer.scene)
  const pickPositionCartographic = viewer.scene.globe.ellipsoid.cartesianToCartographic(
    pickPosition
  )
  return {
    longitude: Cesium.Math.toDegrees(pickPositionCartographic.longitude),
    latitude: Cesium.Math.toDegrees(pickPositionCartographic.latitude),
  }
}

export const goTo = (viewer: Cesium.Viewer, obj: IBookmark, complete?: Function) => {
  const bs = getBoundingSphere(viewer, obj)
  viewer.camera.setView({
    destination: bs.position,
    orientation: {
      direction: bs.direction,
      up: bs.up,
      // right: bs.right,
      transform: bs.transform,
      frustum: bs.frustum,
    },
  })

  // setView does not have "complete" callback hence fake it here.
  if (typeof complete === "function") {
    setTimeout(() => {
      complete()
    }, 1000)
  }
}

export const getBookmark = (viewer: Cesium.Viewer, name: string, desc: string): IBookmark => {
  const camera = viewer.camera
  const view: IBookmark = {
    name,
    description: desc,
    position: new Cesium.Cartesian3(),
    direction: new Cesium.Cartesian3(),
    up: new Cesium.Cartesian3(),
    right: new Cesium.Cartesian3(),
    transform: new Cesium.Matrix4(),
    frustum: new Cesium.PerspectiveFrustum(),
  }

  view.position = camera.position.clone(view.position)
  view.direction = camera.direction.clone(view.direction)
  view.up = camera.up.clone(view.up)
  view.right = camera.right.clone(view.right)
  view.transform = camera.transform.clone(view.transform)
  ;(camera.frustum as Cesium.PerspectiveFrustum).clone(view.frustum as Cesium.PerspectiveFrustum)

  return view
}

export const transformBSphere = (bookmark: IBSphere, transform?: Cesium.Matrix4): IBSphere => {
  if (transform == null) {
    return bookmark
  }

  bookmark.position = Cesium.Matrix4.multiplyByPoint(
    transform,
    bookmark.position,
    new Cesium.Cartesian3()
  )

  bookmark.direction = Cesium.Matrix4.multiplyByPointAsVector(
    transform,
    bookmark.direction,
    new Cesium.Cartesian3()
  )

  bookmark.up = Cesium.Matrix4.multiplyByPointAsVector(
    transform,
    bookmark.up,
    new Cesium.Cartesian3()
  )

  if (bookmark.right) {
    bookmark.right = Cesium.Matrix4.multiplyByPointAsVector(
      transform,
      bookmark.right,
      new Cesium.Cartesian3()
    )
  }

  return bookmark
}

export const getBoundingSphere = (viewer: Cesium.Viewer, obj: IBookmark) => {
  // bs.right = new Cesium.Cartesian3(obj.right.x, obj.right.y, obj.right.z)

  let tforms: number[]
  tforms = []
  let i = 0
  while (typeof obj.transform[i] !== "undefined") {
    tforms.push(obj.transform[i])
    i++
  }

  const bs: IBSphere = {
    position: new Cesium.Cartesian3(obj.position.x, obj.position.y, obj.position.z),
    direction: new Cesium.Cartesian3(obj.direction.x, obj.direction.y, obj.direction.z),
    up: new Cesium.Cartesian3(obj.up.x, obj.up.y, obj.up.z),
    transform: Cesium.Matrix4.fromArray(tforms),
    frustum: new Cesium.PerspectiveFrustum(),
  }

  bs.frustum.aspectRatio = viewer.scene.canvas.clientWidth / viewer.scene.canvas.clientHeight

  bs.frustum.fov = Cesium.Math.PI_OVER_THREE
  bs.frustum.near = obj.frustum.near
  bs.frustum.far = obj.frustum.far

  return bs
}

export interface IBSphere {
  position: Cesium.Cartesian3
  direction: Cesium.Cartesian3
  up: Cesium.Cartesian3
  right?: Cesium.Cartesian3
  transform: Cesium.Matrix4
  frustum: Cesium.PerspectiveFrustum
}

export const getUnitNormal = (v1: Cartesian3, v2: Cartesian3): Cartesian3 => {
  return Cesium.Cartesian3.normalize(
    Cesium.Cartesian3.cross(v1, v2, new Cartesian3()),
    new Cartesian3()
  )
}

export const getVectorFromCustomOrigin = (
  origin: { x: number; y: number; z: number },
  point: { x: number; y: number; z: number }
): Cesium.Cartesian3 => {
  const v = { x: point.x - origin.x, y: point.y - origin.y, z: point.z - origin.z }
  return new Cesium.Cartesian3(v.x, v.y, v.z)
}

export const rotatePointByAngleAboutVector = (
  p: Cesium.Cartesian3,
  v: Cesium.Cartesian3,
  angle: number
) => {
  const u = Cartesian3.normalize(v, new Cartesian3())
  // assume in radians already
  const rot = Quaternion.fromAxisAngle(u, angle, new Quaternion())
  const Rmatrix = Matrix3.fromQuaternion(rot)
  return Cesium.Matrix3.multiplyByVector(Rmatrix, p, new Cesium.Cartesian3())
}

export const createArcLine = (
  p0: Cesium.Cartesian3,
  p1: Cesium.Cartesian3,
  origin: Cesium.Cartesian3,
  angleBetweenPoints: number
): Cesium.Cartesian3[] => {
  const points = [p0]
  const v0 = Cesium.Cartesian3.subtract(p0, origin, new Cesium.Cartesian3())
  const v1 = Cesium.Cartesian3.subtract(p1, origin, new Cesium.Cartesian3())
  const dp = Cartesian3.dot(
    Cartesian3.normalize(v0, new Cartesian3()),
    Cartesian3.normalize(v1, new Cartesian3())
  )

  const angle = Math.acos(dp)

  const n = Math.floor(angle / angleBetweenPoints)

  const unitNormal = getUnitNormal(v0, v1)
  for (let i = 1; i < n + 1; i++) {
    const a = i * angleBetweenPoints
    points.push(
      Cesium.Cartesian3.add(
        origin,
        rotatePointByAngleAboutVector(v0, unitNormal, a),
        new Cesium.Cartesian3()
      )
    )
  }
  points.push(p1)
  return points
}
