/* tslint:disable:jsx-no-lambda */
import { useCallback, useEffect, useState } from "react"
import { useSitePointgrabDownloads } from "../../api/Hooks/PointgrabHooks"
import { Loading } from "../Loading"
import { VizTransition } from "../VizTransition"
import { RenderIfPermission } from "../../helpers/Permissions"
import { IPointCloudDownload } from "../../interfaces"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useConfirmationModal } from "../../hooks/useConfirmationModal"
import { apiHostname } from "../../api/constants"

const downloadUrl = (filename: string) => `${apiHostname}/files/pointgrab/download/${filename}`

interface IDownloadItemProps {
  item: IPointCloudDownload
  onDelete: Function
}

export const DownloadItemStatus = (props: IDownloadItemProps) => {
  const { item } = props
  const downloadFile = useCallback(() => {
    window.open(downloadUrl(item.filename), "_blank")
  }, [item])

  const pdalCompleted = "completed"
  const pdalPending = "pending"
  const pdalError = "error"

  return (
    <div className={"icon"} style={{ marginRight: "7px" }}>
      {item.status.toLowerCase() === pdalCompleted.toLowerCase() ? (
        <span
          title={"Download"}
          style={{ cursor: "pointer" }}
          onClick={downloadFile}
          data-testid={"pointGrabDownload"}
        >
          <FontAwesomeIcon icon={"download"} />
        </span>
      ) : item.status.toLowerCase() === pdalPending.toLowerCase() ? (
        <span data-testid={"pointGrabPending"}>
          <Loading width={12} />
        </span>
      ) : item.status.toLowerCase() === pdalError.toLowerCase() ? (
        <span title={"Download"} data-testid={"pointGrabError"}>
          <FontAwesomeIcon data-tip={item.message} icon={"exclamation-circle"} />
        </span>
      ) : null}
    </div>
  )
}
export const DownloadItem = (props: IDownloadItemProps) => {
  const { item, onDelete } = props

  return (
    <div className={"pointgrab-downloads-row"}>
      <div>
        <div className={"file-format"}>.{item.filename.substr(item.filename.length - 3)}</div>
        {item.description}
      </div>
      <div className={"pointgrab-controls"}>
        <RenderIfPermission token={"POINTGRAB:DOWNLOAD"} siteSpecific={true}>
          <DownloadItemStatus item={item} onDelete={onDelete} />
        </RenderIfPermission>
        <RenderIfPermission token={"POINTGRAB:DELETE"} siteSpecific={true}>
          <div className={"icon"}>
            <span title={"Delete"} style={{ cursor: "pointer" }} onClick={() => onDelete()}>
              <FontAwesomeIcon icon={"trash"} />
            </span>
          </div>
        </RenderIfPermission>
      </div>
    </div>
  )
}

export const PointGrabDownloads = () => {
  const {
    pointgrabDownloads,
    removePointgrabDownload,
    pointgrabDownloadsLoading,
    refreshPointgrabDownloads,
  } = useSitePointgrabDownloads()
  const { getConfirmation } = useConfirmationModal()
  const [showPts, setShowPts] = useState(true)
  const [showE57, setShowE57] = useState(true)

  useEffect(() => {
    const refreshInterval = setInterval(refreshPointgrabDownloads, 3000)
    return () => clearInterval(refreshInterval)
  }, [refreshPointgrabDownloads])

  return (
    <>
      {pointgrabDownloadsLoading && (
        <div className="loading-centered">
          <Loading />
        </div>
      )}
      {!pointgrabDownloadsLoading && pointgrabDownloads.length === 0 && (
        <div className="pgError">No PointGrab downloads are currently available for this site.</div>
      )}
      {!pointgrabDownloadsLoading && pointgrabDownloads.length > 0 && (
        <VizTransition>
          <>
            <div className={"download-file-format-select-container"}>
              <div className={"format"}>
                <input
                  id="format-pts"
                  type="checkbox"
                  name="pts"
                  value="pts"
                  checked={showPts}
                  onChange={() => setShowPts(!showPts)}
                />
                <label htmlFor="format-pts">PTS</label>
              </div>

              <div className={"format"}>
                <input
                  id={"format-e57"}
                  type="checkbox"
                  name="e57"
                  value="e57"
                  checked={showE57}
                  onChange={() => setShowE57(!showE57)}
                />
                <label htmlFor="format-e57">E57</label>
              </div>
            </div>
            <div className="pointgrab-downloads">
              {pointgrabDownloads
                .filter(
                  item =>
                    (showPts && item.filename.endsWith(".pts")) ||
                    (showE57 && item.filename.endsWith(".e57"))
                )
                .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                .map(item => (
                  <DownloadItem
                    key={item.uuid}
                    item={item}
                    onDelete={async () => {
                      const result = await getConfirmation(
                        "Confirmation",
                        "Are you sure you want to delete this pointcloud?"
                      )
                      if (result) {
                        await removePointgrabDownload(item.uuid)
                      }
                    }}
                  />
                ))}
            </div>
          </>
        </VizTransition>
      )}
    </>
  )
}
