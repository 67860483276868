import { useEffect, useContext } from "react"
import { ViewerContext } from "../../../context"
import { Viewer } from "cesium"

export const useForwardCuttingPlane = (viewer: Viewer | null, modelType: Number) => {
  const { cuttingPlaneDistance } = useContext(ViewerContext)

  useEffect(() => {
    if (!viewer) {
      return
    }

    if (modelType === 2 || modelType === 3) {
      viewer.camera.frustum.near = 1.0
    } else {
      viewer.camera.frustum.near = cuttingPlaneDistance
    }

    // @ts-ignore
    // viewer.scene._view.updateFrustums = true // Hack to force Cesium to update the frustum correctly when lowering the .near value
    viewer.scene.requestRender()
  }, [viewer, cuttingPlaneDistance, modelType])
}
