/* tslint:disable:jsx-no-lambda */
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Item, Menu, MenuProvider } from "react-contexify"
import { useApiCad } from "../../api/Hooks/CadHooks"
import { Loading } from "../Loading"
import { CadMetadataContext } from "./CadMetadata"

import "react-contexify/dist/ReactContexify.min.css"

const TreeMenu = (props: any) => {
  return (
    <Menu id="tree_menu">
      <Item onClick={(e: any) => e.props.selectCallback()}>Select</Item>
      <Item onClick={(e: any) => e.props.copyNameCallback()}>Copy Item Name</Item>
      <Item onClick={(e: any) => e.props.flyToCallback()}>Fly to</Item>
    </Menu>
  )
}

const TreeItem = (props: any) => {
  const { item, selectedPath, selectItem, selectedItem, scrollTo } = props
  const { flyToMetadataItem } = useContext(CadMetadataContext)

  const path = item.path
  const selected = path === selectedPath
  const [subSelected, setSubSelected] = useState(
    selectedItem && selectedItem.batchId.startsWith(item.batchId)
  )

  const [expanded, setExpanded] = useState(false)
  const { items, itemsLoading } = useApiCad(path)

  useEffect(() => {
    if (
      selectedItem &&
      (selectedItem.batchId.startsWith(item.batchId) || selectedItem.batchId === item.batchId)
    ) {
      setExpanded(true)
      setSubSelected(true)
    } else {
      setSubSelected(false)
    }
  }, [item, selectedItem])

  const flyToCallback = () => flyToMetadataItem(item)
  const selectCallback = () => selectItem(item, path)
  const copyNameCallback = useCallback(async () => {
    if (item) {
      // @ts-ignore
      navigator.clipboard.writeText(item.name)
    }
  }, [item])

  return (
    <MenuProvider id="tree_menu" data={{ flyToCallback, selectCallback, copyNameCallback }}>
      <div
        className={
          "navisworks-tree-item" +
          (selected ? " selected" : "") +
          (subSelected ? " sub-selected" : "")
        }
      >
        <span className={"item-icon"} onClick={() => setExpanded(!expanded)}>
          {expanded ? "▼" : "⯈"}
        </span>
        <span
          className={"item-name" + (item.hasExternalMetadata ? " meta" : "")}
          onClick={selectCallback}
        >
          {item.name}
        </span>
      </div>
      {expanded && (
        <div className={"navisworks-tree-children"}>
          {itemsLoading ? (
            <Loading />
          ) : (
            items.map((item, i) => {
              if (item.hasChildren) {
                return (
                  <TreeItem
                    key={i}
                    item={item}
                    selectedPath={selectedPath}
                    selectItem={selectItem}
                    selectedItem={selectedItem}
                    scrollTo={scrollTo}
                  />
                )
              } else {
                return (
                  <TreeChildlessItem
                    key={i}
                    item={item}
                    selectedPath={selectedPath}
                    selectItem={selectItem}
                    selectedItem={selectedItem}
                    scrollTo={scrollTo}
                  />
                )
              }
            })
          )}
        </div>
      )}
    </MenuProvider>
  )
}

const TreeChildlessItem = (props: any) => {
  const { flyToMetadataItem } = useContext(CadMetadataContext)

  const { item, selectedPath, selectItem, selectedItem, scrollTo } = props
  const path = item.path
  const selected = selectedItem && item.batchId === selectedItem.batchId
  const subSelected = !selected && selectedPath.length > 0 && path === selectedPath

  const itemContainer = useRef(null)

  const setRef = useCallback(
    (node: any) => {
      itemContainer.current = node

      if (node && selectedItem && item.id === selectedItem.id) {
        scrollTo(node.offsetTop)
      }
    },
    [item.id, scrollTo, selectedItem]
  )

  useEffect(() => {
    if (selectedItem && item.id === selectedItem.id) {
      if (!selectedItem.position) {
        selectItem(item, path.slice(0, path.length))
      }

      if (itemContainer.current) {
        // @ts-ignore
        scrollTo(itemContainer.current.offsetTop)
      }
    }
  }, [item, path, scrollTo, selectItem, selectedItem])

  const flyToCallback = useCallback(() => flyToMetadataItem(item), [flyToMetadataItem, item])
  const selectCallback = useCallback(() => selectItem(item, path), [item, path, selectItem])
  const copyNameCallback = useCallback(async () => {
    if (item) {
      // @ts-ignore
      navigator.clipboard.writeText(item.name)
    }
  }, [item])

  return (
    <div ref={setRef}>
      <MenuProvider id="tree_menu" data={{ flyToCallback, selectCallback, copyNameCallback }}>
        <div
          className={
            "navisworks-tree-item" +
            (selected ? " selected" : "") +
            (subSelected ? " sub-selected" : "")
          }
        >
          <img src={"assets/img/cube.png"} className={"item-icon"} alt="" />

          <span
            className={"item-name" + (item.hasExternalMetadata ? " meta" : "")}
            onClick={() => selectItem(item, path.slice(0, path.length))}
          >
            {item.name}
          </span>
        </div>
      </MenuProvider>
    </div>
  )
}

export const NavisworksTree = (props: any) => {
  const { onSelect, selectedItem } = props
  const { items, itemsLoading } = useApiCad()
  const [selectedPath, setSelectedPath] = useState("")
  const treeContainer = useRef(null)

  const selectItem = useCallback(
    (item, path) => {
      if (path === selectedPath) {
        setSelectedPath("")
        onSelect(null)
      } else {
        setSelectedPath(path)
        onSelect(item)
      }
    },
    [selectedPath, onSelect]
  )

  const scrollTo = useCallback(
    (offsetTop: number) => {
      if (treeContainer.current) {
        // @ts-ignore
        treeContainer.current.scrollTop = offsetTop
      }
    },
    [treeContainer]
  )

  if (itemsLoading) {
    return <Loading />
  }

  return (
    <div className={"navisworks-tree"} data-TestId={"navisworks-tree"} ref={treeContainer}>
      {items.map((item, i) => {
        if (item.hasChildren) {
          return (
            <TreeItem
              key={i}
              item={item}
              selectedPath={selectedPath}
              selectItem={selectItem}
              selectedItem={selectedItem}
              scrollTo={scrollTo}
            />
          )
        } else {
          return (
            <TreeChildlessItem
              key={i}
              item={item}
              selectedPath={selectedPath}
              selectItem={selectItem}
              selectedItem={selectedItem}
              scrollTo={scrollTo}
            />
          )
        }
      })}
      <TreeMenu />
    </div>
  )
}
