import * as React from "react"

// IState of the HOC you need to compute the InjectedProps
interface IState<TModel> {
  model: TModel
}

// Props you want the resulting component to take (besides the props of the wrapped component)
export interface IExternalProps<TModel> {
  initialModel?: TModel
}

export interface IBindInputFuncResult<TModel> {
  name: keyof TModel
  value: string
  onChange: (e: any) => void
}

export type BindInputFunc<TModel> = (name: keyof TModel) => IBindInputFuncResult<TModel>

// Props the HOC adds to the wrapped component
export interface InjectedProps<TModel> {
  bindInput?: BindInputFunc<TModel>
  bindToChangeEvent?: (e: any) => void
  model?: TModel
  setProperty?: (prop: keyof TModel, value: any) => TModel
  setModel?: (model: TModel) => TModel
}

// // Options for the HOC factory that are not dependent on props values
interface IHocProps<TModel> {
  // // TODO can i make types better ?
  middleware?: (props: InjectedProps<TModel>) => InjectedProps<TModel>
  trace?: boolean
}

interface ISetupProps<TModel> {
  initialModel?: TModel
  hocProps?: IHocProps<TModel>
}

/**
 * Inject utilities and model into a core Form component.
 *
 * Requires explicit type of model. eg `reformed<Model>()(FormComponent)`.
 */
export const reformed = <TModel extends {}>(setupProps?: ISetupProps<TModel>) => <
  TOriginalProps extends {}
>(
  Component: React.ComponentType<TOriginalProps & InjectedProps<TModel>>
) => {
  type ResultProps = TOriginalProps & IExternalProps<TModel> & InjectedProps<TModel>

  const FormWrapper = class Reformed extends React.Component<ResultProps, IState<TModel>> {
    // Define how your HOC is shown in ReactDevTools
    static insideName = Component.displayName || Component.name
    // static displayName = `Reformed(${Reformed.insideName})`
    static displayName = `Reformed(${Component.displayName || Component.name})`

    constructor(props: ResultProps, ctx: any) {
      super(props)
      let initialModel: TModel
      if (props.initialModel) {
        initialModel = props.initialModel
      } else if (setupProps && setupProps.initialModel) {
        initialModel = setupProps.initialModel
      } else {
        initialModel = {} as TModel
      }
      this.state = {
        // Init the state here
        model: initialModel,
      }
    }

    trace = (context: string, data: string | undefined | null) => {
      if (!setupProps || !setupProps.hocProps) {
        return
      }
      const { trace = false } = setupProps.hocProps
      if (trace) {
        console.log(context, (data || "").substring(0, 256))
      }
    }

    setModel = (model: TModel) => {
      this.trace("reformed trace setModel", JSON.stringify(model))
      this.setState({ model })
      return model
    }

    // value: any.   pike out, but may not be easy to fix.
    setProperty = (prop: keyof TModel, value: any) => {
      this.trace("reformed trace setProperty", value)
      return this.setModel(
        Object.assign({}, this.state.model, {
          [prop]: value,
        })
      )
    }

    // This, of course, does not handle all possible inputs. In such cases,
    // you should just use `setProperty` or `setModel`. Or, better yet,
    // extend `reformed` to supply the bindings that match your needs.
    bindToChangeEvent = (e: any) => {
      const { name, type, value } = e.target

      // hard to ensure name is right type here its arbitrary name of dom element.
      // validating in with TModel as starting point is non trivial.... afaik.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const valueString: string = value

      if (type === "checkbox") {
        const newCheckboxValue = e.target.checked

        this.setProperty(name, newCheckboxValue)
      } else {
        this.setProperty(name, value)
      }
    }

    /**
     * Output props suitable to bind to a dom elements name, value, onChange.
     */
    bindInput = (name: keyof TModel): IBindInputFuncResult<TModel> => {
      // console.log('bindInput', name, this.state.model, this.state.model[name])
      let value = this.state.model[name] as any
      if (typeof value !== "number" && !value) {
        value = ""
      }
      return {
        name,
        value,
        onChange: this.bindToChangeEvent,
      }
    }

    render() {
      const nextProps = Object.assign({}, this.props, {
        bindInput: this.bindInput,
        bindToChangeEvent: this.bindToChangeEvent,
        model: this.state.model,
        setProperty: this.setProperty,
        setModel: this.setModel,
      })
      return <Component {...nextProps} />

      // this next line produces a type error, the above does not... no idea why at moment
      // return React.createElement(Component, nextProps);
    }
  }

  // ?? todo hoistNonReactStatics ????
  return FormWrapper
}

export default reformed
