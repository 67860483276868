import { ComponentType, createContext, Fragment, useCallback, useMemo, useState } from "react"
import { onKeyPress } from "../hooks/useKeyPress"
import { useSetViewerBackgrounded } from "../hooks/viewer/useSetViewerBackgrounded"

interface IModalOptions {
  disableClose: boolean
}

export const DEFAULT_MODAL_OPTIONS: IModalOptions = {
  disableClose: false,
}

export const ModalContext = createContext({
  // tslint:disable-next-line:no-empty
  showModal: (modal: ComponentType<any>, options?: IModalOptions) => {},
  // tslint:disable-next-line:no-empty
  hideModal: () => {},
})

const ModalContainer = (props: any) => {
  const { hideModal, content, options } = props

  if (!options.disableClose) {
    onKeyPress("Escape", hideModal)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSetViewerBackgrounded()
  }

  return (
    <div className="modal is-active">
      {/* tslint:disable-next-line:jsx-no-lambda */}
      <div className="modal-background" onClick={() => !options.disableClose && hideModal()} />
      <div className="modal-card">{content}</div>
    </div>
  )
}

export const ModalContextProvider = (props: any) => {
  const [modalContent, setModal] = useState(null as any)
  const [modalOptions, setModalOptions] = useState(DEFAULT_MODAL_OPTIONS)

  const showModal = useCallback((modal: ComponentType<any>, options?: IModalOptions) => {
    if (options) {
      setModalOptions(Object.assign({}, DEFAULT_MODAL_OPTIONS, options))
    } else {
      setModalOptions(DEFAULT_MODAL_OPTIONS)
    }

    setModal(modal)
  }, [])
  const hideModal = useCallback(() => setModal(null), [])
  const contextValue = useMemo(() => ({ showModal, hideModal }), [hideModal, showModal])

  return (
    <ModalContext.Provider value={contextValue}>
      <Fragment>
        {props.children}
        {modalContent && (
          <ModalContainer hideModal={hideModal} content={modalContent} options={modalOptions} />
        )}
      </Fragment>
    </ModalContext.Provider>
  )
}
