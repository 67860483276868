/* tslint:disable:jsx-no-lambda */
import { useState } from "react"
import { withRouter } from "react-router"
import { Box } from "trunx"
import { toast } from "react-toastify"
import { styled } from "@mui/material/styles"
import { useApiRole, useApiTeams, useApiUsers } from "../../../api/ApiHooks"
import { Loading } from "../../Loading"
import { DualListBox, IListBoxItem, teamListTransform, userListTransform } from "../DualListBox"
import { PermissionField } from "./PermissionField"
import { Button, TextField } from "@mui/material"
import { grey, orange } from "@mui/material/colors"

const textFieldStyle = {
  color: "white",
  borderColour: "white",
  mt: 2,
  mr: 2,
  mb: 2,
  "& .MuiInputLabel-root": { color: "white" },
  "& .MuiOutlinedInput-input": { color: "white" },
  "& label.Mui-focused": { color: "white" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "orange",
    },
    "&.Mui-focused fieldset": {
      borderColor: "orange",
    },
  },
}
const errorTextFieldStyle = {
  color: "white",
  borderColour: "white",
  mt: 2,
  mr: 2,
  mb: 2,
  "& .MuiOutlinedInput-input": { color: "white" },
}

const SaveButton = styled(Button)(({ theme }) => ({
  color: "White",
  backgroundColor: "Orange",
  "&:hover": {
    backgroundColor: orange[600],
    boxShadow: "none",
  },
  height: 40,
  marginTop: 30,
  marginRight: 10,
}))

const CancelButton = styled(Button)(({ theme }) => ({
  color: "White",
  backgroundColor: "#2b2b2b",
  "&:hover": {
    backgroundColor: grey[900],
  },
  height: 40,
  marginTop: 30,
}))

const CreateEditRole = withRouter((props: any) => {
  const isEditMode = "id" in props.match.params

  const { role, setRole, roleLoading, saveRole } = useApiRole(props.match.params.id)
  const { users, usersLoading } = useApiUsers()
  const { teams, teamsLoading } = useApiTeams()
  const [errors, setErrors] = useState({ displayName: "" })
  const [displayNameErrorOpen, setDisplayNameErrorOpen] = useState(false)

  if (roleLoading || usersLoading || teamsLoading) {
    return (
      <Box className={"roles-management"}>
        <div className={"edit-role-form"}>
          <h4>{isEditMode ? "Edit" : "Create"} Role</h4>
          <Loading />
        </div>
      </Box>
    )
  }

  const updateMemberList = (selectedItems: Array<IListBoxItem>) => {
    const userList = users.filter(user =>
      selectedItems.find(item => item.id === user.id.toString())
    )

    setRole(Object.assign({}, role, { userList }))
  }

  const updateTeamList = (selectedItems: Array<IListBoxItem>) => {
    const teamList = teams.filter(team =>
      selectedItems.find(item => item.id === team.id.toString())
    )

    setRole(Object.assign({}, role, { teamList }))
  }

  const performSaveRole = async () => {
    if (role.displayName.length === 0) {
      setErrors({
        displayName: "Please enter a display name",
      })
      setDisplayNameErrorOpen(true)
      return
    }

    const saveSuccess = await saveRole()
    if (!saveSuccess) {
      toast.error("There was a problem saving this role")
    } else {
      toast.success(`Role has been ${isEditMode ? "updated" : "created"}`)
      props.history.push("/settings/security/roles")
    }
  }

  const updatePermissionMap = (siteId: number, token: string, value: boolean | null) => {
    if (!role.explicitPermissions[siteId]) {
      role.explicitPermissions[siteId] = {}
    }

    if (value == null) {
      delete role.explicitPermissions[siteId][token]
    } else {
      role.explicitPermissions[siteId][token] = value
    }

    const updatedPermissions = {}
    Object.keys(role.explicitPermissions).forEach(siteId => {
      updatedPermissions[siteId] = Object.assign({}, role.explicitPermissions[siteId])
    })

    setRole(Object.assign({}, role, { explicitPermissions: updatedPermissions }))
  }

  return (
    <Box className={"roles-management"}>
      <div className={"edit-role-form"}>
        <h4 data-testid={"createrole"}>{isEditMode ? "Edit" : "Create"} Role</h4>

        <div className={"edit-role-form-general"}>
          {displayNameErrorOpen ? (
            <TextField
              error={true}
              id="outlined-error-helper-text"
              label="Error"
              variant={"outlined"}
              sx={{ ...errorTextFieldStyle }}
              value={role.displayName}
              onChange={e =>
                setRole(Object.assign({}, role, { displayName: e.currentTarget.value }))
              }
              helperText="Please enter a display name"
            />
          ) : (
            <TextField
              sx={{ ...textFieldStyle }}
              label={"Display Name"}
              value={role.displayName}
              onChange={e =>
                setRole(Object.assign({}, role, { displayName: e.currentTarget.value }))
              }
            />
          )}

          <TextField
            sx={{ ...textFieldStyle }}
            label={"Description"}
            value={role.description}
            onChange={e => setRole(Object.assign({}, role, { description: e.currentTarget.value }))}
          />

          {/*<Field defaultValue={"Description:"} isHorizontal isLarge>*/}
          {/*  <Textarea*/}
          {/*    rows={3}*/}
          {/*    value={role.description}*/}
          {/*    onChange={e => setRole(Object.assign({}, role, {description: e.currentTarget.value}))}*/}
          {/*  />*/}
          {/*</Field>*/}
        </div>

        <div className={"edit-role-form-membership"}>
          <div>
            <span className={"edit-role-form-section-header"}>User Membership</span>
            <DualListBox
              listItems={users.map(userListTransform)}
              selectedItems={role.userList.map(userListTransform)}
              onSelectedItemsChange={updateMemberList}
              loading={usersLoading}
              leftHeader={"Remaining Users"}
              rightHeader={"Role Members"}
            />
          </div>

          <div>
            <span className={"edit-role-form-section-header"}>Team Membership</span>
            <DualListBox
              listItems={teams.map(teamListTransform)}
              selectedItems={role.teamList.map(teamListTransform)}
              onSelectedItemsChange={updateTeamList}
              loading={teamsLoading}
              leftHeader={"Remaining Teams"}
              rightHeader={"Team Members"}
            />
          </div>
        </div>

        <PermissionField
          permissionMap={role.explicitPermissions}
          onPermissionChange={updatePermissionMap}
        />

        <div>
          <SaveButton onClick={performSaveRole}>Save Role</SaveButton>
          <CancelButton onClick={() => props.history.push("/settings/security/roles")}>
            Cancel
          </CancelButton>
        </div>
      </div>
    </Box>
  )
})

export { CreateEditRole }
