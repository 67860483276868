import { useState, useEffect, useContext, useCallback, useRef } from "react"
import { airscopeColor } from "../helpers/colours"
import { IPoint } from "../interfaces"
import { ViewerContext } from "../context"
import { circle, hoverCircle, blueCircle } from "../assets"
import { useEntityGroups } from "./viewer/useEntityGroups"
import { usePointSelector } from "./viewer/usePointSelector"
import { usePointDistanceCursor } from "./viewer/usePointDistanceCursor"
import { distanceToText } from "../helpers"
import { useLocalStorageBoolean } from "./useLocalStorage"
// @ts-ignore
import {
  CallbackProperty,
  Cartesian2,
  Cartesian3,
  Color,
  ColorMaterialProperty,
  CustomDataSource,
  EntityOptions,
  PolylineDashMaterialProperty,
  PolylineGraphics,
  Viewer,
} from "cesium"
import * as Cesium from "cesium"

interface IHistory {
  pointHistory: IPoint[][]
  historyOffset: number
}

interface ICartesianDistance {
  distance: Cartesian3
  vecX: Cartesian3
  vecY: Cartesian3
  vecZ: Cartesian3
}

const CartesianLineColours = {
  x: new Color(1.0, 0, 0, 0.8),
  y: new Color(0, 1.0, 0, 0.8),
  z: new Color(0, 0, 1.0, 0.8),
}

function createMidpointLabel(points: Cartesian3[], text: string, datasource: CustomDataSource) {
  const midpoint = Cartesian3.add(
    points[0],
    Cartesian3.multiplyByScalar(
      Cartesian3.subtract(points[1], points[0], new Cartesian3()),
      0.5,
      new Cartesian3()
    ),
    new Cartesian3()
  )

  const labelObj = {
    position: midpoint,
    label: {
      text,
      pixelOffset: new Cartesian2(0, -25),
      showBackground: true,
      font: "14px sans-serif",
      show: true,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
    },
  }

  datasource.entities.add(labelObj)
}

export const useMeasureMode = () => {
  const { viewersForEach, getTilesetCoordUnitVector } = useContext(ViewerContext)
  const [hoveredPoint, setHoveredPoint] = useState(null as null | IPoint)
  const [history, setHistory] = useState({ pointHistory: [], historyOffset: 0 } as IHistory)
  const [cartensianDistance, setCartensianDistance] = useState(
    [] as Array<ICartesianDistance | null>
  )
  const [enableCartesian, setEnableCartensian] = useLocalStorageBoolean(
    "measure-cartesian-enabled",
    true
  )
  const { datasources } = useEntityGroups()
  const { points, setPoints, clearPoints } = usePointSelector()
  usePointDistanceCursor(points)
  const shouldSkipHistoryUpdate = useRef(0)
  const hoveredPointRef = useRef(null as null | IPoint)

  useEffect(() => {
    const tileSetUnitVec = getTilesetCoordUnitVector()

    if (shouldSkipHistoryUpdate.current > 0) {
      shouldSkipHistoryUpdate.current--
    } else {
      setHistory(currentHistory => {
        const newHistory: IHistory = {
          pointHistory: currentHistory.pointHistory.slice(0, currentHistory.historyOffset + 1),
          historyOffset: 0,
        }
        newHistory.pointHistory.push(points)
        newHistory.historyOffset = newHistory.pointHistory.length - 1

        return newHistory
      })
    }

    const cartensianDistance: Array<ICartesianDistance | null> = points.map(
      (point: IPoint, idx: number) => {
        if (idx > 0 && tileSetUnitVec) {
          const previousPoint = points[idx - 1]
          const difference = Cartesian3.subtract(
            point.position.cartesian3,
            previousPoint.position.cartesian3,
            new Cartesian3()
          )

          const vecX = Cartesian3.projectVector(difference, tileSetUnitVec.uX, new Cartesian3())
          const vecY = Cartesian3.projectVector(difference, tileSetUnitVec.uY, new Cartesian3())
          const vecZ = Cartesian3.projectVector(difference, tileSetUnitVec.uZ, new Cartesian3())

          return {
            distance: new Cesium.Cartesian3(
              Cartesian3.magnitude(vecX),
              Cartesian3.magnitude(vecY),
              Cartesian3.magnitude(vecZ)
            ),
            vecX,
            vecY,
            vecZ,
          }
        } else {
          return null
        }
      }
    )
    setCartensianDistance(cartensianDistance)

    viewersForEach((viewer: Viewer, viewerIdx: number) => {
      points.forEach((point: IPoint, idx: number) => {
        const targetObj: EntityOptions = {
          id: point.id + "-target",
          position: point.position.cartesian3,
          billboard: {
            image: new CallbackProperty(() => {
              if (hoveredPointRef.current === point) {
                return hoverCircle
              }
              if (idx === points.length - 1) {
                return blueCircle
              }
              return circle
            }, false),
            width: 10,
            height: 10,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            show: true,
            color: new Color(1.0, 1.0, 1.0, 0.9),
          },
        }

        if (idx > 0 && point.distance) {
          const previousPoint = points[idx - 1]

          const lineColourCallback = new CallbackProperty(() => {
            if (hoveredPointRef.current === previousPoint || hoveredPointRef.current === point) {
              return new Color(1.0, 1.0, 1.0, 0.9)
            }
            return airscopeColor.withAlpha(0.9)
          }, false)

          targetObj.polyline = new PolylineGraphics({
            positions: [previousPoint.position.cartesian3, point.position.cartesian3],
            material: new ColorMaterialProperty(lineColourCallback),
            depthFailMaterial: new PolylineDashMaterialProperty({ color: lineColourCallback }),
            followSurface: false,
            width: 2,
          })

          createMidpointLabel(
            [point.position.cartesian3, previousPoint.position.cartesian3],
            distanceToText(point.distance),
            datasources.current[viewerIdx]
          )

          // Cartesian polylines
          const cartDistance = cartensianDistance[idx]

          if (enableCartesian && tileSetUnitVec && cartDistance) {
            const positions = [previousPoint.position.cartesian3]
            positions.push(Cartesian3.add(positions[0], cartDistance.vecX, new Cartesian3()))
            positions.push(Cartesian3.add(positions[1], cartDistance.vecY, new Cartesian3()))
            positions.push(Cartesian3.add(positions[2], cartDistance.vecZ, new Cartesian3()))

            datasources.current[viewerIdx].entities.add({
              polyline: {
                positions: positions.slice(0, 2),
                width: 1,
                material: CartesianLineColours.x,
                depthFailMaterial: new PolylineDashMaterialProperty({
                  color: CartesianLineColours.x.withAlpha(0.9),
                }),
              },
            })

            datasources.current[viewerIdx].entities.add({
              polyline: {
                positions: positions.slice(1, 3),
                width: 1,
                material: CartesianLineColours.y,
                depthFailMaterial: new PolylineDashMaterialProperty({
                  color: CartesianLineColours.y.withAlpha(0.9),
                }),
              },
            })

            datasources.current[viewerIdx].entities.add({
              polyline: {
                positions: positions.slice(2, 4),
                width: 1,
                material: CartesianLineColours.z,
                depthFailMaterial: new PolylineDashMaterialProperty({
                  color: CartesianLineColours.z.withAlpha(0.9),
                }),
              },
            })

            createMidpointLabel(
              positions.slice(0, 2),
              "X " + distanceToText(cartDistance.distance.x),
              datasources.current[viewerIdx]
            )

            createMidpointLabel(
              positions.slice(1, 3),
              "Y " + distanceToText(cartDistance.distance.y),
              datasources.current[viewerIdx]
            )

            createMidpointLabel(
              positions.slice(2, 4),
              "Z " + distanceToText(cartDistance.distance.z),
              datasources.current[viewerIdx]
            )
          }
        }

        datasources.current[viewerIdx].entities.add(targetObj)
      })

      viewer.scene.requestRender()
    })
    return () => {
      viewersForEach((viewer: Viewer, viewerIdx: number) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        datasources.current[viewerIdx].entities.removeAll()
        viewer.scene.requestRender()
      })
    }
  }, [viewersForEach, points, enableCartesian, getTilesetCoordUnitVector, datasources])

  useEffect(() => {
    hoveredPointRef.current = hoveredPoint
    viewersForEach((viewer: Viewer) => setTimeout(() => viewer.scene.requestRender(), 10))
  }, [viewersForEach, hoveredPoint])

  /*
   *	History
   */

  const undo = useCallback(() => {
    if (history.historyOffset <= 0) {
      return
    }

    shouldSkipHistoryUpdate.current++
    setHistory(currentHistory => {
      const newHistory: IHistory = {
        pointHistory: currentHistory.pointHistory.slice(0),
        historyOffset: currentHistory.historyOffset - 1,
      }
      setPoints(newHistory.pointHistory[newHistory.historyOffset])
      return newHistory
    })
  }, [history.historyOffset, setPoints])

  const redo = useCallback(() => {
    if (history.historyOffset >= history.pointHistory.length - 1) {
      return
    }

    shouldSkipHistoryUpdate.current++
    setHistory(currentHistory => {
      const newHistory: IHistory = {
        pointHistory: currentHistory.pointHistory.slice(0),
        historyOffset: currentHistory.historyOffset + 1,
      }
      setPoints(newHistory.pointHistory[newHistory.historyOffset])
      return newHistory
    })
  }, [history.historyOffset, history.pointHistory.length, setPoints])

  return {
    points,
    setPoints,
    clearPoints,
    setHoveredPoint,
    hoveredPoint,
    history,
    undo,
    redo,
    cartensianDistance,
    enableCartesian,
    setEnableCartensian,
  }
}
