import * as React from "react"
import { Box } from "trunx"

export const InstanceSettings = () => {
  return (
    <Box className={"instance-settings"}>
      <h4>Instance Settings</h4>
    </Box>
  )
}
