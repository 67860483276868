import { useEffect, useState } from "react"
import { IPermissionFieldDefault, IPermissionsMap, IUser } from "../../interfaces"
import {
  apiAuthAcceptdisclaimer,
  apiAuthCheck,
  apiHostname,
  apiPermissionsDefaults,
} from "../constants"
import { api } from "../api"
import { toast } from "react-toastify"
import axios from "axios"

export const useApiDefaultPermissions = () => {
  const [defaultPermissions, setDefaultPermissions] = useState([] as Array<IPermissionFieldDefault>)
  const [defaultPermissionsLoading, setLoading] = useState(true)

  useEffect(() => {
    api
      .get(apiPermissionsDefaults)
      .then(response => {
        setDefaultPermissions(response.default)
      })
      .catch(error => {
        toast.error("There was a problem fetching the default permissions")
      })

    setLoading(false)
  }, [])

  return { defaultPermissions, defaultPermissionsLoading }
}

export const useCurrentUser = () => {
  const [currentUserPermissions, setCurrentUserPermissions] = useState([] as IPermissionsMap)
  const [currentUser, setCurrentUser] = useState({} as IUser)
  const [currentUserPermissionsLoading, setLoading] = useState(true)
  const [acceptDisclaimer, setAcceptDisclaimer] = useState(true)

  const token: string = localStorage.getItem("auth")
  const url: string = apiHostname + "/api" + apiAuthCheck
  axios.defaults.withCredentials = true

  useEffect(() => {
    axios
      .get(url, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      })
      .then(response => {
        if (response.status === 401) {
          setCurrentUser(null)
          localStorage.removeItem("userData")
          localStorage.removeItem("auth")
        }
        setCurrentUser(response.data.user)
        setCurrentUserPermissions(response.data.permissions)
        setAcceptDisclaimer(response.data.user.data.agreedToDisclaimer)
      })
      // tslint:disable-next-line:no-empty
      .catch(error => {})
    setLoading(false)
  }, [])

  return { currentUserPermissions, currentUser, currentUserPermissionsLoading, acceptDisclaimer }
}

export async function UseAcceptDisclaimer() {
  const token: string = localStorage.getItem("auth")
  const url: string = apiHostname + "/api" + apiAuthAcceptdisclaimer
  let result = false
  axios.defaults.withCredentials = true

  axios
    .post(url, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      withCredentials: true,
    })
    .then(response => {
      if (response.data.data.includes('agreedToDisclaimer":true')) result = true
      else result = false
    })
    .catch(error => {
      toast.error("There was a problem fetching the default permissions")
    })

  return result
}
