/* tslint:disable:jsx-no-lambda */
import { Fragment, useMemo } from "react"
import { useMeasureMode } from "../../hooks/useMeasureMode"
import { onKeyPress } from "../../hooks/useKeyPress"
import { VizTransition } from "../VizTransition"
import { Button } from "trunx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const Measure = () => {
  const {
    points,
    setPoints,
    hoveredPoint,
    setHoveredPoint,
    history,
    undo,
    redo,
    enableCartesian,
    setEnableCartensian,
  } = useMeasureMode()

  onKeyPress("z", (e: KeyboardEvent) => {
    if (e.type === "keydown" && e.ctrlKey) {
      undo()
    }
  })

  onKeyPress("y", (e: KeyboardEvent) => {
    if (e.type === "keydown" && e.ctrlKey) {
      redo()
    }
  })

  const content = useMemo(() => {
    if (points.length === 0) {
      return (
        <VizTransition>
          <p style={{ marginTop: "10px" }}>Click on the model to start measuring.</p>
        </VizTransition>
      )
    }
    return points.map((point, idx) => {
      const nextPoint = idx < points.length - 1 ? points[idx + 1] : null

      if (idx > 0 && nextPoint == null) {
        return null
      }

      return (
        <VizTransition timeout={200} key={"css-target-path-" + idx}>
          <div className={"measure-results"}>
            <div className="measure-mode-line" key={"target-path-" + idx}>
              <>
                <Button
                  key={"button-target-path-" + idx}
                  isText={true}
                  className="measure-icon"
                  onClick={() => setPoints(points => points.filter(p => p !== point))}
                  onMouseOver={() => setHoveredPoint(point)}
                  onMouseOut={() => setHoveredPoint(p => (p === point ? null : p))}
                >
                  <FontAwesomeIcon icon={point === hoveredPoint ? "times" : "circle"} />
                </Button>
                {nextPoint && (
                  <Fragment>
                    <div className={"point-line"}>
                      {nextPoint.distance && nextPoint.distance.toFixed(2)}m
                    </div>
                    <Button
                      key={"button-target-path-2-" + idx}
                      isText={true}
                      className="measure-icon"
                      onClick={() => setPoints(points => points.filter(p => p !== nextPoint))}
                      onMouseOver={() => setHoveredPoint(nextPoint)}
                      onMouseOut={() => setHoveredPoint(p => (p === nextPoint ? null : p))}
                    >
                      <FontAwesomeIcon icon={nextPoint === hoveredPoint ? "times" : "circle"} />
                    </Button>
                  </Fragment>
                )}
              </>
            </div>
          </div>
        </VizTransition>
      )
    })
  }, [points, hoveredPoint, setPoints, setHoveredPoint])

  const totalDistance = useMemo(
    () => points.reduce((dist, point) => (dist += point.distance || 0), 0).toFixed(2),
    [points]
  )

  return (
    <div className="card-content">
      <div className="measure-actions">
        <Button isActive={history.historyOffset !== 0} onClick={undo} className={"measure-icons"}>
          <FontAwesomeIcon icon={"undo"} />
        </Button>
        <Button
          isActive={history.historyOffset !== history.pointHistory.length - 1}
          onClick={redo}
          className={"measure-icons"}
        >
          <FontAwesomeIcon icon={"redo"} />
        </Button>
        <Button
          onClick={() => setEnableCartensian(!enableCartesian)}
          style={
            enableCartesian
              ? { padding: "0 5px", border: "1px solid #faa21b" }
              : { padding: "0 5px" }
          }
        >
          <img
            style={{ width: "28px" }}
            src={"assets/icons/xyz_icon.png"}
            alt="Display XYZ Measurements"
          />
        </Button>
      </div>

      {content}

      {points.length > 0 && (
        <div className="measure-actions">
          <div style={{ marginLeft: "10px", marginTop: "10px" }}>Total: {totalDistance}m</div>
        </div>
      )}
    </div>
  )
}
