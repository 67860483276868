import * as React from "react"
import { Section } from "trunx"
import * as Sentry from "@sentry/browser"

interface IProps {
  children: React.ReactNode
}
interface IState {
  error: Error | null
  errorInfo: React.ErrorInfo | null
  hasError: boolean
}
export class ErrorBoundary extends React.Component<IProps, IState> {
  state: IState = {
    error: null,
    errorInfo: null,
    hasError: false,
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      errorInfo,
      hasError: true,
    })
    // this won't work in develop due to blacklistUrls
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      console.log(Sentry.captureException(error), "Sentry capture")
    })
  }
  render() {
    if (this.state.hasError) {
      if (!this.state.errorInfo) {
        return (
          <Section>
            <h1>Something went wrong.</h1>
          </Section>
        )
      }
      if (!this.state.error) {
        return (
          <Section>
            <h1>Something went wrong.</h1>
          </Section>
        )
      }
      return (
        <Section>
          <h1>Something went wrong.</h1>
          <h2>{this.state.error.message}</h2>
          <pre>{this.state.errorInfo.componentStack}</pre>
        </Section>
      )
    }

    return React.Children.only(this.props.children)
  }
}
