/* tslint:disable:jsx-no-lambda */
import { useState } from "react"
import { FeedbackForm } from "./FeedbackForm"
import FeedbackSVG from "../../assets/img/feedback.svg"
import SVG from "react-inlinesvg"

export const Feedback = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div className="feedback-button" onClick={() => !isOpen && setOpen(true)}>
      <SVG src={FeedbackSVG} />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a>
        Leave
        <br />
        Feedback
      </a>

      {isOpen && (
        <FeedbackForm
          // @ts-ignore
          onClose={() => setOpen(false)}
          initialModel={{
            phoneNumber: "",
            callback: false,
            feedbackType: "idea",
            screenshot: null,
          }}
        />
      )}
    </div>
  )
}
